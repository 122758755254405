import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,
	CButton,
	CFormInput,
	CInputGroup,
	CLink,
	CTooltip,
	//CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
//import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubpenggunacabang = (props) => {
	/*const {
		setToken,setLoading, showToast 
	} = props//-*/

	//--DOM--/
	//const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	//const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	
	const [uInpkeyword,setInpkeyword]= React.useState(uKeywordObj.kwd_penggunacabang||"");

	const hdlKeydown = (_EV) => {
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		const vHeaderTmpObj	= uHeaderActionObj;
		uDispatch({type: "set", gInitHeaderAction: {}});//-->HARUS_DIKOSONGIN_DULU_BIAR_ADA_EFFECT--/

		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_penggunacabang = uInpkeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		vHeaderTmpObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: vHeaderTmpObj});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_penggunacabang = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpkeyword("");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);

			const vHeaderTmpObj	= uHeaderActionObj;
			uDispatch({type: "set", gInitHeaderAction: {}});
			vHeaderTmpObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: vHeaderTmpObj});
		}

		if((uInpkeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubpenggunacabang - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		<small>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" 
				value={uInpkeyword} 
				onChange={(e) => setInpkeyword(e.target.value)} 
				onKeyDown={(e) => hdlKeydown(e)} 
				style={{minWidth:270,width:"auto"}} 
				placeholder={"--"+(uBahasaObj.petunjuk_keyword||"Masukkan uKeyword")+".."} 
				id="inpkeyword"/>
			<CButton type="button" color="secondary" onClick={()=>hdlKlikCari()} id="btnCari" className="py-0">
				<CIcon icon="cilMagnifyingGlass" className="text-white" />
			</CButton>
			</CInputGroup>
			</CForm>
			<span className="mx-1 d-none d-lg-block">&middot;</span>

			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="ms-2 ms-md-0 classikon classikonreset classcursorpointer" />
			</CTooltip>
				
			<span className="me-2 d-none d-md-block">&middot;</span>
			<span className="d-none d-md-block">
			<b id="idjmldata" className="text-primary">0</b>{" "}{uBahasaObj.word_data||"Data"}
			</span>			
		</div>
		</>
	)
}

export default Headersubpenggunacabang
