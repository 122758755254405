import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogview,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
//const isMobile 	= false;
const Penggunacabang = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uUsersettingObj			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? (uUsersettingObj.jml_mobile || 7) : (uUsersettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [uIsShowview,setShowDlgview]	= React.useState(false);
	const [uDlgviewheader,setHeaderdlg]	= React.useState("");
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlgview = () =>{ setShowDlgview(false) }
	const hdlChangeCabangall	= (_EVN,_IDTABEL,_INITGUDANG) => {
		_INITGUDANG		= _INITGUDANG || "TIDAK";
		_IDTABEL 		= parseInt(_IDTABEL||0);if(_IDTABEL <= 0) return;

		const vArridx	= uDatamainArr.findIndex(vItems =>
			parseInt(vItems.id) === parseInt(_IDTABEL) && vItems.init_usergudang === _INITGUDANG
		);
		const vIscheckedall = _EVN.target.checked;
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		//--PROSES_API--/
		apiProsescheckall(vArridx,vIscheckedall);
		//--END PROSES_API--/
	}
	const hdlChangeCabangakses	= (_EVN,_IDTABEL,_INITGUDANG) => {
		_INITGUDANG		= _INITGUDANG || "TIDAK";
		_IDTABEL = parseInt(_IDTABEL||0);if(_IDTABEL <= 0) return;
		const vValueID	= _EVN.target.value;
		const vIschecked= _EVN.target.checked;

		const vArridx	= uDatamainArr.findIndex(vItems =>
			parseInt(vItems.id) === _IDTABEL && vItems.init_usergudang === _INITGUDANG
		);
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_INITGUDANG+_IDTABEL)
		setRowselect(vIdxRow);

		//--PROSES_API--/
		apiProsescheckrow(vArridx,vValueID,vIschecked)
		//--END PROSES_API--/
	}

	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(User - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setIDselect(0)
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		let vCaptionUpper	= "";
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_penggunacabang || "");
		const vKeywordcolor = uUsersettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		const vTokencabangidArr = (uTokenObj.usercabangid||"").split(",") || 
			uDatacabangArr.map(vItems=>(vItems.id||"0").toString());
		const vCabangAllowArr = 
			uTokenObj.userhak==="SUPERADMIN" ? uDatacabangArr
			: uDatacabangArr.filter(vItems => 
				vTokencabangidArr.includes((vItems.id||"").toString()) )

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1 text-start">{uBahasaob.caption_datapengguna||"Data User"}</th>
			<th width="5%" className="px-1">&middot;</th>
			<th width="67%" className="px-1 d-none d-md-table-cell text-start">
				{uBahasaob.word_cabang||"Unit Cabang"}
			</th>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,username,usercaption,hak,cabangid_arr,init_process,
				init_usergudang,
			} = vItems;

			vNumber++;
			vCaptionUpper	= (usercaption||"").toUpperCase();

			const vLangshak	= uBahasaob["hakuser_"+(hak||"").toLowerCase()] || (hak||"UNDF");
			const vUsername	= vKeyword==="" ? (username||"") 
				: (username||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsercaption	= vKeyword==="" ? (usercaption||"") 
				: (usercaption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserhak	= vKeyword==="" ? (vLangshak) 
				: (vLangshak).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vCabangidArr= cabangid_arr || [];
			let vIscheckedall = true;
			vCabangAllowArr.forEach(vItemcabang => {
				if(!vCabangidArr.includes(vItemcabang.id)) {
					vIscheckedall = false;
				} 
			});
			const vCabangapproveArr	= (uDatacabangArr.filter((vItemcabang)=>{
				return (vCabangidArr.includes(vItemcabang.id))
			})).map((vItemcabang,vKeycabang)=>{
				return vItemcabang.nama_cabang;
			})
			
			return (
			<tr id={"idtr"+init_usergudang+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
				<td className="px-1">{vNumber}.</td>
				<td className="px-1 text-start">
					<span className="text-info">{UFunc.renderHTML(vUsercaption)}</span>
					<span className="mx-1">&middot;</span>
					<small className="text-primary fw-bold">({UFunc.renderHTML(vUsername)})</small>
					<div>{uBahasaob.caption_hakakses||"Hak Akses"}: <span className="text-success">{UFunc.renderHTML(vUserhak)}</span></div>
				</td>
				
				<td className="px-1 text-center">
				{((init_usergudang||"TIDAK")=="YA" 
					&& uIsHapusallow
				) && (
				<>
					<div className="d-none d-md-block">
					{(init_process||false)===true && (
						<CSpinner color="dark" size="sm"/>
					)}
					{(init_process||false)===false && (
						<CFormCheck size="sm"
							value="ALL"
							checked={vCabangidArr.length === vTokencabangidArr.length}
							onChange={(e)=>hdlChangeCabangall(e,id,init_usergudang)}
							id={"inpcabangall"+id}/>
					)}
					</div>

					<div className="d-md-none">
						<CLink
							onClick={()=>{setIDselect(id);setShowDlgview(true)}}
							className="classcursorpointer py-1 px-2 border rounded bg-dark text-white">
							<CIcon icon="cilBuilding" height={15}/>
						</CLink>
					</div>
				</>
				)}
				</td>

				<td className="px-1 px-md-0 align-top text-md-start d-none d-md-table-cell">
				{((init_usergudang||"TIDAK")!=="YA" 
					|| !uIsHapusallow
				) ? (
				<>
				{vCabangapproveArr.map((vItemcabang,vKeycabang)=>{
					return (
					<span key={vKeycabang}>
						<b>{vItemcabang}</b>
						{vKeycabang<vCabangapproveArr.length-1 && (
						<>
						<span className="ms-1">&middot;</span>
						{" "}
						</>
						)}
					</span>
					)
				})}
				{vCabangapproveArr.length <= 0 && (<small className="text-primary">UNSET</small>)}
				</>
				) : (
				<>
				{vCabangAllowArr.map((vItemcabang,vKeycabang)=>{
					return (
					<div style={{minWidth:200}}
						key={vKeycabang}
						className="d-inline-block ps-2 pe-1 bg-light border rounded me-2 mb-2">
					<CFormCheck size="sm" inline
						value={vItemcabang.id}
						label={vItemcabang.nama_cabang}
						checked={vCabangidArr.includes(vItemcabang.id)}
						onChange={(e)=>hdlChangeCabangakses(e,id,init_usergudang)}
						disabled={(init_process||false)}
						name={"inpcabangid"+id}
						id={"inpcabangid"+id+"_"+vItemcabang.id}/>
					</div>
					)
				})}
				</>
				)}
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgview = () => {
		if(!uIsShowview) return (<></>)
		if(parseInt(uIDselect) <= 0) return (<></>);

		const vArridx		= UFunc.getObjectIndeks(uDatamainArr,uIDselect);
		const vCabangidArr	= uDatamainArr[vArridx].cabangid_arr || [];

		const vInisproses	= uDatamainArr[vArridx].init_process || false;

		const vTokencabangidArr = uTokenObj.usercabangid || uDatacabangArr.map(vItems=>(vItems.id||"0").toString());
		//const vTokencabangidArr = uTokenObj.user_cabangarr || [];
		const vCabangAllowArr = uDatacabangArr.filter(vItems => 
			vTokencabangidArr.includes((vItems.id||"").toString())
		)

		let vIscheckedall = true;
		vCabangAllowArr.forEach(vItems => {
			if(!vCabangidArr.includes(vItems.id)) {
				vIscheckedall = false;
			} 
		});

		return (
		<CForm>
		<CRow className="my-2">
		<CCol xs="12" className="d-flex justify-content-between py-2">
			{uBahasaob.caption_aksescabang||"Akses Unit Cabang"}
			<div>
				{vInisproses===true && (
					<CSpinner color="dark" size="sm"/>
				)}
				{vInisproses===false && (
				<CFormCheck inline size="sm"
					value="ALL"
					id={"inpformcabangall"}
					checked={vIscheckedall}
					onChange={(e)=>hdlChangeCabangall(e,uIDselect)}
					className=""/>
				)}
			</div>
		</CCol>
		<CCol xs="12" className="">
			<CInputGroup size="sm">
			{vCabangAllowArr.map((vItems,vKeys)=>{
			return (
				<div key={vKeys}
					className="d-inline-block ps-2 pe-1 bg-light border rounded me-2 mb-2"
					style={{minWidth:150}}>
				<CFormCheck inline size="sm"
					value={vItems.id}
					id={"inpformcabangid"+vItems.id}
					disabled={vInisproses} 
					label={vItems.nama_cabang}
					checked={vCabangidArr.includes(vItems.id)}
					onChange={(e)=>hdlChangeCabangakses(e,uIDselect)}
					className="classcursorpointer"/>
				</div>
			)
			})}
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= [
			{id:"1",usercaption:"Nama Pengguna 1",username:"penggunaone",hak:"PENGELOLA",
				cabangid_arr:["1","3","5","7","9"]},
			{id:"2",usercaption:"Nama Pengguna 1",username:"penggunaone",hak:"PENGELOLA",
				cabangid_arr:["1","3","5","7","9"]},
			{id:"3",usercaption:"Nama Pengguna 1",username:"penggunaone",hak:"PENGELOLA",
				cabangid_arr:["1","3","5","7","9"]},
			{id:"4",usercaption:"Nama Pengguna 1",username:"penggunaone",hak:"PENGELOLA",
				cabangid_arr:["1","3","5","7","9"]},
			{id:"5",usercaption:"Nama Pengguna 1",username:"penggunaone",hak:"PENGELOLA",
				cabangid_arr:["1","3","5","7","9"]},
			{id:"6",usercaption:"Nama Pengguna 1",username:"penggunaone",hak:"PENGELOLA",
				cabangid_arr:["1","3","5","7","9"]},
			{id:"7",usercaption:"Nama Pengguna 1",username:"penggunaone",hak:"PENGELOLA",
				cabangid_arr:["1","3","5","7","9"]},
		]

		let vTimeout = setTimeout(()=>{
			setDatamainArr(vTmpObj);
			setJmlData((parseInt(vTmpObj.length)||0));
			setEfekview(!uEfekview);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_penggunacabang || "";
		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_penggunacabang/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		//}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsescheckall = (_IDXDATA,_VALUECHECKED) => {
		const vArridx		= parseInt(_IDXDATA);
		_VALUECHECKED		= _VALUECHECKED||false;

		const vBtnsimpan	= document.getElementById("btnDialogviewok");
		const vTokencabangidArr = uTokenObj.userhak!=="SUPERADMIN" 
			? (uTokenObj.usercabangid||"").split(",")
			: uDatacabangArr.map(vItems=>(vItems.id||"0").toString());
		const vCabanginvisibleArr 	= uDatamainArr[vArridx].cabanginvisible_arr || [];
		//console.log("(Penggunacabang - apiProsescheckall) vCabanginvisibleArr = "+JSON.stringify(uDatamainArr[vArridx].cabanginvisible_arr));

		let vTmpAll		= vCabanginvisibleArr;
		if(_VALUECHECKED) {
			vTokencabangidArr.forEach(vItems => vTmpAll = [...vTmpAll,vItems]);
		}

		uDatamainArr[vArridx].init_process	= true;
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)

			showToast(JSON.stringify(vTmpAll));
			uDatamainArr[vArridx].cabangid_arr = vTmpAll;
			setEfekview2(!uEfekview2);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].id,
			send_initgudang : uDatamainArr[vArridx].init_usergudang,
			send_cabangidlist : vTmpAll.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_penggunacabang/pr_checked";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		//}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				uDatamainArr[vArridx].cabangid_arr = vTmpAll;
				setEfekview2(!uEfekview2);
			} else {
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Penggunacabang - apiProsescheckall) output_string.errors : "+output_string.errors);
					const vMsg 	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setShowDlgview(false); setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setEfekview2(!uEfekview2);

			console.log("(Penggunacabang - apiProsescheckall) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsescheckrow = (_IDXDATA,_VALUEID,_ISCHECKED) => {
		const vArridx	= parseInt(_IDXDATA);
		_VALUEID		= parseInt(_VALUEID||0).toString();
		_ISCHECKED		= _ISCHECKED||false;

		const vBtnsimpan= document.getElementById("btnDialogviewok");
		let vTmpChkArr	= uDatamainArr[vArridx].cabangid_arr || [];
		if(_ISCHECKED) {
			vTmpChkArr	= [...vTmpChkArr,_VALUEID]
		} else {
			const vIdx = vTmpChkArr.findIndex(vItems => vItems.toString()===_VALUEID.toString());
			vTmpChkArr.splice(vIdx,1);
		}

		uDatamainArr[vArridx].init_process	= true;
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		console.log("(Penggunacabang - apiProsescheckrow) vTmpChkArr = "+JSON.stringify(vTmpChkArr))
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			uDatamainArr[vArridx].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)

			showToast(JSON.stringify(vTmpChkArr));
			uDatamainArr[vArridx].cabangid_arr = vTmpChkArr;
			setEfekview2(!uEfekview2);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uDatamainArr[vArridx].id,
			send_initgudang : uDatamainArr[vArridx].init_usergudang,
			send_cabangidlist : vTmpChkArr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_penggunacabang/pr_checked";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		//}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				uDatamainArr[vArridx].cabangid_arr = vTmpChkArr;
				setEfekview2(!uEfekview2);
			} else {
				setEfekview2(!uEfekview2);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Penggunacabang - apiProsescheckrow) output_string.errors : "+output_string.errors);
					const vMsg 	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setShowDlgview(false); setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].init_process	= false;
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setEfekview2(!uEfekview2);

			console.log("(Penggunacabang - apiProsescheckrow) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		setShowDlgview(false);
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{ 
		setJmlData(uDatamainArr.length);

		if(uDatamainArr.length <= 0) return;

		const vTokencabangidArr = uTokenObj.userhak!=="SUPERADMIN" 
			? (uTokenObj.usercabangid||"").split(",")
			: uDatacabangArr.map(vItems=>(vItems.id||"0").toString());

		console.log("(Penggunacabang - [uDatamainArr]) vTokencabangidArr => "+JSON.stringify(vTokencabangidArr))
		uDatamainArr.map((vItems,vKeys)=>{
			const vCabangIDArr 			=  vItems.cabangid_arr || [];
			const vCabangIDinvisibleArr = vCabangIDArr.filter(vItems=>
				!vTokencabangidArr.includes(vItems.toString())
			)

			uDatamainArr[vKeys].cabanginvisible_arr = vCabangIDinvisibleArr;
		})
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		/*const vKeyword = uKeywordObj.kwd_penggunacabang || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("User - [uHandelTambah] 1 => "+uHandelTambah);
		if(!uHandelTambah) return;

		setHeaderdlg((uBahasaob.word_tambah||"Tambah")+" "+uBahasaob["menus_"+uActiveroute.toLowerCase()]);

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		//console.log("User - [uHandelTambah] 2 => "+uHandelTambah);
		setShowDlgview(true);
	},[uHandelTambah])
	React.useEffect(()=>{
		if(!uIsShowview) { initFormAwal(); return; }

		const vArridx = UFunc.getObjectIndeks(uDatamainArr,uIDselect)
		setHeaderdlg("Akses User: "+uDatamainArr[vArridx].usercaption);

		//console.log("("+cComponentName+"[uIsShowview]) uIsShowview 1: "+uIsShowview);
	},[uIsShowview])
	//console.log("User - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) {
		setLoading(true); prosesExpired(); return;
	}
	if(uHtml500msg)  return (
		<MyPage500 content={uHtml500msg}/>
	)

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<strong>{uBahasaob.caption_listdata||"List Data"}</strong>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeelapse)}ms</small>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uIsShowview} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggledlgview}
			renderContent={contentDlgview()}/>
		</>
	)
}	

export default Penggunacabang