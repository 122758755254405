import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CButton,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 			= require('../../package.json')
const Billingtransaksi 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? 20 : 100;
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	//const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--LOAD_BY_PAGE--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//--END LOAD_BY_PAGE--/

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	//const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikHistori = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		uDispatch({type: "set", gInitTabelSelected: {}});
		initSelectrow(_IDTABEL);

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vTmpObj	= {"initID":_IDTABEL,"initPage":uPageActive,}
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uDatamainArr[vArridx].status_processing = true;
		uNavigate("/billingtransaksi/pasienhistori/"+_IDTABEL);
	}
	const hdlKlikProsesbilling = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		uDispatch({type: "set", gInitTabelSelected: {}});
		initSelectrow(_IDTABEL);
		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vTmpObj	= {"initID":_IDTABEL,"initPage":uPageActive,}
		uDispatch({type: "set", gInitTabelSelected: vTmpObj});

		uDatamainArr[vArridx].status_processing = true;
		uNavigate("/billingtransaksi/formbilling/0");
	}
	//--END HANDEL--/

	//--INIT--/
	const initResetview = () => {
    	setPageActive(1);
    	setJmlData(0);
    	setDatamainArr([]); 
    	setDataselect({});
    	setRowselect(-1);
    	setEfekview(!uEfekview);
    }
	const initJumlahdata = () => {
		const vElJmlmobile	= document.getElementById("idjmldatamobile");
		const vKeyword 		= uKeywordObj.kwd_billingpasien || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			vElJmlmobile && (vElJmlmobile.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		vElJmlmobile && (vElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_billingpasien || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=>vItems.pg === uPageActive)
		//uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="align-top px-0 pr-1 classfontsmaller">No</th>
			<th className="align-top px-0 classfontsmaller">
			<CRow className="mx-0">
			<CCol md="4" className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol md="3" className="text-start">&middot;</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,nama_pasien,panggilan,sex,alamat,umur,
				no_telp,pekerjaan,cabang_register,status_processing,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (nama_pasien||"vCaptionupper").toUpperCase();
			const vCaptionsex	= (sex||"sex")[0];
			const vCaptionumur	= (umur||"umur").toUpperCase();

			const vColorsex		= (sex||"")[0] === "L" ? "752421" : "FE47F3";

			const vNamapasien	= vKeyword==="" ? (nama_pasien||"nama_pasien") 
				: (nama_pasien||"nama_pasien").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPanggilan	= vKeyword==="" ? (panggilan||"panggilan") 
				: (panggilan||"panggilan").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vCabangregister	= vKeyword==="" ? (cabang_register||"cabang_register") 
				: (cabang_register||"cabang_register").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vAlamat	= vKeyword==="" ? (alamat||"alamat") 
				: (alamat||"alamat").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTelepon	= vKeyword==="" ? (no_telp||"no_telp") 
				: (no_telp||"no_telp").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPekerjaan	= vKeyword==="" ? (pekerjaan||"pekerjaan") 
				: (pekerjaan||"pekerjaan").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 align-top text-end">{(vNumber)}.</td>

			<td className="px-2 px-md-0 align-top text-end">
			<CRow className="mx-0">
			<CCol md="4" className="d-none d-md-block px-1 text-md-start">
				<span className="text-info text-capitalize">{UFunc.renderHTML(vNamapasien)}</span>
				{(panggilan||"")!=="" && (
				<>
				<span className="mx-1">&middot;</span>
				<small className="fst-italic text-capitalize">{UFunc.renderHTML(vPanggilan)}</small>
				</>
				)}

				<div>
				<small>-{UFunc.renderHTML(vCabangregister)}-</small>
				</div>
			</CCol>

			<CCol md="3" className="px-1 text-md-start d-none d-md-block">
				<b style={{color:"#"+vColorsex}} className="text-uppercase">[{vCaptionsex}]</b>
				
				{(umur||"")!=="" && (
				<>
				<span className="mx-1">&middot;</span>
				<small className="text-capitalize">{vCaptionumur}</small>
				</>
				)}
				
				{(pekerjaan||"")!=="" && (
				<>
				<span className="mx-1">&middot;</span>
				<small className="text-capitalize">{UFunc.renderHTML(vPekerjaan)}</small>
				</>
				)}
			</CCol>

			<CCol md="3" className="px-1 text-md-start d-none d-md-block">
				<small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small>
				<div>Telp/HP: <b className="text-uppercase">{UFunc.renderHTML(vTelepon)}</b></div>
			</CCol>

			<CCol md="2" className="px-0 text-md-center d-none d-md-block">
				{(status_processing||false)===true ? (
				<CSpinner color="success"/>
				) : (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_historipembelian||"Histori Pembelian")+": "+vCaptionupper}>
					<CLink className="classcursorpointer"
						onClick={()=>hdlKlikHistori(id)}>
					<CImage src={pjson.homepage+"api/images/icon_histori.png"} className="classikonbox" height={27}/>
					</CLink>
				</CTooltip>

				<CTooltip content={"--"+(uBahasaObj.caption_prosesbilling||"Proses Billing")+": "+vCaptionupper}>
					<CLink className="classcursorpointer"
						onClick={()=>hdlKlikProsesbilling(id)}>
					<CImage src={pjson.homepage+"api/images/icon_kasir.png"} 
						className="classikonbox" 
						style={{
							borderColor:"#229954",
							color:"#229954",
							backgroundColor:"#22995455",
						}}
						height={27}/>
					</CLink>
				</CTooltip>
				</>
				)}
			</CCol>

			<CCol xs="12" className="px-1 d-md-none">
			<div className="d-flex justify-content-between">
				<span className="text-info text-capitalize">{UFunc.renderHTML(vNamapasien)}</span>

				<CDropdown className="d-md-none align-items-start">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikHistori(id)}>
						<CImage src={pjson.homepage+"api/images/icon_histori.png"} className="classikonbox" height={25}/>
						{uBahasaObj.caption_historipembelian||"Histori Pembelian"}
					</CDropdownItem>
				</CDropdownMenu>
				</CDropdown>
			</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="6" className="px-1 d-md-none text-start"><small>{uBahasaObj.caption_cabangregister||"Cabang Register"}</small></CCol>
			<CCol xs="6" className="px-1 d-md-none">
				<small className="classfontsmaller">{UFunc.renderHTML(vCabangregister)}</small>
			</CCol>

			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_sex||"Sex"}</CCol>
			<CCol xs="6" className="px-1 d-md-none">
				<b style={{color:"#"+vColorsex}} className="text-uppercase">[ {vCaptionsex} ]</b>
			</CCol>

			{(umur||"")!=="" && (
			<>
			<CCol xs="6" className="px-1 d-md-none text-start"><small>{uBahasaObj.word_umur||"Umur"}</small></CCol>
			<CCol xs="6" className="px-1 d-md-none"><small>{vCaptionumur}</small></CCol>
			</>
			)}

			{(pekerjaan||"")!=="" && (
			<>
			<CCol xs="5" className="px-1 d-md-none text-start"><small>{uBahasaObj.word_pekerjaan||"Pekerjaan"}</small></CCol>
			<CCol xs="7" className="px-1 d-md-none fst-italic"><small>{UFunc.renderHTML(vPekerjaan)}</small></CCol>
			</>
			)}

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_alamat||"Alamat"}</CCol>
			<CCol xs="8" className="px-1 d-md-none"><small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small></CCol>

			{(no_telp||"")!=="" && (
			<>
			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_notelp||"Telp/HP"}</CCol>
			<CCol xs="7" className="px-1 d-md-none fw-bolder">{UFunc.renderHTML(vTelepon)}</CCol>
			</>
			)}

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>
			<CCol xs="12" className="px-1 d-md-none my-2">
			<CButton size="sm"
				color="transparent"
				className="py-1 px-2 classikonbox classcursorpointer"
				style={{
					borderColor:"#229954",
					color:"#229954",
					backgroundColor:"#22995433",
				}}
				 onClick={()=>hdlKlikProsesbilling(id)}>
				<CImage src={pjson.homepage+"api/images/icon_kasir.png"} 
					className="me-2"
					height={20}/>
				Kasir Penunjang
				<CIcon icon="cilChevronDoubleRight" className="ms-2 align-middle" height={20}/>
			</CButton>
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = (_PAGE) => {
		setHtml500msg();
		const vPage	= parseInt(_PAGE)||1;

		const vHandelview	= 
			((uHandelView||false)===true || uFirstload==="YA" || uDatamainArr.length <= 0)
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_billingpasien || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==vPage);
			}
		}
		setLoading(true);
		//console.log("(Billingtransaksi - apiLoaddata) uHandelView : "+uHandelView);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: vPage,
			send_maxdata: uMaxData,
			send_loadjml : vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_billingtransaksi/ld_pasien";
		//alert (vDATAS);

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vTmpArr]);

				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Billingtransaksi - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Billingtransaksi - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{//--JIKA_PAGINATION_DIATUR_BACKEND--/
		//console.log("(Barangmasuk - [uIsInitbefore]) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) {
			//console.log("(Barangmasuk - []) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
			apiLoaddata();
		}

    	return () => {
			initResetview(); setFirstload("YA");
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		//console.log("(Billingtransaksi - [uHandelView]) uHandelView : "+uHandelView);
		if(!uHandelView) return;

		initResetview();
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uPageActive > 1) setPageActive(1); else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		initJumlahdata();
	},[uJmlData])
	React.useEffect(()=>{
		if(uFirstload==="YA") return;
		if(uHandelView) return;

		setDataselect({});
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		//console.log("(Billingtransaksi - [uPageActive]) uHandelView : "+uHandelView);
		apiLoaddata(uPageActive);
	},[uPageActive])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<i className="text-primary classfontsmaller">
			<CImage src={pjson.homepage+"api/images/icon_step_1.png"} className="align-middle" height={30}/>
			<span className="mx-1">&middot;</span>
			Silahkan Pilih <b className="text-uppercase">Data Pasien</b>
		</i>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary" id="idjmldatamobile">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<span className="classfontsmaller"><small className="text-muted">{UFunc.formatAngka(uTimeelapse)}ms</small></span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		</>
	)
}	

export default Billingtransaksi