import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialogform,
	MyDialoglookup,
	MyPage500,
} from '../../components/index'
import {
	ContHeaderProfile,
} from '../../containers/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Billinghistori = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const { tabel_id } 			= useParams(); 
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uPasienID					= parseInt(tabel_id)||0;
	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? 10 : 30;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const uDatacabangArr			= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uCabangIDinit				= localStorage.getItem("cabangidselect")||"0";

	const [uFirstload,setFirstload]			= React.useState("YA");
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uRowselect,setRowselect]			= React.useState(-1);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uDataloadObj,setDataloadObj]		= React.useState({})
	const [uDatamainArr,setDatamainArr]		= React.useState([]);
	const [uInpCabangid,setInpCabangid]		= React.useState(uCabangIDinit);

	const [uDatainputObj,setDatainputObj]	= React.useState({})
	const [uIteminputObj,setIteminputObj]	= React.useState({})
	const [uTabeleditObj,setTabeleditObj]	= React.useState({})
	const [uISFormupdated,setFormupdated]	= React.useState(false)
	const [uDatabarangArr,setDatabarangArr]	= React.useState([])

	//--DLG_FORM--/
	const [uDlgformheader,setDlgformheader]	= React.useState("")
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false)
	const [uDlglookupObj,setDlglookupObj]	= React.useState({})
	//--END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKlikBack = async() => {
		uNavigate(-1);
	}
	const hdlKlikReset = () => {
		if(uHtml500msg) {
			apiLoadpasien(); return;
		}

		if(parseInt(uCabangIDinit) !== parseInt(uInpCabangid)) {
			setInpCabangid(uCabangIDinit);
			return;
		}

		setDatamainArr([]);
		setJmlData(0);

		apiLoaddata();
	}
	const hdlKlikTabelCollapse = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);

		if((uDatamainArr[vArridx].init_expand||"TIDAK")==="YA") {
			uDatamainArr[vArridx].init_expand = "TIDAK";
			setEfekview(!uEfekview);
			return;
		}

		setRowselect(vRowidx);
		const vItemArr	= uDatamainArr[vArridx].items_arr || [];
		if(vItemArr.length > 0) {
			uDatamainArr[vArridx].init_expand = "YA";
			return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikToKasir = () => {
		setLoading(true);
		uNavigate("/billingtransaksi/formbilling/0");
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initChangecabang = () => {
		setDatamainArr([]);
		setJmlData(0);

		apiLoaddata();
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.filter(vItems=>vItems.pg === uPageActive)

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="align-top px-0 classfontsmaller">No</th>
			<th className="align-top px-0 classfontsmaller">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaob.word_tanggal||"Tanggal"}</CCol>
			<CCol md="4" className="text-start">{uBahasaob.caption_nonota||"No.Nota"}</CCol>
			<CCol md="4" className="text-start">{uBahasaob.word_keterangan||"Keterangan"}</CCol>
			<CCol md="2" className="">{uBahasaob.caption_nilaitotal||"N.Total"} (Rp)</CCol>
			</CRow>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,tgl_transaksi,nilai_bruto,diskon,nota_arr,
				item_fst_nama,item_fst_harga,item_fst_qty,
				items_arr,status_loading,init_expand
			} = vItems;

			vNumber++;
			const vItemArr	= items_arr || [];
			const vNotaArr	= nota_arr || [];

			return (
			<tr id={"idtr"+id} key={vKeys} className={(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 align-top text-end">{vNumber}.</td>

			<td className="px-1 align-top text-end">
			<CRow className="mx-0">
			<CCol md="2" className="px-1 text-start">{UFunc.TglAngka(tgl_transaksi)}</CCol>

			<CCol xs="12" className="px-1 d-md-none classborderbottom my-2"/>
			<CCol md="4" className="px-1 text-start">
			<div className="fw-bolder d-md-none">Histori Pembayaran:</div>
			{vNotaArr.length <= 0 ? ("-") : (
			<ul className="ps-4 classfontsmaller">
				{vNotaArr.map((vItemnota,vKeynota)=>{
					return (
					<li key={vKeynota}>
					<div className="my-1">
						<span>{vItemnota.no_nota}</span>
						<span className="mx-1">&middot;</span>
						<small className="fw-bolder">({UFunc.TglAngka(vItemnota.tgl_bayar)})</small>
						<span className="me-1">:</span>
						<span className="text-success">Rp {UFunc.formatAngka(vItemnota.nilai_bayar)}</span>
					</div>
					</li>
					)
				})}
			</ul>
			)}
			</CCol>

			<CCol xs="12" className="px-1 d-md-none classborderbottom my-2"/>
			<CCol md="4" className="px-1 text-start">
				<div className="d-flex justify-content-between mb-2 mb-md-1 justify-content-md-start">
					<b>Item Barang</b>
					<span className="mx-2 d-none d-md-block">&middot;</span>
					{(status_loading||false)===false ? (
					<CLink
						onClick={()=>hdlKlikTabelCollapse(id)}
						className="classcursorpointer">
					<CIcon icon={(init_expand||"TIDAK")==="TIDAK"?"cilArrowBottom":"cilArrowTop"} 
						className="align-middle" height={20}/>
					</CLink>
					) : (
					<CSpinner color="primary" className="align-middle" size="sm"/>
					)}
				</div>

				<ol className="ps-4 classfontsmaller m-0 mb-1">
				{(init_expand||"TIDAK")==="TIDAK" ? (
					<li className="text-muted fst-italic"><small>{item_fst_nama||"UNDF"}...</small></li>
				) : (
				<>
				{vItemArr.map((vItembarang,vKeybarang)=>{
					const vNurseArr = vItembarang.nurse_arr || [];
					return (
					<li key={vKeybarang} className="mb-1">
						<span className="fst-italic">{vItembarang.nama_barang}</span>

						<span className="mx-1">&nbsp;</span>
						(<b className="">{UFunc.formatAngka(vItembarang.qty)}</b> PCS
							<span className="mx-1">x</span>
							<b>Rp{UFunc.formatAngka(vItembarang.harga_satuan)}</b>
						)
							
						{vNurseArr.length > 0 && (
						<div className="text-primary">(
							{vNurseArr.map((vItemnurse,vKeynurse)=>{
								return (
								<>
								{vItemnurse}
								{vKeynurse < vNurseArr.length-1 && (
								<span className="mx-1">&middot;</span>
								)}
								</>
								)	
							})}
							)
						</div>
						)}
					</li>
					)
				})}
				</>
				)}
				</ol>

				{(parseFloat(diskon)||0) > 0 && (
				<>
				<CCol xs="12" className="px-1 classborderbottom my-2"/>
				<div className="d-flex justify-content-between">
					<b>Diskon</b>
					<span className="text-danger">Rp{UFunc.formatAngka(diskon)}</span>
				</div>
				</>
				)}
			</CCol>
			
			<CCol xs="12" className="px-1 d-md-none classborderbottom my-2"/>
			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaob.caption_nilaitotal||"N.Total"} (Rp)</CCol>
			<CCol xs="6" md="2" className="px-1 text-success"><b>{UFunc.formatAngka(nilai_bruto-diskon)}</b></CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoadpasien = () => {
		setHtml500msg();
		setDataloadObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_billingtransaksi/ld_pasiendata";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Billinghistori - apiLoaddata) output_string.datajenisbarang : "+output_string.datajenisbarang);
				setDataloadObj(JSON.parse(output_string.dataobject||"{}"));
				apiLoaddata();
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Billinghistori - apiLoadpasien) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Billinghistori - apiLoadpasien) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddata = (_PAGE) => {
		const vPage	= parseInt(_PAGE)||1;

		const vHandelview	= (uDatamainArr.length <= 0 || _PAGE === undefined)
			? "YA":"TIDAK";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==vPage);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_cabangid : uInpCabangid,
			send_pg: vPage,
			send_maxdata: uMaxData,
			send_loadjml : vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_billingtransaksi/ld_histori";
		//console.log("(Billinghistori -  apiLoaddata) vDATAS : "+vDATAS)

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Billinghistori - apiLoaddata) output_string.datajenisbarang : "+output_string.datajenisbarang);
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vTmpArr]);

				setJmlData(parseInt(output_string.totaldata||uJmlData))

				setFirstload("TIDAK");
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Billinghistori - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Billinghistori - apiLoaddata) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].status_loading = true;

		/*let vTimeout	= setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].status_loading 	= false;
			uDatamainArr[vArridx].init_expand 		= "YA";
			setEfekview(!uEfekview);
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangid: uInpCabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_billingtransaksi/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].status_loading 	= false;
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				uDatamainArr[vArridx].items_arr 		= vTmpArr;
				uDatamainArr[vArridx].init_expand 		= "YA";
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Billinghistori - apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].status_loading 	= false;
			console.log("(Billinghistori - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !!uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoadpasien();

    	return () => {
    		setFirstload("YA");
    		setDatamainArr([]);
    		setInpCabangid(uCabangIDinit)
			setLoading(false);
    	}	
	},[])
	React.useEffect(()=>{ 
		if(uHtml500msg) return;
		if(uFirstload === "YA") return;

		initChangecabang(); 
	},[uInpCabangid])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
	},[uJmlData])
	React.useEffect(()=>{
		if(uFirstload==="YA") return;

		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		//console.log("(Billingtransaksi - [uPageActive]) uHandelView : "+uHandelView);
		apiLoaddata(uPageActive);
	},[uPageActive])
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }

	//console.log("(Billinghistori) uNavigate => "+JSON.stringify(uNavigate))
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop">
		<CContainer fluid className="m-0 p-2 border-bottom">
			<CHeaderToggler className="ps-1"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="d-md-flex me-auto text-normal">
				<span>Histori Pembelian Penunjang</span>
			</CHeaderNav>

			<CHeaderNav className="ms-3 align-items-center">
				<ContHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 py-1 px-2 px-lg-3 justify-content-center justify-content-md-between align-items-center">
			<div className="d-none d-md-block d-lg-flex align-items-center">
			{(uHtml500msg) ? ("") : (
			<>
				{(UFunc.isEmpty(uDataloadObj)) ? (
					<big className="classblinking fst-italic text-warning">Loading..</big>
				) : (
				<>
				<b className="text-info text-uppercase">{uDataloadObj.nama_pasien||"NAMA PASIEN"}</b>
				<span className="mx-1">&middot;</span>
				<small style={{
					color:(uDataloadObj.sex||"")[0]==="L"?"#752421":"#FE47F3",
					}}>[ {(uDataloadObj.sex||"")[0]||"SEX"} ]</small>
				<span className="mx-1 d-none d-lg-inline-block">&middot;</span>
				<small className="fst-italic d-none d-lg-inline-block">{UFunc.br2nl(uDataloadObj.alamat||"Alamat Panjang disini")}</small>
				<span className="mx-1 d-none d-lg-inline-block">&middot;</span>
				<div><small className="classfontsmaller">Telp/HP: <b>{uDataloadObj.no_telp||"NO TELP"}</b></small></div>
				</>
				)}
			</>
			)}
			</div>

			<div className="d-flex align-items-center">
			<CFormSelect size="sm"
				className=""
				value={uInpCabangid}
				onChange={(e)=>setInpCabangid(e.target.value)}
				id="inpcabangid">
				{uDatacabangArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>{vItems.nama_cabang}</option>
					)
				})}
			</CFormSelect>

			<CTooltip content="Refresh Tampilan Data">
			<CLink className="ms-2 classcursorpointer"
				style={{}}
				 onClick={()=>hdlKlikReset()}>
				 <CIcon className="classikonreset" height={35}/>
			</CLink>
			</CTooltip>

			<CTooltip content="Proses Kasir Penunjang">
			<CButton size="sm"
				color="transparent"
				className="p-0 py-1 px-2 classikonbox classcursorpointer"
				style={{
					borderColor:"#229954",
					color:"#229954",
					backgroundColor:"#22995433",
					width: 115
				}}
				 onClick={()=>hdlKlikToKasir()}>
				<CImage src={pjson.homepage+"api/images/icon_kasir.png"} 
					className="ms-1"
					height={25}/>
				<CIcon icon="cilChevronDoubleRight" className="align-middle" height={20}/>
			</CButton>
			</CTooltip>

			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">
		<CContainer xxl className={"px-0"}>
			{(uHtml500msg) ? (
				<MyPage500 content={uHtml500msg}/>
			) : (
			<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between py-1">
			<div className="">
				{(UFunc.isEmpty(uDataloadObj)) ? (
				<small className="d-md-none classblinking fst-italic text-warning">Loading..</small>
				) : (
				<b className="d-md-none text-info text-uppercase">{uDataloadObj.nama_pasien||"NAMA PASIEN"}</b>
				)}
			</div>

			<div>
				<small>(<b className="text-primary">{UFunc.formatAngka(uJmlData)}</b>)</small>
				<span className="mx-1">&middot;</span>
				<small className="text-muted classfontsmaller align-middle">{UFunc.formatAngka(uTimeelapse)}ms</small>
			</div>
			</CCardHeader>
			
			<CCardBody className="px-0">{contentMain()}</CCardBody>
			</CCard>
			)}
		</CContainer>
		</div>
		</div>
		</>
	) 
}	

export default Billinghistori