import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	CSpinner,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogview,
	MyPage500,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Lapkasir	 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? 30 : 100;
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelFilter		= uHeaderActionObj.isHeaderFilter || false;
	const uHandelReset 		= uHeaderActionObj.isHeaderReset || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uSortingObj,setSortingObj]		= React.useState({});

	const [uDatakasirArr,setDatakasirArr]	= React.useState([]);
	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]");

	const uCabandIDtokenArr	= ((uTokenObj||{}).usercabangid || "").split(",");
	const uCabangallowedArr = uTokenObj.userhak === "SUPERADMIN" ? uDatacabangArr
		: uDatacabangArr.filter(vItems => uCabandIDtokenArr.includes(vItems.id.toString()));

	//console.log("uCabandIDtokenArr => "+JSON.stringify(uCabandIDtokenArr));
	//console.log("uCabangallowedArr => "+JSON.stringify(uCabangallowedArr));
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgforminputObj,setDlgforminputObj]= React.useState({});
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	const [uIsDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]= React.useState({});
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	const uElJmlmobile	= document.getElementById("idjmldatamobile");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikDlgformsimpan = () => {
		let vElFocus = document.getElementById("inpcabangid");
		if((parseInt(uDlgforminputObj.cabang_id)||0) <= 0) {
			vElFocus && vElFocus.focus();
			showToast("Cabang Harus Dipilih !");return;
		}
		vElFocus = document.getElementById("inpperiode");
		if(uDlgforminputObj.jenis_periode!=="TANGGAL" && uDlgforminputObj.jenis_periode!=="BULAN") {
			vElFocus && vElFocus.focus();
			showToast("Jenis Periode Harus Dipilih !");return;
		}

		let vTanggals;
		if(uDlgforminputObj.jenis_periode==="TANGGAL") {
			vElFocus = document.getElementById("inptgl");
			vTanggals	= (uDlgforminputObj.thn+"-"+uDlgforminputObj.bln+"-"+uDlgforminputObj.tgl);
		} else {
			vElFocus = document.getElementById("inpbln");
			vTanggals	= (uDlgforminputObj.thn+"-"+uDlgforminputObj.bln+"-01");
		}
		const vTanggalDT = UFunc.toDate(vTanggals);
		if(!UFunc.DateIsValid(vTanggals)) {
			vElFocus && vElFocus.focus();
			showToast("Periode Tanggal/Bulan Tidak Valid !");return;
		}
		if(UFunc.DateIsBeyond(vTanggalDT)) {
			vElFocus && vElFocus.focus();
			showToast("Periode Tanggal/Bulan Belum Tercapai !");return;
		}
		if(parseInt(uDlgforminputObj.kasir_idx||"-1") < -1) {
			vElFocus && vElFocus.focus();
			showToast("Kasir Harus Dipilih !");return;
		}

		apiLoadview();
	}
	const hdlKlikDetil = (_IDTABEL) => {
		if(uJmlData <= 0) return;

		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabellaporan","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikSorting = (_NAMAFIELD) => {
		_NAMAFIELD 			= (_NAMAFIELD || "").trim();
		const vSortnamenow	= uSortingObj.nama || "";
		const vSortjenis	= (uSortingObj.jenis||"").toUpperCase();
		const vSortarget	= vSortjenis===""?"DESC"
			: (vSortjenis==="DESC" ? "ASC" : "");

		let vSortednew	= {}
		if(vSortnamenow !== _NAMAFIELD) {
			vSortednew = { nama: _NAMAFIELD, jenis: "DESC" }
		} else {
			if(vSortjenis === "ASC") vSortednew = {}
			else vSortednew = { nama: _NAMAFIELD, jenis: vSortarget }
		}
		setSortingObj(vSortednew);
		setEfekview(!uEfekview);
	}
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		const vKasirID	= parseInt((uDataloadedObj||{}).kasir_id)||"0";
		const vBulanID	= parseInt((uDataloadedObj||{}).bln)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).thn)||"0";

		const vColspan 	= uDataloadedObj.jenis_periode === "TANGGAL"	 ? 5 : 3;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= uDataloadedObj.jenis_periode === "TANGGAL" ? ({
			no : "",
			no_nota: "",
			caption_pasien: "",
			nilai_transaksi: "",
			nilai_bayar: "",
		}) : ({
			no : "",
			tgl_bayar: "",
			nilai_bayar: "",
		})
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				id,tgl_bayar,caption_pasien,telp_pasien,nilai_transaksi,no_nota,nilai_bayar,
				nama_kasir,
			} = vItems;

			vRowcount++;
			vColposwidth = (caption_pasien||"").length > vColposwidth 
				? (caption_pasien||"").length : vColposwidth; 

			const vTglbayar	= UFunc.toDatetime(tgl_bayar);
			vTmpObj	= uDataloadedObj.jenis_periode === "TANGGAL" ? ({
				no : {v:vKeys+1,t:"n"},
				no_nota: {v:(no_nota||"-")+(vKasirID > 0 ? "" : "\r\n["+nama_kasir+"]"),
					t:"s",s:{wrapText:true}},
				caption_pasien: (caption_pasien||"Undf Pasien"),
				nilai_transaksi: { v:parseFloat(nilai_transaksi), t:"n", s:{numFmt: "#,##0"} },
				nilai_bayar: { v:parseFloat(nilai_bayar), t:"n", s:{numFmt: "#,##0"} },
			}) : ({
				no : {v:vKeys+1,t:"n"},
				tgl_bayar: {v:vTglbayar,t:"d"},
				nilai_bayar: { v:parseFloat(nilai_bayar), t:"n", s:{numFmt: "#,##0"} },
			}) 
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			no_nota: "",
			caption_pasien: "",
			nilai_transaksi: "",
			nilai_bayar: { v:parseFloat(uDataloadedObj.sum_bayar)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		if(uDataloadedObj.jenis_periode !== "TANGGAL") {
			delete vTmpObj.no_nota;
			delete vTmpObj.caption_pasien;
			delete vTmpObj.nilai_transaksi;
		}

		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
			{wpx:40},
			{wpx:100},
			{wch:vColposwidth},
			{wpx:85},
			{wpx:85},
		];
		if(uDataloadedObj.jenis_periode !== "TANGGAL") wscols.splice(2,2);
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPKASIR_"+
			(uDataloadedObj.jenis_periode==="TANGGAL"?uDataloadedObj.tgl:"")+
			(cBulanPendek[vBulanID-1]).toUpperCase()+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+uTitelObj.periode+
			(uTitelObj.subtitel ? "\r\n"+uTitelObj.subtitel : '')+
		"";
		const vHeaderArr = uDataloadedObj.jenis_periode === "TANGGAL" 
			? [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"No.Nota",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Pasien",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Transaksi (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			] : [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormreset = () => {
		setDataselect({})
		setDlgforminputObj({});
		setEfekview(!uEfekview);
	}
	const prosesReset = async() => {
		const vFuncnext = () => {
			setDataloadedObj();
			setDatamainArr([]);
			setEfekview(!uEfekview);
		}

		if(uDataloadedObj) {
			if(await Konfirm("Apakah Anda Yakin Akan Reset Laporan ?")) vFuncnext();
		} else {
			apiLoadinit();
		}
	}
	const initSUMdata = () => {
		uDataloadedObj && (
			uDataloadedObj.sum_bayar = (uDatamainArr||[]).reduce((vSums,vItems)=>{
				return vSums + (parseFloat(vItems.nilai_bayar)||0)
			},0)
		);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>)//>

		if(uDataloadedObj && uJmlData <= 0) return (
			<div className="m-2 p-2 m-lg-5 p-lg-5 text-center fst-italic text-primary">
			&middot;&middot; Maaf, Data Tidak Ditemukan.. &middot;&middot;
			</div>
		)//>

		if(uDataloadedObj.jenis_periode === "BULAN") return contentBulan();
		if(uDataloadedObj.jenis_periode === "TANGGAL") return contentTanggal();

		return (<></>)//>
	}
	const contentBulan = () => {
		if(uDataloadedObj.jenis_periode !== "BULAN") return (<></>)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabellaporan">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1 text-start">{uBahasaObj.word_tanggal||"Tanggal"}</th>
			<th className="px-1" width="25%">
			<span className={"me-1"+((uSortingObj.nama||"")==="nilai_bayar" ? " text-danger" : "")}>
				{uBahasaObj.caption_nilaibayar||"Nilai Bayar"} (Rp)
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikSorting("nilai_bayar")} className="classcursorpointer">
				<CIcon icon={((uSortingObj.nama||"")==="nilai_bayar" && (uSortingObj.jenis||"")==="ASC" ? "cilArrowTop" : "cilArrowBottom")}
					className="align-middle" height={20}/>
				</CLink>
			</span>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,tgl_bayar,nilai_bayar
			} = vItems;

			vNumber++;
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="text-end">{vNumber}.</td>

			<td className="text-start">{UFunc.HariAngka(tgl_bayar)}</td>

			<td className="text-end text-success">{UFunc.formatAngka(nilai_bayar)}</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot>
		<tr>
		<td colSpan={2} align="center">JUMLAH</td>
		<td align="right">{UFunc.formatAngka(uDataloadedObj.sum_bayar)}</td>
		</tr>
		</tfoot>
		</table>
		)//>
	}
	const contentTanggal = () => {
		if(uDataloadedObj.jenis_periode !== "TANGGAL") return (<></>)//>

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vColspan		= 6;

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabellaporan">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1 text-start" width="15%">{uBahasaObj.caption_nonota||"No. Nota"}</th>
			<th className="px-1 text-start">{uBahasaObj.word_pasien||"Pasien"}</th>
			<th width="5%" className="px-1">&middot;</th>
			<th className="px-1" width="15%">{uBahasaObj.caption_nilaitransaksi||"Nilai Transaksi"} (Rp)</th>
			<th className="px-1" width="20%">
			<span className={"me-1"+((uSortingObj.nama||"")==="nilai_bayar" ? " text-danger" : "")}>
				{uBahasaObj.caption_nilaibayar||"Nilai Bayar"} (Rp)
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikSorting("nilai_bayar")} className="classcursorpointer">
				<CIcon icon={((uSortingObj.nama||"")==="nilai_bayar" && (uSortingObj.jenis||"")==="ASC" ? "cilArrowTop" : "cilArrowBottom")}
					className="align-middle" height={20}/>
				</CLink>
			</span>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={vColspan}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,nama_kasir,tgl_bayar,nilai_bayar,nilai_transaksi,caption_pasien,telp_pasien,
				no_nota,is_loading,jenis_bayar
			} = vItems;

			vNumber++;
			const vJenisbayar 	= (jenis_bayar||"DP")==="DP" ? "PENJUALAN" : "PIUTANG";
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="text-end">{vNumber}.</td>

			<td className="text-start">
				{(no_nota||"-")}
				{parseInt(uDataloadedObj.kasir_id) <= 0 && (
				<div className="text-danger text-uppercase classfontsmaller"><small>[{UFunc.Usercaptionfilter(nama_kasir)}]</small></div>
				)}
			</td>
			<td className="text-start">
				<div className="fw-bolder text-primary">{(caption_pasien||"UNDF PASIEN")}</div>
				<small className={"fst-italic"+((jenis_bayar||"DP")==="PIUTANG"?" fw-bolder":"")}>{vJenisbayar}</small>
			</td>
			
			<td className="text-center">
			{(is_loading||false)===true ? (
				<CSpinner color="primary" size="sm"/>
			) : (
				<CLink onClick={()=>hdlKlikDetil(id)} className="classcursorpointer">
				<CIcon icon="cilSearch" className="classikonbox" height={23}/>
				</CLink>
			)}
			</td>
			
			<td className="text-end">{UFunc.formatAngka(nilai_transaksi)}</td>
			<td className="text-end text-success">{UFunc.formatAngka(nilai_bayar)}</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot>
		<tr>
		<td colSpan={(vColspan-1)} align="center">JUMLAH</td>
		<td align="right">{UFunc.formatAngka(uDataloadedObj.sum_bayar)}</td>
		</tr>
		</tfoot>
		</table>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;
		return (
		<CForm className="">
		<CRow className="my-2">
		<CCol xs="4" className="text-start">{uBahasaObj.word_cabang||"Cabang"}</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm"
				value={uDlgforminputObj.cabang_id}
				onChange={(e)=>{uDlgforminputObj.cabang_id=e.target.value;setEfekview(!uEfekview)}}
				id="inpcabangid">
			{uCabangallowedArr.map((vItems,vKeys)=>{
				return (
				<option key={vKeys} value={vItems.id}>{vItems.nama_cabang}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-start">{uBahasaObj.caption_jenisperode||"Jenis Periode"}</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm"
				value={uDlgforminputObj.jenis_periode}
				onChange={(e)=>{uDlgforminputObj.jenis_periode=e.target.value;setEfekview(!uEfekview)}}
				id="inpperiode">
				<option value="TANGGAL">per TANGGAL</option>
				<option value="BULAN">per BULAN</option>
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-start">
			{uDlgforminputObj.jenis_periode === "TANGGAL" 
				? (uBahasaObj.word_tanggal||"Tanggal")
				:(uBahasaObj.word_bulan||"Bulan")}
		</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			{uDlgforminputObj.jenis_periode === "TANGGAL" ? (
			<>
			<CFormSelect size="sm"
				value={uDlgforminputObj.tgl}
				onChange={(e)=>{uDlgforminputObj.tgl=e.target.value;setEfekview(!uEfekview)}}
				id="inptgl">
				{(() => {
					let vTmpArr = [];
					for(var vIdx=1;vIdx <= 31;vIdx++) {
						vTmpArr.push(
						<option value={UFunc.leadZero(vIdx)} key={vIdx}>{UFunc.leadZero(vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			<span className="me-1 d-none d-lg-block"/>
			<CFormSelect size="sm"
				value={uDlgforminputObj.bln}
				onChange={(e)=>{uDlgforminputObj.bln=e.target.value;setEfekview(!uEfekview)}}
				id="inpbln">
				{cBulanPendek.map((vItems,vKeys)=>{
					return(
					<option key={vKeys} value={UFunc.leadZero(vKeys+1)}>{vItems.toUpperCase()}</option>
					)
				})}
			</CFormSelect>
			<span className="me-1 d-none d-lg-block"/>
			<CFormSelect size="sm"
				value={uDlgforminputObj.thn}
				onChange={(e)=>{uDlgforminputObj.thn=e.target.value; setEfekview(!uEfekview)}}
				id="inpthn">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</> 
			) : (
			<>
			<CFormSelect size="sm"
				value={uDlgforminputObj.bln}
				onChange={(e)=>{uDlgforminputObj.bln=e.target.value;setEfekview(!uEfekview)}}
				id="inpbln">
				{cBulanPanjang.map((vItems,vKeys)=>{
					return(
					<option key={vKeys} value={UFunc.leadZero(vKeys+1)}>{vItems.toUpperCase()}</option>
					)
				})}
			</CFormSelect>
			<span className="mx-1 d-none d-lg-block"/>
			<CFormSelect size="sm"
				value={uDlgforminputObj.thn}
				onChange={(e)=>{uDlgforminputObj.thn=e.target.value; setEfekview(!uEfekview)}}
				id="inpthn">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</>
			)}
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-start">{uBahasaObj.word_kasir||"Kasir"}</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm"
				value={uDlgforminputObj.kasir_idx}
				onChange={(e)=>{uDlgforminputObj.kasir_idx=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpkasir">
				<option value="-1">--PILIH SEMUA KASIR--</option>
				{uDatakasirArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vKeys}>{vItems.nama_kasir}</option>
					)
				})}
			</CFormSelect>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentDlgview = () => {
		if(!uDlgviewdataObj) return (<></>)//>

		const vDetilArr	= uDlgviewdataObj.items_arr || [];
		let vSumtotal	= 0;

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldetil">
		<thead>
		<tr className="text-center align-top classfontsmaller">
		<td className="px-1 text-end" width="5%">No</td>
		<td className="px-1 text-start">Nama Barang</td>
		<td className="px-1" width="7%">Qty</td>
		<td className="px-1" width="11%">H.Satuan</td>
		<td className="px-1" width="11%">N.Total</td>
		</tr>
		</thead>

		<tbody>
		{vDetilArr.map((vItems,vKeys)=>{
			const {
				nama_barang,qty,harga_satuan,id
			} = vItems;

			vSumtotal	= vSumtotal + ((parseFloat(harga_satuan)||0) * (parseFloat(qty)||0));
			return (
			<tr className="align-top" key={vKeys}>
			<td className="text-end">{vKeys+1}.</td>
			<td className="text-start fw-bolder">{nama_barang||"Undf"}</td>
			<td className="text-center">{UFunc.formatAngka(qty)}</td>
			<td className="text-end">{UFunc.formatAngka(harga_satuan)}</td>
			<td className="text-end text-success">{UFunc.formatAngka(harga_satuan * qty)}</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot className="border-top border-top-dark">
		<tr className="fw-bolder">
		<td colSpan={4} className="text-end">JUMLAH</td>
		<td className="text-end">{UFunc.formatAngka(vSumtotal)}</td>
		</tr>
		{(parseFloat(uDlgviewdataObj.nilai_diskon)||0) > 0 && (
			<tr className="text-danger">
			<td colSpan={4} className="text-end">Diskon</td>
			<td className="text-end">{UFunc.formatAngka(uDlgviewdataObj.nilai_diskon)}</td>
			</tr>
		)}
		</tfoot>
		</table>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Lapkasir - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= uDataloadedObj.jenis_periode==="TANGGAL" ? 5 : 3;
		const vDataviewArr	= uDatamainArr;
		const vKasirID		= parseInt(uDataloadedObj.kasir_id)||0;
		const vKasiridx		= parseInt(uDataloadedObj.kasir_idx || "-1");
		const vCabangidx	= UFunc.getObjectIndeks(uDatacabangArr,uDataloadedObj.cabang_id);

		vHTMLs	+= UFunc.RenderTabel(vColspan);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()];
		const vJudulsub		= `
			<div>Periode <b>`+uTitelObj.periode+`</b> (`+uTitelObj.cabang+`)</div>`+
			(uTitelObj.subtitel ? `<div>`+uTitelObj.subtitel+`</div>` : ``)+`
		`;

		const vTabelheader	= 
		uDataloadedObj.jenis_periode === "TANGGAL"
			? `
			<th width="5%">No</th>
			<th width="18%" align="left">`+(uBahasaObj.caption_nonota||"No.Nota")+`</th>
			<th align="left">Pasien</th>
			<th width="18%" align="left">N.Transaksi (Rp)</th>
			<th width="15%">N.Bayar (Rp)</th>
			</tr>
			`
			: `
			<th width="5%">No</th>
			<th align="left">`+(uBahasaObj.word_tanggal||"Tanggal")+`</th>
			<th width="25%">N.Bayar (Rp)</th>
			</tr>
			`;
		
		let vTabelbody	= "";
		let vNumber		= 0;
		vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_bayar,caption_pasien,telp_pasien,nilai_transaksi,no_nota,nilai_bayar,
				nama_kasir,
			} = vItems;
			vNumber++;

			vTabelbody += 
			uDataloadedObj.jenis_periode === "TANGGAL"
				? `
				<tr valign="top" key=`+vKeys+`>
				<td align="right">`+vNumber+`.</td>
				<td align="left">`+
					(no_nota)+`
					`+(vKasirID <= 0 ? `
						<div>
						<small>[`+UFunc.Usercaptionfilter(nama_kasir)+`]</small>
						</div>
						` : ``
					)+`
				</td>

				<td align="left">`+
					`<b>`+(caption_pasien||"-")+`</b>`+
					((telp_pasien||"")!==""
						? `<div>Telp/HP.`+telp_pasien+`</div>`+ 
						``
						: ``)+
				`</td>

				<td align="right">`+UFunc.formatAngka(nilai_transaksi)+`</td>
				<td align="right"><b>`+UFunc.formatAngka(nilai_bayar)+`</b></td>
				</tr>
				` : `
				<tr valign="top" key=`+vKeys+`>
				<td align="right">`+vNumber+`.</td>
				<td align="left">`+UFunc.HariAngka(tgl_bayar)+`</td>
				<td align="right"><b>`+UFunc.formatAngka(nilai_bayar)+`</b></td>
				</tr>
				`;
		}).join("");
		
		const vTabelfooter 	= `
			<td colspan="`+(vColspan-1)+`">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDataloadedObj.sum_bayar)+`</td>
		`;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadinit = () => {
		setHtml500msg();
		setDatamainArr([]);
		setDataselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_barangjenis || "";
		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatakasirArr(JSON.parse(output_string.datakasir||"[]"));
				if(uFirstload==="YA") setFirstload("TIDAK");
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapkasir - apiLoadinit) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		setDataloadedObj();
		setDatamainArr([]);setDatadefaultArr([]);
		setDataselect({});
		setRowselect(-1);
		setSortingObj({});
		const vBtnsimpan	= document.getElementById("btnDialogSimpan");
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoadview) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uDlgforminputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_view";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataview||"[]");
				setDatamainArr(vTmpArr);
				setDatadefaultArr([{init_change:"1"},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);

				setDataloadedObj(uDlgforminputObj);
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapkasir - apiLoadview) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0;
		const vArridx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);if(vArridx<0) return;

		if((uDatamainArr[vArridx].items_arr||[]).length > 0) {
			uDlgviewdataObj.nilai_diskon= uDatamainArr[vArridx].nilai_diskon;
			uDlgviewdataObj.items_arr	= uDatamainArr[vArridx].items_arr||[];
			uDlgviewdataObj.headers 	= "Detil Item: "+uDatamainArr[vArridx].no_nota;
			setDlgviewshow(true);

			return;
		}

		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoadview) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_cabangid : uDataloadedObj.cabang_id,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapkasir/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_loading = false;
			setSessionaktif(true);
			if(output_string.tampil) {
				const vTmpArr = (JSON.parse(output_string.datadetil||"[]"));
				uDatamainArr[vArridx].items_arr = vTmpArr;

				uDlgviewdataObj.nilai_diskon	= uDatamainArr[vArridx].nilai_diskon;
				uDlgviewdataObj.items_arr		= vTmpArr;
				uDlgviewdataObj.headers = "Detil Item: "+uDatamainArr[vArridx].no_nota;
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapkasir - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("(Lapkasir - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoadinit();

    	return () => {
    		setDatamainArr([]); setDataloadedObj();setDatadefaultArr([]);
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA"); setSortingObj({});

			setDlgformshow(false); setDlgviewshow(false);
    	}
	},[])
	React.useEffect(()=>{
		//console.log("(Lapkasir-[uHandelFilter]) uHandelFilter = "+uHandelFilter)
		if(!uHandelFilter) return;
		uHeaderActionObj.isHeaderFilter = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uDlgforminputObj.headers = "Filter Laporan Kasir";
		setDlgformshow(true);
	},[uHandelFilter])
	React.useEffect(()=>{
		//console.log("(Lapkasir-[isHeaderReset]) isHeaderReset = "+isHeaderReset)
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		prosesReset();
	},[uHandelReset])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
		if(uDatamainArr.length <= 0) return;

		initSUMdata(); 
	},[uDatamainArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		uElJmlmobile && (uElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		const vEls 	= document.getElementById("btnFilter");
		if(!uIsDlgformshow) { 
			initFormreset(); 
			vEls && (vEls.disabled = false);
			return; 
		}

		const vTglnowDT 	= new Date();
		if(uDlgforminputObj.cabang_id === undefined) {
			const vCabangid = localStorage.getItem("cabangidselect") || uCabangallowedArr[0].id;
			uDlgforminputObj.cabang_id = vCabangid;
		}
		if(uDlgforminputObj.jenis_periode === undefined) 
			uDlgforminputObj.jenis_periode = uDataloadedObj ? uDataloadedObj.jenis_periode : "TANGGAL";
		if(uDlgforminputObj.tgl === undefined) 
			uDlgforminputObj.tgl = uDataloadedObj ? uDataloadedObj.tgl : UFunc.leadZero(vTglnowDT.getDate());
		if(uDlgforminputObj.bln === undefined) 
			uDlgforminputObj.bln = uDataloadedObj ? uDataloadedObj.bln : UFunc.leadZero(vTglnowDT.getMonth() + 1);
		if(uDlgforminputObj.thn === undefined) 
			uDlgforminputObj.thn = uDataloadedObj ? uDataloadedObj.thn : UFunc.leadZero(vTglnowDT.getFullYear(),4);
		if(uDlgforminputObj.kasir_idx === undefined) 
			uDlgforminputObj.kasir_idx = uDataloadedObj ? uDataloadedObj.kasir_idx : -1;

		vEls && (vEls.disabled = true);

		//console.log("("+cComponentName+"[uIsDlgformshow]) uIsDlgformshow 1: "+uIsDlgformshow);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElFocus = document.getElementById("inpcabangid");
			if(!isMobile) vElFocus && vElFocus.focus();
		},250);
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(uDlgforminputObj.kasir_idx === undefined) return;
		const vKasiridx 	= parseInt(uDlgforminputObj.kasir_idx);
		if(vKasiridx < 0) {
			uDlgforminputObj.kasir_id = 0;
			uDlgforminputObj.kasir_usergudang = undefined;
			return;
		}

		uDlgforminputObj.kasir_id = uDatakasirArr[vKasiridx].id || 0;
		uDlgforminputObj.kasir_usergudang = uDatakasirArr[vKasiridx].usergudang || "TIDAK";
	},[uDlgforminputObj.kasir_idx])
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		const vIdxcabang	= UFunc.getObjectIndeks(uDatacabangArr,uDataloadedObj.cabang_id);
		uTitelObj.cabang 	= uDatacabangArr[vIdxcabang].nama_cabang;

		if(uDataloadedObj.jenis_periode === "BULAN")
			uTitelObj.periode 	= cBulanPanjang[parseInt(uDataloadedObj.bln)-1]+" "+uDataloadedObj.thn;
		else
			uTitelObj.periode 	= UFunc.HariAngka(uDataloadedObj.thn+"-"+uDataloadedObj.bln+"-"+uDataloadedObj.tgl);
		
		const vArridx		= parseInt(uDataloadedObj.kasir_idx);
		if(vArridx >= 0)
			uDatakasirArr && (uTitelObj.subtitel = uDatakasirArr[vArridx].nama_kasir);
	},[uDataloadedObj]);
	React.useEffect(()=>{
		const vEls 	= document.getElementById("btnFilter");
		if(!uIsDlgviewshow) { 
			setDlgviewdataObj({});
			vEls && (vEls.disabled = false); return;
		}
		vEls && (vEls.disabled = true);
	},[uIsDlgviewshow])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else { setRowselect(-1); setDataselect({}); }

		if(JSON.stringify(uSortingObj||{}) === "{}") { setDatamainArr(uDatadefaultArr.slice(1)); return; }

		//let vSortedArr	= [];
		const vNamasort = (uSortingObj.nama||"").toLowerCase();
		if(uSortingObj.jenis === "ASC") {
			uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]);
		}else if(uSortingObj.jenis === "DESC") {
			uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			const vDefaultsort = uDatadefaultArr.slice(1);
			setDatamainArr(vDefaultsort);//setEfekview(!uEfekview);
		}
	},[uSortingObj,
	uSortingObj.nama,
	uSortingObj.jenis,
	])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )//>

	//console.log("uDatamainArr => "+JSON.stringify(uDatamainArr));
	return (
		<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b> &middot; ({uTitelObj.cabang})</div>
			<small className="text-primary">{uTitelObj.subtitel}</small>
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeelapse)}ms
					</small>
				</span>
			</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgforminputObj.headers}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewdataObj.headers}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>
		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)//>
}	

export default Lapkasir