import React from 'react'
import { 
	CCard,
	CCardHeader,
	CCardBody,
	CLink,
	CTooltip
} from '@coreui/react'
import { UFunc } from "../helpers/functions";

const MyPage500 = (props) => {
	const {content,isWithrefresh,hdlReset,...rest} = props
	
	return (
	<CCard className="classcardbg mx-lg-5">
	<CCardHeader className="d-flex justify-content-between align-items-center">
		<b>Error Page 500</b>
		{(isWithrefresh||false)===true && (
			<CTooltip content="Reset Loading">
			<CLink className="classikon classikonreset classcursorpointer"
				onClick={()=>{hdlReset && hdlReset()}}/>
			</CTooltip>
		)}
	</CCardHeader>

	<CCardBody className="p-lg-5">{UFunc.renderHTML(content||"Content Not Existed")}</CCardBody>
	</CCard>
	)
}	

export default MyPage500