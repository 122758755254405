import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	CButton,
	CFormCheck,
	CFormSelect,CInputGroup,CInputGroupText,
	CImage,
	CBadge,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyPage500,
	MyButton,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson = require('../../package.json')
const Nota 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 12) : (uSettinguserObj.jml_tabel || 30);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--MAIN_DATA_FORM--/
	const uFontstyleArr					= [
		{value:"Courier, Courier New, Sans Serif",caption:"Courier, Courier New, Sans Serif"},
		{value:"Verdana, Arial, Helvetica",caption:"Verdana, Arial, Helvetica"},
		{value:"Calibri, Trebuchet, Garamond",caption:"Calibri, Trebuchet, Garamond"},
		{value:"Monaco, DejaVu Sans Mono, OCR-A",caption:"Monaco, DejaVu Sans Mono, OCR-A"},
	];
	const refFileImg 					= React.useRef();
	const [uFormdataObj,setFormdataObj]	= React.useState({});
	const uHTMLstylenota				= useSelector(state => state.gHTMLstyleNota);
	const uSettingcetakObj				= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas					= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri					= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize						= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle					= uSettingcetakObj.fontstyle || uFontstyleArr[0].value;
	const uFrameExport 					= document.getElementById("ifmcontentstoprint");
	const uUrllogoDefault				= "https://gdental.giodentalcare.com/images/logobw.png";
	const uDatanotaObj		= {
		tgl_bayar:UFunc.DbDate(), no_nota:"20230606S001",
		jenis_bayar:"DP", nilai_uang:"250000",
		id_pasien:"20416",caption_pasien:"Novario Rahmad Dani Dibikin Panjang Namanya",
		tgl_transaksi:"2023-06-06 00:00:00",
		nilai_bruto:"205000", diskon:"5000",terbayar:"0",
		inputby:"70",inputgudang:"TIDAK",inputcaption:"Pengelola Kedua Seorang Kapiten",
		id_transaksi:"810",id:"816",
		items_arr:[
			{harga_satuan:"50000",qty:"1",nama_barang:"Dental Floss 1 Pack",id_barang:"66",id:"1272"},
			{harga_satuan:"45000",qty:"2",nama_barang:"Cefixime 100mg/15",id_barang:"14",id:"1273"},
			{harga_satuan:"65000",qty:"1",nama_barang:"Azithromycin/5",id_barang:"6",id:"1274"}
		]
	}
	//--END MAIN_DATA_FORM--/

	//--DATA_TABEL--/
	const [uFirstload,setFirstload]		= React.useState("YA");
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikReset = () => { 
		if(uHtml500msg) { apiLoaddata(); return; }

		initFormreset();
	}
	const hdlCheckLogo = () => {
		//console.log("(Nota - hdlCheckLogo) view_image_nota => "+uFormdataObj.view_image_nota)
		uFormdataObj.view_image_nota = (uFormdataObj.view_image_nota||"YA")==="YA"
			? "TIDAK" : "YA";
		setEfekview(!uEfekview)
	}
	const hdlChangeLogo = (_EV) => {
		const fileUploaded 	= _EV.target.files[0];
		//console.log("(Pasieninput - hdlChangeFoto) fileUploaded => "+fileUploaded);

		UFunc.getBase64(fileUploaded).then(result => {
			const fileBase64 = result;
			uFormdataObj.url_image_nota = fileBase64; setEfekview(!uEfekview) 
			//console.log("File Is", fileBase64);
			_EV.target.value = '';
		}).catch(err => {
			_EV.target.value = '';
			console.log(err);
		});
	}
	const hdlKlikPilihlogo = () => { refFileImg.current.click(); }
	const hdlKlikHapuslogo = async() => {
		const vConfirm	= 
			"<B>"+(uBahasaObj.caption_hapuslogo||"Hapus Logo")+"</B>.."+
			"<br/><br/>"+(uBahasaObj.confirm_hapus||"confirm_hapus")+""+
		"";
		const vFuncnext	= ()=>{
			uFormdataObj.url_image_nota = ""; setEfekview(!uEfekview) 
		}

		if(await Konfirm(vConfirm)) vFuncnext();
	}
	const hdlKlikSimpan = async() => {
		//--VALIDASI--/
		let vElfocus	= document.getElementById("inplebarkertas");
		if(parseInt(uFormdataObj.lebarkertas) < 0) {
			vElfocus && vElfocus.focus();
			showToast("Lebar Kertas Tidak Valid !"); return;
		}
		vElfocus	= document.getElementById("inpmarginkiri");
		if(parseInt(uFormdataObj.marginkiri) < 0) {
			vElfocus && vElfocus.focus();
			showToast("Margin Kiri Kertas Tidak Valid !"); return;
		}
		vElfocus	= document.getElementById("inpfontsize");
		if(parseInt(uFormdataObj.fontsize) < 0) {
			vElfocus && vElfocus.focus();
			showToast("Ukuran Font Tidak Valid !"); return;
		}
		vElfocus	= document.getElementById("inpfontfamily");
		if((uFormdataObj.fontstyle||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Font Family Nota Tidak Valid !"); return;
		}
		//--END VALIDASI--/

		if((uFormdataObj.url_image_nota||"").trim() !== "") {
			//--HARUS_PAKE_FUNGSI_PROMISE--/
			UFunc.validateBase64Image(uFormdataObj.url_image_nota).
				then((isValidimg)=>{
					if(!isValidimg) {
						showToast("Image LOGO Tidak Valid !"); return;
					} else apiProsessimpan();
				});//*/
			/*if(!UFunc.isBase64Image(uFormdataObj.url_image_nota)) {
				showToast("Image LOGO Tidak Valid !"); return;
			}//-*/
			//--END HARUS_PAKE_FUNGSI_PROMISE--/
		} 
		else apiProsessimpan();
		//apiProsessimpan();
	}
	const hdlKlikTestprint = () => {
		var vContent 	= contentHTMLnota();
		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormreset = () => {
		setFormdataObj({})
		setEfekview(!uEfekview);
	}
	const initImgcache = (_URLlogo) => {
		const pri 		= uFrameExport && uFrameExport.contentWindow;
		
		const vImglogo	= _URLlogo || uSystemsettingObj.url_image_nota;
		pri.document.open();
		pri.document.write(`<img src="`+vImglogo+`"/>`);
		pri.document.close();//-*/
	}
	//--END INIT--/

	//--CONTENT--/
	const contentFormnota = () => {
		if(uHtml500msg) return (<></>);

		return (
		<CForm>
		<div className="d-flex justify-content-between">
			<CFormCheck size="sm"
				onChange={(e)=>hdlCheckLogo(e)}
				checked={((uFormdataObj.view_image_nota||"YA")==="YA")}
				label={(uBahasaObj.caption_denganlogo||"caption_denganlogo").toUpperCase()}
				value={"YA"} 
				id="inpwithlogo"/> 
			{(uFormdataObj.url_image_nota||"")==="" ? (
			<div>
				<input
					type="file"
					accept="image/png,image/jpeg,image/gif"
					ref={refFileImg}
					onChange={(e)=>hdlChangeLogo(e)}
					className="d-none"
					id="inpfileimg"/>

				<CBadge color="dark">{(uBahasaObj.caption_logodefault||"caption_logodefault").toUpperCase()}</CBadge>

				{(uTokenObj.userhak==="SUPERADMIN" || uTokenObj.userhak==="PENGELOLA") && (
				<>
				<span className="mx-2">&middot;</span>
				<CTooltip content={"Ganti Logo Nota"}>
				<CLink className="classcursorpointer"
					onClick={()=>hdlKlikPilihlogo()}>
					<CIcon icon="cilNoteAdd" className="align-middle" height={25}/>
				</CLink>
				</CTooltip>
				</>
				)}
			</div>
			) : (
			<div>
				<CBadge color="success">
					{(uBahasaObj.caption_logocustom||"caption_logocustom").toUpperCase()}
				</CBadge>
				{(uTokenObj.userhak==="SUPERADMIN" || uTokenObj.userhak==="PENGELOLA") && (
				<>
					<span className="mx-1">&middot;</span>
					<CTooltip content="Hapus Logo Nota">
					<CLink className="classcursorpointer classikontabel classikonhapus"
						onClick={()=>hdlKlikHapuslogo()}/>
					</CTooltip>
				</>
				)}
			</div>
			)}
		</div>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2 mx-0">
			<CCol xs="4">Nama Klinik</CCol>
			<CCol xs="8" className="text-end fw-bolder">{uSystemdataObj.nama_klinik||""}</CCol>
		</CRow>
		<CRow className="my-2 mx-0">
			<CCol xs="4">Alamat</CCol>
			<CCol xs="8" className="text-end fw-bolder">{UFunc.renderHTML(uSystemdataObj.alamat||"")}</CCol>
		</CRow>
		<CRow className="my-2 mx-0">
			<CCol xs="4">Telp/HP</CCol>
			<CCol xs="8" className="text-end fw-bolder">{uSystemdataObj.telp||"-"}</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2 mx-0">
			<CCol xs="6" lg="3">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_lebarkertas||"caption_lebarkertas")}</div>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					step="5" min="0"
					className="text-end"
					value={uFormdataObj.lebarkertas||"0"}
					onChange={(e)=>{uFormdataObj.lebarkertas=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>(e.target.select())}
					id="inplebarkertas"/>
				<CInputGroupText><small>mm</small></CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol lg="3" className="d-none d-lg-block"/>
			<CCol xs="6" lg="3">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_marginkiri||"caption_marginkiri")}</div>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					min="0" step="5"
					className="text-end"
					value={uFormdataObj.marginkiri||"0"}
					onChange={(e)=>{uFormdataObj.marginkiri=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>(e.target.select())}
					id="inpmarginkiri"/>
				<CInputGroupText><small>mm</small></CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol md="3" className="d-none d-lg-block"/>
		</CRow>

		<CRow className="my-2 mx-0">
			<CCol xs="6" lg="3">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_fontsize||"caption_fontsize")}</div>
				<CInputGroup size="sm">
				<CFormInput type="number" size="sm"
					min={7} max={20}
					className="text-end"
					value={uFormdataObj.fontsize||"0"}
					onChange={(e)=>{uFormdataObj.fontsize=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>(e.target.select())}
					id="inpfontsize"/>
				<CInputGroupText>px</CInputGroupText>
				</CInputGroup>
			</CCol>
			<CCol lg="3" className="d-none d-lg-block"/>
			<CCol xs="6">
				<div className="classpetunjuk px-0">{(uBahasaObj.caption_fontfamily||"caption_fontfamily")}</div>
				<CFormSelect size="sm"
					value={uFormdataObj.fontstyle}
					onChange={(e)=>{uFormdataObj.fontstyle=e.target.value;setEfekview(!uEfekview)}}
					id="inpfontfamily">
					{uFontstyleArr.map((vItems,vKeys)=>{
						return (<option value={vItems.value} key={vKeys}>{vItems.caption}</option>);
					})}
				</CFormSelect>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<div className="my-2 mx-0 fst-italic text-center fw-bolder">
			{uSystemdataObj.footer_kasir||""}
		</div>

		<div className="my-3 mt-5 classborderbottom"/>

		<div className="d-flex justify-content-between">
			<MyButton
				onClick={()=>hdlKlikSimpan()}
				id="btnFormsimpan">{uBahasaObj.word_simpan||"Simpan"}</MyButton>
			<CButton size="sm"
				onClick={()=>hdlKlikTestprint()}
				className="px-3 fw-bolder"
				id="btnTestprint">
				Test Print<CIcon icon="cilArrowRight" className="ms-2 align-middle"/>
			</CButton>
		</div>
		</CForm>
		)
	}
	const contentTampilannota = () => {
		if(uHtml500msg) return (<></>);

		let vHTMLs			= UFunc.Rendernota(uDatanotaObj);
		const vIsWithlogo	= (uSystemsettingObj.view_image_nota||"YA")==="YA";
		const vURLlogo		= uSystemsettingObj.url_image_nota||uUrllogoDefault;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<div>
				<img src="`+vURLlogo+`" height="110"/>
			</div>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uSystemdataObj.telp || "") !== "")
			vTelpPerusahaan += `
			<div>`+
				(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uSystemdataObj.telp || "")+
			`</div>
			`;
		//---END TELP--/

		const vFooternota = (uSystemdataObj.footer_kasir||"").trim() !=="" ?
			`&nbsp;
			<hr class="classnotahr"/>
			<div align="center">`+(uSystemdataObj.footer_kasir||"")+`</div>
			` : ``;

		const vNotaHR	= `<hr class="classnotahr"`;

		vHTMLs 		= vHTMLs.replace(/<hr/g,vNotaHR);
		vHTMLs 		= vHTMLs.replace(/_DIVLOGOIMAGE_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uSystemdataObj.nama_klinik || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uSystemdataObj.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_DIVROWTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_DIVFOOTERNOTA_/g,vFooternota);

		return (
		<div style={{
			fontFamily:uFontstyle,
			fontSize:uFontsize * 1.25,
		}}>
			{UFunc.renderHTML(vHTMLs)}
		</div>
		)
	}
	const contentHTMLnota = () => {
		if(UFunc.isEmpty(uDatanotaObj)) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uSystemsettingObj.view_image_nota||"YA")==="YA";
		const vURLlogo		= (uSystemsettingObj.url_image_nota||uUrllogoDefault);//+"?"+(new Date()).getTime();

		const vMainbody	= UFunc.Rendernota(uDatanotaObj);
		vHTMLs 			+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<div>
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm"/>
			</div>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uSystemdataObj.telp || "") !== "")
			vTelpPerusahaan += `
			<div>`+
				(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uSystemdataObj.telp || "")+
			`</div>
			`;
		//---END TELP--/

		const vFooternota = (uSystemdataObj.footer_kasir||"").trim() !=="" ?
			`&nbsp;
			<hr/>
			<div align="center">`+(uSystemdataObj.footer_kasir||"")+`</div>
			` : ``;
		
		vHTMLs 		= vHTMLs.replace(/_DIVLOGOIMAGE_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uSystemdataObj.nama_klinik || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uSystemdataObj.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_DIVROWTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_DIVFOOTERNOTA_/g,vFooternota);

		return vHTMLs;
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs		= pjson.homepage+"api/api_init/pr_session";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Nota - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Nota - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		const vSettingcetakObj 	= {
			lebarkertas : parseInt(uFormdataObj.lebarkertas),
			marginkiri : parseInt(uFormdataObj.marginkiri),
			fontsize : parseInt(uFormdataObj.fontsize),
			fontstyle : uFormdataObj.fontstyle||uFontstyleArr[0].value,
		}

		const vBtnsimpan= document.getElementById("btnFormsimpan");
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiProsessimpan) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uFormdataObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_nota/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				//console.log("(Nota - apiProsessimpan) output_string.systemsetting : "+output_string.systemsetting);

				const vSystemsettingObj = JSON.parse(output_string.systemsetting || JSON.stringify(uSystemsettingObj));
				uDispatch({type: "set", gSystemsetting: vSystemsettingObj});

				localStorage.setItem("settingnota",JSON.stringify(vSettingcetakObj));
				setFormdataObj({});
				showToast(uBahasaObj.pesan_sukses||"pesan_sukses","SUKSES");
				initImgcache(vSystemsettingObj.url_image_nota);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Nota - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Nota - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

		const vHeightmost = document.getElementById("idcardview").offsetHeight;
		document.getElementById("idcardform").style.minHeight = vHeightmost+"px";

    	return () => {
    		setFirstload("YA");
    		setFormdataObj({});
    	}
	},[])
	React.useEffect(()=>{
		if(!uFrameExport) return;
		initImgcache(uSystemsettingObj.url_image_nota||uUrllogoDefault);
	},[uFrameExport])
	React.useEffect(()=>{
		if(uFormdataObj.view_image_nota === undefined)
			uFormdataObj.view_image_nota = uSystemsettingObj.view_image_nota;
		if(uFormdataObj.url_image_nota === undefined) {
			if((uSystemsettingObj.url_image_nota||"").toUpperCase() === uUrllogoDefault.toUpperCase())
				uFormdataObj.url_image_nota = "";
			else
				uFormdataObj.url_image_nota = uSystemsettingObj.url_image_nota;
		}
		if(uFormdataObj.lebarkertas === undefined)
			uFormdataObj.lebarkertas = uLebarKertas;
		if(uFormdataObj.marginkiri === undefined)
			uFormdataObj.marginkiri = uMarginKiri;
		if(uFormdataObj.fontstyle === undefined)
			uFormdataObj.fontstyle = uFontstyle;
		if(uFormdataObj.fontsize === undefined)
			uFormdataObj.fontsize = uFontsize;
	},[uFormdataObj,
	uFormdataObj.view_image_nota,
	uFormdataObj.lebarkertas,
	uFormdataObj.marginkiri,
	uFormdataObj.fontstyle,
	uFormdataObj.fontsize,
	])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg} hdlReset={apiLoaddata()} isWithrefresh={true}/> )

	return (
		<>
		<CRow className="mx-0">
		<CCol className="px-0 pe-md-1">
		<CCard className="classcardbg" id="idcardform">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<b>{uBahasaObj.caption_settingnota||"Setting Nota"}</b>
		<CLink onClick={()=>hdlKlikReset()} className="classikontabel classikonreset classcursorpointer"/>
		</CCardHeader>

		<CCardBody className="">
		{contentFormnota()}
		<img src={uSystemsettingObj.url_image_nota||uUrllogoDefault} className="d-none"/>
		</CCardBody>

		<CCardFooter className="">
		<div className="classpetunjuk">
		*) NOTE:
		<ol className="">
			<li>Lebar Kertas Apabila diisi NOL, akan diset AUTO..</li>
			<li>Untuk melakukan TEST Print, semua perubahan harus di-SIMPAN dulu..</li>
		</ol>
		</div>
		</CCardFooter>
		</CCard>
		</CCol>
		
		<CCol className="px-0 ps-md-1 d-none d-md-block">
		<CCard className="classcardbg" id="idcardview">
		<CCardHeader><b>Tampilan Nota</b></CCardHeader>
		<CCardBody>{contentTampilannota()}</CCardBody>
		</CCard>
		</CCol>
		</CRow>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	)
}	

export default Nota