import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	//CImage,
	CLink,
	CTooltip,
	CSpinner,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Barangmasuk 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? 20 : 100;
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 			= uHeaderActionObj.isHeaderTambah || false;
	
	//const uLastinitObj 				= useSelector(state => state.gInitTabelSelected);// || {};
	//const [uInitselectedObj,setInitselectedObj] = React.useState({});

	//--LOAD_BY_PAGE--/
	const uTabelSelectObj 			= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 			= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//--END LOAD_BY_PAGE--/

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikEdit = (_IDTABEL) => {}
	const hdlKlikExpandcollapse = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper	= (UFunc.TglAngka(uDatamainArr[vArridx].tgl_masuk)+
			((uDatamainArr[vArridx].no_nota||"").trim() !== "" 
				? (" - ["+uDatamainArr[vArridx].no_nota+"]") : "")+
			"").toUpperCase();
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		if((uDatamainArr[vArridx].status_expand||false)===true 
			|| (uDatamainArr[vArridx].items_arr||[]).length > 0 
			) {
			uDatamainArr[vArridx].status_expand = !(uDatamainArr[vArridx].status_expand||false);
			setEfekview(!uEfekview);
			return;
		}
		apiLoaditem(_IDTABEL);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionupper	= (UFunc.TglAngka(uDatamainArr[vArridx].tgl_masuk)+
			((uDatamainArr[vArridx].no_nota||"").trim() !== "" 
				? (" - ["+uDatamainArr[vArridx].no_nota+"]") : "")+
			"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionupper+"</B>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT--/
	const initResetview = () => {
    	setDatamainArr([]); setJmlData(0);
    	setDataselect({}); setRowselect(-1);
    	//setPageActive(1);
    }
	const initJumlahdata = () => {
		const vElJmlmobile	= document.getElementById("idjmldatamobile");
		const vKeyword 		= uKeywordObj.kwd_barangmasuk || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			vElJmlmobile && (vElJmlmobile.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		vElJmlmobile && (vElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow=(_IDTABEL)=>{
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_barangmasuk || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= uDatamainArr.filter(vItems => vItems.pg = uPageActive);
		const vDataviewArr	= uDatamainArr.filter(vItems =>
			parseInt(vItems.pg||1) === parseInt(uPageActive));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="align-top px-0 pr-1 classfontsmaller">No</th>
			<th className="align-top px-0">
			<small className="classfontsmaller">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"Tanggal"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.caption_nonota||"No.Nota"}</CCol>
			<CCol md="6" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</small>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(vDataviewArr)}</th></tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,tgl_masuk,no_nota,
				barang_id_first,barang_caption_first,barang_qty_first,
				items_arr,status_expand,status_loading
			} = vItems;

			vNumber++;
			const vCaptionupper	= (UFunc.TglAngka(tgl_masuk)+
				((no_nota||"").trim() !== "" ? (" - ["+no_nota+"]") : "")+
				"").toUpperCase();

			const vNonota	= vKeyword==="" ? (no_nota||"-") 
				: (no_nota||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tgl_masuk) 
				: UFunc.TglAngka(tgl_masuk).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

			const vItemArr 	= items_arr || [];
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 align-top text-end">{(vNumber)+"."}</td>

			<td className="px-2 px-md-0 align-top text-end">
			<CRow className="mx-0">
			<CCol md="2" className="d-none d-md-block px-1 text-md-start">
				<div>{UFunc.renderHTML(vTanggal)}</div>

				<div className="text-danger classfontsmaller">
				<small>[
				{UFunc.DbDate(UFunc.toDatetime(tgl_input)) !== UFunc.DbDate(UFunc.toDate(tgl_masuk)) && (
				<span className="d-md-none d-lg-inline">
					{UFunc.WaktuAngka(tgl_input||"")}
					<span className="mx-1">&middot;</span>
				</span>
				)}
				{UFunc.renderHTML(vUserinput)}
				]</small>
				</div>
			</CCol>

			<CCol xs="12" className="px-1 d-md-none">
			<div className="d-flex justify-content-between">
				<span className="text-info text-capitalize">{UFunc.TglAngka(tgl_masuk)}</span>

				{(uIsHapusallow) && (
				<>
				<CDropdown className="d-md-none align-items-start">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="text-primary" height={18}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem className="d-none" onClick={()=>hdlKlikEdit(id)}>
						<CIcon className="classikonedit me-2 align-middle" height={25}/>
						{uBahasaObj.caption_editdata||"Edit Data"}
					</CDropdownItem>
							
					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="align-middle classikonhapus me-2" height={25}/>
						{uBahasaObj.caption_hapusdata||"Hapus Data"}
					</CDropdownItem>
				</CDropdownMenu>
				</CDropdown>
				</>
				)}
			</div>
			</CCol>

			<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_nonota||"No.Nota"}</CCol>
			<CCol xs="8" md="3" className="px-1 text-md-start">{UFunc.renderHTML(vNonota)}</CCol>

			<CCol xs="12" md="6" className="px-1 text-start">
			<div className="py-2 pt-md-0 d-flex align-items-center justify-content-between justify-content-md-start">
			<b>Item Barang</b>
			
			<span className="mx-1 d-none d-md-block">&middot;</span>

			{(status_loading||false)===false ? (
			<CLink className="text-decoration-none classcursorpointer"
				onClick={()=>hdlKlikExpandcollapse(id)}>
				{(status_expand||false)===false ? (
				<CIcon icon="cilArrowBottom" className="align-middle" 
					height={23}/>
				) : (
				<CIcon icon="cilArrowTop" className="align-middle" height={23}/>
				)}
			</CLink>
			) : (
			<CSpinner color="primary" size="sm" className="mx-1 align-middle"/>
			)}
			</div>

			<ul className="d-block w-100 m-0 ps-4 classfontsmaller">
			{(status_expand||false)===false ? (
			<li className="text-muted">
				<div className="">{barang_caption_first} ...</div>
			</li>
			) : (
			<>
			{vItemArr.map((vItembarang,vKeybarang)=>{
				const {
					id,barang_id,barang_caption,barang_qty
				} = vItembarang;
				
				const vBarangcaption	= vKeyword==="" ? (barang_caption||"-") 
					: (barang_caption||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
				return (
				<li className="" key={vKeybarang}>
				<span className="fst-italic">{UFunc.renderHTML(vBarangcaption)}</span>
				<span className="me-2">:</span>
				<b>{UFunc.formatAngka(barang_qty)} PCS</b>
				</li>
				)
			})}
			</>
			)}
			</ul>
			</CCol>

			<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

			<CCol md="1" className="px-0 text-md-center d-none d-md-block">
				<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="d-none classikontabel classikonedit classcursorpointer"/>
				</CTooltip>

				{uIsHapusallow && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
				</CTooltip>
				</>
				)}
			</CCol>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
			<CCol xs="8" className="px-1 text-danger d-md-none">
				<small className="classfontsmaller">[
				{UFunc.DbDate(UFunc.toDatetime(tgl_input)) !== UFunc.DbDate(UFunc.toDate(tgl_masuk)) && (
				<>
					{UFunc.WaktuAngka(tgl_input||"")}
					<span className="mx-1">&middot;</span>
				</>
				)}
				{UFunc.renderHTML(vUserinput)}
				]</small>
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = (_PAGE) => {
		setHtml500msg();
		const vPage	= parseInt(_PAGE)||1;

		const vHandelview	= ((uHandelView||false)===true || uFirstload==="YA")
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_barangmasuk || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==vPage);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: vPage,
			send_maxdata: uMaxData,
			send_loadjml	: vHandelview,
			//send_loadjml: _PAGE ? "TIDAK" : "YA",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangmasuk/ld_data";
		//console.log("(Barangmasuk - apiLoaddata) uFirstload : "+uFirstload);

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vTmpArr]);

				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Barangmasuk - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg(vMsg);
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Barangmasuk - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].status_loading = true;
		setEfekview(!uEfekview);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].status_expand  = true;
			uDatamainArr[vArridx].status_loading = false;
			uDatamainArr[vArridx].items_arr = [
				{id:"1",barang_id:"3",barang_caption:"Nama Barang Caption 1",barang_qty:5},
				{id:"2",barang_id:"4",barang_caption:"Nama Barang Caption 2",barang_qty:5},
			];
			setEfekview2(!uEfekview2);
		},2500); return//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangmasuk/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].status_loading = false;
			if(output_string.tampil) {
				uDatamainArr[vArridx].status_expand  = true;
				uDatamainArr[vArridx].items_arr = JSON.parse(output_string.dataobject||"[]");
				setEfekview2(!uEfekview2);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Barangmasuk - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].status_loading = false;
			console.log("(Barangmasuk - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = (_IDTABEL) => {}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiProsessimpan) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangmasuk/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Barangmasuk - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Barangmasuk - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{//--JIKA_PAGINATION_DIATUR_BACKEND--/
		//console.log("(Barangmasuk - [uIsInitbefore]) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit); apiLoaddata(vPageinit);
	},[uIsInitbefore]);
	React.useEffect(()=>{
		setFirstload("YA");
		initResetview(); 

		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) {
			//console.log("(Barangmasuk - []) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
			apiLoaddata();
		}

		return () => {
			setDatamainArr([]);
			uDispatch({type: "set", gInitTabelSelected: {}});
		}
	},[])
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		initResetview();
		if(uPageActive > 1) setPageActive(1);
		else apiLoaddata()
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("(Barangmasuk-[uHandelTambah]) uHandelTambah = "+uHandelTambah)
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uDispatch({type: "set", gInitTabelSelected: {}})
		uNavigate("/barangmasuk/form/0");
	},[uHandelTambah])
	React.useEffect(()=>{
		if(!uElJmlData) return;
		initJumlahdata();
	},[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		initJumlahdata();
	},[uJmlData])
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;

		setDataselect({});
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;

		uDatamainArr.map(vItems=>{
			const vItemArr = vItems.items_arr || [];
			if((parseInt(vItems.jml_item_first)||0) === 1 && vItemArr.length <= 0) {
				const vTmpObj = {
					id: vItems.id_first||"0",
					barang_id: vItems.barang_id_first||"0",
					barang_caption: vItems.barang_caption_first||"UNDF",
					barang_qty: vItems.barang_qty_first||"0"
				};
				vItems.items_arr = [...vItemArr,vTmpObj];
			}
		});
	},[uDatamainArr,uDatamainArr.length])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<span className="text-primary">{uBahasaObj.caption_rekapinputdata||"Rekap Input Data"}</span>
		
		<div>
			<small className="me-2 d-md-none">
			(<b className="text-primary" id="idjmldatamobile">0</b>) &middot;
			</small>

			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
			</span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		</>
	)
}	

export default Barangmasuk