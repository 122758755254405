import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	//CImage,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Rekapnota 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? 20 : 100;
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]= React.useState(); 
	const [uTimeelapse,setTimeelapse]= React.useState(0); 

	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);
	
	const uHeaderActionObj 		 	= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 			= uHeaderActionObj.isHeaderTambah || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--LOAD_BY_PAGE--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//--END LOAD_BY_PAGE--/

	//--NOTA_CETAK--/
	const [uDatanotaObj,setDatanotaObj]		= React.useState({})
	const uHTMLstylenota					= useSelector(state => state.gHTMLstyleNota);
	const uSettingcetakObj					= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas						= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri						= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize							= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle						= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 						= document.getElementById("ifmcontentstoprint");
	//--END NOTA_CETAK--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikToggleitem = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		initSelectrow(_IDTABEL);

		if((uDatamainArr[vArridx].init_expand||"TIDAK")==="YA") {
			uDatamainArr[vArridx].init_expand = "TIDAK";
			setEfekview(!uEfekview); return;
		}

		if((uDatamainArr[vArridx].items_arr||[]).length > 0) {
			uDatamainArr[vArridx].init_expand = "YA";
			setEfekview(!uEfekview); return;
		}

		apiLoaditem(_IDTABEL);
	}
	const hdlKlikCetaknota = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].no_nota||"").toUpperCase();
		initSelectrow(_IDTABEL);

		if(!UFunc.isEmpty(uDatamainArr[vArridx].nota_obj)) {
			setDatanotaObj(uDatamainArr[vArridx].nota_obj);
			return;
		}

		apiLoadnota(_IDTABEL);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].no_nota||"").toUpperCase();
		initSelectrow(_IDTABEL);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionupper+"</B>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormreset = () => {}
	const initResetview = () => {
    	setPageActive(1);
    	setJmlData(0);
    	setDatamainArr([]); 
    	setDataselect({});
    	setRowselect(-1);
    	setEfekview(!uEfekview);
    }
	const initJumlahdata = () => {
		const vElJmlmobile	= document.getElementById("idjmldatamobile");
		const vKeyword 		= uKeywordObj.kwd_rekapnota || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			vElJmlmobile && (vElJmlmobile.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		vElJmlmobile && (vElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowIdx);
		setEfekview(!uEfekview)
	}
	const prosesCetaknota = () => {
		let vContent 	= contentNota();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setDatanotaObj({});
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_rekapnota || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=>vItems.pg === uPageActive)
		//const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width="5%" className="align-top px-0">No</th>
			<th className="px-0">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.caption_tglbayar||"Tgl Bayar"}</CCol>
			<CCol md="2" className="text-start">{uBahasaObj.word_nota||"Nota"}</CCol>
			<CCol md="2" className="text-start">{uBahasaObj.word_pasien||"Pasien"}</CCol>
			<CCol md="3" lg="4" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="2" lg="1" className="">{uBahasaObj.caption_nbayar||"N.Bayar"} (Rp)</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,no_nota,tgl_bayar,jenis_bayar,
				id_pasien,caption_pasien,telp_pasien,nilai_bruto,diskon,nilai_bayar,
				items_arr,nama_barang_first,is_itemloading,init_expand,is_notaloading
			} = vItems;

			vNumber++;
			const vCaptionupper	= (no_nota||"").toUpperCase();

			const vTgltransaksi	= vKeyword==="" ? UFunc.TglAngka(tgl_bayar) 
				: (UFunc.TglAngka(tgl_bayar)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vNonota	= vKeyword==="" ? (no_nota||"Undf") 
				: (no_nota||"Undf").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPasiennama	= vKeyword==="" ? (caption_pasien||"Undf") 
				: (caption_pasien||"Undf").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vPasientelp	= vKeyword==="" ? (telp_pasien||"") 
				: (telp_pasien||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKasir	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vItemArr		= items_arr || [];
			const vJenisbayar	= (jenis_bayar||"DP")==="DP" ? "Billing Penunjang" : "Piutang Penunjang";

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 pe-1">{vNumber}.</td>

			<td className="px-1 ps-1">
			<CRow className="mx-0">
			<CCol md="2" className="d-none d-md-block px-1 text-md-start">
				<div>{UFunc.renderHTML(vTgltransaksi)}</div>

				<div className="classfontsmaller">
				<small>Kasir: <i className="text-danger">{UFunc.renderHTML(vKasir)}</i></small>
				</div>
			</CCol>

			<CCol xs="12" md="2" className="d-flex justify-content-between px-1 text-start">
				<div>
				<div className="fw-bolder text-uppercase">{UFunc.renderHTML(vNonota)}</div>

				<div className="d-none d-md-block classfontsmaller">
					<small className={"text-uppercase"+(jenis_bayar==="DP"?"":" text-danger")}>
					&middot;{UFunc.renderHTML(vJenisbayar)}&middot;
					</small>
				</div>
				</div>
			
				<div className="d-md-none">
				{(is_notaloading||false)===true ? (
					<CSpinner color="primary" size="sm"/>
				) : (
				<CDropdown>
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox align-top" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetaknota(id)}>
						<CIcon className="classikoncetak classikonbox m-0 me-2" height={25}/>
						{uBahasaObj.caption_cetakcopynota||"Cetak Copy Nota"}
					</CDropdownItem>
							
					{(uIsHapusallow) && (
					<>
					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="classikonhapus align-middle me-2" height={25}/>
						{uBahasaObj.caption_hapusnota||"Hapus Nota"}
					</CDropdownItem>
					</>
					)}
				</CDropdownMenu>
				</CDropdown>
				)}
				</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_tglbayar||"Tgl Bayar"}</CCol>
			<CCol xs="6" className="px-1 d-md-none">{UFunc.TglAngka(tgl_bayar)}</CCol>

			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_jenisbayar||"Jenis Pembayaran"}</CCol>
			<CCol xs="6" className="px-1 d-md-none"><small>{UFunc.renderHTML(vJenisbayar)}</small></CCol>

			<CCol xs="4" className="px-1 d-md-none text-start">Pasien</CCol>
			<CCol xs="8" md="2" className="px-1 text-md-start">
				<div className="text-info text-capitalize">{UFunc.renderHTML(vPasiennama)}</div>
				{(telp_pasien||"")!=="" && (
				<div className="classfontsmaller">
					<small>Telp/HP: <b>{UFunc.renderHTML(vPasientelp)}</b></small>
				</div>
				)}
			</CCol>

			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_kasir||"Inputby"}</CCol>
			<CCol xs="6" className="px-1 text-danger d-md-none">
				<small>{UFunc.renderHTML(vKasir)}</small>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="12" md="3" lg="4" className="px-1 text-start">
				<div className="d-flex justify-content-between justify-content-md-start mb-1">
				<small className="fw-bolder">Items Barang Penunjang:</small>
				{(is_itemloading||false)===true ? (
					<CSpinner color="primary" size="sm" className="ms-3 align-top"/>
				) : (
					<CLink onClick={()=>hdlKlikToggleitem(id)} className="classcursorpointer">
					{(init_expand||"TIDAK")==="YA" ? (
					<CIcon icon="cilArrowTop" className="align-middle ms-2" height={23}/>
					) : (
					<CIcon icon="cilArrowBottom" className="align-middle ms-2" height={23}/>
					)}
					</CLink>
				)}
				</div>

				<ol className="classfontsmaller m-0 mb-1 mb-md-0 ps-md-3">
				{(init_expand||"TIDAK")==="YA" ? (
				<>
				{vItemArr.map((vItembarang,vKeybarang)=>{
					const vNamabarang	= vKeyword==="" ? (vItembarang.nama_barang||"") 
						: (vItembarang.nama_barang||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

					return (
					<li key={vKeybarang}>
					<i>{UFunc.renderHTML(vNamabarang)}</i>
					<span className="mx-1">&middot;</span>
					(<b>{UFunc.formatAngka(vItembarang.qty)}</b> PCS x {UFunc.formatAngka(vItembarang.harga_satuan)})
					</li>
					)
				})}
				</>
				) : (
				<li className="text-muted fst-italic">{nama_barang_first||"nama_barang_first"}...</li>
				)}
				</ol>

				<div className="d-none d-md-block">
					<div className="classborderbottom my-2"/>

					<div className="d-flex justify-content-between">
					<div className="w-50 fw-bolder">{uBahasaObj.caption_ntransaksi||"N.Transaksi"}</div>
					<div className="w-50 text-end text-primary">Rp {UFunc.formatAngka(nilai_bruto)}</div>
					</div>

					{parseFloat(diskon||0) > 0 && (
					<div className="d-flex justify-content-between">
					<div className="w-50 fw-bolder">{uBahasaObj.word_diskon||"Diskon"}</div>
					<div className="w-50 text-end text-danger">Rp {UFunc.formatAngka(diskon)}</div>
					</div>
					)}
				</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_ntransaksi||"N.Transaksi"}</CCol>
			<CCol xs="6" className="px-1 d-md-none">{UFunc.formatAngka(nilai_bruto)}</CCol>

			{parseFloat(diskon||0) > 0 && (
			<>
			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.word_diskon||"Diskon"}</CCol>
			<CCol xs="6" className="px-1 d-md-none text-danger">{UFunc.formatAngka(diskon)}</CCol>
			</>
			)}

			<CCol xs="6" className="px-1 d-md-none text-start">{uBahasaObj.caption_nbayar||"N.Bayar"}</CCol>
			<CCol xs="6" md="2" lg="1" className="px-1">
				<b className="text-success">{UFunc.formatAngka(nilai_bayar)}</b>
			</CCol>

			<CCol md="1" className="px-0 text-md-center d-none d-md-block">
			{(is_notaloading||false)===true ? (
				<CSpinner color="primary" size="sm"/>
			) : (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_cetakcopynota||"Cetak Copy Nota")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikCetaknota(id)} className="classcursorpointer">
					<CIcon className="classikoncetak classikonbox m-0 me-1" height={25}/>
					</CLink>
				</CTooltip>

				{uIsHapusallow && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
				</CTooltip>
				</>
				)}
				</>
			)}
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentNota = () => {
		//console.log("uDatanotaObj = "+JSON.stringify(uDatanotaObj||{}));
		if(UFunc.isEmpty(uDatanotaObj)) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uSystemsettingObj.view_image_nota||"YA")==="YA";
		const vURLlogo		= uSystemsettingObj.url_image_nota||"";//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(uDatanotaObj);
		vHTMLs 			+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<div>
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</div>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uSystemdataObj.telp || "") !== "")
			vTelpPerusahaan += `
			<div>`+
				(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uSystemdataObj.telp || "")+
			`</div>
			`;
		//---END TELP--/

		const vFooternota = (uSystemdataObj.footer_kasir||"").trim() !=="" ?
			`&nbsp;
			<hr/>
			<div align="center">`+(uSystemdataObj.footer_kasir||"")+`</div>
			` : ``;
		
		const vCopynota = `
			<div align="center">-- C O P Y --</div>
			<hr/>
			<table id="idtabelheader"
			`;

		vHTMLs 		= vHTMLs.replace(/_DIVLOGOIMAGE_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uSystemdataObj.nama_klinik || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uSystemdataObj.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_DIVROWTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_DIVFOOTERNOTA_/g,vFooternota);
		vHTMLs 		= vHTMLs.replace(/<table id="idtabelheader"/g,vCopynota);
		
		return vHTMLs;
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = (_PAGE) => {
		setHtml500msg();
		const vPage	= parseInt(_PAGE)||1;

		const vHandelview	= 
			((uHandelView||false)===true || uFirstload==="YA" || uDatamainArr.length <= 0)
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_rekapnota || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==vPage);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: vPage,
			send_maxdata: uMaxData,
			send_loadjml : vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekapnota/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vTmpArr]);

				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}

				if(uFirstload==="YA") { setFirstload("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekapnota - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Rekapnota - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_itemloading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("(Rekapnota - apiLoaditem) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			uDatamainArr[vArridx].is_itemloading = false;
			uDatamainArr[vArridx].init_expand = "YA";
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekapnota/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_itemloading = false;
			setSessionaktif(true);
			if(output_string.tampil) {
				uDatamainArr[vArridx].items_arr 	= JSON.parse(output_string.dataitem||"[]");
				uDatamainArr[vArridx].init_expand 	= "YA";
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekapnota - apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_itemloading = false;
			console.log("(Rekapnota - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadnota = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_notaloading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("(Rekapnota - apiLoadnota) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].is_notaloading = false;
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekapnota/ld_nota";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_notaloading = false;
			setSessionaktif(true);
			if(output_string.tampil) {
				//console.log("(Rekapnota - apiLoadnota) output_string.notaobject : "+output_string.notaobject);
				const vTmpObj	= JSON.parse(output_string.notaobject||"{}");
				uDatamainArr[vArridx].nota_obj = vTmpObj;
				setDatanotaObj(vTmpObj);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekapnota - apiLoadnota) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_notaloading = false;
			console.log("(Rekapnota - apiLoadnota) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(Rekapnota - apiLoaditem) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekapnota/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Rekapnota - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Rekapnota - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/


	React.useEffect(()=>{
		//console.log("(Barangmasuk - [uIsInitbefore]) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit);
		apiLoaddata(vPageinit);//--JIKA_PAGINATION_DIATUR_BACKEND--/
	},[uIsInitbefore]);
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		if(!uIsInitbefore) {
			apiLoaddata();
		}

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA");
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		//console.log("(Rekapnota-[uHandelView]) uHandelView = "+uHandelView)
		if(!uHandelView) return;

		initResetview();
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uPageActive > 1) setPageActive(1); else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		initJumlahdata();
	},[uJmlData])
	React.useEffect(()=>{
		if(uFirstload==="YA") return;
		if(uHandelView) return;

		setDataselect({});
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		console.log("(Billingtransaksi - [uPageActive]) uHandelView : "+uHandelView);
		apiLoaddata(uPageActive);
	},[uPageActive])
	React.useEffect(()=>{
		if(!uFrameExport) return;

		const pri 		= uFrameExport.contentWindow; if(!pri) return;
		const vURLlogo	= uSystemsettingObj.url_image_nota;
		pri.document.open();
		pri.document.write(`<img src="`+vURLlogo+`"/>`);
		pri.document.close();
	},[uFrameExport])
	React.useEffect(()=>{
		if(UFunc.isEmpty(uDatanotaObj)) return;

		prosesCetaknota();
	},[uDatanotaObj])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<span className="text-primary">Rekap Pembayaran Penunjang</span>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary" id="idjmldatamobile">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<span className="classfontsmaller"><small className="text-muted">{UFunc.formatAngka(uTimeelapse)}ms</small></span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	)
}	

export default Rekapnota