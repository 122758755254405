import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderDivider,
  CImage
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

const ContHeaderSub = (props) => {
	const { setToken,setLoading, showToast } = props 
	const uActiveroute			= useSelector(state => state.activeRoute);

	if((uActiveroute||"").trim() === "") return (<></>);

	const vRoutename = "Headersub"+(uActiveroute||"").trim().toLowerCase();
	let TheComponent;
	try {
		TheComponent = require("../views/headersub/"+vRoutename).default;
	} catch (ex) {
		//console.log("(ContHeaderSub) route.name(ex) = ../views/headersub/"+vRoutename);
	}
	
	if(!TheComponent) return (<></>);

	//console.log("ContHeaderSub - uActiveroute 3 => "+(uActiveroute));
	return (
		<>
		<CHeaderDivider />
		<CContainer fluid className="bg-white">
			<TheComponent {...props}/>
		</CContainer>
		</>
	)
}

export default ContHeaderSub
