import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom'
import { 
	CContainer,
	CFade,
} from '@coreui/react'
import { UFunc } from "../helpers/functions"

const Error404 = require('../views/Error404').default;

const ContContent=(props)=>{
	const uDispatch 			= useDispatch();

	//const uEfekapp 				= useSelector(state => state.effectApp);
	const uRoutes				= JSON.parse(localStorage.getItem("routeslist") || "[]");
	const uPathname 			= (window.location.pathname);

	const vRouteArr				= uRoutes.filter((vItems) => vItems.path === uPathname );
	React.useEffect(()=>{
		if(vRouteArr.length <= 0){ 
			uDispatch({type: "set", activeRoute: ""});
			return;
		}

		let TheComponent;
		try {
			TheComponent = require("../views/"+UFunc.capitalize(vRouteArr[0].name)).default;
		} catch (ex) {
			uDispatch({type: "set", activeRoute: ""});
			return;
		}

		uDispatch({type: "set", activeRoute: vRouteArr[0].name});
	},[vRouteArr])

	//console.log("ContContent - uPathname => "+(uPathname));
	return (
		<CContainer xxl className="px-lg-1">
        <Routes>
		{uRoutes.map((route, idx) => {
			let TheComponent;
			try {
				TheComponent = require("../views/"+UFunc.capitalize(route.name)).default;
			} catch (ex) {
				//console.log("("+uComponentName+") route.name(ex) = ../views/"+route.name);
			}

			return (
				TheComponent && (
				<Route key={idx}
					path={route.path}
					exact={route.exact}
					name={route.name}
					element={<TheComponent {...props}/>}/>
				)
			)
		})}
		<Route exact path="/error404" element={<Error404 {...props}/>}/>
		<Route path="*" element={<Error404 {...props}/>}/>
		</Routes>
		</CContainer>
	)
}

export default ContContent
	