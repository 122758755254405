import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
	CSidebar,
	CSidebarBrand,
	CSidebarNav,
	CSidebarToggler,
	CImage
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ContSidebarNav } from './ContSidebarNav'

// sidebar nav config
//import navigation from '../_nav'

const pjson = require('../../package.json');
const ContSidebar = () => {
	const uDispatch 	= useDispatch()
	const uUnfoldable 	= useSelector((state) => state.sidebarUnfoldable);
	const uSidebarshow	= useSelector((state) => state.sidebarShow)
	const uListnav 		= useSelector((state) => state.listNav)

	//console.log("(ContSidebar) uListnav => "+JSON.stringify(uListnav));
	//console.log("(ContSidebar) navigation => "+JSON.stringify(navigation));
	return (
		<CSidebar position="fixed" unfoldable={uUnfoldable}
			visible={uSidebarshow}
			onVisibleChange={(visible) => {
				uDispatch({ type: 'set', sidebarShow: visible })
			}}
			className="bg-secondary"
			id="idsidebarmain">
		<CSidebarBrand className="d-none d-md-flex">
			<CImage src={"/images/logobw40.png"} className="sidebar-brand-narrow" height={30}/>
			{(()=>{
				const vNameArr = pjson.name.split("tor");
				return (
				<div style={{fontSize:28,marginBottom:"-7px",color:"#f0f0f0"}}
					className="classfontbrand sidebar-brand-full pt-2">
				{vNameArr[0]}t
				<CImage src={"/images/logo192.png"} className="mx-1" height={25}/>
				r{vNameArr[1]}
				</div>
				);
			})()}
		</CSidebarBrand>

		<CSidebarNav>
			<ContSidebarNav items={uListnav} />
		</CSidebarNav>

		<CSidebarToggler className="d-none d-lg-flex"
			onClick={() => uDispatch({ type: 'set', sidebarUnfoldable: !uUnfoldable })}/>
		</CSidebar>
	)
}

export default React.memo(ContSidebar)