import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialogform,
	MyDialoglookup,
	MyPage500,
	MyProfiledetil,
} from '../../components/index'
import {
} from '../../containers/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Formmasuk = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const { tabel_id } 			= useParams(); 
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTabelID 					= parseInt(tabel_id)||0;
	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const [uFirstLoad,setFirstLoad]			= React.useState("YA");
	const [uDataloadObj,setDataloadObj]		= React.useState({})
	const [uDatainputObj,setDatainputObj]	= React.useState({})
	const [uIteminputObj,setIteminputObj]	= React.useState({})
	const [uTabeleditObj,setTabeleditObj]	= React.useState({})
	const [uISFormupdated,setFormupdated]	= React.useState(false)
	const [uDatabarangArr,setDatabarangArr]	= React.useState([])
	const uDatatabelArr						= uDatainputObj.items_arr || [];

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false)
	const [uDlgformObj,setDlgformObj]		= React.useState({})
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false)
	const [uDlglookupObj,setDlglookupObj]	= React.useState({})
	//--END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if(uISFormupdated) {
			if(await(Konfirm(
				"Field Input/Edit Sudah Ada yang Berubah.."+
				"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
				"<br/>"+
				"<br/><b>Apakah Anda Yakin</b> ?"+
				""))) uNavigate(-1);

			return;
		}
		uNavigate(-1);
	}
	const hdlKDownDocument = (_EV) => {
		if(_EV.which === 120) {
			_EV.preventDefault(); _EV.stopPropagation();
			document.getElementById("btnSimpanmain") &&
				document.getElementById("btnSimpanmain").click();
			return;
		}
	}
	const hdlKlikReset = async() => {
		if(uHtml500msg) { apiLoaddata(); return }
		if(!uISFormupdated) { apiLoaddata(); return }

		const vFunc	= () => {
			if(uTabelID > 0) setDatainputObj(uDataloadObj);
			else setDatainputObj({});
			setIteminputObj({});
			setTabeleditObj({});
			setEfekview(!uEfekview)
		}

		if(await(Konfirm(
			"Field Input/Edit Sudah Ada yang Berubah.."+
			"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
			"<br/>"+
			"<br/><b>Apakah Anda Yakin</b> ?"+
			""))) vFunc();
	}
	const hdlKDownItemnama = (_EV) => {
		uIteminputObj.qty = undefined;

		if(_EV.keyCode !== 13) { return; }
		if((uIteminputObj.caption_barang||"").trim() === "") return;

		_EV.stopPropagation()
		_EV.preventDefault()

		const vfilterArr = uDatabarangArr.filter(vItems => 
			vItems.caption_barang.toUpperCase().includes(
				(uIteminputObj.caption_barang||"").trim().toUpperCase()
		))

		if(vfilterArr.length === 1) {
			setIteminputObj({
				id_barang: vfilterArr[0].id,
				caption_barang: vfilterArr[0].caption_barang,
				qty: 0,
			})

			document.getElementById("inpitemqty") &&
				document.getElementById("inpitemqty").focus()
			return
		}

		hdlKlikItemlookup()
	}
	const hdlKlikItemlookup = () => {
		uDlglookupObj.header 		= "Lookup Data Barang";
		uDlglookupObj.inputvalue 	= uIteminputObj.caption_barang||"";
		uDlglookupObj.data_arr		= uDatabarangArr.map(vItems=>{
			return ({
				id: vItems.id,
				caption: vItems.caption_barang
			})
		});

		setDlglookupshow(true);
	}
	const hdlKlikItemtambah = () => {
		if(!initTambahitem()) return;

		document.getElementById("inpitemnama") &&
			document.getElementById("inpitemnama").focus();
	}
	const hdlKlikItemtambahmobile = () => {
		uDlgformObj.header 	= "Tambah Item";
		setDlgformshow(true);
	}
	const hdlKlikTabeleditfield = (_IDXDATA,_FIELD) => {
		const vArridx 	= parseInt(_IDXDATA);
		_FIELD			= _FIELD || "";

		const vItemArr	= uDatatabelArr;//(uDatainputObj.items_arr || []);
		let vValue;
		if(_FIELD === "QTY")
			vValue = parseFloat(vItemArr[vArridx].qty)||0;

		setTabeleditObj({
			idxkey: vArridx,
			field : _FIELD,
			value: vValue,
		});
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
			let vElfocus = document.getElementById("inptabelqty")
			vElfocus && vElfocus.focus()
		},350)
	}
	const hdlKDownTabelfield = (_EV,_IDXDATA) => {
		if(_EV.which===27) {
			_EV.preventDefault();
			setTabeleditObj({}); setEfekview(!uEfekview);
			return;
		}
		if(_EV.which===13) {
			_EV.preventDefault();
			_EV.stopPropagation();

			hdlKlikTabelprosesfield(_IDXDATA);
			return
		}
	}
	const hdlKlikTabelprosesfield = (_IDXDATA) => {
		const vArridx 	= parseInt(_IDXDATA);

		const vItemArr	= uDatatabelArr;//(uDatainputObj.items_arr || []);
		if(uTabeleditObj.field === "QTY")
			vItemArr[vArridx].qty = parseFloat(uTabeleditObj.value)||0;

		uDatatabelArr.status_editdone = !(uDatatabelArr.status_editdone||false);
		setTabeleditObj({}); setEfekview(!uEfekview);
	}
	const hdlKlikTabeleditmobile = (_IDXDATA) => {
		const vArridx 			= parseInt(_IDXDATA);
		uTabeleditObj.idxkey 	= vArridx;
		uIteminputObj.id_barang			= uDatatabelArr[vArridx].id_barang;
		uIteminputObj.caption_barang	= uDatatabelArr[vArridx].caption_barang;
		uIteminputObj.qty				= uDatatabelArr[vArridx].qty;

		setDlgformshow(true);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
			let vElfocus = document.getElementById("inpformqty")
			vElfocus && vElfocus.focus()
		},350)
	}
	const hdlKlikTabelhapus = async(_IDXDATA) => {
		const vArridx 	= parseInt(_IDXDATA);
		const vItemArr	= uDatatabelArr;//(uDatainputObj.items_arr || []);
		const vCaptionupper = (vItemArr[vArridx].caption_barang||"").toUpperCase();

		if(await(Konfirm(
			"Hapus Item <b>"+vCaptionupper+"</b>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) {
			if((parseInt(vItemArr[vArridx].id)||0) <= 0)
				vItemArr.splice(vArridx,1);
			else
				vItemArr[vArridx].init_hapus = "YA";
			setEfekview(!uEfekview);
		}
	}
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgformsimpan = () => {
		const vArridx	= uTabeleditObj.idxkey!==undefined 
			? parseInt(uTabeleditObj.idxkey) : -1;
		if(!initTambahitem(vArridx)) return;

		if(vArridx < 0) {
			showToast("Berhasil !<br/>Tambah / Update Item..","SUKSES");

			setTabeleditObj({});
			document.getElementById("inpitembarangid") &&
				document.getElementById("inpitembarangid").focus();
		}
		else hdlToggleDlgform()
	}
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookup = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0;if(_IDITEM <= 0) return;

		const vArridx = UFunc.getObjectIndeks(uDatabarangArr,_IDITEM);
		if(vArridx < 0) {
			hdlToggleDlglookup(); return;
		}
		setIteminputObj({
			id_barang: uDatabarangArr[vArridx].id,
			caption_barang: uDatabarangArr[vArridx].caption_barang,
			qty: 0,
		})

		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
			document.getElementById("inpitemqty") &&
				document.getElementById("inpitemqty").focus()
		},350)
	}
	const hdlKlikSimpanmain = async() => {
		//--VALIDASI--/
		//console.log("uDatainputObj.tgl_masuk x = "+uDatainputObj.tgl_masuk);
		let vElfocus = document.getElementById("inptglmasuk");
		if((uDatainputObj.tgl_masuk||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Masuk Tidak Valid !"); return;
		}
		const vTglmasukDT = UFunc.toDate(uDatainputObj.tgl_masuk);
		if(UFunc.DateIsBeyond(vTglmasukDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tgl Masuk Melewati Tanggal Hari Ini !"); return;
		}
		vElfocus = document.getElementById("inpitemnama");
		if(uDatatabelArr.length <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Item Barang Belum Diinputkan !"); return;
		}
		//--END VALIDASI--/

		const vTmps	= 
			"<b>Input Data Barang Masuk</b>"+

			"<div class='my-3 classborderbottom'></div>"+

			"<div class='d-flex justify-content-between my-2'>"+
			"<div class=''>Tgl Masuk</div>"+
			"<div class='text-end'>"+UFunc.TglAngka(uDatainputObj.tgl_masuk)+"</div>"+
			"</div>"+

			((uDatainputObj.no_nota||"")!== "" 
				? "<div class='d-flex justify-content-between my-2'>"+
				"<div class=''>No.Nota</div>"+
				"<div class='text-end text-uppercase'>"+(uDatainputObj.no_nota||"-")+"</div>"+
				"</div>"
				: "")+

			"<div class='d-flex justify-content-between my-2'>"+
			"<div class=''>Jml Item</div>"+
			"<div class='text-end'><b>"+UFunc.formatAngka(uDatatabelArr.length)+" ITEM</b>"+
				" &middot; <span class='text-primary'>("+UFunc.formatAngka(uDatainputObj.jmlitem)+" PCS)</span>"+
				"</div>"+
			"</div>"+
			"<div class='my-3 classborderbottom'></div>"+
			"Apakah Anda Yakin ?"+
		"";
		if(await(Konfirm(vTmps))) apiProsessimpan();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initTambahitem = (_IDXDATA) => {
		const vArridx	= _IDXDATA!==undefined ? parseInt(_IDXDATA) : -1;
		if(UFunc.isEmpty(uIteminputObj)) return;

		let vElfocus = document.getElementById("inpformqty");
		if(!vElfocus) vElfocus = document.getElementById("inpitemqty");
		if((parseFloat(uIteminputObj.qty)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Qty Lookup Barang Tidak Valid !"); return;
		}
		vElfocus = document.getElementById("inpformbarangid");
		if(!vElfocus) vElfocus = document.getElementById("inpitemnama");
		if((parseInt(uIteminputObj.id_barang)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("NAMA Lookup Barang Tidak Valid !"); return;
		}

		//--CEK_DATA_SUDAH_ADA_DI_TABEL--/
		const vArritem		= UFunc.getObjectIndeks(uDatatabelArr,uIteminputObj.id_barang,"id_barang");
		if(vArritem >= 0 && vArritem !== vArridx) {
			vElfocus && vElfocus.focus();
			showToast("<b>Barang Tersebut Sudah Ada</b>.."+
				"<br/>Silahkan Update QTY jika Ingin Merubah Datanya !"+
				"<br/>"+vArritem+" === "+vArridx+
				""); return;
		}
		//--END CEK_DATA_SUDAH_ADA_DI_TABEL--/

		const vArrbarang	= UFunc.getObjectIndeks(uDatabarangArr,uIteminputObj.id_barang);
		const vTmpObj		= {
			id_barang: uIteminputObj.id_barang,
			caption_barang: uDatabarangArr[vArrbarang].caption_barang,
			qty: uIteminputObj.qty,
		}
		if(vArridx < 0) 
			uDatainputObj.items_arr = [vTmpObj,...uDatatabelArr];
		else {
			uDatatabelArr[vArridx]	= vTmpObj;
			uDatatabelArr.status_editdone = !(uDatatabelArr.status_editdone||false);
		}
		setIteminputObj({}); setEfekview(!uEfekview);

		return true
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentTanggal = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg">
		<CCardHeader className="py-0 text-muted">
			<small className="classfontsmaller">Tanggal</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="">
			<CCol xs="6" className="text-start">Tanggal Masuk</CCol>

			<CCol xs="6" className="text-end">
			<CFormInput size="sm" type="date"
				value={uDatainputObj.tgl_masuk||""}
				onChange={(e)=>{uDatainputObj.tgl_masuk=e.target.value;setEfekview(!uEfekview)}}
				id="inptglmasuk"/>
			</CCol>
			</CRow>

			<CRow className="mt-2 d-md-none">
			<CCol xs="6" className="text-start">No.Nota (Bukti)</CCol>

			<CCol xs="6" className="text-end">
			<CFormInput size="sm"
				maxLength={30}
				value={uDatainputObj.no_nota||""}
				onChange={(e)=>{uDatainputObj.no_nota=e.target.value;setEfekview(!uEfekview)}}
				className="text-uppercase"
				id="inpnonota"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentNota = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg">
		<CCardHeader className="py-0 text-muted">
			<small className="classfontsmaller">Nota</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="">
			<CCol xs="6" className="text-start">No.Nota (Bukti)</CCol>

			<CCol xs="6" className="text-end">
			<CFormInput size="sm"
				maxLength={30}
				value={uDatainputObj.no_nota||""}
				onChange={(e)=>{uDatainputObj.no_nota=e.target.value;setEfekview(!uEfekview)}}
				className="text-uppercase"
				id="inpnonota"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentItemform = () => {
		if(uHtml500msg) return (<></>)

		return (
		<>
		<div className="d-none d-md-flex">
		<small className="text-muted classfontsmaller">Lookup Barang</small>
		<span className="mx-1">&middot;</span>
		<div style={{width:"30%",minWidth:275}}>
		<CInputGroup size="sm">
		<CFormInput size="sm"
			placeholder="--Lookup Keyword Barang.."
			value={uIteminputObj.caption_barang||""}
			onChange={(e)=>{uIteminputObj.caption_barang=e.target.value;setEfekview(!uEfekview)}}
			onKeyDown={(e)=>hdlKDownItemnama(e)}
			id="inpitemnama"/>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemlookup()}
			id="btnitemlookup">
			<CIcon icon="cilMagnifyingGlass"/>
		</CButton>
		</CInputGroup>
		<div className="classpetunjuk">Nama Barang</div>
		</div>

		<span className="mx-1">&middot;</span>

		<div style={{width:"5%",minWidth:50}}>
		<CFormInput size="sm" type="number"
			value={uIteminputObj.qty||0}
			min={0}
			onChange={(e)=>{uIteminputObj.qty=e.target.value;setEfekview(!uEfekview)}}
			onFocus={(e)=>e.target.select()}
			className="text-end"
			id="inpitemqty"/>
		<div className="classpetunjuk">QTY</div>
		</div>

		<span className="mx-1">&middot;</span>
		<div>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemtambah()}
			id="btnitemtambah">
			<CIcon icon="cibAddthis"/>
		</CButton>
		</div>
		</div>

		<div className="d-flex justify-content-between my-1 d-md-none">
		<small>Item Barang Masuk</small>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemtambahmobile()}
			id="btnitemtambah">
			<CIcon icon="cibAddthis" className="me-2"/>Tambah Item
		</CButton>
		</div>
		</>
		)
	}
	const contentItem = () => {
		if(uHtml500msg) return (<></>)

		const vItemArr	= (uDatatabelArr).filter(vItems=>
			(vItems.init_hapus||"TIDAK")!=="YA");
		return (
		<table className="table table-borderless table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="align-top px-0 classfontsmaller">No</th>
			<th className="align-top px-0">
			<small className="classfontsmaller">
			<CRow className="mx-0">
			<CCol md="8" className="text-start">{uBahasaObj.caption_namabarang||"Nama Barang"}</CCol>
			<CCol md="2" className="">{uBahasaObj.word_qty||"Qty"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</small>
			</th>
			</tr>
		</thead>

		<tbody>
		{vItemArr.map((vItems,vKeys)=>{
			const {
				id,id_barang,caption_barang,qty,status_edit
			} = vItems;

			return (
			<tr id={"idtr"+vKeys} key={vKeys} className={"classborderbottom"+(uTabeleditObj.idxkey===vKeys?" classrowselect":"")}>
			<td className="px-1 align-top text-end">{(vKeys+1)+"."}</td>

			<td className="px-2 px-md-0 align-top text-end">
			<CRow className="mx-0">
			<CCol md="8" className="px-1 text-start">
			<div className="d-flex justify-content-between">
				<div className="text-info">{caption_barang||"Undf"}</div>
				<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikTabeleditmobile(vKeys)}>
						<CIcon className="classikonedit me-2 align-middle" height={25}/>
						{uBahasaObj.caption_editItem||"Edit Item"}
					</CDropdownItem>
							
					<CDropdownItem onClick={()=>hdlKlikTabelhapus(vKeys)}>
						<CIcon className="align-middle classikonhapus me-2" height={25}/>
						{uBahasaObj.caption_hapusitem||"Hapus Item"}
					</CDropdownItem>
				</CDropdownMenu>
				</CDropdown>
			</div>
			</CCol>
			
			<CCol md="2" className="px-1 d-none d-md-block text-md-center">
			{(uTabeleditObj.field==="QTY" && uTabeleditObj.idxkey===vKeys) ? (
			<>
				{(status_edit||false)===false ? (
				<CForm className="mx-lg-3 ">
				<CInputGroup size="sm" className="">
				<CFormInput size="sm" type="number"
					className="text-end"
					value={uTabeleditObj.value||0}
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>{ uTabeleditObj.value = e.target.value; setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKDownTabelfield(e,vKeys)}
					id="inptabelqty"/>
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikTabelprosesfield(vKeys)}
					id="btntabelqty"> 
					<CIcon icon="cilCheckAlt"/>
				</CButton>
				</CInputGroup>
				</CForm>
				) : (
				<CSpinner size="sm"/>
				)}
			</>
			) : (
				<CTooltip content="--Klik Untuk Edit">
				<CLink className="classcursorpointer text-black"
					onClick={()=>hdlKlikTabeleditfield(vKeys,"QTY")}>
				<big className="fw-bold">{UFunc.formatAngka(qty)} PCS</big>
				</CLink>
				</CTooltip>
			)}
			</CCol>
			
			<CCol md="2" className="px-1 d-none d-md-block text-md-center">
				<CTooltip content="--Hapus Data">
				<CLink className="classikontabel classikonhapus classcursorpointer"
					onClick={()=>hdlKlikTabelhapus(vKeys)}/>
				</CTooltip>
			</CCol>

			<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

			<CCol xs="5" className="px-1 d-md-none text-start">QTY</CCol>
			<CCol xs="7" className="px-1 d-md-none">
				<big className="fw-bold">{UFunc.formatAngka(qty)} PCS</big>
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)

		const vArredit	= uTabeleditObj.idxkey!==undefined ? parseInt(uTabeleditObj.idxkey) : -1;
		return (
		<CForm>
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_namabarang||"Nama Barang"}</CCol>
		<CCol md="8" className="">
			{(vArredit < 0)  ? (
			<CFormSelect size="sm"
				className=""
				value={uIteminputObj.id_barang||"0"}
				onChange={(e)=>{uIteminputObj.id_barang = (e.target.value); setEfekview(!uEfekview);}}
				id="inpformbarangid">
			<option value="0">--PILIH ITEM BARANG--</option>
			{uDatabarangArr.map((vItems,vKeys)=>{
				return (
				<option value={vItems.id} key={vKeys}>{vItems.caption_barang}</option>
				)
			})}
			</CFormSelect>
			) : (
			<big className="text-info">{uIteminputObj.caption_barang||"UNDF"}</big>
			)}
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="7" className="text-md-end">{uBahasaObj.word_qty||"QTY"}</CCol>
		<CCol xs="5" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				autoComplete="off"
				min={0}
				className="text-end"
				value={uIteminputObj.qty||"0"}
				onFocus={(e)=>e.target.select()}
				onChange={(e)=>{uIteminputObj.qty = (e.target.value); setEfekview(!uEfekview);}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpformqty"/>
			<CInputGroupText>PCS</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDataloadObj({});
		setIteminputObj({});
		setTabeleditObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_firstload : uFirstLoad,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangmasuk/ld_form";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Barang - apiLoaddata) output_string.datajenisbarang : "+output_string.datajenisbarang);
				setDatabarangArr(JSON.parse(output_string.databarang || "[]"));
				if(uTabelID > 0) {
					setDatainputObj(JSON.parse(output_string.dataobject || "{}"))
				}
				if(uFirstLoad==="YA") { setFirstLoad("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Formmasuk - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg(vMsg);
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Formmasuk - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		const vElTombol	= document.getElementById("btnSimpanmain");
		vElTombol && (vElTombol.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_datajson : JSON.stringify(uDatainputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangmasuk/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);
			if(output_string.sukses) {
				if(uTabelID <= 0) {
					setDatainputObj({});
					showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
					if(!isMobile) {
						document.getElementById("inptglmasuk") &&
							document.getElementById("inptglmasuk").focus();
					}
				} else {
					uNavigate(-1);
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Formmasuk - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);
			console.log("(Formmasuk - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		hdlToggleDlgform();
		uDispatch({type: "set", effectApp: !!uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

    	if(!isMobile) {
    		const vElfocus = document.getElementById("inptglmasuk");
    		vElfocus && vElfocus.focus();
    	}
    	return () => {
    		setDatainputObj({});
    		setIteminputObj({});
    		setTabeleditObj({});
    		setDlglookupObj({});
    		setDlgformObj({});
    	}
	},[])
	React.useEffect(()=>{
		if(!uISDlgformshow) {
			setTabeleditObj({});
			setIteminputObj({});
			setDlgformObj({}); return;
		}
	},[uISDlgformshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow) {
			setDlglookupObj({}); return;
		}
	},[uISDlglookupshow])
	React.useEffect(()=>{
		if(uTabelID <= 0) return;
		setDatainputObj(uDataloadObj);
	},[uDataloadObj])
	React.useEffect(()=>{
		const vItemloadArr 	= uDataloadObj.items_arr || [];
		let vSumitem 		= 0;
		vItemloadArr.forEach(vItems=> vSumitem = vSumitem + parseFloat(vItems.qty));
		uDataloadObj.jmlitem = vSumitem; 
	},[uDataloadObj,
	uDataloadObj.items_arr
	])
	React.useEffect(()=>{
		if(uDatainputObj.tgl_masuk === undefined)
			uDatainputObj.tgl_masuk = UFunc.DbDate();
	},[uDatainputObj,
	uDatainputObj.tgl_masuk,
	uDatainputObj.items_arr
	])
	React.useEffect(()=>{
		let vSumitem = 0;
		const vItemArr	= (uDatatabelArr).filter(vItems=>
			(vItems.init_hapus||"TIDAK")!=="YA");
		vItemArr.forEach(vItems=> vSumitem = vSumitem + parseFloat(vItems.qty));
		uDatainputObj.jmlitem 	= vSumitem; 
	},[uDatatabelArr,
	uDatatabelArr.length,
	uDatatabelArr.status_editdone
	])
	React.useEffect(()=>{
		const vItemArr	= (uDatatabelArr).filter(vItems=>
			(vItems.init_hapus||"TIDAK")!=="YA");
		const vItemloadArr 	= uDataloadObj.items_arr || [];
		if(uDatainputObj.tgl_masuk !== (uDataloadObj.tgl_masuk||UFunc.DbDate())
			|| (uDatainputObj.no_nota||"") !== (uDataloadObj.no_nota||"")
			|| vItemArr.length !== vItemloadArr.length
			|| uDatainputObj.jmlitem !== uDataloadObj.jmlitem
			) setFormupdated(true);
		else setFormupdated(false);
	},[uDatainputObj,uDatatabelArr,
	uDatainputObj.tgl_masuk,
	uDatainputObj.no_nota,
	uDatatabelArr.length
	])
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) {
		setLoading(true); prosesExpired(); return;
	}

	//console.log("(Formmasuk) uNavigate => "+JSON.stringify(uNavigate))
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop">
		<CContainer fluid className="m-0 p-2 border-bottom">
			<CHeaderToggler className="ps-1"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="d-md-flex me-auto text-normal">
				<strong>Form Barang Masuk</strong>
			</CHeaderNav>

			<CHeaderNav className="ms-3 align-items-center">
				<MyProfiledetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 py-0 px-3 align-items-center">
			<div className="d-none d-md-block "/>
			<div className=" mx-auto mx-md-0">
			<CLink className="classikon classikonreset classcursorpointer"
				 onClick={()=>hdlKlikReset()}/>
			<span className="mx-1 me-2">&middot;</span>
			<small className="align-top d-none">Rp</small>
			<span style={{fontSize:25}} className="align-middle">
				(<span className="text-primary fw-bold">{UFunc.formatAngka(uDatainputObj.jmlitem)}</span>)
			</span>
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className="mx-auto px-0">
			{(uHtml500msg) ? (
				<MyPage500 content={uHtml500msg}/>
			) : (
			<CForm>
			<CRow className="mx-0 mb-3">
			<CCol md="6" className="px-md-0 pe-md-2">{contentTanggal()}</CCol>
			<CCol md="6" className="px-md-0 ps-md-2 d-none d-md-block">{contentNota()}</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol className="px-md-0">
			<CCard className="classcardbg">
			<CCardHeader className="py-1">{contentItemform()}</CCardHeader>
			<CCardBody className="px-0 px-lg-2">{contentItem()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="fixed" 
			className="px-3 text-black-50 justify-content-center" 
			style={{backgroundColor:"#f0f0f0"}}>
		<MyButton
			disabled={uHtml500msg}
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaObj.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformObj.header||""}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>
		<MyDialoglookup
			show={uISDlglookupshow} 
			dialogHeader={uDlglookupObj.header||""}
			toggle={hdlToggleDlglookup}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr||[]}
			onKlikPilihItem={hdlKlikDlglookup}/>
		</>
	) 
}	

export default Formmasuk