import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,
	CButton,
	CFormSelect,
	CInputGroup,
	CLink,
	CTooltip,
	//CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc,cBulanPendek } from '../../helpers/functions'

const pjson 			= require('../../../package.json')
const Headersubdashboard= (props) => {
	//const { setToken,setLoading, showToast } = props 

	//--DOM--/
	//const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);

	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uFormfilterObj,setFormfilterObj]= React.useState({});
	const uTglNowDT 			= UFunc.toDatetime();
	const [uISFirstload,setFirstload] = React.useState(true);

	const uCabangtokenArr		= (uTokenObj.usercabangid||"").split(",");
	const uCabangallowedArr		= uTokenObj.userhak==="SUPERADMIN" ? uDatacabangArr
		: uDatacabangArr.filter(vItems => 
			uCabangtokenArr.includes((vItems.id||"0").toString())
			);
	
	const hdlKlikLoad = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.filter_dashboard = JSON.stringify(uFormfilterObj); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	React.useEffect(()=>{
		setFirstload(false);
    	return () => {
    		setFormfilterObj({})
			uKeywordObj.filter_dashboard = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			setFirstload(true);
    	}
	},[])
	React.useEffect(()=>{
		if(uFormfilterObj.id_cabang === undefined)
			uFormfilterObj.id_cabang = localStorage.getItem("cabangidselect") || uDatacabangArr[0].id_cabang;
		if(uFormfilterObj.tgl_awal === undefined) 
			uFormfilterObj.tgl_awal = UFunc.DbDate(uTglNowDT);
	},[uFormfilterObj,
	uFormfilterObj.id_cabang,
	uFormfilterObj.tgl_awal,
	])
	React.useEffect(()=>{ 
		if(uISFirstload === true) return;
		hdlKlikLoad(); 
	},[uEfekview])

	//console.log("Headersubdashboard - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<small>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>
		

		<div className="d-flex align-items-center mx-auto mx-lg-2">
			<CLink onClick={hdlKlikLoad} className="d-none classcursorpointer classikon classikonreset"/>
			<CForm>
			<CInputGroup size="sm" className="">
			<CFormSelect size="sm" 
				value={uFormfilterObj.id_cabang} 
				onChange={(e) => {uFormfilterObj.id_cabang=e.target.value;setEfekview(!uEfekview)}} 
				className="w-auto"
				id="inpcabangid">
			{uCabangallowedArr.map((vItems,vKeys)=>{
				return (
				<option key={vKeys} value={vItems.id}>{(vItems.nama_cabang||"").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
			<span className="ms-1"/>
			<CFormSelect size="sm"
				className="w-auto"
				value={uFormfilterObj.tgl_awal} 
				onChange={(e) => {uFormfilterObj.tgl_awal=e.target.value;setEfekview(!uEfekview)}} 
				id="inptglawal">
			{(()=>{
				const vOptionhtml = [];
				let vTglloopDT;
				for(let vIdx=0;vIdx<=5;vIdx++) {
					vTglloopDT = UFunc.toDatetime();
					vTglloopDT.setMonth(vTglloopDT.getMonth() - vIdx);

					const vBulan = vTglloopDT.getMonth();
					const vTahun = vTglloopDT.getFullYear();
					const vTanggalvalue = vTahun+"-"+UFunc.leadZero(vBulan + 1)+"-01";

					vOptionhtml.push(
					<option key={vIdx-1} value={vTanggalvalue}>
						{cBulanPendek[vBulan].toUpperCase()} {vTahun}
					</option>
					)

				}

				return vOptionhtml;
			})()}
			</CFormSelect>
			</CInputGroup>
			</CForm>		
		</div>
		</>
	)
}

export default Headersubdashboard
