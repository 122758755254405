import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { MyButton } from './index';
import { UFunc } from "../helpers/functions";
import PropTypes from 'prop-types';

const MyDialogsession = props => {	
	const {show,toggle,Countertime,onKlikyes,...rest} = props;
	const uBahasaob  = useSelector(state => state.listBahasa);

	return (
		<CModal
			alignment="center"
			visible={show}
			onClose={toggle}
			scrollable 
			{...rest}>
		<CModalHeader 
			className="bg-danger text-white"
			closeButton>
			<CModalTitle>
				<div className="d-none d-md-block">Alert: Waktu Session !</div>
				<small className="d-md-none">Alert: Waktu Session !</small>
			</CModalTitle>
		</CModalHeader>

		<CModalBody 
			style={{
			maxHeight:(window.innerHeight - 195),overflowY:"auto",overflowX:"hidden"
			}}>
			<div>
				Waktu Session Anda Tinggal:{" "}
				<big><b className={parseInt(Countertime)>=0?"text-primary":"text-danger"}>{UFunc.formatAngka(Countertime)}</b></big>
				{" "}detik
			</div>
			<div className="my-2">
			<small className="fst-italic">
			Untuk Memperpanjang Waktu Session Anda, Silahkan KLIK <b className="text-primary">YA</b>,<br/>sebelum Waktu Anda Habis.
			</small>
			</div>
			<div className="m-3 fw-bolder text-danger">
			Apakah Akan Memperpanjang Session ?
			</div>
		</CModalBody>
		<CModalFooter>
		<MyButton
			onClick={()=>onKlikyes()}
			id="btnDlgsessionYa">
			{uBahasaob.word_ya||"YA"}
		</MyButton>{" "}
		<CButton color="light" onClick={toggle}>{uBahasaob.word_tidak||"Tidak"}</CButton>
		</CModalFooter>
		</CModal>
	)
}
MyDialogsession.defaultProps = {
	show: false,
	toggle: () => { return false }
};
MyDialogsession.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	onKlikyes: PropTypes.func,
}

export default MyDialogsession;