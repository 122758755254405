import React, { Component, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
	BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom'
import './assets/scss/style.scss'
import CIcon from '@coreui/icons-react'
import { UFunc } from './helpers/functions'
import useToken from './helpers/useToken'
import { isMobile } from "react-device-detect";
import { 
	MyLoader,
	MyToaster,
	MyDialogsession
} from './components/index'
import { 
	Login,
	Error500,
	Layout
} from "./pages/index"

const pjson	= require('../package.json');
const App 	= (props) => {
	const { token, setToken } 			= useToken();
	const uDispatch    					= useDispatch();

	const uToastlist					= useSelector(state => state.listToast);
	const [uRoutes,setRoutes]			= React.useState(JSON.parse(localStorage.getItem("routeslist")) || "[]");
	const uActiveRoute  				= useSelector(state => state.activeRoute);
	const uRouteProfileArr				= useSelector(state => state.profileRoute); 
	const uSystemsettingObj				= useSelector(state => state.gSystemsetting);
	const uSystemdataObj				= useSelector(state => state.gSystemdata);

	const [uIsTotopshow,setTotopshow]	= React.useState(false);
	const [uBahasaob,setBahasaob]		= React.useState({});
	const [isApploading,setApploading]	= React.useState(false);
	const [isLoading, setLoading]      	= React.useState(false);
	const [isInitKoneksi, setInitKoneksi ]= React.useState(true);

	//--TIMEOUTSESSION--/
	const uTimeouttimer					= parseInt(useSelector(state => state.gTimesessionlimit)) || 900;
	const [uISSessionaktif,setSessionaktif]		= React.useState(false);
	const [uCoundownsession,setCoundownsession]	= React.useState(uTimeouttimer);
	//--END TIMEOUTSESSION--/

	//--DLG_FORM--/
	const [uIsDlgsessionshow,setDlgsessionshow]	= React.useState(false)
	//--END DLG_FORM--/

	//---DETIL_PAGE--/
	const uDetilPageArr = [
		{path:"/barangmasuk/form/:tabel_id",name:"formmasuk",owner:"barangmasuk",},
		{path:"/billingtransaksi/pasienhistori/:tabel_id",name:"billinghistori",owner:"billingtransaksi",},
		{path:"/billingtransaksi/formbilling/:tabel_id",name:"billingform",owner:"billingtransaksi",},
		{path:"/piutang/formpiutang/:tabel_id",name:"piutangform",owner:"piutang",},
		{path:"/pakaiklinik/forminput/:tabel_id",name:"pakaiklinikform",owner:"pakaiklinik",},
		{path:"/stokopname/formstokopname",name:"stokopnameform",owner:"stokopname",},
		{path:"/stokopname/rekapstokopname",name:"stokopnamerekap",owner:"stokopname",},
	]
	//---END DETIL_PAGE--/

	//---HANDEL--/
	const hdlToggleDlgsession = () => { setDlgsessionshow(false)}
	const hdlKlikDlgsessionYA = () => {  apiProsessession() }
	const hdlKlikToTop = () => {

		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	const hdlKDownField=(_EV)=>{
		if (_EV.keyCode === 13 && (_EV.target.nodeName === "INPUT"
			|| _EV.target.nodeName === "SELECT"
			|| _EV.target.nodeName === "TEXTAREA")
		){
			if (_EV.ctrlKey && _EV.target.nodeName === "TEXTAREA") {
				_EV.preventDefault();
				_EV.stopPropagation();
				var content = _EV.target.value;
				var start   = _EV.target.selectionStart;
				var substr1 = content.slice(0, start);
				var substr2 = content.slice(_EV.target.selectionEnd);
				//alert(_EV.target.selectionEnd);
				_EV.target.value  = substr1 + '\n' + substr2;
				return;
			}

			var form      = _EV.target.form;
			/*console.log("("+uComponentname+") addEventListener => form 1 "+form);
			if(isNaN(form)) {
				console.log("("+uComponentname+") addEventListener => form 1a "+form);
			}//-*/
			if(UFunc.isEmpty(form)) return;
			//console.log("("+uComponentname+") addEventListener => form 2 "+form);
			var index     = Array.prototype.indexOf.call(form, _EV.target);
			var vJmlLoop  = 0;
			var vFocusedBl= false;
			do{
				index++;
				vJmlLoop++;
				var vElement  = form.elements[index];
				if(vElement && !vElement.disabled 
					&& vElement.style.visibility !== "hidden"
					&& vElement.style.display !== "none"
				) {
					vFocusedBl = true;
					vElement.focus();
				}
				/*console.log("("+uComponentname+") addEventListener => ID "+(vElement.id && vElement.id));
				console.log("("+uComponentname+") addEventListener => visibility : "+(vElement.style.visibility));
				console.log("("+uComponentname+") addEventListener => display : "+(vElement.style.display));//-*/
			} while(!vFocusedBl && vJmlLoop <= 5)
			_EV.preventDefault();
		}
	}
	const hdlScroll=()=>{
		if (window.pageYOffset > 300) setTotopshow(true);
		else setTotopshow(false);
	}
	const hdlScrollWindow=(e)=>{
        const windowHeight 	= "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body 			= document.body;
        const html			= document.documentElement;
        const docHeight 	= Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight-5) {
			uDispatch({type: "set", gIsScrollBottom: true});
		} else {
			uDispatch({type: "set", gIsScrollBottom: false});
		}
	}
	//---END HANDEL--/

	//---GLOBAL_PROP_FUNCTION--/
	const clearStorage = () => {
		localStorage.removeItem("routeslist");
		localStorage.removeItem("listkeyword");

		setSessionaktif(false);
		uDispatch({type: "set", gInitTabelSelected: {}});
	}
	const showToast   = (_PESAN, _TIPE="INFO") => {
		const vTmps  = { type : _TIPE, teksIsi : _PESAN };
		uDispatch({type: "set", listToast: [...uToastlist, vTmps]});
		//uDispatch({type: "set", listToast: vTmps});
	};
	const prosesExpired = () => {
		clearStorage();

		showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
		setToken("");
		setLoading(false);
	}
	//---END GLOBAL_PROP_FUNCTION--/

	//---REST_API--/
	const apiLoadinit = () => {
		setApploading(true);
		setInitKoneksi(true);

		/*//---TEST_FRONTEND--/
		let vTimeout	= setTimeout(function(){
			setApploading(false);
			//setInitKoneksi(false);
			//setToken("")
		},2000); return;
		//---END TEST_FRONTEND--*/

		const vDATAS= JSON.stringify({ send_tokenauth : token });
		const vURLs	= pjson.homepage+"api/api_init/ld_init";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		//}).then((response)=> { if (response.status === 200) { return response.json(); }})
		.then((output_string) => {
			setApploading(false);
			//console.log("(App - apiLoadinit) output_string : "+JSON.stringify(output_string));
			localStorage.setItem("cabanglist",output_string.cabanglist||"[]");
			setBahasaob(JSON.parse(output_string.langs||"[]"));
			
			if(output_string.tampil) {
				uDispatch({type: "set", gSystemsetting: JSON.parse(output_string.systemsetting || "{}")});
				uDispatch({type: "set", gSystemdata: JSON.parse(output_string.systemdata || "{}")});
				//console.log("(App - apiLoadinit) output_string.systemdata : "+output_string.systemdata);
				if(token) {
					setSessionaktif(true);
					uDispatch({type: "set", listNav: JSON.parse(output_string.nav || "[]")});
					//console.log("(App - apiLoadinit) output_string.nav : "+output_string.nav);
					if(output_string.routes) {
						const vRouteLoadArr = JSON.parse(output_string.routes||"[]");
						const vNewRoutes	= [...uRouteProfileArr,...vRouteLoadArr];
						localStorage.setItem("routeslist", JSON.stringify(vNewRoutes)); 
					}
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(App - apiLoadinit) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.limited) {
				showToast(output_string.limited,"ERROR");
				if(token) setToken("");
			} else if(output_string.expired) {
				if(token) { 
					showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
					setToken(""); 
				}
			}
		}).catch((error) =>{
			setInitKoneksi(false);
			setApploading(false);
			console.log("(App - apiLoadinit) catch-error : "+error);
		});
	}
	const apiProsessession = () =>{
		const vEldisabled = document.getElementById("btnDlgsessionYa")
		vEldisabled && (vEldisabled.disabled=true);
		setLoading(true)

		const vDATAS	= JSON.stringify({ send_tokenauth:token });
		const vURLs		= pjson.homepage+"api/api_init/pr_session";

		hdlToggleDlgsession();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			vEldisabled && (vEldisabled.disabled=false);
			setLoading(false);
			if(output_string.sukses) {
				setSessionaktif(true);
			} else if(output_string.expired) {
				if(token) { 
					showToast(uBahasaob.pesan_hasexpired || pjson.mydefault.msgExpired);
					setToken(""); 
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(App - apiProsessession) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.disabled=false);
			setLoading(false);
			console.log("(App - apiProsessession) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(() => {
		document.addEventListener("keydown", hdlKDownField);
		document.addEventListener("scroll", hdlScroll);
		window.addEventListener("scroll", hdlScrollWindow);

		//return function cleanup() {
		return () => {
			window.removeEventListener("scroll", hdlScrollWindow);
			document.removeEventListener("keydown",hdlKDownField);
			document.removeEventListener("scroll",hdlScroll);
		}
	});
	React.useEffect(() => {
		localStorage.removeItem("lasturl");

		//---LOAD_BAHASA--/
		apiLoadinit();
		//---END LOAD_BAHASA--/

		return ()=>{
			setBahasaob([]);
			hdlToggleDlgsession();
		}
	},[]);
	React.useEffect(() => { if(!token) clearStorage(); },[token]);
	React.useEffect(() => {
		//console.log("(App - [uSystemsettingObj]) uSystemsettingObj : "+JSON.stringify(uSystemsettingObj));
		if(uSystemsettingObj.limit_session === undefined) return;

		uDispatch({type: "set", 
			gTimesessionlimit: parseInt(uSystemsettingObj.limit_session)||uTimeouttimer});
	},[uSystemsettingObj,uSystemsettingObj.limit_session]);
	React.useEffect(() => {
		if(!uISSessionaktif) 
			{ setCoundownsession(uTimeouttimer) }
	},[uISSessionaktif]);
	React.useEffect(() => { setCoundownsession(uTimeouttimer) },[uTimeouttimer]);
	React.useEffect(() => {
		if(!uISSessionaktif) return;

    	const vTimercountdown = setTimeout(()=>{
    		clearTimeout(vTimercountdown)

    		setCoundownsession(uCoundownsession-1);
			if(uCoundownsession === 25) setDlgsessionshow(true)
			else if(uCoundownsession > 25) uIsDlgsessionshow && setDlgsessionshow(false)
    	},1000)

    	return () => {
    		clearTimeout(vTimercountdown) 
    	}
	},[uISSessionaktif,uCoundownsession]);//-*/
	React.useEffect(() => { uDispatch({type: "set", listBahasa: uBahasaob}); },[uBahasaob]);
	//---END EFFECT--/

	//---FIRSTLOADING--/
	if(isApploading) return ( <><MyLoader isShow={isApploading} /></> );
	//---END FIRSTLOADING--/

	//---JIKA_INTERNET_PROBLEMS--/
	if(!isInitKoneksi)  return (
			<BrowserRouter>
			<Routes>
				<Route path="/error500" element={ 
					<Error500
						showToast={showToast}
						setLoading={setLoading}
						{...props}/>
					}/>
				<Route path="*"
					element={<Navigate from="/" to="/error500"/>}/>
			</Routes>
			</BrowserRouter>
	)
	//---END JIKA_INTERNET_PROBLEMS--*/

	//---NO_TOKEN_AUTH--/
	if(!token) {
		//setDlgsessionshow(false);

		return (
		<>
		<MyLoader isShow={isLoading} />
		<MyToaster toastData={uToastlist} />
		<div style={{zIndex:2000}} className="d-none position-fixed top-0 start-50 translate-middle-x fw-bolder text-danger">uCoundownsession = {uCoundownsession}</div>
		<BrowserRouter>
		<Routes>
			<Route path="/error500" element={ 
				<Error500
					showToast={showToast}
					setLoading={setLoading}
					{...props}/>
				}/>
			<Route path="/login" element={ 
				<Login
					setToken={setToken}
					showToast={showToast}
					setLoading={setLoading}
					setSessionaktif={setSessionaktif}
					{...props}/>
				}/>
			<Route path="*"
				element={<Navigate from="/" to="/login"/>}/>
		</Routes>
		</BrowserRouter>
		</>
		);
	}
	//---END NO_TOKEN_AUTH--*/
	
	const loadDetilpage = (_MAINMENU) => {
	 	if(_MAINMENU.toUpperCase() === ("barangmasuk").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("billingtransaksi").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("piutang").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("pakaiklinik").toUpperCase() 
	 		|| _MAINMENU.toUpperCase() === ("stokopname").toUpperCase() 
	 	) {
	 		//console.log("("+uComponentname+") uDetilPageArr => "+JSON.stringify(uDetilPageArr));
	 		return uDetilPageArr.map((vItem,vKey)=>{
				const {path,name,owner} = vItem;

				const vDetilname = name.toLowerCase();
				if(owner.toUpperCase() === _MAINMENU.toUpperCase()) {
	 				//console.log("(App - loadDetilpage) path => "+(path));
					let KomponenDetil;
					try {
						KomponenDetil = require("./pages/detil/"+UFunc.capitalize(vDetilname)).default;
					} catch (ex) {
						//console.log("(App - loadDetilpage) uDetilPageArr.name(ex) = ./detil/"+vDetilname);
					}
					return KomponenDetil && (
						<Route
							key={vKey}
							path={path}
							exact={false}
							name={UFunc.capitalize(vDetilname)}
							element={
								<KomponenDetil
									setToken={setToken}
									showToast={showToast}
									setLoading={setLoading}
									prosesExpired={prosesExpired}
									clearStorage={clearStorage}
									setSessionaktif={setSessionaktif}
									{...props}/>
							}/>
					)	
				}
			})
	 	} 
	}

	//console.log("(App) uActiveRoute = "+uActiveRoute)
	//					setSessionaktif={setSessionaktif}
	return (
		<>
		<div className="position-fixed text-warning" style={{zIndex:2000,left:10,bottom:10}}>[{uCoundownsession}]</div>
		<MyLoader isShow={isLoading} />
		<MyToaster toastData={uToastlist} />
		<BrowserRouter>
		<Routes>
			{loadDetilpage(uActiveRoute)}
			<Route exact path="*" name="Layout" 
				element={
					<Layout 
						setToken={setToken}
						showToast={showToast}
						setLoading={setLoading}
						prosesExpired={prosesExpired}
						clearStorage={clearStorage}
						setSessionaktif={setSessionaktif}
						{...props}/>
				}/>
			<Route exact path="/" element={<Navigate from="/" to="/dashboard"/>}/>
			<Route exact path="/login" element={<Navigate to="/dashboard"/>}/>
			<Route exact path="/error500" element={<Navigate to="/dashboard"/>}/>
		</Routes>
		{uIsTotopshow &&
			<div onClick={()=>hdlKlikToTop()} className="text-danger classToTop" >
			<CIcon icon="cilArrowCircleTop" height={35} />
			</div>
		}
		</BrowserRouter>

		<MyDialogsession
			onKlikyes={()=>hdlKlikDlgsessionYA()}
			show={uIsDlgsessionshow} 
			toggle={hdlToggleDlgsession} 
			Countertime={uCoundownsession}/>
		</>
    )
}

export default App
	