import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CRow,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CFormCheck,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CImage,
  CPagination,
  CPaginationItem,
  CLink,
  CTooltip,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
//const isMobile 	= false;
const Pengguna = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 7) : (uSettinguserObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const uIsEditallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA"||uTokenObj.userhak==="MANAJER");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const uRoledefault		= [{value:"GUDANG",caption:"GUDANG"}]
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatadefaultArr,setDatadefaultArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstLoad,setFirstLoad]		= React.useState("YA");
	const [uDataroleArr,setDataroleArr]	= React.useState(uRoledefault);
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [uISShowform,setShowform]			= React.useState(false);
	const [uHeaderdlg,setHeaderdlg]			= React.useState("");
	const [uDataformObj,setDataformObj]		= React.useState({});
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlg = () =>{ setShowform(false) }
	const hdlKlikOffline = async(_IDTABEL,_INITGUDANG) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		_INITGUDANG = _INITGUDANG || "TIDAK";

		const vArridx = uDatamainArr.findIndex((vItems,vKeys)=>{
			return ((vItems.id||"0").toString() === _IDTABEL.toString())
				&& (vItems.init_usergudang === _INITGUDANG);
		});
		const vCaptionupper = (uDatamainArr[vArridx].usercaption||"").toUpperCase();
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_INITGUDANG+_IDTABEL)
		setRowselect(vIdxRow);
		setDataselect({id:uDatamainArr[vArridx].id,init_usergudang:uDatamainArr[vArridx].init_usergudang,})

		if(await(Konfirm("Set Status OFFLINE: <B>"+vCaptionupper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProsesonline(vArridx);
	}
	const hdlKlikEdit = (_IDTABEL,_INITGUDANG) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		_INITGUDANG = _INITGUDANG || "TIDAK";

		const vArridx = uDatamainArr.findIndex((vItems,vKeys)=>{
			return ((vItems.id||"0").toString() === _IDTABEL.toString())
				&& (vItems.init_usergudang === _INITGUDANG);
		});
		const vCaptionupper = (uDatamainArr[vArridx].usercaption||"").toUpperCase();
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_INITGUDANG+_IDTABEL)
		setRowselect(vIdxRow);
		setDataselect({id:uDatamainArr[vArridx].id,init_usergudang:uDatamainArr[vArridx].init_usergudang,})

		uDataformObj.usercaption= (uDatamainArr[vArridx].usercaption||"");
		uDataformObj.username 	= (uDatamainArr[vArridx].username||"");
		uDataformObj.hak 		= (uDatamainArr[vArridx].hak||"GUDANG");

		setHeaderdlg("Edit Data: "+vCaptionupper);
		setShowform(true);
	}
	const hdlKlikHapus = async(_IDTABEL,_INITGUDANG) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		_INITGUDANG = _INITGUDANG || "TIDAK";

		const vArridx = uDatamainArr.findIndex((vItems,vKeys)=>{
			return ((vItems.id||"0").toString() === _IDTABEL.toString())
				&& (vItems.init_usergudang === _INITGUDANG);
		});
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_INITGUDANG+_IDTABEL)
		const vCaptionupper = (uDatamainArr[vArridx].usercaption||"").toUpperCase();
		setRowselect(vIdxRow);
		//setDataselect({id:uDatamainArr[vArridx].id,init_usergudang:uDatamainArr[vArridx].init_usergudang,})

		if(await(Konfirm("Hapus Data: <B>"+vCaptionupper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProseshapus(vArridx);
	}
	const hdlKlikRole = (_IDTABEL,_INITGUDANG) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;
		_INITGUDANG = _INITGUDANG || "TIDAK";

		const vTmpObj	= { initID:_IDTABEL, initGudang: _INITGUDANG, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})
		uNavigate("/subdatamaster/pengguna/setrole/"+_IDTABEL+"/"+_INITGUDANG);
	}
	const hdlKlikCabangaksesall	= () => {
		let vIscheckedall = true;
		const vCabangfilterArr	= uDataformObj.cabangid_arr || [];
		uDatacabangArr.forEach(vItems => {
			if(!vCabangfilterArr.includes((vItems.id))) {
				vIscheckedall = false;
			} 
		});

		uDataformObj.cabangid_arr = [];
		//(uDataformObj.cabangid_arr||[]).splice(0,(uDataformObj.cabangid_arr||[]).length); 
		if(vIscheckedall) { setEfekview(!uEfekview); return; }

		let vTmpAll	= []
		uDatacabangArr.forEach(vItems => vTmpAll = [...vTmpAll,vItems.id]);
			
		uDataformObj.cabangid_arr = vTmpAll;
		setEfekview(!uEfekview);
	}
	const hdlChangeCabangakses	= (_EVN) => {
		const vValueID	= _EVN.target.value;
		const vIschecked= _EVN.target.checked;
		const vCabangfilterArr	= uDataformObj.cabangid_arr || [];

		//showToast("vValueID = "+vValueID+" | vIschecked = "+vIschecked)
		if(vIschecked) {
			vCabangfilterArr.push(vValueID);
		} else {
			const vIdx = (uDataformObj.cabangid_arr||[]).findIndex(vItems => vItems===vValueID);
			(uDataformObj.cabangid_arr||[]).splice(vIdx,1);
		}
		setEfekview(!uEfekview);
	}
	const hdlKlikDlgsimpan = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpusercaption");
		if((uDataformObj.usercaption||"").trim()==="") {
			vElFocus.focus();
			showToast("Nama User Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inpusername");
		if((uDataformObj.username||"").trim()==="") {
			vElFocus.focus();
			showToast("Username Harus Diisi !");return;
		}
		if( (parseInt(uDataselect.id) || 0) <= 0) {
			vElFocus = document.getElementById("inppasswd1");
			if((uDataformObj.passwd1||"") === "") {
				vElFocus.focus();
				showToast("Password Harus Diisi !"); return;
			}
			vElFocus = document.getElementById("inppasswd2");
			if((uDataformObj.passwd2||"") === "") {
				vElFocus.focus();
				showToast("Konfirmasi Password Harus Diisi !"); return;
			}
		}
		vElFocus = document.getElementById("inppasswd1");
		if((uDataformObj.passwd1||"") !=="" || (uDataformObj.passwd2||"")!=="") 
			if((uDataformObj.passwd1||"") !== (uDataformObj.passwd2||"")) {
				vElFocus.focus();
				showToast("Password dan Konfirmasi Password Harus Sama !"); return;
			}
		vElFocus = document.getElementById("inphak");
		if((uDataformObj.hak||"")!=="ADMIN" && (uDataformObj.hak||"")!=="GUDANG" 
			&& (uDataformObj.hak||"")!=="DOKTER") {
			vElFocus && vElFocus.focus();
			showToast("Hak Akses Harus Dipilih !");return;
		}
		if((parseInt(uDataselect.id)||0)<=0)
			if(uDataformObj.cabangid_arr.length <= 0) {
				showToast("Akses Cabang Harus Dipilih !");return;
			}

		let vArridx = -1;
		if((parseInt(uDataselect.id)||0) > 0) {
			vArridx = uDatamainArr.findIndex((vItems,vKeys)=>{
				return ((vItems.id||"0").toString() === (uDataselect.id||"0").toString())
					&& (vItems.init_usergudang === "YA");
			});
			if(vArridx < 0) {
				showToast("User Tidak Dapat DiPerbarui, Karena Bukan User Khusus GUDANG !");return;
			}
		}

		//console.log("(User - hdlKlikDlgsimpan) vArridx = "+vArridx); 
		apiProsessimpan(vArridx);
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(User - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setDataselect({})
		setDataformObj({});
		setEfekview(!uEfekview);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		let vCaptionupper	= "";
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_user || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center classfontsmaller align-top">
			<th width="5%" className="px-1">No</th>
			<th width="5%" className="px-1">&middot;</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="text-start">{uBahasaObj.word_username||"Username"}</CCol>
			<CCol md="5" className="text-start">{uBahasaObj.caption_usercaption||"Nama Pengguna"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_hakakses||"Hak Akses"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={3}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,onlinestatus,username,usercaption,hak,
				cabangid_arr,modullist,init_usergudang
			} = vItems;

			vNumber++;
			vCaptionupper	= (usercaption||"").toUpperCase();

			const vLangshak	= uBahasaObj["hakuser_"+(hak||"").toLowerCase()] || (hak||"UNDF");

			const vUsername	= vKeyword==="" ? (username||"") 
				: (username||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsercaption	= vKeyword==="" ? (usercaption||"") 
				: (usercaption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserhak	= vKeyword==="" ? (vLangshak) 
				: (vLangshak).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vCabangIDArr= cabangid_arr || [];
			
			return (
			<tr id={"idtr"+init_usergudang+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1">{(vNumber)+"."}</td>
			<td className="px-1">
			{(onlinestatus||false)===true && (
				<CTooltip content={"--"+(uBahasaObj.status_online||"ONLINE")+": "+vCaptionupper+" (Klik Untuk SET Offline)"}>
				<CLink onClick={()=>hdlKlikOffline(id,init_usergudang)} className="classcursorpointer">
				<CIcon icon="cilContact" className="text-success classblinking classverticalmiddle" height={25}/>
				</CLink>
				</CTooltip>
			)}
			{(onlinestatus||false)===false && (
				<CTooltip content={"--"+(uBahasaObj.status_offline||"Status Offline")+": "+vCaptionupper}>
				<CIcon icon="cilBan" className="text-dark classverticalmiddle" height={20}/>
				</CTooltip>
			)}
			</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="d-none d-md-block px-1 text-md-start">
				<div className="fw-bolder text-primary">{UFunc.renderHTML(vUsername)}</div>

				<small className="text-danger classfontsmaller">
				[{UFunc.WaktuAngka(tgl_input||"")}
				<span className="mx-1">&middot;</span>
				{UFunc.renderHTML(vUserinput)}]
				</small>
			</CCol>

			<CCol md="5" className="px-1 text-start">
				<div className="d-flex justify-content-between">
					<span className="text-capitalize text-info">{UFunc.renderHTML(vUsercaption)}</span>
					
					{((uTokenObj.userhak||"") !== (hak||"") && (init_usergudang==="YA")) && (
					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} size="sm"
						className="p-0" 
						color="transparent">
						<CIcon icon="cilOptions" className="classikonbox" height={20}/>
					</CDropdownToggle>
					<CDropdownMenu>
						{(uIsEditallow) && (
						<CDropdownItem onClick={()=>hdlKlikEdit(id,init_usergudang)}>
							<CIcon className="classikonedit me-2 align-middle" height={25}/>{uBahasaObj.caption_editdata||"Edit Data"}
						</CDropdownItem>
						)}
						
						{(uTokenObj.userhak||"")==="SUPERADMIN" && ((hak||"")!=="SUPERADMIN") && (
						<>
						<CDropdownItem className="d-none" onClick={()=>hdlKlikRole(id,init_usergudang)}>
							<CImage src={pjson.homepage+"api/images/menus_role.png"} height={18} className="me-2"/>
							{uBahasaObj.caption_roleakses||"Role Akses Menu"}
						</CDropdownItem>
						</>
						)}
							
						{(uIsHapusallow) && (
						<>
						<CDropdownItem onClick={()=>hdlKlikHapus(id,init_usergudang)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							{uBahasaObj.caption_hapusdata||"Hapus Data"}
						</CDropdownItem>
						</>
						)}

						{(!uIsEditallow && !uIsHapusallow) && (
						<CDropdownItem>
						<CIcon icon="cilBan" className="me-2 align-middle" height={25}/>NO ACTION
						</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
				</div>

				<div className="d-none classborderbottom my-2"/>
				<div className="d-none d-md-block">
				<small className="classfontsmaller">
				{(hak||"")!=="SUPERADMIN" && (
				<>
				<b>Akses Cabang</b>: {vCabangIDArr.map((vItemcabang,vKeycabang)=>{
					const vArridx = UFunc.getObjectIndeks(uDatacabangArr,vItemcabang);
					return (
					<span key={vKeycabang}>{uDatacabangArr[vArridx].nama_cabang}{vKeycabang < vCabangIDArr.length - 1 && (<> &middot; </>)}</span>
					)
				})}
				{vCabangIDArr.length <= 0 && (<i className="text-primary">UNSET</i>)}
				</>
				)}

				{(hak||"")==="SUPERADMIN" && (
					<>Akses Cabang: <b>UNLIMITED</b></>
				)}
				</small>
				</div>

				<div className="d-none classborderbottom my-2"/>

				{(modullist||"")==="" ? (
					<div className="d-none text-primary">&middot; Default Role &middot;</div>
				) : (
					<b className="d-none text-warning">&middot; CUSTOM ROLE &middot;</b>
				)}
			</CCol>
			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_username||"Username"}</CCol>
			<CCol xs="7" className="px-1 d-md-none text-md-start fw-bolder text-primary">
				{UFunc.renderHTML(vUsername)}
			</CCol>

			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_hakakses||"Hak Akses"}</CCol>
			<CCol xs="7" md="2" className="px-1 text-md-center text-uppercase">
				{UFunc.renderHTML(vUserhak)}
			</CCol>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
			<CCol xs="8" className="px-1 text-danger d-md-none">
				<small className="classfontsmaller">[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
			</CCol>

			{(hak||"")!=="SUPERADMIN" && (
			<>
			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>
			<CCol xs="12" className="px-1 d-md-none text-start fw-bolder">{uBahasaObj.caption_aksescabang||"Akses Cabang"}</CCol>
			<CCol xs="12" className="px-1 text-start classfontsmaller d-md-none">
				<small>
				{vCabangIDArr.map((vItemcabang,vKeycabang)=>{
					const vArridx = UFunc.getObjectIndeks(uDatacabangArr,vItemcabang);
					return (
					<span key={vKeycabang}>{uDatacabangArr[vArridx].nama_cabang}{vKeycabang < vCabangIDArr.length - 1 && (<> &middot; </>)}</span>
					)
				})}
				{vCabangIDArr.length <= 0 && (<i className="text-primary">UNSET</i>)}
				</small>
			</CCol>
			</>
			)}

			{(hak||"")==="SUPERADMIN" && (
			<>
			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_aksescabang||"Akses Cabang"}</CCol>
			<CCol xs="7" className="px-1 text-end classfontsmaller d-md-none">
				<b>UNLIMITED</b>
			</CCol>
			</>
			)}

			<CCol md="2" className="px-0 text-md-center d-none d-md-block">
				{(uTokenObj.userhak||"") === (hak||"") 
					|| (!uIsEditallow) 
					|| (init_usergudang!=="YA") ? 
				(
					<>&middot;</>
				) : (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikEdit(id,init_usergudang)} className="classikontabel classikonedit classcursorpointer"/>
				</CTooltip>

				{(uTokenObj.userhak||"")==="SUPERADMIN" && ((hak||"")!=="SUPERADMIN") && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_setrolemenu||"Atur User Role")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikRole(id,init_usergudang)} className="classcursorpointer d-none">
					<CImage src={pjson.homepage+"api/images/menus_role.png"} height={25}/>
				</CLink>
				</CTooltip>
				</>
				)}

				{uIsHapusallow && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapus(id,init_usergudang)} className="classikontabel classikonhapus classcursorpointer"/>
				</CTooltip>
				</>
				)}
				</>
				)}
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISShowform) return (<></>)

		let vRoleFilterArr 		= uDataroleArr;
		const vTokencabangArr 	= (uTokenObj.usercabangid||"").split(",");
		const vCabangAllowArr 	= uTokenObj.userhak!=="SUPERADMIN" 
			? uDatacabangArr.filter(vItems => vTokencabangArr.includes((vItems.id||"").toString()) )
			: uDatacabangArr;

		return (
		<CForm className="mx-1 mx-lg-5">
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_usercaption||"Nama User"}</CCol>
		<CCol md="8" lg="7" className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uDataformObj.usercaption||""}
				onChange={(e)=>{uDataformObj.usercaption = (e.target.value); setEfekview(!uEfekview);}}
				id="inpusercaption"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.word_username||"Username"}</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm"
				maxLength={50}
				value={uDataformObj.username||""}
				onChange={(e)=>{uDataformObj.username = (e.target.value); setEfekview(!uEfekview);}}
				id="inpusername"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol md="4" className="text-md-end">Password</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uDataformObj.passwd1||""}
				autoComplete="off"
				onChange={(e)=>{uDataformObj.passwd1 = (e.target.value); setEfekview(!uEfekview);}}
				id="inppasswd1"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">Konfirmasi Password</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uDataformObj.passwd2||""}
				onChange={(e)=>{uDataformObj.passwd2 = (e.target.value); setEfekview(!uEfekview);}}
				autoComplete="off"
				onKeyDown={(e)=>{
					!document.getElementById("inphak") && UFunc.
						hdlKeyDownInput(e,"btnDialogSimpan")
				}}
				id="inppasswd2"/>
		</CCol>
		</CRow>
		
		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_hakakses||"Hak Akses"}</CCol>
		<CCol md="6" lg="4" className="">
			{(uDataformObj.hak!=="SUPERADMIN") && (
			<CFormSelect size="sm"
				value={uDataformObj.hak}
				onChange={(e)=>{uDataformObj.hak = (e.target.value); setEfekview(!uEfekview);}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inphak">
			{vRoleFilterArr.map((vItems,vKeys)=>{
				return (<option key={vKeys} value={vItems.value}>{vItems.caption}</option>)
			})}
			</CFormSelect>
			)}
			{(uDataformObj.hak==="SUPERADMIN") && (
				<strong>{uBahasaObj["hakuser_"+(uDataformObj.hak||"freerole").toLowerCase()]||"UNDF"}</strong>
			)}
		</CCol>
		</CRow>

		{(parseInt(uDataselect)<=0) && (
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">
			<CLink
				onClick={()=>{uDataformObj.isCheckallCabang = (!uDataformObj.isCheckallCabang);hdlKlikCabangaksesall()}}
				className="classcursorpointer">
			{uBahasaObj.caption_aksescabang||"Akses Cabang"}
			</CLink>
		</CCol>
		<CCol md="8" className="">
			<CInputGroup size="sm">
			{vCabangAllowArr.map((vItems,vKeys)=>{
			return (
				<CInputGroupText
					key={vKeys}
					className="p-0 ps-2 pt-1 me-3 mb-2 bg-light text-dark"
					style={{minWidth:"150px !important"}}>
				<CFormCheck inline size="sm"
					value={vItems.id}
					id={"inpcabangid"+vItems.id} 
					label={vItems.nama_cabang}
					onChange={(e)=>hdlChangeCabangakses(e)}
					checked={(uDataformObj.cabangid_arr||[]).includes(vItems.id)}
					className="classcursorpointer"/>
				</CInputGroupText>
			)
			})}
			</CInputGroup>
		</CCol>
		</CRow>
		)}
		</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setDatamainArr([]);
		setDataselect(0);
		setRowselect(-1);
		setPageActive(1);
		setDataroleArr(uRoledefault);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_user || "";
		const vDATAS	= JSON.stringify({
			send_firstload : uFirstLoad,
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pengguna/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setDataroleArr(JSON.parse(output_string.datarole||"[]"));
				if(uFirstLoad==="YA") { setFirstLoad("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = (_IDXDATA) => {
		const vArridx 	= parseInt(_IDXDATA);
		const vTabelID	= vArridx >= 0 ? parseInt(uDatamainArr[vArridx].id) : 0;

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		//--ADD_init_usergudang--/
		//console.log("(User - apiProsessimpan) uDatamainArr[vArridx] = "+JSON.stringify(uDatamainArr[vArridx])); 
		if(vTabelID > 0) {
			uDataformObj.init_usergudang = uDatamainArr[vArridx].init_usergudang;
		} else uDataformObj.init_usergudang = "YA";
		//--END ADD_init_usergudang--/

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiProsessimpan) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_datajson: JSON.stringify(uDataformObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pengguna/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(vTabelID <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||255,
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						onlinestatus:false,
						username:uDataformObj.username,
						usercaption:uDataformObj.usercaption,
						hak: uDataformObj.hak,
						cabangid_arr:uDataformObj.cabangid_arr,
						init_usergudang: "YA",
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);

					initFormAwal();
					setPageActive(1);
					setRowselect(-1);
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					uDatamainArr[vArridx].usercaption 	= uDataformObj.usercaption;
					uDatamainArr[vArridx].hak 			= uDataformObj.hak;
					uDatamainArr[vArridx].username 		= uDataformObj.username;

					hdlToggledlg();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setShowform(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(User - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDXDATA) => {
		const vArridx 	= parseInt(_IDXDATA); if(vArridx < 0) return;
		const vTabelID	= parseInt(uDatamainArr[vArridx].id);
		const vInitgudang= uDatamainArr[vArridx].init_usergudang || "";
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_initgudang : vInitgudang,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pengguna/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			
				//setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesonline = (_IDXDATA) => {
		const vArridx 	= parseInt(_IDXDATA); if(vArridx < 0) return;
		const vTabelID	= parseInt(uDatamainArr[vArridx].id);
		const vInitgudang= uDatamainArr[vArridx].init_usergudang || "";
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			uDatamainArr[vArridx].onlinestatus = false;
			
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vTabelID,
			send_initgudang : vInitgudang,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pengguna/pr_offline";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vArridx].onlinestatus = false;

				setDatamainArr(uDatadefaultArr);
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiProsesonline) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiProsesonline) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		setShowform(false);
		/*if(uTokenObj.userhak!=="SUPERADMIN" && uTokenObj.userhak!=="ADMIN") {
			uNavigate("/a1profile"); return;
		}//-*/
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

    	return () => {
    		setDatamainArr([]);
    		setDatadefaultArr([]);
    		setDataroleArr([]);
    	}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		const vKeyword = (uKeywordObj.kwd_user||"").trim();
		if(vKeyword === "") setFirstLoad("YA");
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("User - [uHandelTambah] 1 => "+uHandelTambah);
		if(!uHandelTambah) return;

		setHeaderdlg((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		//console.log("User - [uHandelTambah] 2 => "+uHandelTambah);
		setShowform(true);
	},[uHandelTambah])
	React.useEffect(()=>{
		setDatadefaultArr(uDatamainArr.slice());
		setJmlData(uDatamainArr.length);
		if(uDatamainArr.length <= 0) return;

		uDatamainArr.sort((a,b) => (a.onlinestatus > b.onlinestatus) ? ((b.onlinestatus > a.onlinestatus) ? 0 : -1) : 1 )
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		/*const vKeyword = uKeywordObj.kwd_user || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uISShowform) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[uISShowform]) uISShowform 1: "+uISShowform);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpusercaption");
			vElFocus && vElFocus.focus();
		},200);
	},[uISShowform])
	React.useEffect(()=>{
		if(uDataformObj.hak === undefined)
			uDataformObj.hak = "GUDANG";
		if(uDataformObj.cabangid_arr === undefined)
			uDataformObj.cabangid_arr = [];
		if(uDataformObj.isCheckallCabang === undefined)
			uDataformObj.isCheckallCabang = false;
	},[uDataformObj,
		uDataformObj.hak,
		uDataformObj.cabangid_arr,
		uDataformObj.isCheckallCabang
	])

	if(UFunc.isEmpty(uTokenObj)) {
		setLoading(true); prosesExpired(); return;
	}
	if(uHtml500msg)  return (
		<MyPage500 content={uHtml500msg}/>
	)

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<strong>{uBahasaObj.caption_listdata||"List Data"}</strong>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeelapse)}ms</small>
		</div>
		</CCardHeader>
		<CCardBody className="px-0">
		{contentMain()}
		</CCardBody>
		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uISShowform} 
			dialogHeader={uHeaderdlg}
			toggle={hdlToggledlg}
			renderContent={contentDlgform()}/>
		</>
	)
}	

export default Pengguna