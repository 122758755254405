import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyPage500,
	MyProfiledetil,
	MyPagination,
	MyDialogform,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'
import NumberFormat from 'react-number-format';

const pjson 	= require('../../../package.json')
const Stokopnameform = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? 30: 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const [uFirstLoad,setFirstLoad]			= React.useState("YA");
	const [uDatainputObj,setDatainputObj]	= React.useState({})
	const [uIteminputObj,setIteminputObj]	= React.useState({})
	const [uPageActive,setPageActive]		= React.useState(1)
	const [uTabeleditObj,setTabeleditObj]	= React.useState({})
	const [uISFormupdated,setFormupdated]	= React.useState(false)
	const [uJmlitemopname,setJmlitemopname]	= React.useState(0);
	const [uJmlItemview,setJmlItemview]		= React.useState(0);
	const [uJmlHalview,setJmlHalview]		= React.useState(0);
	const [uDatabarangArr,setDatabarangArr]	= React.useState([])

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow]	= React.useState(false)
	const [uDlgformdataObj,setDlgformdataObj]= React.useState({})
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	//--END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if(uISFormupdated) {
			if(await(Konfirm(
				"Field Input/Edit Sudah Ada yang Berubah.."+
				"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
				"<br/>"+
				"<br/><b>Apakah Anda Yakin</b> ?"+
				""))) uNavigate(-1);

			return;
		}
		uNavigate(-1);
	}
	const hdlKDownDocument = (_EV) => {
		if(_EV.which === 120) {
			_EV.stopPropagation();
			_EV.preventDefault();

			document.getElementById("btnSimpanmain") &&
				document.getElementById("btnSimpanmain").click();

			return;
		}
	}
	const hdlKlikReset = async() => {
		if(uHtml500msg || !uISFormupdated) { apiLoaddata(); return }

		if(await(Konfirm(
			"Field Input/Edit Sudah Ada yang Berubah.."+
			"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
			"<br/>"+
			"<br/><b>Apakah Anda Yakin</b> ?"+
			""))) {
			setPageActive(1);
			setDatainputObj({});
			setEfekview(!uEfekview);
		}
	}
	const hdlKlikTabeledit = (_IDTABEL,_FIELD) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		_FIELD		= _FIELD||"";

		uTabeleditObj.id 		= _IDTABEL;
		uTabeleditObj.field 	= _FIELD;
		const vArridx			= UFunc.getObjectIndeks((uDatainputObj.items_arr || []),_IDTABEL);
		const vArridxbarang		= UFunc.getObjectIndeks(uDatabarangArr,_IDTABEL);
		uTabeleditObj.value 	= vArridx < 0 
			? parseFloat(uDatabarangArr[vArridxbarang].stok_init) : parseFloat((uDatainputObj.items_arr || [])[vArridx].stok_riil || 0);

		setEfekview(!uEfekview);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			document.getElementById("inptabeledit") &&
				document.getElementById("inptabeledit").focus();
		},250)
	}
	const hdlKDownTabelinput = (_EV) => {
		if(_EV.which === 27) {
			_EV.preventDefault();
			_EV.stopPropagation();
			setTabeleditObj({}); setEfekview(!uEfekview);
		}
		if(_EV.which === 13) {
			_EV.preventDefault();
			_EV.stopPropagation();
			hdlKlikTabelproses();
		}
	}
	const hdlKlikTabelproses = () => {
		const vIDtabel 	= parseInt(uTabeleditObj.id)||0;
		const vField 	= uTabeleditObj.field||"";

		const vArridxbarang	= UFunc.getObjectIndeks(uDatabarangArr,vIDtabel);
		const vArridx		= UFunc.getObjectIndeks((uDatainputObj.items_arr || []),vIDtabel);

		const vStokinit		= parseFloat(uDatabarangArr[vArridxbarang].stok_init||0);
		const vStokriil		= parseFloat(uTabeleditObj.value || 0);

		if(vStokinit === vStokriil) {
			(uDatainputObj.items_arr || []).splice(vArridx,1);
			setTabeleditObj({}); setEfekview(!uEfekview);
			return;
		}

		const vTmpObj = {
			id: vIDtabel,
			caption_barang: uDatabarangArr[vArridxbarang].caption_barang,
			harga_bahan: uDatabarangArr[vArridxbarang].harga_bahan,
			stok_riil: vStokriil,
			selisih: vStokriil - vStokinit
		}

		if(vArridx < 0) uDatainputObj.items_arr = [...(uDatainputObj.items_arr||[]),vTmpObj]	
		else (uDatainputObj.items_arr || [])[vArridx] = vTmpObj;

		setTabeleditObj({}); setEfekview(!uEfekview);
	}
	const hdlKlikTabelsmedit = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx= UFunc.getObjectIndeks((uDatainputObj.items_arr || []),_IDTABEL);
		const vArridxbarang		= UFunc.getObjectIndeks(uDatabarangArr,_IDTABEL);

		uTabeleditObj.id 		= _IDTABEL;
		uDlgformdataObj.id 		= _IDTABEL;
		uDlgformdataObj.value 	= vArridx < 0 
			? parseFloat(uDatabarangArr[vArridxbarang].stok_init) : parseFloat((uDatainputObj.items_arr || [])[vArridx].stok_riil || 0);
		uDlgformdataObj.headers = "Edit Stok RIIL: "+(uDatabarangArr[vArridxbarang].nama_barang||"").toUpperCase();

		setDlgformshow(true);
	}
	const hdlKlikDlgformsimpan = () => {
		const vIDtabel 	= parseInt(uDlgformdataObj.id)||0;

		const vArridxbarang	= UFunc.getObjectIndeks(uDatabarangArr,vIDtabel);
		const vArridx		= UFunc.getObjectIndeks((uDatainputObj.items_arr || []),vIDtabel);

		const vStokinit		= parseFloat(uDatabarangArr[vArridxbarang].stok_init||0);
		const vStokriil		= parseFloat(uDlgformdataObj.value || 0);

		if(vStokinit === vStokriil) {
			(uDatainputObj.items_arr || []).splice(vArridx,1);
			setDlgformshow(false);
			return;
		}

		const vTmpObj = {
			id: vIDtabel,
			caption_barang: uDatabarangArr[vArridxbarang].caption_barang,
			harga_bahan: uDatabarangArr[vArridxbarang].harga_bahan,
			stok_riil: vStokriil,
			selisih: vStokriil - vStokinit
		}

		if(vArridx < 0) uDatainputObj.items_arr = [...(uDatainputObj.items_arr||[]),vTmpObj]	
		else (uDatainputObj.items_arr || [])[vArridx] = vTmpObj;

		setDlgformshow(false);
	}
	const hdlKlikSimpanmain = async() => {
		let vElfocus	= document.getElementById("inptglproses");
		if((uDatainputObj.tgl_proses||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tgl Stok Opname Tidak Valid !"); return;
		}
		const vTanggalDT	= UFunc.toDate(uDatainputObj.tgl_proses);
		if(UFunc.DateIsBeyond(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("<b>Tgl Stok Opname Melewati Tanggal Hari Ini</b>..<br/>Mohon Diperbaiki !"); return;
		}
		vElfocus	= document.getElementById("inpketerangan");
		if((uDatainputObj.keterangan||"").trim() !== "") 
			if((uDatainputObj.keterangan||"").length < 3) {
				vElfocus && vElfocus.focus();
				showToast("Keterangan yang Anda Inputkan Tidak Valid !"); return;
			}
		if((uDatainputObj.items_arr||[]).length <= 0) {
			showToast("<b>Item Stok Opname Belum Ada yang Ditambahkan</b>.."+
				"<br/><br/>Minimal Harus Ada 1 (SATU) Item Barang yang Akan DIPROSES."+
				"<br/>Mohon Diperbaiki !"+
				""); return;
		}

		if(await(Konfirm(
			"<div class='fw-bolder mb-3 pb-3 classborderbottom'>INPUT STOK OPNAME</div>"+
			"<div class='mx-md-3'>"+
				"<div class='d-flex justify-content-between my-1'>"+
				"<div class='text-start'>Tgl Stok Opname</div>"+
				"<div class='text-end fw-bolder'>"+UFunc.TglAngka(uDatainputObj.tgl_proses)+"</div>"+
				"</div>"+

				"<div class='d-flex justify-content-between my-1'>"+
				"<div class='text-start'>Keterangan</div>"+
				"<div class='text-end fw-bolder fst-italic'>"+(uDatainputObj.keterangan||"-")+"</div>"+
				"</div>"+

				"<div class='d-flex justify-content-between my-1'>"+
				"<div class='text-start'>Jml Item Opname</div>"+
				"<div class='text-end fw-bolder'>"+UFunc.formatAngka(uJmlitemopname)+" Items</div>"+
				"</div>"+
			"</div>"+

			"<div class='d-flex justify-content-between my-3 classborderbottom'></div>"+
			"<div class='fw-bolder fst-italic text-center'>Apakah Anda Yakin ?</div>"+
			""))) apiProsesmain();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentTanggal = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg">
		<CCardHeader className="py-1 text-muted classfontsmaller">
			<small>Tanggal</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="mx-0">
			<CCol className="text-start px-0 px-md-auto">
				<small className="d-md-none">Tgl Stok Opname</small>
				<span className="d-none d-md-block">Tgl Stok Opname</span>
			</CCol>

			<CCol xs="8" md="6" className="text-end">
			{(uTokenObj.userhak==="SUPERADMIN" || uTokenObj.userhak==="PENGELOLA"
				) ? (
			<CFormInput size="sm" type="date"
				value={uDatainputObj.tgl_proses||""}
				onChange={(e)=>{uDatainputObj.tgl_proses=e.target.value;setEfekview(!uEfekview)}}
				id="inptglproses"/>
			) : (
				<b>{UFunc.HariAngka(uDatainputObj.tgl_proses)}</b>
			)}
			</CCol>
			</CRow>

			<CRow className="mx-0 mt-2 d-md-none">
			<CCol className="text-start px-0">
				<small className="d-md-none">Keterangan</small>
				<span className="d-none d-md-block">Keterangan</span>
			</CCol>

			<CCol xs="8" className="text-end">
			<CFormInput size="sm"
				maxLength={255}
				value={uDatainputObj.keterangan||""}
				onChange={(e)=>{uDatainputObj.keterangan=e.target.value;setEfekview(!uEfekview)}}
				className="text-capitalize"
				id="inpketerangan"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentKet = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg">
		<CCardHeader className="py-1 text-muted classfontsmaller">
			<small>Keterangan</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="mx-0">
			<CCol className="text-start">Keterangan</CCol>

			<CCol md="8" className="text-end">
			<CFormInput size="sm"
				maxLength={255}
				value={uDatainputObj.keterangan||""}
				onChange={(e)=>{uDatainputObj.keterangan=e.target.value;setEfekview(!uEfekview)}}
				className="text-capitalize"
				id="inpketerangan"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentItemform = () => {
		if(uHtml500msg) return (<></>)

		const vKeywordstr 	= uIteminputObj.caption_item || "";
		const vDataviewArr	= (uDatabarangArr||[]).filter(vItems=>
			((vItems.nama_barang||"").toUpperCase().includes((vKeywordstr||"").toUpperCase()) 
				|| (vItems.kode_sku||"").toUpperCase().includes((vKeywordstr||"").toUpperCase())
				|| (vItems.nama_jenis||"").toUpperCase().includes((vKeywordstr||"").toUpperCase())
			)
		);

		return (
		<>
		<div style={{width:"35%",minWidth:270}}>
		<CInputGroup size="sm">
		<CFormInput size="sm"
			placeholder="--Masukkan Keyword Barang.."
			value={uIteminputObj.caption_item||""}
			onChange={(e)=>{uIteminputObj.caption_item=e.target.value;setEfekview(!uEfekview)}}
			id="inpitemnama"/>
		</CInputGroup>
		</div>

		{(uIteminputObj.caption_item||"") !== "" && (
		<>
		<span className="mx-1">&middot;</span>
		<CTooltip content="--Reset Keyword">
		<CLink onClick={()=>{
			setTabeleditObj({});setPageActive(1);
			setIteminputObj({}); setEfekview(!uEfekview);
		}} className="classcursorpointer text-danger">
		<CIcon icon="cilX" className="classikonbox" height={25}/>
		</CLink>
		</CTooltip>
		</>
		)}

		<span className="mx-1">&middot;</span>
		<b className="d-none d-md-inline">({UFunc.formatAngka(uJmlItemview)})</b>
		<small className="d-md-none">({UFunc.formatAngka(uJmlItemview)})</small>
		</>
		)
	}
	const contentItem = () => {
		if(uHtml500msg) return (<></>)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vKeywordstr 	= uIteminputObj.caption_item || "";
		const vDataviewArr	= (uDatabarangArr||[]).filter(vItems=>
			((vItems.nama_barang||"").toUpperCase().includes((vKeywordstr||"").toUpperCase()) 
				|| (vItems.kode_sku||"").toUpperCase().includes((vKeywordstr||"").toUpperCase())
				|| (vItems.nama_jenis||"").toUpperCase().includes((vKeywordstr||"").toUpperCase())
			)
		).slice(vMin,(vMax));

		const vItemupdatedArr	= uDatainputObj.items_arr || [];

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="6" className="text-start">{uBahasaObj.caption_namabarang||"Nama Barang"}</CCol>
			<CCol md="1" className="px-0">{uBahasaObj.caption_stokminimal||"Stok Min"}</CCol>
			<CCol md="1" className="px-0">{uBahasaObj.caption_stoksistem||"Stok Sistem"}</CCol>
			<CCol md="2" className="text-primary text-uppercase">{uBahasaObj.caption_stokriil||"Stok Riil"}</CCol>
			<CCol md="2" className="">{uBahasaObj.word_selisih||"Selisih"}</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><td colSpan={2}>{JSON.stringify(vItemupdatedArr)}</td></tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys)=>{
			const {
				id,nama_barang,kode_sku,nama_jenis,stok_init,stok_minimal
			} = vItems;

			vNumber++;

			const vArridx		= UFunc.getObjectIndeks(vItemupdatedArr,id);
			const vStokminimal	= (parseFloat(stok_minimal)||0) < 0 ? 0 : parseFloat(stok_minimal)
			const vStokupdated	= vArridx < 0 ? parseFloat(stok_init) : parseFloat(vItemupdatedArr[vArridx].stok_riil || 0);

			const vSelisih		= vArridx < 0 ? 0 : parseFloat(vItemupdatedArr[vArridx].selisih || 0);

			return (
			<tr id={"idtr"+id}
				className={"align-top text-end "+(parseInt(uTabeleditObj.id||0)===parseInt(id)?"classrowselect":"")}
				key={vKeys}>
			<td className="px-1">{(vNumber)}.</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol md="6" className="px-1 text-start d-flex justify-content-between justify-content-md-between">
				<div>
				<span className="d-none d-md-inline-block">
				{(nama_jenis||"")==="" ? (
					<i className="text-muted">(Unselect Jenis)</i>
				) : (
					<span className="">{nama_jenis}</span>
				)}
				<span className="mx-1">&middot;</span>
				</span>

				{(nama_jenis||"")!=="" && (
				<div className="d-md-none fst-italic">
					<span className="">{nama_jenis}</span>
				</div>
				)}
		
				<span className="text-info">{nama_barang}</span>
		
				{(kode_sku||"")!="" && (
				<>
				<span className="mx-1">&middot;</span>
				<span className="">{kode_sku}</span>
				</>
				)}
				</div>

				<CLink onClick={()=>hdlKlikTabelsmedit(id)} className="d-md-none classcursorpointer">
					<small className="classikonbox px-2 fst-italic">Edit Stok</small>
				</CLink>
			</CCol>

			<CCol xs="12" className="px-1 my-2 classborderbottom d-md-none"/>

			<CCol xs="6" className="px-1 text-start d-md-none">Stok Minimal</CCol>
			<CCol xs="6" md="1" className="px-1 text-md-center">
				<span className="">{UFunc.formatAngka(vStokminimal)}</span>
				<span className="ms-1 d-md-none classfontsmaller"><small>PCS</small></span>
			</CCol>
			
			<CCol xs="6" className="px-1 text-start d-md-none">Stok Sistem</CCol>
			<CCol xs="6" md="1" className="px-1 text-md-center text-success">
				<span className="">{UFunc.formatAngka(stok_init)}</span>
				<span className="ms-1 d-md-none classfontsmaller"><small>PCS</small></span>
			</CCol>
			
			<CCol xs="12" className="px-1 mt-1 d-md-none"/>
			<CCol xs="6" className="px-1 text-start d-md-none">Stok RIIL</CCol>
			<CCol xs="6" className="px-1 text-md-center d-md-none">
				<span className="pb-1 px-2 bg-primary text-white rounded-3">
				<b className="">{UFunc.formatAngka(vStokupdated)}</b>
				<span className="ms-1 classfontsmaller"><small>PCS</small></span>
				</span>
			</CCol>
			
			<CCol md="2" className="px-1 d-none d-md-block text-md-center">
				{(parseInt(uTabeleditObj.id) === parseInt(id) 
					&& uTabeleditObj.field === "STOKRIIL") ? (
				<div className="mx-lg-3">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					className="text-end"
					value={uTabeleditObj.value}
					onChange={(e)=>{uTabeleditObj.value=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKDownTabelinput(e)}
					onFocus={(e)=>e.target.select()}
					id="inptabeledit"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikTabelproses()}
					id="btnTabeledit">
					<CIcon icon="cilCheckAlt" className="fw-bolder"/>
				</CButton>
				</CInputGroup>
				</div>
				) : (
				<CTooltip content="---Klik Untuk Edit">
				<CLink 
					className="d-block classcursorpointer border border-primary rounded-3"
					style={{borderStyle:"dotted !important"}}
					onClick={()=>hdlKlikTabeledit(id,"STOKRIIL")}>
					<big className="fw-bold">
					{UFunc.formatAngka(vStokupdated)}
					</big>
					</CLink>
				</CTooltip>
				)}
			</CCol>

			<CCol xs="12" className="px-1 my-2 classborderbottom d-md-none"/>
			<CCol xs="6" className="px-1 text-start d-md-none">Selisih</CCol>
			<CCol xs="6" md="2" className="px-1 text-md-center">
			{vSelisih !== 0 ? (
				<>
				<small className="text-danger fst-italic fw-bolder">
					( {vSelisih>0 && ("+")}{UFunc.formatAngka(vSelisih)} )
				</small>
				<span className="ms-1 d-md-none text-danger classfontsmaller"><small>PCS</small></span>
				</>
			) : (
				<>
				<span className="d-none d-md-block">&nbsp;</span>
				<span className="d-md-none">0</span>
				<span className="ms-1 d-md-none classfontsmaller"><small>PCS</small></span>
				</>
			)}
			</CCol>

			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uISDlgformshow) return (<></>)

		const vArridx	= UFunc.getObjectIndeks(uDatabarangArr,uDlgformdataObj.id);
		if(vArridx < 0) return (<></>);

		return (
		<CForm>
		<CRow className="mx-0 my-1">
			<CCol xs="6">Stok Sistem</CCol>
			<CCol xs="6" className="text-end text-success">
				{UFunc.formatAngka(uDatabarangArr[vArridx].stok_init)} PCS
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="mx-0 my-1 text-primary fw-bolder">
			<CCol xs="6">Stok Riil</CCol>
			<CCol xs="6" className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					value={uDlgformdataObj.value||0}
					onChange={(e)=>{uDlgformdataObj.value = e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					className="text-end"
					id="inpstokriil"/>
				<CInputGroupText>PCS</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setIteminputObj({});
		setTabeleditObj({});
		setDatabarangArr([]);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstLoad,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_forminit";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Barang - apiLoaddata) output_string.databarang : "+output_string.databarang);
				setDatabarangArr(JSON.parse(output_string.databarang || "[]"));
				if(uFirstLoad==="YA") { setFirstLoad("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Stokopnameform - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Stokopnameform - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesmain = () => {
		const vElbutton	= document.getElementById("btnSimpanmain");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			showToast(uBahasaObj.pesan_sukses||"pesan_sukses","SUKSES");
			uNavigate(-1);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_parsjson : JSON.stringify(uDatainputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				//console.log("(Barang - apiProsesmain) output_string.datajenisbarang : "+output_string.datajenisbarang);
				showToast(uBahasaObj.pesan_sukses||"pesan_sukses","SUKSES");
				uNavigate(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Stokopnameform - apiProsesmain) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Stokopnameform - apiProsesmain) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !!uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		apiLoaddata();

		if(!isMobile) {
			const vElfocus = document.getElementById("inptglproses");
			vElfocus && vElfocus.focus();
		}
		return () => {
			setPageActive(1); setDlgformshow(false);
			setDatainputObj({});setDatabarangArr([]);
		}
	},[])
	React.useEffect(()=>{
		if(uDatainputObj.tgl_proses === undefined)
			uDatainputObj.tgl_proses = UFunc.DbDate();

		setJmlitemopname((uDatainputObj.items_arr||[]).length); 
	},[uDatainputObj,
	uDatainputObj.tgl_proses,
	(uDatainputObj.items_arr||[]).length
	])
	React.useEffect(()=>{
		const vItemArr 		= uDatainputObj.items_arr || [];
		//console.log("vItemArr.length = "+(uDatainputObj.items_arr||[]).length);
		if(uDatainputObj.tgl_proses !== (UFunc.DbDate())
			|| (uDatainputObj.keterangan||"") !== ("")
			|| (uDatainputObj.items_arr||[]).length > 0
			) setFormupdated(true);
		else setFormupdated(false);
	},[uDatainputObj,
	uDatainputObj.tgl_proses,
	uDatainputObj.keterangan,
	(uDatainputObj.items_arr||[]).length
	])
	React.useEffect(()=>{
		const vKeywordstr 	= uIteminputObj.caption_item || "";
		const vDataviewArr	= (uDatabarangArr||[]).filter(vItems=>
			((vItems.nama_barang||"").toUpperCase().includes((vKeywordstr||"").toUpperCase()) 
				|| (vItems.kode_sku||"").toUpperCase().includes((vKeywordstr||"").toUpperCase())
				|| (vItems.nama_jenis||"").toUpperCase().includes((vKeywordstr||"").toUpperCase())
			)
		);

		setJmlItemview(vDataviewArr.length);
	},[uIteminputObj,uIteminputObj.caption_item,uDatabarangArr]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlItemview / uMaxData);
		setJmlHalview(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);
	},[uJmlItemview]);
	React.useEffect(()=>{
		if(!uISDlgformshow) { 
			setTabeleditObj({}); setDlgformdataObj({}); 
			return;
		}
	},[uISDlgformshow]);
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }

	//console.log("(Stokopnameform) uNavigate => "+JSON.stringify(uNavigate))
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop">
		<CContainer fluid className="m-0 p-2 border-bottom">
			<CHeaderToggler className="ms-1 px-2"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="d-md-flex me-auto text-normal">
				<b>Input Stok Opname</b>
			</CHeaderNav>

			<CHeaderNav className="me-1 align-items-center">
				<MyProfiledetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 px-3 px-lg-4 py-1 justify-content-center justify-content-md-between align-items-center">
			<div className="d-none d-md-block"/>
			<div>
			<CLink className="classikon classikonreset classcursorpointer"
				 onClick={()=>hdlKlikReset()}/>
			<span className="mx-1">&middot;</span>
			<span className="align-middle">
				(<span className="text-primary fw-bold">{UFunc.formatAngka(uJmlitemopname)}</span>)
			</span>
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className="mb-5 pb-3">
			{(uHtml500msg) ? (
				<MyPage500 content={uHtml500msg}/>
			) : (
			<CForm>
			<CRow className="mx-0">
			<CCol className="px-0">{contentTanggal()}</CCol>
			<CCol className="d-none d-md-block px-0 ps-2">{contentKet()}</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol className="px-0">
			<CCard className="classcardbg mt-3">
				<CCardHeader className="d-flex justify-content-center justify-content-md-end align-items-center">
					{contentItemform()}
				</CCardHeader>
				<CCardBody className="px-0 px-lg-3">{contentItem()}</CCardBody>
				
				{(uJmlItemview > uMaxData) && (
				<CCardFooter>
					<MyPagination 
						activePage={uPageActive}
						pages={uJmlHalview}
						onActivePageChange={(i)=>setPageActive(i)}
						align="start"/>
				</CCardFooter>
				)}
			</CCard>
			</CCol>
			</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="fixed" 
			className="px-3 text-black-50 justify-content-center"  
			style={{backgroundColor:"#f0f0f0"}}>
		<MyButton
			disabled={uHtml500msg}
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaObj.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{centered:true}}
			show={uISDlgformshow} 
			onSimpan={hdlKlikDlgformsimpan} 
			dialogHeader={uDlgformdataObj.headers}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}
			className="d-md-none"/>
		</>
	) 
}	

export default Stokopnameform