import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CFormInput,
	CListGroup,
	CListGroupItem,
	CCloseButton,
	CRow,
	CCol,
} from '@coreui/react';
import { isMobile } from "react-device-detect";
import PropTypes from 'prop-types';
import { UFunc } from "../helpers/functions";

const MyDialoglookup = props => {
	const {
		show,
		toggle,
		dialogHeader,
		inputValue,
		dataItemArr, 
		onKlikPilihItem,
	} = props;

	const [uInputValue,setInputValue]	= React.useState(inputValue || "xx");
	const [uCursorPos,setCursorPos]		= React.useState(-1);
	const [uDataSearchArr,setDataSearchArr]	= React.useState(dataItemArr || MyDialoglookup.defaultProps.dataItemArr);
	const uBahasaob  = useSelector(state => state.listBahasa);

	const hdlKeyDownModal=(_EV)=>{
		if (_EV.keyCode === 38 && uCursorPos >= 0) {//--->UP
			_EV.preventDefault();
			setCursorPos(uCursorPos-1);
			return;
		} else if (_EV.keyCode === 40 && uCursorPos < uDataSearchArr.length-1) {//--->DOWN
			_EV.preventDefault();
			setCursorPos(uCursorPos+1);
			return;
		}

		if(_EV.keyCode === 13) {
			if(uCursorPos < 0 || uCursorPos >= uDataSearchArr.length) return;

			_EV.preventDefault();
			const vIdx = uCursorPos;
			onKlikPilihItem(uDataSearchArr[vIdx].id);
			return;
		}
	}
	const hdlKeyDownInput=(_EV)=>{
		if(_EV.keyCode===13) {
			_EV.preventDefault();
			setCursorPos(0);
			return;
		}
	}

	/*React.useEffect(()=>{
		document.addEventListener("keydown",hdlKeyDownModal);
		return ()=>{
			document.removeEventListener("keydown",hdlKeyDownModal);
		}
	},[])//-*/
    React.useEffect(() => {
		const vElsinput	= document.getElementById("inpdialoglookup");
		const vElsrow	= document.getElementById("idrowscroll");

		if(uCursorPos <= 0) {
			vElsinput && vElsinput.scrollTo(0,0);
		}
		if(uCursorPos < 0) {
			if(!isMobile) vElsinput && vElsinput.focus();
			return;
		}

		if(document.getElementById("idlistitem"+uCursorPos))
			document.getElementById("idlistitem"+uCursorPos).focus();
    }, [uCursorPos]);
	React.useEffect(()=>{
		if(!show)
			{ setInputValue(""); setCursorPos(-1); return; }

		setInputValue(inputValue || "");
		if(inputValue==="") {
			const vDataViewArr	= dataItemArr.slice(0,100);
			setDataSearchArr(vDataViewArr);
		} //-*/

		//console.log("[show] inputValue = "+inputValue);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElsinput	= document.getElementById("inpdialoglookup");
			const vElsrow	= document.getElementById("idrowscroll");

			//console.log("[show] Checked..");
			vElsinput && vElsinput.scrollTo(0,0);
			if(inputValue === "") 
				vElsinput && vElsinput.focus();
			else
				vElsinput && vElsinput.select();
		},350);//-*/
	},[show])//,()=>{  setTimeout(()=>{refInput.focus()}, 1); });
	React.useEffect(()=>{
		setCursorPos(-1);
		if(uInputValue==="") {
			const vDataViewArr	= dataItemArr.slice(0,100);
			setDataSearchArr(vDataViewArr);
			return;
		}

		let vSearchArr = dataItemArr.filter((vItems)=>
			(vItems.caption||"UNDF").toUpperCase().includes((uInputValue||"UNDF").toUpperCase()) ||
				(vItems.caption_sub||"UNDF").toUpperCase().includes((uInputValue||"UNDF").toUpperCase())
		);
		if(uInputValue.length < 3)
			vSearchArr	= vSearchArr.slice(0,100);
		setDataSearchArr(vSearchArr);//-*/
	},[uInputValue]);

	//console.log("("+cComponentname+") Checked..");
	//console.log("(MyDialoglookup) dataItemArr => "+JSON.stringify(dataItemArr));

	return (
		<div onKeyDown={(e)=>hdlKeyDownModal(e)}>
		<CModal
			alignment={"top"}
			size={undefined}
			className="z-2099"
			onClose={toggle}
			visible={show}>
		<CModalHeader closeButton={false} className="bg-dark text-light">
			<CModalTitle>{dialogHeader || MyDialoglookup.defaultProps.dialogHeader}</CModalTitle>
			<CCloseButton className="text-light" onClick={toggle}/>
		</CModalHeader>

		<CModalBody className="p-0" 
			id="idmodalbody">
			<CRow className="sticky-top bg-gray-100 mx-auto" id="idheaderlookup">
			<CCol className="p-3 px-5">
				<CFormInput
					size="sm"
					autoFocus={true}
					value={uInputValue}
					onChange={(e)=>setInputValue(e.target.value)}
					onKeyDown={(e)=>hdlKeyDownInput(e)}
					onFocus={(e)=>setCursorPos(-1)}
					id="inpdialoglookup"/>
			</CCol>
			</CRow>
			<CRow className="py-3 mx-auto">
			<CCol
				style={{maxHeight:(window.innerHeight - 300),overflowY:"auto",overflowX:"hidden"}}
				id="idrowscroll">
			<CListGroup> 
			{(uDataSearchArr).map((vItems,vKeys)=>{
				//console.log("(MyDialoglookup - uDataSearchArr.map) uCursorPos===vKeys => "+uCursorPos+"==="+vKeys);
				return (
					<CListGroupItem key={vKeys}
						id={"idlistitem"+vKeys}
						tabIndex={vKeys}
						active={uCursorPos===vKeys}
						onClick={()=>onKlikPilihItem(vItems.id)}
						className="py-2 list-group-item-action classcursorpointer">
						<CRow>
						<CCol className="px-1">
							<div className="m-0">{vItems.caption}</div>
							{vItems.caption_sub && (
							<small className="mx-2 fst-italic">{vItems.caption_sub}</small>
							)}
						</CCol>
						
						{vItems.harga_satuan && (
						<CCol xs="3" className="px-1 text-end fw-bolder">
							{UFunc.formatAngka(vItems.harga_satuan)}
						</CCol>
						)}
						</CRow>
					</CListGroupItem>
				)
			})}
			</CListGroup>
			</CCol>
			</CRow>
		</CModalBody>
		<CModalFooter>
		<CButton 
			className="px-3"
			color="dark" 
			onClick={toggle}
			id="btnDialogViewOK"> 
		{uBahasaob.word_ok||"Ok !"}
		</CButton>
		</CModalFooter>
		</CModal>
		</div>
	)
}
MyDialoglookup.defaultProps = {
	show: false,
	dataItemArr: [],
	dialogHeader: "Lookup Data Item",
	onKlikPilihItem:()=>{},
};

MyDialoglookup.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,     
	dialogHeader: PropTypes.string,
	inputValue: PropTypes.string,
	dataItemArr: PropTypes.array, 
	onKlikPilihItem: PropTypes.func,     
}

export default MyDialoglookup;
