import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	//CImage,
	CLink,
	CTooltip,
	CSpinner,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Pakaiklinik 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? 20 : 100;
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA"||uTokenObj.userhak==="GUDANG");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--LOAD_BY_PAGE--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//--END LOAD_BY_PAGE--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	const uElJmlmobile		= document.getElementById("idjmldatamobile");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikBarangtoggle = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].tgl_pakai||"").toUpperCase();
		initSelectrow(_IDTABEL);

		if((uDatamainArr[vArridx].init_expand||"TIDAK") === "YA") {
			uDatamainArr[vArridx].init_expand = undefined;
			setEfekview(!uEfekview); return;
		}

		if((uDatamainArr[vArridx].items_arr||[]).length > 0) {
			uDatamainArr[vArridx].init_expand = "YA";
			setEfekview(!uEfekview); return;
		}

		apiLoaditem(_IDTABEL)
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = UFunc.TglAngka(uDatamainArr[vArridx].tgl_pakai).toUpperCase();
		initSelectrow(_IDTABEL);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionupper+"</B>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT--/
	const initResetview = () => {
    	setPageActive(1);
    	setJmlData(0);
    	setDatamainArr([]); 
    	setDataselect({});
    	setRowselect(-1);
    	setEfekview(!uEfekview);
    }
	const initJumlahdata = () => {
		const vKeyword 		= uKeywordObj.kwd_pakaiklinik || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			uElJmlmobile && (uElJmlmobile.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		uElJmlmobile && (uElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_pakaiklinik || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=>vItems.pg === uPageActive)
		//const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="text-start">{uBahasaObj.word_tanggal||"Tanggal"}</CCol>
			<CCol md="3" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="6" className="text-start">{uBahasaObj.word_barang||"Barang"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,tgl_pakai,keterangan,items_arr,is_itemloading,
				caption_barang_first,init_expand,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (tgl_pakai||"").toUpperCase();

			const vTanggal	= vKeyword==="" ? UFunc.TglAngka(tgl_pakai) 
				: UFunc.TglAngka(tgl_pakai).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKeterangan	= vKeyword==="" ? (keterangan||"-") 
				: (keterangan||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vItemArr	= items_arr || [];

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-0 pe-1">{vNumber}.</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol md="2" className="px-1 text-md-start d-flex d-md-block justify-content-between">
				<div>
				<div className="text-info text-capitalize">{UFunc.renderHTML(vTanggal)}</div>

				<div className="classfontsmaller text-danger d-none d-md-block">
				<small>[
					{UFunc.DbDate(UFunc.toDatetime(tgl_input)) !== UFunc.DbDate(UFunc.toDate(tgl_pakai)) && (
					<>{UFunc.WaktuAngka(tgl_input||"")}<span className="mx-1">&middot;</span></>
					)}
					{UFunc.renderHTML(vUserinput)}
				]</small>
				</div>
				</div>

				<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					{(uIsHapusallow) && (
					<>
					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="align-middle classikonhapus me-2" height={25}/>
						{uBahasaObj.caption_hapusdata||"Hapus Data"}
					</CDropdownItem>
					</>
					)}
				</CDropdownMenu>
				</CDropdown>
			</CCol>

			<CCol xs="12" className="px-1 classborderbottom my-2 d-md-none"/>
			<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol xs="8" md="3" className="px-1 text-md-start">{UFunc.renderHTML(vKeterangan)}</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none"/>
			<CCol md="6" className="px-1 text-start">
				<div className="d-flex d-md-block justify-content-between">
				<span><b>Item Barang</b><span className="mx-1 d-none d-md-inline">&middot;</span></span>
				{(is_itemloading||false)===true ? (
				<CSpinner color="primary" size="sm" className="align-top"/>
				):(
				<CLink onClick={()=>hdlKlikBarangtoggle(id)} className="classcursorpointer">
					{(init_expand||"TIDAK")!=="YA" ? (
					<CIcon icon="cilArrowBottom" className="align-top" height={23}/>
					) : (
					<CIcon icon="cilArrowTop" className="align-top" height={23}/>
					)}
				</CLink>
				)}
				</div>

				<small>
				<ol className="m-0">
				{(init_expand||"TIDAK")!=="YA" ? (
					<li className="text-muted fst-italic">
					{caption_barang_first||"caption_barang_first"} ...
					</li>
				) : (
				<>
					{vItemArr.map((vItembarang,vKeybarang)=>{
						const vNamabarang	= vKeyword==="" ? (vItembarang.caption_barang||"caption_barang") 
							: (vItembarang.caption_barang||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");

						return (
						<li key={vKeybarang}>
						<div className="d-md-flex justify-content-md-between text-md-end">
							<div>
							<span className="">{UFunc.renderHTML(vNamabarang)}</span>
							<span className="ms-1">(<b>{UFunc.formatAngka(vItembarang.qty)}</b> PCS)</span>
							</div>

							<div className="text-primary fst-italic">
							{UFunc.renderHTML(vItembarang.keperluan||"keperluan")}
							</div>
						</div>
						</li>
						)
					})}
				</>
				)}
				</ol>
				</small>
			</CCol>

			<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
			<CCol xs="8" className="px-1 text-danger classfontsmaller d-md-none">
				<small>
				[
					{UFunc.DbDate(UFunc.toDatetime(tgl_input)) !== UFunc.DbDate(UFunc.toDate(tgl_pakai)) && (
					<>{UFunc.WaktuAngka(tgl_input||"")}<span className="mx-1">&middot;</span></>
					)}
					{UFunc.renderHTML(vUserinput)}
				]
				</small>
			</CCol>

			<CCol md="1" className="px-0 text-md-center d-none d-md-block">
				{uIsHapusallow && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
				</CTooltip>
				</>
				)}
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = (_PAGE) => {
		setHtml500msg();
		const vPage	= parseInt(_PAGE)||1;

		const vHandelview	= 
			((uHandelView||false)===true || uFirstload==="YA" || uDatamainArr.length <= 0)
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_pakaiklinik || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==vPage);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: vPage,
			send_maxdata: uMaxData,
			send_loadjml : vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pakaiklinik/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vTmpArr]);

				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				if(uFirstload==="YA") { setFirstload("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pakaiklinik - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pakaiklinik - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaditem = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_itemloading = true;
		setEfekview(!uEfekview);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].is_itemloading = false;
			uDatamainArr[vArridx].init_expand = "YA";
			setEfekview(!uEfekview);
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pakaiklinik/ld_item";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_itemloading = false;
			setSessionaktif(true);
			if(output_string.tampil) {
				uDatamainArr[vArridx].items_arr = JSON.parse(output_string.dataitem||"[]");
				uDatamainArr[vArridx].init_expand = "YA";
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pakaiklinik - apiLoaditem) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_itemloading = false;
			console.log("(Pakaiklinik - apiLoaditem) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoaditem) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pakaiklinik/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pakaiklinik - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pakaiklinik - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		//console.log("(Barangmasuk - [uIsInitbefore]) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit);
		apiLoaddata(vPageinit);//--JIKA_PAGINATION_DIATUR_BACKEND--/
	},[uIsInitbefore]);
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		if(!uIsInitbefore) {
			apiLoaddata();
		}

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA");
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		if(!uHandelView) return;

		initResetview();
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uPageActive > 1) setPageActive(1); else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("(Pakaiklinik-[uHandelTambah]) uHandelTambah = "+uHandelTambah)
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uNavigate("/pakaiklinik/forminput/0");
	},[uHandelTambah])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJumlahdata();
	},[uJmlData])
	React.useEffect(()=>{
		if(uFirstload==="YA") return;
		if(uHandelView) return;

		setDataselect({});
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<span className="text-primary">{uBahasaObj.caption_rekapinput||"Rekap Input Data"}</span>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary" id="idjmldatamobile">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<span className="classfontsmaller"><small className="text-muted">{UFunc.formatAngka(uTimeelapse)}ms</small></span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		</>
	)
}	

export default Pakaiklinik