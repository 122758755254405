import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CRow,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
	MyButton
} from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json')
const A1profile = (props) => {
	const { 
		setToken, setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");

	const [uHtml500msg,setHtml500msg]	= React.useState(); 

	//---FORM_VARS--/
	const uDatacabangArr			= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const [uInpusername,setInpusername]		= React.useState(uTokenObj.username||"");
	const [uInpusercaption,setInpusercaption]= React.useState(uTokenObj.usercaption||"");
	const [uInppasswdold,setInppasswdold]	= React.useState("");
	const [uInppasswd1,setInppasswd1]		= React.useState("");
	const [uInppasswd2,setInppasswd2]		= React.useState("");
	const [uBtndisabled,setBtndisabled]		= React.useState(false);
	//---END FORM_VARS--/

	const hdlKlikSimpan = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpusercaption");
		if(uInpusercaption.trim()==="") {
			vElFocus.focus();
			showToast("Nama User Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inpusername");
		if(uInpusername.trim()==="") {
			vElFocus.focus();
			showToast("Username Harus Diisi !");return;
		}
		if(uInppasswd1 !=="" || uInppasswd2!=="") {
			vElFocus = document.getElementById("inppasswdold");
			if(uInppasswdold === "") {
				vElFocus.focus();
				showToast("Password Lama Harus Diisi !"); return;
			}

			vElFocus = document.getElementById("inppasswd1");
			if(uInppasswd1 !== uInppasswd2) {
				vElFocus.focus();
				showToast("Password dan Konfirmasi Password Harus Sama !"); return;
			}
		}

		apiProsessimpan();
	}

	const initContentform = () => {
		if(uHtml500msg) return ( UFunc.renderHTML(uHtml500msg) );

		return (
		<CForm className="mx-1 mx-lg-5">
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaob.caption_usercaption||"Nama User"}</CCol>
		<CCol md="8" lg="7" className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpusercaption}
				onChange={(e)=>setInpusercaption(e.target.value)}
				autoComplete="off" 
				id="inpusercaption"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaob.word_username||"Username"}</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm"
				maxLength={50}
				value={uInpusername}
				autoComplete="off" 
				onChange={(e)=>setInpusername(e.target.value)}
				id="inpusername"/>
		</CCol>
		</CRow>

		<CRow className="my-3"><CCol className="classborderbottom"/></CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">Password Lama</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uInppasswdold}
				onChange={(e)=>setInppasswdold(e.target.value)}
				autoComplete="off" 
				id="inppasswdold"/>
		</CCol>
		</CRow>
		<CRow className="my-0 ms-0">
		<CCol md="4" className="text-md-end"/>
		<CCol md="8" className="classpetunjuk">
			&#8627; Harus Diisi Jika MENGGANTI Password
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">Password</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uInppasswd1}
				autoComplete="off" 
				onChange={(e)=>setInppasswd1(e.target.value)}
				id="inppasswd1"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">Konfirmasi Password</CCol>
		<CCol md="6" lg="4" className="">
			<CFormInput size="sm" type="password"
				value={uInppasswd2}
				onChange={(e)=>setInppasswd2(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
				autoComplete="off" 
				id="inppasswd2"/>
		</CCol>
		</CRow>
		
		<div className="my-3 classborderbottom"/>
		
		<CRow className="my-2">
		<CCol xs="6" md="4" className="text-md-end">{uBahasaob.caption_hakakses||"Hak Akses"}</CCol>
		<CCol xs="6" lg="4" className="text-end text-md-start">
			<strong>{uBahasaob["hakuser_"+(uTokenObj.userhak||"fo").toLowerCase()]}</strong>
		</CCol>
		</CRow>
		
		<CRow className="my-2 mt-4 mt-md-2">
		<CCol xs="12" md="4" className="text-md-end">{uBahasaob.caption_aksescabang||"Akses Cabang"}</CCol>
		<CCol xs="12" md="8" className="text-start">
		{uTokenObj.userhak === "SUPERADMIN" ? (
			<i className="text-info">&middot; SEMUA CABANG &middot;</i>
		) : (
		<>
		{uDatacabangArr.filter(vItems=>
			(uTokenObj.usercabangid||"").split(",").includes(vItems.id)
		).map((vItems,vKeys,vRows)=>{
			return (
			<span key={vKeys}>
			<i className="text-info">{vItems.nama_cabang}</i>
			{vKeys+1 < vRows.length && (
			<span className="mx-2">&middot;</span>
			)}
			</span>
			)
		})}
		</>
		)}
		</CCol>
		</CRow>

		{(uTokenObj.usergudanginit !== "YA") && (
		<CRow className="my-2">
		<CCol xs="12" md="4" className="text-md-end">{uBahasaob.word_catatan||"Catatan"}</CCol>
		<CCol xs="12" md="8" className="text-start">
			<div className="classpetunjuk px-0">Untuk Pengguna GiO Dental, Perubahan yang dilakukan JUGA Akan BERPENGARUH pada SISTEM GiO Dental..</div>
		</CCol>
		</CRow>
		)}
		</CForm>
		)
	}

	const apiProsessimpan = () => {
		setLoading(true)
		setBtndisabled(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setLoading(false)
			setBtndisabled(false)

			setInppasswdold("");
			setInppasswd1("");
			setInppasswd2("");

			showToast(uBahasaob.pesan_sukses||"Berhasil Disimpan !","SUKSES");
		},2000); return
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_usercaption : uInpusercaption,
			send_username : uInpusername,
			send_passwdold : uInppasswdold,
			send_passwd1 : uInppasswd1,
			send_passwd2 : uInppasswd1,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_init/pr_profile";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		//}).then((response)=> { if (response.status === 200) { return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setBtndisabled(false)
			setLoading(false)
			if(output_string.sukses) {
				setLoading(false)
				setBtndisabled(false)

				setInppasswdold("");
				setInppasswd1("");
				setInppasswd2("");

				uTokenObj.usercaption  	= (uInpusercaption||"").trim()
				uTokenObj.username  		= (uInpusername||"").trim()
				localStorage.setItem("token",JSON.stringify(uTokenObj));

				showToast(uBahasaob.pesan_sukses||"Berhasil Disimpan !","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(A1profile - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setBtndisabled(true); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			setBtndisabled(false)
			console.log("(A1profile - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		if((uTokenObj.username||"").toUpperCase()==="SUPERADMIN") 
			{uNavigate("/submasterdata/pengguna"); return}//-*/
		
		uDispatch({type: "set", effectApp: !uEfekapp});
		return ()=>{}
	},[])
	//React.useEffect(()=>{ uDispatch({type: "set", effectApp: !uEfekapp}); },[uEfekapp])

	if(UFunc.isEmpty(uTokenObj)) {
		setLoading(true); prosesExpired(); return;
	}

	//console.log("A1profile - uActiveroute => "+(uActiveroute));
	return (
		<CCard className="mx-md-2 mx-lg-5 classcardbg">
		<CCardHeader><strong>Edit Akun Anda</strong></CCardHeader>
		<CCardBody>{initContentform()}</CCardBody>
		<CCardFooter className="text-center">
			<MyButton
				disabled={uBtndisabled}
				onClick={()=>hdlKlikSimpan()}
				iconname="cilCheckAlt"
				id="btnSimpan">Simpan</MyButton>
		</CCardFooter>
		</CCard>
	)
}	

export default A1profile