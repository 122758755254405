import React from 'react'
import { 
	CSpinner,
} from '@coreui/react'

const MyLoader = (props) => {
	const {isShow} = props
	
	if(!isShow) return (<></>)
	
	return (
	<div className="position-fixed w-100 h-100 fs-bold d-flex justify-content-center align-items-center"
		style={{zIndex:1091,backgroundColor:"#6C778E55"}}>
		<CSpinner color="warning" className="" style={{width:'3.5rem', height:'3.5rem'}}/>
	</div>
	)
}	

export default MyLoader