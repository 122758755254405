import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CImage
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { ContHeaderProfile,ContHeaderSub } from './index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json');
const ContHeader = (props) => {
	const { setToken,setLoading, showToast } = props 

	const uDispatch 	= useDispatch()
	const uNavigate 	= useNavigate()
	const uSidebarshow 	= useSelector((state) => state.sidebarShow)
	const uBahasaObj  	= useSelector(state => state.listBahasa)
	const uTokenobj		= JSON.parse(localStorage.getItem("token")||"{}");
	const uActiveroute	= useSelector(state => state.activeRoute)
	//const [uEfekview,setEfekview] 	= React.useState(false);

	//console.log("(ContHeader) uSidebarshow => "+uSidebarshow)
	return (
		<CHeader position="sticky" className="mb-4 classheadertop">
		<CContainer fluid className="">
			<CHeaderToggler onClick={() => {
					uDispatch({ type: 'set', sidebarShow: !uSidebarshow });
				}}
				className="ps-1">
			<CIcon icon="cilMenu" size="lg" className="align-middle"/>
			</CHeaderToggler>

			{(uActiveroute||"").toUpperCase()==="DASHBOARD" 
				|| (uBahasaObj["menus_"+(uActiveroute||"")]||"") === ""
			? (
			<CHeaderBrand className="d-flex d-md-none me-auto text-normal py-0 classcursorpointer" 
				onClick={()=>uNavigate("/dashboard")}>
				<div style={{marginBottom:"-9px",fontSize:25,fontWeight:"normal"}}
					className="classfontbrand">
				{(()=>{
					const vNameArr = pjson.name.split("tor");
					return (
					<>
					{vNameArr[0]}
					t<CIcon className="classikon classikonlogo" height={20}/>r
					{vNameArr[1]}
					</>
					)
				})()}
				</div>
			</CHeaderBrand>
			) : (
			<CHeaderBrand className="d-flex d-md-none me-auto text-normal py-0 classcursorpointer" 
				onClick={()=>uNavigate("/dashboard")}>
				<small><b>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</b></small>
			</CHeaderBrand>
			)}

			<CHeaderBrand className="d-none d-md-flex me-auto text-normal classcursorpointer" 
				onClick={()=>uNavigate("/dashboard")}>
				<small><b>Hi, {UFunc.Usercaptionfilter(uTokenobj.usercaption)}</b></small>
			</CHeaderBrand>

			<CHeaderNav>
			</CHeaderNav>
			<CHeaderNav className="ms-3 align-items-center">
				<ContHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<ContHeaderSub {...props}/>
		</CHeader>
	)
}

export default ContHeader

	