import React from 'react'
//import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CImage,
  //CForm,CButton,CFormInput,CInputGroup,
  //CRow,
  //CCol,
  CLink,
  CTooltip,
} from '@coreui/react'
//import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
//import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubrole = (props) => {
	//const { setToken,setLoading, showToast } = props 

	//--DOM--/
	//const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaob  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)

	const hdlKlikReset = async() => {
		const vProses = () => {
			const vHeaderTmpObj	= uHeaderActionObj;
			uDispatch({type: "set", gInitHeaderAction: {}});
			vHeaderTmpObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: vHeaderTmpObj});
		}

		if(await Konfirm(uBahasaob.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
	}

	//console.log("Headersubrole - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div>
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaob["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<div className="form-horizontal ">
			<CTooltip content={"--"+(uBahasaob.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
		</div>
		</>
	)
}

export default Headersubrole
