import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,
	//CButton,CFormInput,CInputGroup,
	CLink,
	CTooltip,
	//CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
	MyButton,
} from '../../components/index'
//import { Konfirm } from '../../helpers/onConfirm'
//import { UFunc } from '../../helpers/functions'

const pjson 				= require('../../../package.json')
const Headersublapfeenurse	= (props) => {
	/*const { 
		setToken, setLoading, showToast 
	} = props//*/

	//--DOM--/
	//const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	//const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	//const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);
	
	const hdlKlikFilter = () => {
		uHeaderActionObj.isHeaderFilter = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		uHeaderActionObj.isHeaderReset = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}

	//console.log("Headersublapfeenurse - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		<small>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm className="">
			<MyButton
				onClick={()=>hdlKlikFilter()}
				id="btnFilter">
				<CIcon icon="cilApplications" className="me-2"/>
				{uBahasaObj.word_filter||"Filter"}
			</MyButton>
			</CForm>
			<span className="mx-1 d-none d-lg-block">&middot;</span>
			
			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>

			<span className="me-2 d-none d-md-block">&middot;</span>
			<span className="d-none d-md-block">
			<b id="idjmldata" className="text-primary">0</b>{" "}{uBahasaObj.word_data||"Data"}
			</span>			
		</div>
		</>
	)
}

export default Headersublapfeenurse
