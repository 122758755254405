import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	CSpinner,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogview,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Stokopname 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_tabel || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--LOAD_BY_PAGE--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//--END LOAD_BY_PAGE--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	const [uIsDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]= React.useState({});
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	const uElJmlmobile		= document.getElementById("idjmldatamobile");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlDlgviewtoggle = () => { setDlgviewshow(false) }
	const hdlKlikLihatdetil = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = UFunc.TglAngka(uDatamainArr[vArridx].tgl_opname).toUpperCase();
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);
		setDataselect({id:uDatamainArr[vArridx].id})

		uDlgviewdataObj.headers = ("Detil Opname: "+vCaptionupper);

		if((uDatamainArr[vArridx].items_arr || []).length > 0) {
			uDlgviewdataObj.items_arr = uDatamainArr[vArridx].items_arr || []; 
			setDlgviewshow(true);
			return;
		}

		apiLoaddetil(_IDTABEL);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionupper = UFunc.TglAngka(uDatamainArr[vArridx].tgl_opname).toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <b>"+vCaptionupper+"</b>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	//--END HANDEL--/

	//--INIT--/
	const initResetview = () => {
    	setPageActive(1);
    	setJmlData(0);
    	setDatamainArr([]); 
    	setDataselect({});
    	setRowselect(-1);
		//console.log("(Stokopname-initResetview) initResetview = ")
    	setEfekview(!uEfekview);
    }
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_stokopname||"").trim();
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		//const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		const vDataviewArr	= uDatamainArr.filter(vItems=>vItems.pg === uPageActive)

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="text-start">{uBahasaObj.caption_tglopname||"Tgl Opname"}</CCol>
			<CCol md="5" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_jmlitem||"Jml Item Opname"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,keterangan,tgl_opname,jml_item,
				is_loading,
			} = vItems;

			vNumber++;
			const vCaptionupper	= UFunc.TglAngka(tgl_opname).toUpperCase();

			const vKeterangan	= vKeyword==="" ? (keterangan||"-") 
				: (keterangan||"-").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglopname	= vKeyword==="" ? UFunc.TglAngka(tgl_opname) 
				: UFunc.TglAngka(tgl_opname).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTglinput		= vKeyword==="" ? UFunc.WaktuAngka(tgl_input) 
				: UFunc.WaktuAngka(tgl_input).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 pe-0">{vNumber}.</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="d-none d-md-block px-1 text-md-start">
				<div className="text-info text-capitalize">{UFunc.renderHTML(vTglopname)}</div>

				<div className="classfontsmaller">
					<small className="text-danger">[
					{UFunc.DbDate(UFunc.toDatetime(tgl_input)) !== UFunc.DbDate(UFunc.toDate(tgl_opname)) && (
						<>
						{UFunc.renderHTML(vTglinput)}
						<span className="mx-1">&middot;</span>
						</>
					)}
					{UFunc.renderHTML(vUserinput)}]</small>
				</div>
			</CCol>

			<CCol xs="12" className="px-1 d-md-none">
			<div className="d-flex justify-content-between">
				<span className="text-info text-capitalize">{UFunc.renderHTML(vTglopname)}</span>

				{(is_loading||false)===true ? (
				<CSpinner color="primary" size="sm"/>
				) : (
				<CDropdown>
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)}>
						<CIcon icon="cilSearch" className="classikonbox me-2" height={25}/>
						{uBahasaObj.caption_lihatitem||"Lihat Item"}
					</CDropdownItem>
							
					{(uIsHapusallow) && (
					<>
					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="align-middle classikontabel classikonhapus" height={25}/>
						{uBahasaObj.caption_hapusdata||"Hapus Data"}
					</CDropdownItem>
					</>
					)}
				</CDropdownMenu>
				</CDropdown>
				)}
			</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			<CCol xs="8" md="5" className="px-1 text-md-start"><i>{UFunc.renderHTML(vKeterangan)}</i></CCol>

			<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_jmlitem||"Jumlah Item"}</CCol>
			<CCol xs="7" md="2" className="px-1 text-md-center fw-bolder">
				<b>{UFunc.formatAngka(jml_item)}</b> Item
			</CCol>
			
			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
			<CCol xs="8" className="px-1 text-danger d-md-none">
				<small className="classfontsmaller">[{UFunc.renderHTML(vTglinput)} &middot; {UFunc.renderHTML(vUserinput)}]</small>
			</CCol>

			<CCol md="2" className="px-0 text-md-center d-none d-md-block">
				{(is_loading||false)===true ? (
				<CSpinner color="primary" size="sm"/>
				) : (
				<>
					<CTooltip content={"--"+(uBahasaObj.caption_lihatitem||"Lihat Item")+": "+vCaptionupper}>
						<CLink onClick={()=>hdlKlikLihatdetil(id)} className="classcursorpointer">
						<CIcon icon="cilSearch" className="classikonbox" height={25}/>
						</CLink>
					</CTooltip>

					{uIsHapusallow && (
					<>
					<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
						<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
					</CTooltip>
					</>
					)}
				</>
				)}
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgview = () => {
		if(!uIsDlgviewshow) return (<></>)

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,uDataselect.id);
		const vKeterangan	= uDatamainArr[vArridx].keterangan||"";
		const vItemArr		= uDlgviewdataObj.items_arr || [];

		return (
		<CCard className="classcardbg">
		<CCardHeader className="d-flex">
			<b className="me-2">Ket:</b>
			<small className="fst-italic">{vKeterangan||"List Item Opname"}</small>
		</CCardHeader>
		<CCardBody className="px-lg-5">
		<ol>
		{vItemArr.map((vItems,vKeys)=>{
			const {
				caption_barang,id_barang,status_opname,qty
			} = vItems	

			return (
			<li className="classborderbottom mb-1 pb-1">
			<div className="d-flex justify-content-between">
				<div>
				<span>{caption_barang||"Undefined"}</span>
				<span className="mx-1">&middot;</span>
				<span className={"mx-1 "+(status_opname==="MASUK" ? "" : "text-danger")}>({status_opname})</span>
				</div>
				<b className="text-primary">{UFunc.formatAngka(qty)} PCS</b>
			</div>
			</li>
			)
		})}
		</ol>
		</CCardBody>
		</CCard>
		)
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = (_PAGE) => {
		setHtml500msg();
		const vPage	= parseInt(_PAGE)||1;

		const vHandelview	= 
			((uHandelView||false)===true || uFirstload==="YA" || uDatamainArr.length <= 0)
			?"YA":"TIDAK";
		const vKeyword 		= (uKeywordObj.stokopname || "").trim();
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==vPage);
			}
		}
		setDataselect({});
		setRowselect(-1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Stokopname - apiLoaddata) vPage => "+vPage); 
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: vPage,
			send_maxdata: uMaxData,
			send_loadjml : vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_rekap";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vTmpArr]);

				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.totaldata||uJmlData))
				else setJmlData(vTmpArr.length);

				if(uFirstload==="YA") { setFirstload("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Stokopname - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Stokopname - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0;

		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_loading = true;
		setEfekview(!uEfekview);

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoaddetil) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].is_loading = false;
			setDlgviewshow(true);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vArridx].is_loading = false;
			if(output_string.tampil) {
				const vTmpArr	= JSON.parse(output_string.datadetil||"[]");
				uDatamainArr[vArridx].items_arr = vTmpArr;
				uDlgviewdataObj.items_arr 		= vTmpArr;

				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Stokopname - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgviewshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_loading = false;
			console.log("(Stokopname - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoaddetil) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Stokopname - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgviewshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Stokopname - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA");
			initResetview();
			hdlDlgviewtoggle();
    	}
	},[])
	React.useEffect(()=>{
		//console.log("(Stokurgent-[uHandelTambah]) uHandelTambah = "+uHandelTambah)
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uNavigate("/stokopname/formstokopname");
	},[uHandelTambah])
	React.useEffect(()=>{
		//console.log("(Stokopname-[uHandelView]) uHandelView = "+uHandelView)
		if(!uHandelView) return

		initResetview();
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uPageActive > 1) setPageActive(1); else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		uElJmlmobile && (uElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ 
		if(uFirstload==="YA") return;
		if(uHandelView) return;

		setDataselect({});
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		apiLoaddata(uPageActive);
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsDlgviewshow)
			{ setDlgviewdataObj({}); setDataselect(); return }
	},[uIsDlgviewshow])//-*/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<span className="text-primary">Rekap Input Data</span>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary" id="idjmldatamobile">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<span className="classfontsmaller"><small className="text-muted">{UFunc.formatAngka(uTimeelapse)}ms</small></span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewdataObj.headers}
			toggle={hdlDlgviewtoggle}
			renderContent={contentDlgview()}
			{...props}/>
		</>
	)
}	

export default Stokopname