import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyPage500,
	MyProfiledetil,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'
import NumberFormat from 'react-number-format';

const pjson 	= require('../../../package.json')
const Piutangform = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const { tabel_id } 			= useParams(); 
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTabelID 					= parseInt(tabel_id)||0;
	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);

	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const uTrxselectedObj			= useSelector(state => state.gInitTabelSelected) || {};
	const uTransaksiID				= parseInt(uTrxselectedObj.initID) || 0;
	const [uFirstLoad,setFirstLoad]			= React.useState("YA");
	const [uDatapiutangObj,setDatapiutangObj]= React.useState({})
	const [uDataloadObj,setDataloadObj]		= React.useState({})
	const [uDatainputObj,setDatainputObj]	= React.useState({})
	const [uISFormupdated,setFormupdated]	= React.useState(false)
	const [uJmlitem,setJmlitem]				= React.useState(0);
	const [uISItemmobileshow,setItemmobileshow]= React.useState(false)

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	//--END DLG_LOOKUP--/

	//--NOTA_CETAK--/
	const [uDatanotaObj,setDatanotaObj]		= React.useState({})
	const uHTMLstylenota					= useSelector(state => state.gHTMLstyleNota);
	const uSettingcetakObj					= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas						= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri						= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize							= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle						= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 						= document.getElementById("ifmcontentstoprint");
	//--END NOTA_CETAK--/

	const uHeightheader	= document.getElementById("idheadermain") &&
		document.getElementById("idheadermain").offsetHeight;
	const uHeightcardtanggal	= document.getElementById("idcardtanggal") &&
		document.getElementById("idcardtanggal").offsetHeight;

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if(uISFormupdated) {
			if(await(Konfirm(
				"Field Input/Edit Sudah Ada yang Berubah.."+
				"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
				"<br/>"+
				"<br/><b>Apakah Anda Yakin</b> ?"+
				""))) uNavigate(-1);

			return;
		}
		uNavigate(-1);
	}
	const hdlKDownDocument = (_EV) => {
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			document.getElementById("btnSimpanmain") &&
				document.getElementById("btnSimpanmain").click();
		}
	}
	const hdlKlikReset = async() => {
		console.log("tgl_bayar = "+uDataloadObj.tgl_bayar+" -- "+uDatainputObj.tgl_bayar);
		console.log("nilai_bayar = "+uDataloadObj.nilai_bayar+" -- "+uDatainputObj.nilai_bayar);
		if(uHtml500msg) { apiLoaddata(); return }
		if(!uISFormupdated) { apiLoaddata(); return }

		const vFunc	= () => {
			if(uTabelID > 0) setDatainputObj(uDataloadObj);
			else setDatainputObj({});
			setDatanotaObj({});
			setEfekview(!uEfekview)
		}

		if(await(Konfirm(
			"Field Input/Edit Sudah Ada yang Berubah.."+
			"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
			"<br/>"+
			"<br/><b>Apakah Anda Yakin</b> ?"+
			""))) vFunc();
	}
	const hdlKlikSimpanmain = async() => {
		let vElfocus = document.getElementById("inptglbayar");
		if(uDatainputObj.tgl_bayar === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Tidak Valid (1) !"); return;
		}
		if(UFunc.SelisihHari(uDataloadObj.tgl_transaksi||UFunc.DbDate(),uDatainputObj.tgl_bayar) < 0) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Tidak Valid (2) !"); return;
		}
		const vTglbayarDT 	= UFunc.toDate(uDatainputObj.tgl_bayar);
		if(UFunc.DateIsBeyond(vTglbayarDT)){
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Melewati Hari Ini !"); return;
		}
		
		vElfocus = document.getElementById("inpnilaibayar");
		if(parseFloat(uDatainputObj.nilai_bayar||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Bayar Tidak Valid !"); return;
		}

		const vKembalian	= parseFloat(uDatapiutangObj.nilai_bruto||0) - 
				parseFloat(uDatapiutangObj.diskon||0) - 
				parseFloat(uDatapiutangObj.terbayar||0) - 
				parseFloat(uDatainputObj.nilai_bayar||0);

		const vHTMLs	=
		"<center><big class='text-info'>"+uDatapiutangObj.nama_pasien+"</big></center>"+
		"<hr style='border-top-style:dashed;'/>"+
		"<div class='d-flex justify-content-between'>"+
		"<div class=''>Tgl Bayar</div>"+
		"<div class='text-end fw-bolder'>"+UFunc.HariAngka(uDatainputObj.tgl_bayar)+"</div>"+
		"</div>"+

		"<div class='d-flex justify-content-between'>"+
		"<div class=''>Nomor Nota</div>"+
		"<div class='text-end fw-bolder'>"+(uDatainputObj.no_nota||"-")+"</div>"+
		"</div>"+

		"<hr style='border-top-style:dashed;'/>"+

		"<div class='d-flex justify-content-between'>"+
		"<div class=''>Sisa Kekurangan</div>"+
		"<div class='text-end fw-bolder'>Rp "+
		UFunc.formatAngka(
			parseFloat(uDatapiutangObj.nilai_bruto||0) 
				- parseFloat(uDatapiutangObj.diskon||0)
				- parseFloat(uDatapiutangObj.terbayar||0)
			)+
		"</div>"+
		"</div>"+

		"<div class='d-flex justify-content-between text-primary fw-bolder'>"+
		"<div class=''>JML BAYAR</div>"+
		"<div class='text-end'>Rp "+
		UFunc.formatAngka(parseFloat(uDatainputObj.nilai_bayar||0) )+
		"</div>"+
		"</div>"+

		"<hr style='border-top-style:dashed;'/>"+

		"<big>"+
		"<div class='d-flex justify-content-between "+(vKembalian<=0?"text-success":"text-danger")+"'>"+
		"<div class=''>"+(vKembalian<=0?"Kembalian":"Sisa Piutang")+"</div>"+
		"<div class='text-end fw-bolder'>Rp "+UFunc.formatAngka(Math.abs(vKembalian))+"</div>"+
		"</div>"+
		"</big>"+
		"<br/>"+
		"<div align='center'>Apakah Anda Yakin ?</div>"+
		"";

		if(await(Konfirm(vHTMLs))) apiProsesmain();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const prosesCetaknota = () => {
		let vContent 	= contentNota();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setDatanotaObj({});
		uKeywordObj.kwd_piutang = undefined; 
	    localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));

		uDispatch({type: "set", gInitTabelSelected: {}});
		showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
		uNavigate(-1);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentTanggal = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg mb-3" id="idcardtanggal">
		<CCardHeader className="py-0 text-muted">
			<small className="classfontsmaller">Tanggal</small>
		</CCardHeader>

		<CCardBody className="py-1">
		<small>
		<CRow className="my-2">
		<CCol className="">Tgl Transaksi</CCol>
		<CCol className="text-end fw-bolder text-primary">{UFunc.HariAngka(uDatapiutangObj.tgl_transaksi)}</CCol>
		</CRow>

		{(uTokenObj.userhak==="SUPERADMIN" || uTokenObj.userhak==="PENGELOLA") ? (
		<CRow className="my-2">
		<CCol className="">Tgl Bayar</CCol>
		<CCol className="text-end fw-bolder">
			<CFormInput size="sm" type="date"
				value={uDatainputObj.tgl_bayar}
				onChange={(e)=>{uDatainputObj.tgl_bayar = e.target.value;setEfekview(!uEfekview)}}
				id="inptglbayar"/>
		</CCol>
		</CRow>
		) : (
		<CRow className="my-2">
		<CCol className="">Tgl Bayar</CCol>
		<CCol className="text-end fw-bolder">{UFunc.HariAngka(uDatainputObj.tgl_bayar)}</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol className="">No.Nota</CCol>
		<CCol className="text-end fw-bolder">{(uDatainputObj.no_nota||"-")}</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol className="">Kasir</CCol>
		<CCol className="text-end fw-bolder">{UFunc.Usercaptionfilter((uTokenObj.usercaption),2)}</CCol>
		</CRow>
		</small>
		</CCardBody>
		</CCard>
		)
	}
	const contentBayar = () => {
		if(uHtml500msg) return (<></>)

		const vKembalian	= parseFloat(uDatapiutangObj.nilai_bruto||0) - 
				parseFloat(uDatapiutangObj.diskon||0) - 
				parseFloat(uDatapiutangObj.terbayar||0) - 
				parseFloat(uDatainputObj.nilai_bayar||0);

		return (
		<CCard className="classcardbg mb-3">
		<CCardHeader className="py-0 text-muted">
			<small className="classfontsmaller">Nilai Nota</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="my-1">
			<CCol className="">Sub Total</CCol>
			<CCol xs="1" className="px-0">Rp</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatapiutangObj.nilai_bruto)}</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol className="">Diskon</CCol>
			<CCol xs="1" className="px-0">Rp</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatapiutangObj.diskon)}</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol className=""/>
			<CCol className="text-end classborderbottom"/>
			</CRow>

			<CRow className="my-1">
			<CCol className="">Total Netto</CCol>
			<CCol xs="1" className="px-0">Rp</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(
				parseFloat(uDatapiutangObj.nilai_bruto) - parseFloat(uDatapiutangObj.diskon)
			)}</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol className="">Terbayar</CCol>
			<CCol xs="1" className="px-0">Rp</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatapiutangObj.terbayar)}</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol className=""/>
			<CCol className="text-end classborderbottom"/>
			</CRow>

			<CRow className="my-1">
			<CCol className="">Sisa Kekurangan</CCol>
			<CCol xs="1" className="px-0">Rp</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(
				parseFloat(uDatapiutangObj.nilai_bruto) - 
					parseFloat(uDatapiutangObj.diskon) - 
					parseFloat(uDatapiutangObj.terbayar)
			)}</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol className="">Nilai Bayar</CCol>
			<CCol className="text-end pe-1">
				<NumberFormat 
					value={uDatainputObj.nilai_bayar||0}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDatainputObj.nilai_bayar=(values.value)}
					renderText={values => (
						<CFormInput size="sm"
							className="text-end"
							value={values}
							onChange={(e)=>{uDatainputObj.nilai_bayar=e.target.value;setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpanmain")}
							id="inpnilaibayar"/>
					)} 
				prefix={isMobile?'':'Rp'}/>
			</CCol>
			</CRow>

			<CRow className="my-2 mt-3">
			<CCol className=""/>
			<CCol className="text-end classborderbottom"/>
			</CRow>

			<big>
			<CRow className={"my-1 fw-bolder "+(vKembalian<=0?"text-success":"text-danger")}>
			<CCol className="">{(vKembalian<=0?"Kembalian":"Sisa Piutang")}</CCol>
			<CCol xs="1" className="px-0">Rp</CCol>
			<CCol className="text-end">{UFunc.formatAngka(Math.abs(vKembalian))}</CCol>
			</CRow>
			</big>
		</CCardBody>
		</CCard>
		)
	}
	const contentItem = () => {
		if(uHtml500msg) return (<></>)

		const vItemArr		= uDatapiutangObj.items_arr || [];
		const vContentitem	= () => {
			return (
			<>
			{vItemArr.map((vItems,vKeys)=>{
			const {
				id,id_barang,nama_barang,qty,harga_satuan
			} = vItems;

			return (
			<CRow key={vKeys} className="mx-0 my-2 classborderbottom pb-2">
				<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
				<CCol xs="11" className="px-1 fst-italic">{nama_barang}</CCol>
				<CCol xs="1" className="px-1 text-end"/>
				<CCol xs="8" className="px-1">
					(<b>{UFunc.formatAngka(qty)}</b> PCS x {UFunc.formatAngka(harga_satuan)})
				</CCol>
				<CCol xs="3" className="px-1 text-end fw-bolder">{UFunc.formatAngka(
					parseInt(qty) * parseFloat(harga_satuan)
				)}</CCol>
			</CRow>
			)
			})}
			</>
			)
		}

		return (
		<>
		<CCard className="classcardbg mb-3 d-md-none" style={{minHeight:"10px"}}>
		<CCardHeader className="py-1 d-flex justify-content-between">
			<small className="classfontsmaller text-muted">Item Barang</small>
			<CLink className="classcursorpointer"
				onClick={()=>setItemmobileshow(!uISItemmobileshow)}>
				{uISItemmobileshow ? (
				<CIcon icon="cilChevronTop" className="classikonbox" height={25}/>
				) : (
				<CIcon icon="cilChevronBottom" className="classikonbox" height={25}/>
				)}
			</CLink>
		</CCardHeader>
		{uISItemmobileshow && (
		<CCardBody className="">{vContentitem()}</CCardBody>
		)}
		</CCard>

		<CCard className="classcardbg mb-3 d-none d-md-block" id="idcarditem">
		<CCardHeader className="py-0 text-muted">
			<small className="classfontsmaller">Item Barang</small>
		</CCardHeader>

		<CCardBody className="py-1">
		<small>{vContentitem()}</small>
		</CCardBody>
		</CCard>
		</>
		)
	}
	const contentNota = () => {
		console.log("uDatanotaObj = "+JSON.stringify(uDatanotaObj||{}));
		if(UFunc.isEmpty(uDatanotaObj)) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uSystemsettingObj.view_image_nota||"YA")==="YA";
		const vURLlogo		= uSystemsettingObj.url_image_nota||"";//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(uDatanotaObj);
		vHTMLs 			+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<div>
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</div>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uSystemdataObj.telp || "") !== "")
			vTelpPerusahaan += `
			<div>`+
				(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uSystemdataObj.telp || "")+
			`</div>
			`;
		//---END TELP--/

		const vFooternota = (uSystemdataObj.footer_kasir||"").trim() !=="" ?
			`&nbsp;
			<hr/>
			<div align="center">`+(uSystemdataObj.footer_kasir||"")+`</div>
			` : ``;
		
		vHTMLs 		= vHTMLs.replace(/_DIVLOGOIMAGE_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uSystemdataObj.nama_klinik || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uSystemdataObj.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_DIVROWTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_DIVFOOTERNOTA_/g,vFooternota);
		
		return vHTMLs;
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDatapiutangObj({});
		setDataloadObj({});

		const vElTombol	= document.getElementById("btnSimpanmain");
		vElTombol && (vElTombol.disabled = true);
		setLoading(true); 
 
		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_transaksiid : uTransaksiID,
			send_firstload : uFirstLoad,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_piutang/ld_formdata";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			vElTombol && (vElTombol.disabled = false);
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Barang - apiLoaddata) output_string.datajenisbarang : "+output_string.datajenisbarang);
				uDatainputObj.no_nota = output_string.newnotastr || "UNDF";
				setDatapiutangObj(JSON.parse(output_string.datapiutang || "{}"))
				if(uTabelID > 0) {
					setDataloadObj(JSON.parse(output_string.dataobject || "{}"))
				}
				if(uFirstLoad==="YA") { setFirstLoad("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Piutangform - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElTombol && (vElTombol.disabled = false);
			setLoading(false);
			console.log("(Piutangform - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesmain = () => {
		const vElTombol	= document.getElementById("btnSimpanmain");
		vElTombol && (vElTombol.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);

			setDatainputObj({});
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_transaksiid : uTransaksiID,
			send_datajson : JSON.stringify(uDatainputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_piutang/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);
			if(output_string.sukses) {
				if(uTabelID <= 0) {
					setDatainputObj({});
					setDatanotaObj(JSON.parse(output_string.datanota||"{}"))
				} else uNavigate(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Billingform - apiProsesmain) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);
			console.log("(Billingform - apiProsesmain) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !!uEfekapp});
    	apiLoaddata();

    	if(!isMobile) {
    		const vElfocus = document.getElementById("inpnilaibayar");
    		vElfocus && vElfocus.focus();
    	}
    	return () => {
    		setItemmobileshow(false);
			setDatapiutangObj({});
    		setDatainputObj({});
    		setDataloadObj({});
    	}
	},[])
	React.useEffect(()=>{
		if(isMobile) return;

    	const vHeightitem = window.innerHeight - uHeightheader 
    		- uHeightcardtanggal - 120;

		document.getElementById("idcarditem") && (
			document.getElementById("idcarditem").style.minHeight = vHeightitem+"px"
		);
	},[window.innerHeight,uHeightcardtanggal,uHeightheader])
	React.useEffect(()=>{
		if(!uFrameExport) return;

		const pri 		= uFrameExport.contentWindow; if(!pri) return;
		const vURLlogo	= uSystemsettingObj.url_image_nota;
		pri.document.open();
		pri.document.write(`<img src="`+vURLlogo+`"/>`);
		pri.document.close();
	},[uFrameExport])
	React.useEffect(()=>{
		if(uTabelID <= 0) return;
		setDatainputObj(uDataloadObj);
	},[uDataloadObj])
	React.useEffect(()=>{
		if(uDatainputObj.tgl_bayar === undefined)
			uDatainputObj.tgl_bayar = UFunc.DbDate();
	},[uDatainputObj,
	uDatainputObj.tgl_bayar
	])
	React.useEffect(()=>{
		if(uDatainputObj.tgl_bayar !== (uDataloadObj.tgl_bayar||UFunc.DbDate())
			|| parseFloat(uDatainputObj.nilai_bayar||0) !== parseFloat(uDataloadObj.nilai_bayar||0)
			) setFormupdated(true);
		else setFormupdated(false);
	},[uDatainputObj,
	uDatainputObj.tgl_bayar,
	uDatainputObj.nilai_bayar,
	])
	React.useEffect(()=>{
		if(UFunc.isEmpty(uDatanotaObj)) return;

		prosesCetaknota();
	},[uDatanotaObj])
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }

	//console.log("(Piutangform) uNavigate => "+JSON.stringify(uNavigate))
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop"  id="idheadermain">
		<CContainer fluid className="m-0 p-2 border-bottom">
			<CHeaderToggler className="ms-1 px-2"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="d-md-flex me-auto text-normal align-items-center">
				<span>Form Kasir Piutang</span>
				<span className="mx-1">&middot;</span>
				<CImage src={pjson.homepage+"api/images/icon_step_2.png"} 
					className="d-none d-md-inline-block" 
					height={32}/>
			</CHeaderNav>

			<CHeaderNav className="me-1 align-items-center">
				<MyProfiledetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 px-3 px-lg-4 justify-content-center justify-content-md-between align-items-center py-1">
			<div className="d-none d-md-block d-lg-flex align-items-center">
			{(uHtml500msg) ? ("") : (
			<>
				{(UFunc.isEmpty(uDatapiutangObj)) ? (
					<big className="classblinking fst-italic text-warning">Loading..</big>
				) : (
				<>
				<b className="text-info text-uppercase">{uDatapiutangObj.nama_pasien||"NAMA PASIEN"}</b>
				</>
				)}
			</>
			)}
			</div>

			<div className="d-md-none"/>
			<div className="text-end">
			<CLink className="classikon classikonreset classcursorpointer"
				 onClick={()=>hdlKlikReset()}/>
			<span className="mx-1 me-2">&middot;</span>
			(<b className="text-primary">{UFunc.formatAngka((uDatapiutangObj.items_arr||[]).length)}</b>)
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-5">
		<CContainer xxl className="px-0">
			{(uHtml500msg) ? (
				<MyPage500 content={uHtml500msg}/>
			) : (
			<CForm>
			<CRow className="mx-0">
			<CCol md="6" className="pe-md-2">
				{contentTanggal()}
				{contentItem()}
			</CCol>

			<CCol className="ps-md-2">{contentBayar()}</CCol>
			</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="fixed" 
			className="px-3 text-black-50 justify-content-center"  
			style={{backgroundColor:"#f0f0f0"}}>
		<MyButton
			disabled={uHtml500msg}
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaObj.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	) 
}	

export default Piutangform