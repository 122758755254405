import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormSelect,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialogform,
	MyDialoglookup,
	MyPage500,
	MyProfiledetil,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'
import NumberFormat from 'react-number-format';

const pjson 	= require('../../../package.json')
const Pakaiklinikform = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const { tabel_id } 			= useParams(); 
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTabelID 					= parseInt(tabel_id)||0;
	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const [uFirstLoad,setFirstLoad]			= React.useState("YA");
	const [uDataloadObj,setDataloadObj]		= React.useState({})
	const [uDatainputObj,setDatainputObj]	= React.useState({})
	const [uIteminputObj,setIteminputObj]	= React.useState({})
	const [uTabeleditObj,setTabeleditObj]	= React.useState({})
	const [uISFormupdated,setFormupdated]	= React.useState(false)
	const [uJmlitem,setJmlitem]				= React.useState(0);
	const [uDatabarangArr,setDatabarangArr]	= React.useState([])

	//--DLG_FORM--/
	const [uDlgformObj,setDlgformObj]		= React.useState({})
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false)
	const [uDlglookupObj,setDlglookupObj]	= React.useState({})
	//--END DLG_LOOKUP--/

	const uCardketheight	= document.getElementById("idcardket") && 
		document.getElementById("idcardket").offsetHeight;

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if(uISFormupdated) {
			if(await(Konfirm(
				"Field Input/Edit Sudah Ada yang Berubah.."+
				"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
				"<br/>"+
				"<br/><b>Apakah Anda Yakin</b> ?"+
				""))) uNavigate(-1);

			return;
		}
		uNavigate(-1);
	}
	const hdlKDownDocument = (_EV) => {
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			document.getElementById("btnSimpanmain") &&
				document.getElementById("btnSimpanmain").click();

			return;
		}
	}
	const hdlKlikReset = async() => {
		if(uHtml500msg) { apiLoaddata(); return }
		if(!uISFormupdated) { apiLoaddata(); return }

		const vFunc	= () => {
			resetForm();
		}

		if(await(Konfirm(
			"Field Input/Edit Sudah Ada yang Berubah.."+
			"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
			"<br/>"+
			"<br/><b>Apakah Anda Yakin</b> ?"+
			""))) vFunc();
	}
	const hdlKDownItemnama = (_EV) => {
		if(_EV.keyCode !== 13) { return; }
		if((uIteminputObj.caption_item||"").trim() === "") return;

		_EV.stopPropagation()
		_EV.preventDefault()

		const vfilterArr = uDatabarangArr.filter(vItems => 
			(vItems.caption_barang||"").toUpperCase().includes((uIteminputObj.caption_item||"").trim().toUpperCase()) ||
				(vItems.nama_jenis||"").toUpperCase().includes((uIteminputObj.caption_item||"").trim().toUpperCase())
		);

		if(vfilterArr.length === 1) {
			const vTmpObj	= {
				id:vfilterArr[0].id,
				caption_item: vfilterArr[0].caption_barang,
				qty: 1,
			}
			setIteminputObj(vTmpObj);

			document.getElementById("inpitemqty") &&
				document.getElementById("inpitemqty").focus()
			return
		}

		hdlKlikItemlookup()
	}
	const hdlKlikItemlookup = () => {
		uDlglookupObj.header 		= "Lookup Data Barang";
		uDlglookupObj.inputvalue 	= uIteminputObj.caption_item||"";
		uDlglookupObj.data_arr		= uDatabarangArr.map(vItems=>{
			return ({
				id: vItems.id,
				caption: vItems.caption_barang
			})
		});

		setDlglookupshow(true);
	}
	const hdlKlikItemtambah = () => {
		if(UFunc.isEmpty(uIteminputObj)) return;

		const vArridxmatch = UFunc.getObjectIndeks((uDatainputObj.items_arr||[]),uIteminputObj.id,"id_barang");
		if(vArridxmatch >= 0) {
			showToast("<b>Barang Tersebut Sudah Ditambahkan</b>.."+
				"<br/>Mohon Ubah QTY jika ADA perubahan !"+
				"");
			return;
		}

		const vTmpObj = {
			id: 0,
			id_barang: uIteminputObj.id,
			caption_barang: uIteminputObj.caption_item,
			qty: parseInt(uIteminputObj.qty),
		}

		uDatainputObj.items_arr = [vTmpObj,...(uDatainputObj.items_arr||[])];
		setIteminputObj({});
		setEfekview(!uEfekview);

		const vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inpitemnama");
			vElfocus && vElfocus.focus();
		},250);
	}
	const hdlKlikItemtambahmobile = () => {
		uDlgformObj.header 	= "Tambah Item Barang";
		setDlgformshow(true);
	}
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		//console.log("(hdlKlikDlglookuppilih) _IDITEM => "+_IDITEM);
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatabarangArr,_IDITEM);
		const vTmpObj	= {
			id:_IDITEM,
			caption_item: uDatabarangArr[vArridx].caption_barang,
			qty: 1,
		}
		setIteminputObj(vTmpObj);
		hdlToggleDlglookup();

		const vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inpitemqty");
			vElfocus && vElfocus.focus();
		},250);
	}
	const hdlKlikTabeltoggle = (_IDXKEY,_FIELDNAME) => {
		_IDXKEY = parseInt(_IDXKEY||0); if(_IDXKEY < 0) return;

		let vValue;
		if(_FIELDNAME === "KEPERLUAN") {
			vValue = (uDatainputObj.items_arr||[])[_IDXKEY].keperluan || "";
		} else {
			vValue = parseInt((uDatainputObj.items_arr||[])[_IDXKEY].qty) || 0;
		}
		uTabeleditObj.idxkey 	= _IDXKEY;
		uTabeleditObj.field 	= _FIELDNAME;
		uTabeleditObj.value 	= vValue;
		setEfekview(!uEfekview);

		const vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inptabelvalue");
			vElfocus && vElfocus.focus();
		},250);
	}
	const hdlKDownTabelinput = (_EV) => {
		if(_EV.which === 27) {
			_EV.preventDefault();
			_EV.stopPropagation();

			setTabeleditObj({});
			setEfekview(!uEfekview); return;
		}
		if(_EV.which === 13) {
			_EV.preventDefault();
			_EV.stopPropagation();

			hdlKlikTabelsimpan(); return;
		}
	}
	const hdlKlikTabelsimpan = () => {
		if(UFunc.isEmpty(uTabeleditObj)) return;

		const vElfocus = document.getElementById("inptabelvalue");
		const vArridx 	= parseInt(uTabeleditObj.idxkey);
		const vField	= uTabeleditObj.field || "";

		let vValue = uTabeleditObj.value;
		if(vField === "KEPERLUAN") {
			vValue = (vValue||"").trim();
			(uDatainputObj.items_arr||[])[vArridx].keperluan = vValue;
		} else {
			vValue = parseInt(vValue || 0);
			if(vValue <= 0) {
				vElfocus && vElfocus.focus();
				showToast("Nilai QTY yang DIINPUTKAN Tidak Valid !"); return
			}
			(uDatainputObj.items_arr||[])[vArridx].qty = vValue;
		}
		setTabeleditObj({});
		setEfekview(!uEfekview);
	}
	const hdlKlikTabelhapus = async(_IDXKEY) => {
		const vArridx 	= parseInt(uTabeleditObj.idxkey);
		if(await(Konfirm("Apakah Anda Yakin ?"+
			""))) {
			(uDatainputObj.items_arr||[]).splice(vArridx,1);
			setEfekview(!uEfekview);
		}
	}
	const hdlKlikTabeleditmobile = (_IDXKEY) => {
		const vArridx 			= parseInt(_IDXKEY);
		uTabeleditObj.idxkey 	= vArridx;
		uIteminputObj.id_barang			= (uDatainputObj.items_arr||[])[vArridx].id_barang;
		uIteminputObj.caption_barang	= (uDatainputObj.items_arr||[])[vArridx].caption_barang;
		uIteminputObj.qty				= (uDatainputObj.items_arr||[])[vArridx].qty;
		uIteminputObj.keperluan			= (uDatainputObj.items_arr||[])[vArridx].keperluan;

		uDlgformObj.header = "Edit Item Barang";
		setDlgformshow(true);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
			let vElfocus = document.getElementById("inpformkeperluan")
			vElfocus && vElfocus.focus()
		},250)
	}
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgformsimpan = () => {
		const vArridx	= uTabeleditObj.idxkey!==undefined 
			? parseInt(uTabeleditObj.idxkey) : -1;
		if(!tambahItemmobile(vArridx)) return;

		if(vArridx < 0) {
			showToast("Berhasil !<br/>Tambah / Update Item..","SUKSES");

			setTabeleditObj({});
			document.getElementById("inpformbarangid") &&
				document.getElementById("inpformbarangid").focus();
		}
		else hdlToggleDlgform()
	}
	const hdlKlikSimpanmain = async() => {
		let vElfocus = document.getElementById("inptglpakai");
		if((uDatainputObj.tgl_pakai||"")==="") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Pakai Tidak Valid !"); return;
		}
		const vTglpakaiDT 	= UFunc.toDate(uDatainputObj.tgl_pakai);
		if(UFunc.DateIsBeyond(vTglpakaiDT)){
			vElfocus && vElfocus.focus();
			showToast("Tanggal Pakai Melewati Hari Ini !"); return;
		}

		vElfocus = document.getElementById("inpitemnama");
		if((uDatainputObj.items_arr||[]).length <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Item Barang Belum Ada !"); return;
		}

		if(await(Konfirm(
			"<b>Input Pakai Klinik</b>"+
			"<div class='classborderbottom my-3'></div>"+

			"<div class='d-flex justify-content-between mx-4'>"+
			"<div class='w-50'>Tanggal Pakai</div>"+
			"<div class='w-50 text-end fw-bolder'>"+UFunc.TglAngka(uDatainputObj.tgl_pakai)+"</div>"+
			"</div>"+

			"<div class='d-flex justify-content-between mx-4'>"+
			"<div class='w-50'>Jml Item</div>"+
			"<div class='w-50 text-end fw-bolder'>"+UFunc.formatAngka((uDatainputObj.items_arr||[]).length)+" ITEMS</div>"+
			"</div>"+

			"<div class='classborderbottom my-3'></div>"+
			"<center>Apakah Anda Yakin ?</center>"+
			""))) apiProsesmain();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const resetForm = () => {
		if(uTabelID > 0) setDatainputObj(uDataloadObj);
		else setDatainputObj({});
		setIteminputObj({});
		setTabeleditObj({});
		setEfekview(!uEfekview)
	}
	const tambahItemmobile = (_IDXDATA) => {
		const vArridx	= _IDXDATA!==undefined ? parseInt(_IDXDATA) : -1;
		if(UFunc.isEmpty(uIteminputObj)) return;

		let vElfocus = document.getElementById("inpformqty");
		if(!vElfocus) vElfocus = document.getElementById("inpitemqty");
		if((parseFloat(uIteminputObj.qty)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Qty Lookup Barang Tidak Valid !"); return;
		}
		vElfocus = document.getElementById("inpformbarangid");
		if(!vElfocus) vElfocus = document.getElementById("inpitemnama");
		if((parseInt(uIteminputObj.id_barang)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("NAMA Lookup Barang Tidak Valid !"); return;
		}

		//--CEK_DATA_SUDAH_ADA_DI_TABEL--/
		const vArritem		= UFunc.getObjectIndeks((uDatainputObj.items_arr||[]),uIteminputObj.id_barang,"id_barang");
		if(vArritem >= 0 && vArritem !== vArridx) {
			vElfocus && vElfocus.focus();
			showToast("<b>Barang Tersebut Sudah Ada</b>.."+
				"<br/>Silahkan Update QTY jika Ingin Merubah Datanya !"+
				"<br/>"+vArritem+" === "+vArridx+
				""); return;
		}
		//--END CEK_DATA_SUDAH_ADA_DI_TABEL--/

		const vArrbarang	= UFunc.getObjectIndeks(uDatabarangArr,uIteminputObj.id_barang);
		const vTmpObj		= {
			id_barang: uIteminputObj.id_barang,
			caption_barang: uDatabarangArr[vArrbarang].caption_barang,
			qty: uIteminputObj.qty,
			keperluan: uIteminputObj.keperluan,
		}
		if(vArridx < 0) 
			uDatainputObj.items_arr = [vTmpObj,...(uDatainputObj.items_arr||[])];
		else {
			(uDatainputObj.items_arr||[])[vArridx]	= vTmpObj;
			(uDatainputObj.items_arr||[]).status_editdone = !((uDatainputObj.items_arr||[]).status_editdone||false);
		}
		setIteminputObj({}); setEfekview(!uEfekview);

		return true
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentTanggal = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg" id="idcardtanggal">
		<CCardHeader className="py-1 text-muted classfontsmaller">
			<small>Tanggal</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="mx-0 my-1 my-md-0">
			<CCol className="text-start">Tanggal Pakai</CCol>

			<CCol className="text-end">
			<CFormInput size="sm" type="date"
				value={uDatainputObj.tgl_pakai||""}
				onChange={(e)=>{uDatainputObj.tgl_pakai=e.target.value;setEfekview(!uEfekview)}}
				id="inptglpakai"/>
			</CCol>
			</CRow>

			<CRow className="mx-0 my-2 d-md-none">
			<CCol className="text-start classfontsmaller">Keterangan</CCol>

			<CCol xs="12" className="text-end">
			<CFormTextarea size="sm"
				rows={2}
				placeholder="--Ganti Baris Tekan [CTRL] + [ENTER]"
				value={UFunc.br2nl(uDatainputObj.keterangan||"")}
				onChange={(e)=>{uDatainputObj.keterangan=UFunc.nl2br(e.target.value);setEfekview(!uEfekview)}}
				id="inpketerangan"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentKet = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg" id="idcardket">
		<CCardHeader className="py-1 text-muted classfontsmaller">
			<small>Keterangan</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="mx-0">
			<CCol className="text-start">Keterangan</CCol>

			<CCol md="8" className="text-end">
			<CFormTextarea size="sm"
				rows={2}
				placeholder="--Ganti Baris Tekan [CTRL] + [ENTER]"
				value={UFunc.br2nl(uDatainputObj.keterangan||"")}
				onChange={(e)=>{uDatainputObj.keterangan=UFunc.nl2br(e.target.value);setEfekview(!uEfekview)}}
				id="inpketerangan"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentItemform = () => {
		if(uHtml500msg) return (<></>)

		return (
		<>
		<div className="d-none d-md-flex">
		<small className="text-muted">Lookup Barang</small>
		<span className="mx-1">&middot;</span>
		<div style={{width:"30%",minWidth:275}}>
		<CInputGroup size="sm">
		<CFormInput size="sm"
			placeholder="--Lookup Keyword Barang.."
			value={uIteminputObj.caption_item||""}
			onChange={(e)=>{uIteminputObj.caption_item=e.target.value;setEfekview(!uEfekview)}}
			onKeyDown={(e)=>hdlKDownItemnama(e)}
			id="inpitemnama"/>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemlookup()}
			id="btnitemlookup">
			<CIcon icon="cilMagnifyingGlass"/>
		</CButton>
		</CInputGroup>
		<div className="classpetunjuk">Nama Barang</div>
		</div>

		<span className="mx-1">&middot;</span>

		<div style={{width:"5%",minWidth:50}}>
		<CFormInput size="sm" type="number"
			value={uIteminputObj.qty||0}
			min={0}
			onChange={(e)=>{uIteminputObj.qty=e.target.value;setEfekview(!uEfekview)}}
			onFocus={(e)=>e.target.select()}
			className="text-end"
			id="inpitemqty"/>
		<div className="classpetunjuk">QTY</div>
		</div>

		<span className="mx-1">&middot;</span>
		<div>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemtambah()}
			id="btnitemtambah">
			<CIcon icon="cibAddthis"/>
		</CButton>
		</div>
		</div>

		<div className="d-flex justify-content-between my-1 d-md-none">
		<div>Item Barang Masuk</div>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemtambahmobile()}
			id="btnitemtambah">
			<CIcon icon="cibAddthis" className="me-2"/>Tambah Item
		</CButton>
		</div>
		</>
		)
	}
	const contentItem = () => {
		if(uHtml500msg) return (<></>)

		const vItemArr	= uDatainputObj.items_arr || [];

		return (
		<table className="table table-borderless table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="align-top px-0 classfontsmaller">No</th>
			<th className="align-top px-0">
			<small className="classfontsmaller">
			<CRow className="mx-0">
			<CCol md="5" className="text-start">{uBahasaObj.caption_namabarang||"Nama Barang"}</CCol>
			<CCol md="4" className="text-start">{uBahasaObj.word_keperluan||"Keperluan"}</CCol>
			<CCol md="2" className="">{uBahasaObj.word_qty||"Qty"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			</CRow>
			</small>
			</th>
			</tr>
		</thead>

		<tbody>
		{vItemArr.map((vItems,vKeys)=>{
			const {
				id,id_barang,caption_barang,qty,keperluan,
			} = vItems;

			const vArridx 	= UFunc.getObjectIndeks(uDatabarangArr,id_barang);
			const vStokinit	= parseFloat(uDatabarangArr[vArridx].stok_init||0); 
			const vStokmin	= parseFloat(uDatabarangArr[vArridx].stok_minimal||0);

			return (
			<tr id={"idtr"+vKeys} key={vKeys} className={"align-top text-end classborderbottom"+(uTabeleditObj.row_idx===vKeys?" classrowselect":"")}>
			<td className="px-0 pe-1">{(vKeys+1)}.</td>

			<td className="px-1">
			<CRow className="mx-0">
				<CCol md="5" className="px-1 text-start d-flex justify-content-between">
					<div>
					<span className="text-info">{caption_barang||"caption_barang"}</span>
					{vStokinit <= vStokmin && (
					<>
					<span className="ms-1"/>
					<small className="text-danger classblinking fst-italic">
						&#9913;Stok: <b>{UFunc.formatAngka(vStokinit)}</b> PCS&#9913;
					</small>
					</>
					)}
					</div>

					<CDropdown className="d-md-none">
					<CDropdownToggle caret={false} size="sm"
						className="p-0" 
						color="transparent">
						<CIcon icon="cilOptions" className="classikonbox" height={20}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikTabeleditmobile(vKeys)}>
							<CIcon className="classikonedit me-2 align-middle" height={25}/>
							{uBahasaObj.caption_editItem||"Edit Item"}
						</CDropdownItem>
								
						<CDropdownItem onClick={()=>hdlKlikTabelhapus(vKeys)}>
							<CIcon className="align-middle classikonhapus me-2" height={25}/>
							{uBahasaObj.caption_hapusitem||"Hapus Item"}
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
				</CCol>
				
				<CCol xs="12" className="px-1 d-md-none classborderbottom my-2"/>

				<CCol xs="4" className="px-1 text-start d-md-none">Keperluan</CCol>
				<CCol xs="8" md="4" className="px-1 text-md-start">
					{uTabeleditObj.idxkey===vKeys && uTabeleditObj.field==="KEPERLUAN" ? (
					<CInputGroup size="sm">
						<CFormInput size="sm"
							maxLength={255}
							value={uTabeleditObj.value}
							onChange={(e)=>{uTabeleditObj.value=e.target.value;setEfekview(!uEfekview)}}
							onKeyDown={(e)=>hdlKDownTabelinput(e)}
							id="inptabelvalue"/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikTabelsimpan()}
							id="btntabeledit">
							<CIcon icon="cilCheckAlt"/>
						</CButton>
					</CInputGroup>
					) : (
					<>
					<CTooltip content="--Klik Untuk EDIT..">
					<CLink onClick={()=>hdlKlikTabeltoggle(vKeys,"KEPERLUAN")} 
						className="classcursorpointer d-none d-md-block">
						{(keperluan||"") === "" ? (
						<CIcon className="classikonedit" height={25}/>
						) : (
						<small className="fst-italic text-capitalize">{keperluan||"-"}</small>
						)}
					</CLink>
					</CTooltip>

					<small className="fst-italic text-capitalize d-md-none">{keperluan||"-"}</small>
					</>
					)}
				</CCol>

				<CCol xs="6" className="px-1 text-start d-md-none">QTY</CCol>
				<CCol xs="6" md="2" className="px-1 px-md-0 text-md-center">
					{uTabeleditObj.idxkey===vKeys && uTabeleditObj.field==="QTY" ? (
					<div className="mx-lg-5">
					<CInputGroup size="sm">
						<CFormInput size="sm" type="number"
							className="text-end"
							min={1}
							value={uTabeleditObj.value}
							onChange={(e)=>{uTabeleditObj.value=e.target.value;setEfekview(!uEfekview)}}
							onKeyDown={(e)=>hdlKDownTabelinput(e)}
							onFocus={(e)=>e.target.select()}
							id="inptabelvalue"/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikTabelsimpan()}
							id="btntabeledit">
							<CIcon icon="cilCheckAlt"/>
						</CButton>
					</CInputGroup>
					</div>
					) : (
					<>
					<CTooltip content="--Klik Untuk EDIT..">
					<CLink onClick={()=>hdlKlikTabeltoggle(vKeys,"QTY")} 
						className="classcursorpointer fw-bolder d-none d-md-block">
					{UFunc.formatAngka(qty)} PCS
					</CLink>
					</CTooltip>

					<span className="fw-bolder d-md-none">{UFunc.formatAngka(qty)} PCS</span>
					</>
					)}
				</CCol>

				<CCol md="1" className="px-1 text-md-center d-none d-md-block">
					<CTooltip content={"--Hapus Data: "+(caption_barang||"").toUpperCase()}>
					<CLink onClick={()=>hdlKlikTabelhapus(vKeys)}
						className="classikontabel classikonhapus classcursorpointer"/>
					</CTooltip>
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)

		const vArredit	= uTabeleditObj.idxkey!==undefined ? parseInt(uTabeleditObj.idxkey) : -1;
		return (
		<CForm>
		<CRow className="my-2">
		<CCol className="text-md-end">{uBahasaObj.caption_namabarang||"Nama Barang"}</CCol>
		<CCol xs="12" className="">
			{(vArredit < 0)  ? (
			<CFormSelect size="sm"
				className=""
				value={uIteminputObj.id_barang||"0"}
				onChange={(e)=>{uIteminputObj.id_barang = (e.target.value); setEfekview(!uEfekview);}}
				id="inpformbarangid">
			<option value="0">--PILIH ITEM BARANG--</option>
			{uDatabarangArr.map((vItems,vKeys)=>{
				return (
				<option value={vItems.id} key={vKeys}>{vItems.caption_barang}</option>
				)
			})}
			</CFormSelect>
			) : (
			<big className="text-info">{uIteminputObj.caption_barang||"UNDF"}</big>
			)}
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol className="">{uBahasaObj.word_keperluan||"Keperluan"}</CCol>
		<CCol xs="12" className="">
			<CFormTextarea size="sm" rows={2}
				value={uIteminputObj.keperluan||""}
				onChange={(e)=>{uIteminputObj.keperluan = (e.target.value); setEfekview(!uEfekview);}}
				id="inpformkeperluan"/>
		</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="7" className="text-md-end">{uBahasaObj.word_qty||"QTY"}</CCol>
		<CCol xs="5" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="number"
				autoComplete="off"
				min={0}
				className="text-end"
				value={uIteminputObj.qty||"0"}
				onFocus={(e)=>e.target.select()}
				onChange={(e)=>{uIteminputObj.qty = (e.target.value); setEfekview(!uEfekview);}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpformqty"/>
			<CInputGroupText>PCS</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		const vElbutton = document.getElementById("btnSimpanmain");
		vElbutton && (vElbutton.disabled = true);

		setDataloadObj({});
		setIteminputObj({});
		setTabeleditObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_firstload : uFirstLoad,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pakaiklinik/ld_form";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			vElbutton && (vElbutton.disabled = false);
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Barang - apiLoaddata) output_string.datajenisbarang : "+output_string.datajenisbarang);
				setDatabarangArr(JSON.parse(output_string.databarang || "[]"));
				if(uTabelID > 0) {
					setDatainputObj(JSON.parse(output_string.dataobject || "{}"))
				}
				if(uFirstLoad==="YA") { setFirstLoad("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pakaiklinikform - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pakaiklinikform - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesmain = () => {
		const vElbutton = document.getElementById("btnSimpanmain");
		vElbutton && (vElbutton.disabled = true);

		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoaditem) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_datajson: JSON.stringify(uDatainputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pakaiklinik/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			vElbutton && (vElbutton.disabled = false);
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDispatch({type: "set", gInitTabelSelected: {}}); 
				showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
				uNavigate(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pakaiklinik - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Pakaiklinik - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !!uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		apiLoaddata();

		if(!isMobile) {
			const vElfocus = document.getElementById("inptglpakai");
			vElfocus && vElfocus.focus();
		}
		return () => {
			setDatainputObj({}); setDataloadObj({});
			setDatabarangArr([]);
			hdlToggleDlgform(); hdlToggleDlglookup();
    	}
	},[])
	React.useEffect(()=>{
		document.getElementById("idcardtanggal") && 
			(document.getElementById("idcardtanggal").style.minHeight = uCardketheight+"px")
	},[uCardketheight])
	React.useEffect(()=>{
		if(!uIsDlgformshow) { setIteminputObj({}); setTabeleditObj({}); return }
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow) {
			setDlglookupObj({}); return;
		}
	},[uISDlglookupshow])
	React.useEffect(()=>{
		if(uTabelID <= 0) return;

		setDatainputObj(uDataloadObj);
	},[uDataloadObj])
	React.useEffect(()=>{
		if(uDatainputObj.tgl_pakai === undefined)
			uDatainputObj.tgl_pakai = UFunc.DbDate();
	},[uDatainputObj,
	uDatainputObj.tgl_pakai
	])
	React.useEffect(()=>{
		const vItemArr 		= uDatainputObj.items_arr || [];
		const vItemloadArr 	= uDataloadObj.items_arr || [];
		if(uDatainputObj.tgl_pakai !== (uDataloadObj.tgl_pakai||UFunc.DbDate())
			|| (uDatainputObj.keterangan||"") !== (uDataloadObj.keterangan||"")
			|| vItemArr.length !== vItemloadArr.length
			) setFormupdated(true);
		else setFormupdated(false);
	},[uDatainputObj,
	uDatainputObj.tgl_pakai,
	uDatainputObj.keterangan,
	uDatainputObj.items_arr
	])
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }

	//console.log("(Pakaiklinikform) uNavigate => "+JSON.stringify(uNavigate))
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent pb-4 py-md-0">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop">
		<CContainer fluid className="m-0 p-2 border-bottom">
			<CHeaderToggler className="ms-1 px-2"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="d-md-flex me-auto text-normal">
				<span>Form Pakai Klinik</span>
			</CHeaderNav>

			<CHeaderNav className="me-1 align-items-center">
				<MyProfiledetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 px-3 px-lg-4 py-1 align-items-center">
			<div className="d-none d-md-block"/>
			<span className="mx-auto mx-md-0">
			<CTooltip content="--Reset FORM">
			<CLink className="classikon classikonreset classcursorpointer"
				 onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			
			<span className="mx-1 me-2">&middot;</span>
			
			<span className="align-middle">
				(<b className="text-primary">{UFunc.formatAngka((uDatainputObj.items_arr||[]).length)}</b>)
			</span>
			</span>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className={"px-0"}>
			{(uHtml500msg) ? (
				<MyPage500 content={uHtml500msg}/>
			) : (
			<CForm>
			<CRow className="mx-0 mb-3">
			<CCol className="px-md-0 pe-md-2">{contentTanggal()}</CCol>
			<CCol className="px-md-0 ps-md-2 d-none d-md-block">{contentKet()}</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol className="px-md-0">
			<CCard className="classcardbg">
			<CCardHeader className="py-1 classfontsmaller">{contentItemform()}</CCardHeader>
			<CCardBody>{contentItem()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="fixed" 
			className="px-3 text-black-50 justify-content-center"  
			style={{backgroundColor:"#f0f0f0"}}>
		<MyButton
			disabled={uHtml500msg}
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaObj.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>
		<MyDialoglookup
			show={uISDlglookupshow} 
			dialogHeader={uDlglookupObj.header||""}
			toggle={hdlToggleDlglookup}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr||[]}
			onKlikPilihItem={hdlKlikDlglookuppilih}/>
		</>
	) 
}	

export default Pakaiklinikform