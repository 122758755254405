import React from 'react'
//import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,CButton,CFormInput,CInputGroup,CFormSelect,CInputGroupText,
	CLink,
	CTooltip,
	CDropdown, CDropdownItem, CDropdownToggle, CDropdownMenu,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubstokurgent = (props) => {
	//const { setToken,setLoading, showToast } = props 

	//--DOM--/
	//const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword") || "{}");
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	const uEfekapp				= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]= React.useState(false);
	
	const uTokenCabangidArr		= (uTokenObj.usercabangid||"").split(",");
	const uDatacabangArr		= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const uCabangviewArr		= uTokenObj.userhak==="SUPERADMIN" ? uDatacabangArr
		: uDatacabangArr.filter(
			vItems => uTokenCabangidArr.includes(vItems.id.toString()));
	const uInitCabangid			= localStorage.getItem("cabangidselect") || uCabangviewArr[0].id;

	const [uFormvalueObj,setFormvalueObj]= React.useState(uKeywordObj.kwd_stokurgent||{});

	const hdlKeydown = (_EV) => {
		if(_EV.which===13) {
			_EV.stopPropagation();
			_EV.preventDefault();
			document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_stokurgent = uFormvalueObj; 
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_stokurgent = undefined; 
			setFormvalueObj({});
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uFormvalueObj.keyword||"").trim() !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	React.useEffect(()=>{
		return () => {
			setFormvalueObj({});
			if((uFormvalueObj.keyword||"").trim() === "") {
				uKeywordObj.kwd_stokurgent = undefined; 
				localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
			}
		}
	},[])

	//console.log("Headersubstokurgent - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-1"
			height={30}/>
		<small>{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}</small>
		</div>

		<div className="d-flex align-items-center mx-auto mx-lg-0">
			<CForm className="">
			<CInputGroup size="sm">
			{(uTokenObj.userhak==="SUPERADMIN" || uTokenObj.userhak==="PENGELOLA") && (
			<>
			<CFormSelect size="sm"
				className="d-none d-md-block"
				value={uFormvalueObj.id_cabang || uInitCabangid}
				onChange={(e)=>{uFormvalueObj.id_cabang = e.target.value;setEfekview(!uEfekview)}}
				id="inpcabangid">
				{uCabangviewArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} key={vKeys}>{vItems.nama_cabang}</option>
					)
				})}
			</CFormSelect>

			<CInputGroupText 
				className="bg-transparent border-0 d-md-none px-0">
				<CDropdown className="">
				<CDropdownToggle caret={false} size="sm" className="p-0"
					color="transparent">
					<span className="classikonbox p-1 fw-bolder">
					{(()=>{
						const vArridx = UFunc.getObjectIndeks(uDatacabangArr,(uFormvalueObj.id_cabang||uInitCabangid));
						return (uDatacabangArr[vArridx].kode_cabang||"").substring(0,3);	
					})()}
					</span>
				</CDropdownToggle>
				<CDropdownMenu>
				{uCabangviewArr.map((vItems,vKeys)=>{
					return (
					<CDropdownItem 
						onClick={()=>{uFormvalueObj.id_cabang = vItems.id;setEfekview(!uEfekview)}}
						key={vKeys}>
						<CIcon icon="cilChevronDoubleRight" className="mx-0 me-1 text-primary align-middle" height={18}/>
						<span className={(vItems.id||"").toString() === (uFormvalueObj.id_cabang||uInitCabangid).toString()?"fw-bolder":""}>
							{vItems.nama_cabang}
						</span>
					</CDropdownItem>
					)
				})}
				</CDropdownMenu>
				</CDropdown>
			</CInputGroupText>
			</>
			)}

			<CFormInput size="sm" 
				className="align-middle"
				value={uFormvalueObj.keyword||""} 
				onChange={(e) =>{uFormvalueObj.keyword = e.target.value; setEfekview(!uEfekview); }} 
				onKeyDown={(e) => hdlKeydown(e)} 
				style={{minWidth:215,width:"auto"}} 
				placeholder={"--"+(uBahasaObj.petunjuk_keyword||"Masukkan uKeyword")+".."} 
				id="inpkeyword"/>
			<CButton type="button" color="secondary" onClick={()=>hdlKlikCari()} id="btnCari" className="py-0">
				<CIcon icon="cilMagnifyingGlass" className="text-white" />
			</CButton>
			</CInputGroup>
			</CForm>
			<span className="mx-1 d-none d-lg-block">&middot;</span>
			
			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
				
			<span className="me-2 d-none d-md-block">&middot;</span>
			<span className="d-none d-md-block">
			<b id="idjmldata" className="text-primary">0</b>{" "}{uBahasaObj.word_data||"Data"}
			</span>			
		</div>
		</>
	)
}

export default Headersubstokurgent
