import React from 'react'
import {
	CPagination,
	CPaginationItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types'
import { UFunc } from '../helpers/functions'

const MyPagination = (props) => {
	const { activePage,pages,onActivePageChange, ...rest} = props
	const [uKomponen,setKomponen]	= React.useState([]);
	const uMaxview	= 4;
	//console.log("MyPagination - pages = "+pages);

	React.useEffect(()=>{
	},[]);
	React.useEffect(()=>{
		const vJmlPage	= pages;	
		const vJmlview	= vJmlPage > uMaxview
			? (activePage < uMaxview ? uMaxview : (uMaxview-1)) : vJmlPage;
		const vFirstindex = activePage < uMaxview ? 1 : (activePage-1); 
		const vLastindex = vFirstindex + vJmlview - 1 > vJmlPage ? vJmlPage : (vFirstindex + vJmlview - 1);

		//console.log("(MyPagination - [pages]) vFirstindex = "+vFirstindex);
		//console.log("(MyPagination - [pages]) vLastindex = "+vLastindex);

		let vPageitem 	= [];
		for(var vIdx=vFirstindex;vIdx<=vLastindex;vIdx++) {
			vPageitem.push(vIdx);
		}
		setKomponen(vPageitem);
	},[pages, activePage]);

	return (
		<CPagination {...rest}>
			<CPaginationItem 
				disabled={(activePage||1)===1}
				className="classcursorpointer"
				onClick={()=>onActivePageChange(1)}>
				<CIcon 
					className={((activePage||1)===1?"text-light":"text-primary")}
					icon="cilChevronDoubleLeft"/>
			</CPaginationItem>
			<CPaginationItem
				disabled={(activePage||1)===1}
				className="classcursorpointer"
				onClick={()=>onActivePageChange((activePage||1)-1)}>
				<CIcon 
					className={((activePage||1)===1?"text-light":"text-primary")}
					icon="cilChevronLeft"/>
			</CPaginationItem>
			<CPaginationItem className={(activePage>uMaxview-1 && pages>uMaxview ? "" :"d-none")}>...</CPaginationItem>
			{uKomponen.map((vItems,vKeys)=>{
				return (
				<CPaginationItem key={vKeys}
				className="classcursorpointer"
					onClick={()=>onActivePageChange(vItems)}
					active={vItems===(activePage||1)}>{vItems}</CPaginationItem>
				)
			})}
			<CPaginationItem className={(activePage<pages-1 && pages>uMaxview ? "" :"d-none")}>...</CPaginationItem>
			<CPaginationItem 
				className="classcursorpointer"
				disabled={(activePage||1) >= (pages||1)}
				onClick={()=>onActivePageChange((activePage||1)+1)}>
				<CIcon 
					className={((activePage||1)>=(pages||1)?"text-light":"text-primary")}
					icon="cilChevronRight"/>
			</CPaginationItem>
			<CPaginationItem
				className="classcursorpointer"
				disabled={(activePage||1) >= (pages||1)}
				onClick={()=>onActivePageChange((pages||1))}>
				<CIcon 
					className={((activePage||1)>= (pages||1)?"text-light":"text-primary")}
					icon="cilChevronDoubleRight"/>
			</CPaginationItem>
		</CPagination>
	);
}

MyPagination.propTypes = {
	activePage: PropTypes.number,
	pages: PropTypes.number,
	onActivePageChange: PropTypes.func,
}

export default MyPagination;