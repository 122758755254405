import React from 'react';

const cHariPendek 	= ["Min","Sen","Sel","Rab","Kam","Jum","Sab"];
const cHariPanjang 	= ["Minggu","Senin","Selasa","Rabu","Kamis","Jumat","Sabtu"];
const cAngkaTgl		= [];
for(let i=1;i<=31;i++) {cAngkaTgl.push(i)}
const cBulanPendek = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"Mei",
	"Jun",
	"Jul",
	"Agu",
	"Sep",
	"Okt",
	"Nov",
	"Des"
];
const cBulanPanjang = [
	"Januari",
	"Februari",
	"Maret",
	"April",
	"Mei",
	"Juni",
	"Juli",
	"Agustus",
	"September",
	"Oktober",
	"November",
	"Desember"
];

const UFunc = {
handleErrors: function(response) {
	if (!response.ok) {
		throw Error(response.statusText);
	}
	return response;
},
formatAngka: function(_VARS){
	_VARS = parseFloat(_VARS) || 0;
	return _VARS.toLocaleString("ID", {maximumFractionDigits:2}); // "1,234.57"
},
formatAngkaexcel: function(_ANGKA){
	_ANGKA = parseFloat(_ANGKA) || 0;
	return _ANGKA.toLocaleString("ID", {maximumFractionDigits:2,useGrouping:false}); // "1,234.57"
},
isEmpty: function(val){
    return (val === undefined
        || typeof(val) === "undefined"
        || val === null
        || val === "NaN"
        //|| val === NaN
        //|| isNaN(val)===true
        || Object.keys(val).length === 0
        || val.length <= 0) ? true : false;
},
nl2br: function(str) {
	if(UFunc.isEmpty(str)) return "";
    return str.replace(/\n/g,"<BR>");
},
br2nl: function(str) {
	if(UFunc.isEmpty(str)) return "";
	
    var HASIL    = str;
    HASIL        = HASIL.replace(/<br\s*\/?>/mg,"\n");
    HASIL        = HASIL.replace(/<BR\s*\/?>/mg,"\n");

    return HASIL;
},
removeTAGS: function(str) {
	if(UFunc.isEmpty(str)) return "";

	var div = document.createElement("div"); 
	div.innerHTML = str;

	return (div.textContent || div.innerText || "");
},
capitalize: function(str){

	return str.charAt(0).toUpperCase() + str.slice(1);
},
getObjectIndeks: function(_OBJECTDATA,_DATASRC,_NAMAOBJ){
	const vHasil = -1;
	_NAMAOBJ = (_NAMAOBJ || "id").trim();

	_DATASRC = (_DATASRC || "").toString().trim();
	if(_DATASRC === "") return vHasil;
	if((_OBJECTDATA||[]).length <= 0) return vHasil;

	return _OBJECTDATA.findIndex((vItems,vKey)=>{
		return (vItems[_NAMAOBJ]||"0").toString() === _DATASRC;
	});

	/*for(var i=0;i<_OBJECTDATA.length;i++) {
		let vDataTks	= (_OBJECTDATA[i][_NAMAOBJ]) || "";
		if(vDataTks.toString() === _DATASRC.toString()) {
			return i;
			//vHasil = i; break;
		}
	}//-*/
},
getTabelRowIndeks: function(_TABELID,_TABELTR) {
	const vElTabel	= document.getElementById(_TABELID) || undefined;
	if(UFunc.isEmpty(vElTabel)) return -1;
	const vElTabelBody	= vElTabel.getElementsByTagName("tbody")[0] || undefined;
	if(UFunc.isEmpty(vElTabelBody)) return -1;

	const vELTabelRow = vElTabelBody.getElementsByTagName("tr") || undefined;
	//console.log("(UFunc) getTabelRowIndeks (vELTabelRow): "+vELTabelRow);
	if(UFunc.isEmpty(vELTabelRow)) return -1;

	for (var i=0; i<vELTabelRow.length; i++) {
		if(vELTabelRow[i].getAttribute("id").toUpperCase() === _TABELTR.toUpperCase()) {
			return i;
		}
	}
},
getOptionSelectTeks:function(_ELEMENT) {
	if(UFunc.isEmpty(_ELEMENT)) return "";

	return (_ELEMENT.options[_ELEMENT.selectedIndex].text);
},
renderHTML: function(escapedHTML: string) { 

	return React.createElement("span", { dangerouslySetInnerHTML: { __html: escapedHTML } });
},
Usercaptionfilter:function(_NAME,_JMLWORD) {
	_NAME 	= (_NAME||"Undf").trim();
	_JMLWORD = parseInt(_JMLWORD) || 2;

	const vTmpArr 	= _NAME.split(" ");
	let vUsercaption= "";
	for (var i = 0; i < vTmpArr.length; i++) {
		vUsercaption += vTmpArr[i];
		if(i >= _JMLWORD-1) break;
		vUsercaption += " "; 
	}

	return vUsercaption;
},
Kalimat2kata:function(_KALIMAT,_IDX) {
	_KALIMAT= (_KALIMAT||"Undf").trim();
	_IDX 	= parseInt(_IDX||"0");

	const vTmpArr 	= _KALIMAT.split(" ");
	let vHasil 		= vTmpArr[_IDX] || "";
	if(vHasil === "") vHasil = vTmpArr[0];

	return vHasil;
},
leadZero: function(str, max) {
	str 	= str || "0";
    max 	= parseInt(max)||0;
    if(max<2)max=2;

    str = str.toString();
    var vDigits = max - (str.length);//-> 2 - 1 = 1
    for(var i=0;i <= vDigits - 1;i++)
	    str = "0" + str.toString();

    return str.toString();
},
DateIsValid: function(_STRDATETIME) {
	_STRDATETIME = (_STRDATETIME||"").trim();
	if(_STRDATETIME==="") return false;

	const vDateDT 	= UFunc.toDatetime(_STRDATETIME);
	if(vDateDT.toString() === 'Invalid Date')  return false;

	const dt  = _STRDATETIME.split(/\-|\s/);
	//return parseInt(dt[1])+" === "+(vDateDT.getMonth() + 1);
	if(parseInt(dt[0])!==vDateDT.getFullYear()
		|| parseInt(dt[1])!==vDateDT.getMonth() + 1
		|| parseInt(dt[2])!==vDateDT.getDate()
		) return false

	return true
},
toDate: function(_STRDATE) {
	if((_STRDATE||"")==="") return new Date();

    var DatesCHK = _STRDATE;//---2011-04-21
    if(_STRDATE.length > 10 ) {
    	let vSplittwo	= _STRDATE.split(" ");
    	DatesCHK		= vSplittwo[0];
    }
    const [year, month, day] = [...DatesCHK.split('-')];
    return new Date(year, (month-1), day);//-->INDEX_MONTH_0_11;
},
toDatetime: function(_STRDATETIME) {
	if((_STRDATETIME||"")==="") return new Date();

	var dt  = _STRDATETIME.split(/\-|\s/);
	return new Date(dt.slice(0,3).join('-') + ' ' + (dt[3] ? dt[3] : "00:00:00"));
},
DbDate: function(_DATE) {
	//_DATE = (UFunc.isEmpty(_DATE)) ? new Date() : _DATE;
	_DATE = _DATE || new Date();

	const vHasil = (_DATE.getFullYear() + "-" +UFunc.leadZero(_DATE.getMonth() + 1) + "-" + 
		UFunc.leadZero(_DATE.getDate()));
	return vHasil.toString();
},
SelisihHari: function(_TGLAWAL, _TGLAKHIR) {
    var vDateAwal   = UFunc.toDate(_TGLAWAL);
    var vDateAkhir  = UFunc.toDate(_TGLAKHIR);

    //return Math.round(Math.abs(vDateAkhir-vDateAwal)/(1000*60*60*24));
    return Math.round((vDateAkhir-vDateAwal)/(1000*60*60*24));
},
Datetimeselisih: function(_TGLAWAL,_TGLAKHIR){
	const vSelisih = Math.abs(_TGLAKHIR - _TGLAWAL);

	return { 
		day: Math.floor(vSelisih / (1000 * 60 * 60 * 24)),
		jam: Math.floor(vSelisih / (1000 * 60 * 60)),
		mnt: Math.floor(vSelisih / (1000 * 60)),
		dtk: Math.floor(vSelisih / 1000)
	};
},
DateIsBeyond: function(_TGLCEKDt) {
	var vTglToday 	= UFunc.DbDate(new Date());
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vTglToday);

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("_TGLCEKDt = "+_TGLCEKDt);

	if(vSelisih > 0) return true; else return false;
},
DateIsMundur: function(_TGLCEKDt){
	var vTglToday 	= UFunc.DbDate(new Date());
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vTglToday);

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	if(vSelisih < 0) return true; else return false;
},
DateIsExceededYear: function(_TGLCEKDt) {
	var vTglCek		= UFunc.DbDate(_TGLCEKDt);

	const vTglTodayDt= new Date();
	const vYearNow	= vTglTodayDt.getFullYear();

    var vDateAwal   = UFunc.toDate(vTglCek);
    var vDateAkhir  = UFunc.toDate(vYearNow+"-01-01");

	var vSelisih	= Math.round((vDateAwal-vDateAkhir)/(1000*60*60*24));

	//console.log("vSelisih = "+vSelisih);

	if(vSelisih < 0) return true; else return false;
},
TglAngka: function(_DATE) {
	var vTANGGAL = _DATE || new Date();
	//if(UFunc.isEmpty(vTANGGAL.getDay())) {
	if(vTANGGAL instanceof Date === false) {
		vTANGGAL = UFunc.toDate(_DATE);
	}
	//console.log("(TglAngka) _DATE = "+_DATE);
	return UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
HariAngka: function(_DATE) {
    var vTANGGAL = _DATE || new Date();
    //if(UFunc.isEmpty(vTANGGAL.getDay())) {
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDate(_DATE);
    }

    return cHariPendek[ vTANGGAL.getDay() ]+","+ UFunc.leadZero(vTANGGAL.getDate())+"/"+UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+vTANGGAL.getFullYear();
},
WaktuAngka: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+" "+
    	UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
WaktuPendek: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return cHariPendek[ vTANGGAL.getDay() ]+", "+
    	UFunc.leadZero(vTANGGAL.getDate())+"/"+
    	UFunc.leadZero(vTANGGAL.getMonth() + 1)+"/"+
    	vTANGGAL.getFullYear()+" "+
    	UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
JamMenit: function(_DATETIME) {
    var vTANGGAL = _DATETIME || new Date();
    if(vTANGGAL instanceof Date === false) {
    	vTANGGAL = UFunc.toDatetime(_DATETIME);
    }
    //return vTANGGAL;

    return UFunc.leadZero(vTANGGAL.getHours())+":"+
    	UFunc.leadZero(vTANGGAL.getMinutes())+
    "";
},
hdlKeyDownInput: function(_EV,_NEXTFIELD) {
	//console.log("(UFunc.hdlKeyDownInput) function = "+_EV.which);
	if(_EV.which===13 && (_EV.target.nodeName === "INPUT"
			|| _EV.target.nodeName === "SELECT"
			|| _EV.target.nodeName === "TEXTAREA")
	) {
		if (_EV.ctrlKey && _EV.target.nodeName === "TEXTAREA") {
			_EV.preventDefault();
			_EV.stopPropagation();
			var content = _EV.target.value;
			var start   = _EV.target.selectionStart;
			var substr1 = content.slice(0, start);
			var substr2 = content.slice(_EV.target.selectionEnd);
			//alert(_EV.target.selectionEnd);
			_EV.target.value  = substr1 + '\n' + substr2;
			return;
		}

		_EV.preventDefault();
		_EV.stopPropagation();

		const ElNext	= document.getElementById(_NEXTFIELD);
		ElNext && ElNext.focus();
	}
},
hdlKDowndecimal: function (_EV) {
	//alert(_EV.which);
	if(_EV.which === 190) {
		_EV.preventDefault(); return;
	}
},
getBase64Image: function(img) {
	var canvas 		= document.createElement("canvas");
	canvas.width 	= img.width;
	canvas.height 	= img.height;
	var ctx 		= canvas.getContext("2d");
	ctx.drawImage(img, 0, 0);
	var dataURL 	= canvas.toDataURL("image/png");
	return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
},
getBase64: function(file) {
	return new Promise((resolve,reject) => {
		let fileInfo;
		// Make new FileReader
		let reader = new FileReader();
		// Convert the file to base64 text
		reader.readAsDataURL(file);

		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object
			const baseURL = reader.result;
			resolve(baseURL);
		};
		reader.onError = (error)=>{
			console.log(error);
			reject(error);
		}
    });
},
validateBase64Image: function(base64Str) {
	//console.log("(Nota - validateBase64Image) base64Str => "+base64Str);
	return new Promise((resolve) => {
		const img 	= new Image();
		img.onload 	= () => resolve(true);
		img.onerror = () => resolve(false);
		img.src = base64Str;
	});//-*/
	/*let vIsvalid;
	const img 	= new Image();
	img.onload 	= () => { vIsvalid = true }
	img.onerror = () => { vIsvalid = false }
	img.src 	= base64Str;

	while (vIsvalid === undefined) {}
	return vIsvalid;//-*/
},
isBase64Image: async function(base64Str) {
	await UFunc.validateBase64Image(base64Str).
		then((isValidimg)=>{
			if(!isValidimg) {
				return false;
			}

			return true;
		});
},
filterRegex: function(_KEYWORD) {
	_KEYWORD		= _KEYWORD||"";
	var vInvalidchr = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;

	return _KEYWORD.replace(vInvalidchr,"");
},
Rendernota: function(_DATANOTAOBJ) {
	const vDatanotaObj 	= _DATANOTAOBJ || {};
	const vDataItemArr	= (vDatanotaObj.items_arr || []);
	//console.log("(Rendernota) vSettingObj => "+JSON.stringify(vSettingObj));

	const vNilainetto	= parseFloat(vDatanotaObj.nilai_bruto||0) - 
		parseFloat(vDatanotaObj.diskon||0) - 
		parseFloat(vDatanotaObj.terbayar||0);
	const vNilaikembali	= vNilainetto - parseFloat(vDatanotaObj.nilai_uang||0);
	const vCaptionkembali	= vNilaikembali > 0 ? "SISA PIUTANG" : "Kembalian";

	let vHTMLs		= "";
	vHTMLs += `
		<div style="display:flex;align-items:center">
		_DIVLOGOIMAGE_
		<div style="margin:0 10px">
			<div>_NAMAPERUSAHAAN_</div>
			<div>_ALAMATPERUSAHAAN_</div>
			_DIVROWTELP_
		</div>
		</div>
		<hr/>
		<table id="idtabelheader" align="left" class="classtabelhtml">
		<tbody>
		<tr valign="top">
			<td align="left" width="50%">No.Nota</td>
			<td align="right">`+(vDatanotaObj.no_nota)+`</td>
		</tr>
		<tr valign="top">
			<td align="left">Tgl Bayar</td>
			<td align="right">`+UFunc.TglAngka(vDatanotaObj.tgl_bayar)+`</td>
		</tr>
		<tr valign="top">
			<td align="left">Tgl Transaksi</td>
			<td align="right">`+UFunc.TglAngka(vDatanotaObj.tgl_transaksi)+`</td>
		</tr>
		<tr valign="top">
			<td align="left">Pasien</td>
			<td align="right"><b>`+vDatanotaObj.caption_pasien+`</b></td>
		</tr>
		<tr valign="top">
			<td align="left">Kasir</td>
			<td align="right">`+vDatanotaObj.inputcaption+`</td>
		</tr>
		<tr valign="top">
			<td align="left" colspan="2"><hr/></td>
		</tr>
		</tbody>
		</table>
		`;

	vHTMLs += `
		<table id="idtabelitem" align="left" class="classtabelhtml">
		<tbody>
		`;
	vDataItemArr.map((vItems,vKeys)=>{
		const {
			harga_satuan, qty,nama_barang,id_barang
		} = vItems;
		
		vHTMLs += `
		<tr valign="top" key="`+vKeys+`">
			<td align="right">`+(vKeys+1)+`.</td>
			<td align="left" colspan="3">`+(nama_barang)+`</td>
		</tr>
		<tr valign="top">
			<td align="right">&nbsp;</td>
			<td align="left" colspan="2">`+UFunc.formatAngka(qty)+` PCS x `+UFunc.formatAngka(harga_satuan)+`</td>
			<td align="right">Rp `+UFunc.formatAngka((qty * harga_satuan))+`</td>
		</tr>
		`;
	}).join("");
	vHTMLs += `
		<tr valign="top">
			<td align="right" colspan="3">&nbsp;</td>
			<td align="right"><hr/></td>
		</tr>
		<tr valign="top">
			<td align="right" colspan="3">Sub Total</td>
			<td align="right">Rp `+UFunc.formatAngka(vDatanotaObj.nilai_bruto)+`</td>
		</tr>
		<tr valign="top">
			<td align="right" colspan="3">Diskon</td>
			<td align="right">Rp `+UFunc.formatAngka(vDatanotaObj.diskon)+`</td>
		</tr>
		<tr valign="top">
			<td align="right" colspan="3">&nbsp;</td>
			<td align="right"><hr/></td>
		</tr>
		<tr valign="top">
			<td align="right" colspan="3">TOTAL</td>
			<td align="right">Rp `+UFunc.formatAngka(vNilainetto)+`</td>
		</tr>
		<tr valign="top">
			<td align="right" colspan="3"><b>JML BAYAR</b></td>
			<td align="right"><b>Rp `+UFunc.formatAngka(vDatanotaObj.nilai_uang)+`</b></td>
		</tr>
		<tr valign="top">
			<td align="right" colspan="3">`+vCaptionkembali+`</td>
			<td align="right">Rp `+UFunc.formatAngka(Math.abs(vNilaikembali))+`</td>
		</tr>
		<tr valign="top">
			<td align="right" width="9%">&nbsp;</td>
			<td align="right">&nbsp;</td>
			<td align="right" width="25%">&nbsp;</td>
			<td align="right" width="25%">&nbsp;</td>
		</tr>
		<tr valign="top">
			<td align="center" colspan="2">
			<div>Pasien,</div>
			<br/>
			<br/>
			<br/>
			<b><u>`+(vDatanotaObj.caption_pasien)+`</u></b>
			</td>
			<td align="center" colspan="2">
			<div>Front Office,</div>
			<br/>
			<br/>
			<br/>
			<b><u>`+UFunc.Usercaptionfilter(vDatanotaObj.inputcaption)+`</u></b>
			</td>
		</tr>
	</tbody>
	</table>
	`;
	vHTMLs 	+= `_DIVFOOTERNOTA_`;

	return vHTMLs;
},
RenderTabel: function(_COLSPAN) {
	let vHTMLs	= ``;

	const vColspan = parseInt(_COLSPAN) || 1;
	vHTMLs += `
		<table id="idtabelhtml">
		<thead>
		<tr>
		<td colspan="`+vColspan+`">
		<big style="font-weight:bolder;text-transform:uppercase">_JUDULLAPORAN_</big>
		<div><small>_TITELSUB_</small></div>
		</td>
		</tr>
		<tr valign="top">_TABELHEADER_</tr>
		</thead>
		<tbody>_TABELBODY_</tbody>
		<tfoot>
		<tr>
		_TABELFOOTER_
		</tr>
		</tfoot>
		</table>
	`;

	return vHTMLs;
}
}
export {UFunc,cBulanPanjang,cHariPendek,cHariPanjang,cAngkaTgl,cBulanPendek};