import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	CImage,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogview,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Piutang	 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 20) : (uSettinguserObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--LOAD_BY_PAGE--/
	const uTabelSelectObj 	= useSelector(state => state.gInitTabelSelected);
	const uIsInitbefore 	= (JSON.stringify(uTabelSelectObj||{})!=="{}");
	const [uInitbeforeprocess,setInitbeforeprocess] = React.useState(false);
	//--END LOAD_BY_PAGE--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	const [uISDlgviewshow,setDlgviewshow]		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]	= React.useState({});
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	const uHeightcardnilai	= document.getElementById("idcarddetilnilai") &&
		document.getElementById("idcarddetilnilai").offsetHeight;
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlDlgviewtoggle = () => { setDlgviewshow(false) };
	const hdlKlikDetil = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();
		uDlgviewdataObj.header = ("View Detil Penunjang: "+vCaptionupper);
		setDataselect({id:uDatamainArr[vArridx].id})

		const vDetilObj		= uDatamainArr[vArridx].detil_obj || {};
		if(!UFunc.isEmpty(vDetilObj)) {
			setDlgviewshow(true);
			return;
		}

		apiLoaddetil(uDatamainArr[vArridx].id);
	}
	const hdlKlikBayar = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		initSelectrow(_IDTABEL);
		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].pasien_caption||"").toUpperCase();

		if(await(Konfirm("Bayar Piutang: <B>"+vCaptionupper+"</B>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) {
			const vTmpObj	= {"initID":_IDTABEL,"initPage":uPageActive,}
			uDispatch({type: "set", gInitTabelSelected: vTmpObj});

			uDatamainArr[vArridx].is_processing = true;
			setEfekview(!uEfekview);
			uNavigate("/piutang/formpiutang/0");
		}
	}
	//--END HANDEL--/

	//--INIT--/
	const initResetview = () => {
    	setPageActive(1);
    	setJmlData(0);
    	setDatamainArr([]); 
    	setDataselect({});
    	setRowselect(-1);
    	setEfekview(!uEfekview);
    }
	const initJumlahdata = () => {
		const vElJmlmobile	= document.getElementById("idjmldatamobile");
		const vKeyword 		= uKeywordObj.kwd_piutang || "";
		/*if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			vElJmlmobile && (vElJmlmobile.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		vElJmlmobile && (vElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	}
	const initSelectrow = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL) || 0; if(_IDTABEL <= 0) return;
		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowIdx);
		setEfekview(!uEfekview)
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_piutang || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.filter(vItems=>vItems.pg === uPageActive)
		//const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center classfontsmaller align-top">
			<th width="5%" className="px-0">No</th>
			<th className="px-0">
			<CRow className="mx-0">
			<CCol md="2" lg="3" className="text-start">{uBahasaObj.word_pasien||"Pasien"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_tgltransaksi||"Tgl Transaksi"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_ntransaksi||"N.Transaksi"} (Rp)</CCol>
			<CCol md="2" className="">{uBahasaObj.word_terbayar||"Terbayar"} (Rp)</CCol>
			<CCol md="2" className="">{uBahasaObj.word_sisa||"Sisa"} (Rp)</CCol>
			<CCol md="2" lg="1" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,pasien_caption,pasien_telp,tgl_transaksi,
				nilai_piutang,nilai_bayar,is_processing,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (pasien_caption||"").toUpperCase();

			const vNamapasien	= vKeyword==="" ? (pasien_caption||"Undf") 
				: (pasien_caption||"Undf").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTgltransaksi	= vKeyword==="" ? UFunc.TglAngka(tgl_transaksi||"") 
				: UFunc.TglAngka(tgl_transaksi||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vTelppasien	= vKeyword==="" ? (pasien_telp||"") 
				: (pasien_telp||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 text-end">{vNumber}.</td>

			<td className="px-0 text-end">
			<CRow className="mx-0">
			<CCol xs="12" md="2" lg="3" className="px-1 text-start d-flex d-md-block justify-content-between">
				<div>
				<div className="text-info text-capitalize">{UFunc.renderHTML(vNamapasien)}</div>
				{(pasien_telp||"") !== "" && (
				<small className="d-none d-md-block">Telp/HP. <b>{UFunc.renderHTML(vTelppasien)}</b></small>
				)}
				</div>

				<div className="d-md-none">
				{(is_processing||false)===true ? (
					<CSpinner color="primary" size="sm"/>
				) : (
				<CDropdown className="">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikDetil(id)}>
						<CIcon icon="cilSearch" className="classikonbox me-2 align-middle" height={25}/>
						{uBahasaObj.caption_detilpiutang||"Detil Piutang"}
					</CDropdownItem>
							
					<CDropdownItem onClick={()=>hdlKlikBayar(id)}>
						<CIcon icon="cilCreditCard" className="align-middle classikonbox me-2" height={25}/>
						{uBahasaObj.caption_bayarpiutang||"Bayar Piutang"}
					</CDropdownItem>
				</CDropdownMenu>
				</CDropdown>
				)}
				</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			{(pasien_telp||"") !== "" && (
			<>
			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_telphp||"Telp/HP"}</CCol>
			<CCol xs="7" md="2" className="px-1 d-md-none">{UFunc.renderHTML(vTelppasien)}</CCol>
			</>
			)}

			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_tgltransaksi||"Tgl Transaksi"}</CCol>
			<CCol xs="7" md="2" className="px-1 text-md-center">
				<span className="d-md-none d-lg-block">{UFunc.renderHTML(vTgltransaksi)}</span>
				<small className="d-none d-md-block d-lg-none">{UFunc.renderHTML(vTgltransaksi)}</small>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_ntransaksi||"N. Transaksi"} (Rp)</CCol>
			<CCol xs="7" md="2" className="px-1">{UFunc.formatAngka(nilai_piutang)}</CCol>

			<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_terbayar||"Terbayar"} (Rp)</CCol>
			<CCol xs="7" md="2" className="px-1">{UFunc.formatAngka(nilai_bayar)}</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="5" className="px-1 d-md-none text-start fw-bolder">{uBahasaObj.word_sisa||"Sisa"} (Rp)</CCol>
			<CCol xs="7" md="2" className="px-1 fw-bolder text-success">{UFunc.formatAngka(parseFloat(nilai_piutang) - parseFloat(nilai_bayar))}</CCol>

			<CCol md="2" lg="1" className="px-0 text-md-center d-none d-md-block">
			{(is_processing||false)===true ? (
				<CSpinner color="primary" size="sm"/>
			) : (
			<>
				<CTooltip content={"--"+(uBahasaObj.caption_detilpiutang||"Detil Piutang")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikDetil(id)} className="classcursorpointer">
					<CIcon icon="cilSearch" className="classikonbox" height={25}/>
					</CLink>
				</CTooltip>

				<CTooltip content={"--"+(uBahasaObj.caption_bayarpiutang||"Bayar Piutang")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikBayar(id)} className="classcursorpointer">
					<CImage src={pjson.homepage+"api/images/icon_kasir.png"} 
						className="classikonbox mx-0"
						style={{
							borderColor:"#229954",
							color:"#229954",
							backgroundColor:"#22995455",
						}}
						height={26}/>
					</CLink>
				</CTooltip>
			</>
			)}
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)
		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,uDataselect.id);
		if(vArridx < 0) return "";

		const vDetilObj = uDatamainArr[vArridx].detil_obj || {};
		if(UFunc.isEmpty(vDetilObj)) return "";

		const vItemArr	= vDetilObj.items_arr || [];
		const vBayarArr	= vDetilObj.bayar_arr || [];

		return (
		<small>
		<CRow className="mx-0 my-2">
		<CCol md="6" className="">
		<CCard id="idcarddetildata">
		<CCardHeader className="py-1 text-muted">Data Transaksi</CCardHeader>
		<CCardBody className="p-1 px-2">
			<CRow className="my-1">
			<CCol className="text-start">{uBahasaObj.caption_tgltransaksi||"Tgl Transaksi"}</CCol>
			<CCol className="text-end">{UFunc.HariAngka(uDatamainArr[vArridx].tgl_transaksi)}</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol xs="8" className="text-end text-info">{(uDatamainArr[vArridx].pasien_caption)}</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol xs="4" className="text-start">{uBahasaObj.caption_telphp||"Telp/HP"}</CCol>
			<CCol xs="8" className="text-end fw-bolder">{(uDatamainArr[vArridx].pasien_telp||"-")}</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		</CCol>
		
		<CCol md="6" className="pt-2 pt-md-0">
		<CCard id="idcarddetilnilai">
		<CCardHeader className="py-1 text-muted">Total Nilai</CCardHeader>
		<CCardBody className="p-1 px-3">
			<CRow className="my-1">
			<CCol className="text-start">{uBahasaObj.caption_ntransaksi||"N Transaksi"} (Rp)</CCol>
			<CCol className="text-end">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_bruto)}</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol className="text-start">{uBahasaObj.word_diskon||"Diskon"} (Rp)</CCol>
			<CCol className="text-end">{UFunc.formatAngka(uDatamainArr[vArridx].diskon)}</CCol>
			</CRow>

			<CRow className="my-1">
			<CCol className="text-start">{uBahasaObj.word_terbayar||"Terbayar"} (Rp)</CCol>
			<CCol className="text-end">{UFunc.formatAngka(uDatamainArr[vArridx].nilai_bayar)}</CCol>
			</CRow>

			<div className="my-2 classborderbottom"/>

			<CRow className="my-1">
			<CCol className="text-start fw-bolder text-success">{uBahasaObj.caption_sisapiutang||"Sisa Piutang"} (Rp)</CCol>
			<CCol className="text-end fw-bolder text-success">
				{UFunc.formatAngka(parseFloat(uDatamainArr[vArridx].nilai_piutang) - parseFloat(uDatamainArr[vArridx].nilai_bayar))}
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		</CCol>
		</CRow>

		<CRow className="mx-0 my-2">
		<CCol md="6" className="">
		<CCard>
		<CCardHeader className="py-1 text-muted">Item Barang Penunjang</CCardHeader>
		<CCardBody className="p-1 px-2">
		{vItemArr.map((vItems,vKeys)=>{
			return (
			<CRow className="mb-1 mx-0">
			<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
			<CCol xs="8" className="px-1">
				<div className="fst-italic">{vItems.nama_barang}</div>
				<small>
				(<b>{UFunc.formatAngka(vItems.qty)}</b> PCS x {UFunc.formatAngka(vItems.harga_satuan)})
				</small>
			</CCol>
			<CCol xs="3" className="px-1 text-end">
				<div>&nbsp;</div>
				<b>{UFunc.formatAngka(parseFloat(vItems.qty) * parseFloat(vItems.harga_satuan))}</b>
			</CCol>
			</CRow>
			)
		})}
		</CCardBody>
		</CCard>
		</CCol>
		
		<CCol md="6" className="pt-2 pt-md-0">
		<CCard>
		<CCardHeader className="py-1 text-muted">Histori Pembayaran</CCardHeader>
		<CCardBody className="p-1 px-2">
		{vBayarArr.map((vItems,vKeys)=>{
			return (
			<CRow className="my-1 mx-0">
			<CCol xs="1" className="px-1 text-center">
				&#10042;
			</CCol>
			<CCol xs="4" className="px-1">
				<small className="fst-italic">{UFunc.HariAngka(vItems.tgl_bayar)}</small>
			</CCol>
			<CCol xs="4" className="px-1">
				<b className="text-uppercase classfontsmaller">[{vItems.no_nota}]</b>
			</CCol>
			<CCol xs="3" className="px-1 text-end">
				<b>{UFunc.formatAngka(vItems.nilai_bayar)}</b>
			</CCol>
			</CRow>
			)
		})}
		{vBayarArr.length <= 0 && (<center className="my-3 text-danger fst-italic">&middot;&middot;Belum Ada Pembayaran&middot;&middot;</center>)}
		</CCardBody>
		</CCard>
		</CCol>
		</CRow>
		</small>
		)
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = (_PAGE) => {
		setHtml500msg();
		const vPage	= parseInt(_PAGE)||1;

		const vHandelview	= 
			((uHandelView||false)===true || uFirstload==="YA" || uDatamainArr.length <= 0)
			?"YA":"TIDAK";
		const vKeyword 		= uKeywordObj.kwd_piutang || "";
		let vDatamainArr 	= uDatamainArr;
		if(vHandelview==="YA") {
			vDatamainArr	= [];
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)===1);
			} else {
				vDatamainArr	= uDatamainArr.filter(vItems=>parseInt(vItems.pg||1)!==vPage);
			}
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_pg: vPage,
			send_maxdata: uMaxData,
			send_loadjml : vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_piutang/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				vTmpArr.map(vItems=>vItems.pg = vPage);
				setDatamainArr([...vDatamainArr,...vTmpArr]);

				setJmlData(parseInt(output_string.totaldata||uJmlData))
				//if(vKeyword.length >= 3)
				//else setJmlData(vTmpArr.length);

				if(uIsInitbefore) {
					setInitbeforeprocess(true)
				}
				if(uFirstload==="YA") { setFirstload("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Piutang - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Piutang - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoaddetil = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		uDatamainArr[vArridx].is_processing = true;
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoaddetil) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vArridx].is_processing = false;

			uDatamainArr[vArridx].detil_obj = {};
			setDlgviewshow(true);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_piutang/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			uDatamainArr[vArridx].is_processing = false;
			setSessionaktif(true);
			if(output_string.tampil) {
				uDatamainArr[vArridx].detil_obj = JSON.parse(output_string.detilobject || "{}");
				setDlgviewshow(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Piutang - apiLoaddetil) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgviewshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArridx].is_processing = false;
			console.log("(Piutang - apiLoaddetil) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		//console.log("(Barangmasuk - [uIsInitbefore]) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
		if(!uIsInitbefore) return;

		const vPageinit	= parseInt(uTabelSelectObj.initPage)||1;
		setPageActive(vPageinit);
		apiLoaddata(vPageinit);//--JIKA_PAGINATION_DIATUR_BACKEND--/
	},[uIsInitbefore]);
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!uIsInitbefore) {
			//console.log("(Barangmasuk - []) uTabelSelectObj = "+JSON.stringify(uTabelSelectObj));
			apiLoaddata();
		}

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			initResetview(); 
    		setFirstload("YA");
    		setDatamainArr([]);
			hdlDlgviewtoggle();
    	}
	},[])
	React.useEffect(()=>{
		if(!uInitbeforeprocess) return;

		setInitbeforeprocess(false)
		uDispatch({type: "set", gInitTabelSelected: {}}); 

		const vInitID	= uTabelSelectObj.initID;
		initSelectrow(vInitID);
		
		const vElscroll	= document.getElementById("idtr"+(vInitID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))
	},[uInitbeforeprocess]);
	React.useEffect(()=>{
		document.getElementById("idcarddetildata") && (
			document.getElementById("idcarddetildata").style.minHeight = uHeightcardnilai+"px"
		);
	},[uHeightcardnilai])
	React.useEffect(()=>{
		//console.log("(Piutang-[uHandelView]) uHandelView = "+uHandelView)
		if(!uHandelView) return;

		initResetview();
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uPageActive > 1) setPageActive(1); else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		initJumlahdata();
	},[uJmlData])
	React.useEffect(()=>{
		if(uFirstload==="YA") return;
		if(uHandelView) return;

		setDataselect({});
		setRowselect(-1);
		const vDatapageArr = uDatamainArr.filter(vItems=>parseInt(vItems.pg||0)===parseInt(uPageActive||1));
		if(vDatapageArr.length > 0) { 
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}

		//console.log("(Billingtransaksi - [uPageActive]) uHandelView : "+uHandelView);
		apiLoaddata(uPageActive);
	},[uPageActive])
	React.useEffect(()=>{
		if(!uISDlgviewshow) { setDlgviewdataObj({}); return; }
	},[uISDlgviewshow])//-*/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<small className="text-primary">{uBahasaObj.caption_listdata||"Data"} {uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]}</small>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary" id="idjmldatamobile">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<span className="classfontsmaller"><small className="text-muted">{UFunc.formatAngka(uTimeelapse)}ms</small></span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogview
			show={uISDlgviewshow} 
			toggle={hdlDlgviewtoggle}
			options={{size:"lg",centered:true}}
			dialogHeader={(uDlgviewdataObj.header||"")}
			renderContent={contentDlgview()}
			{...props}/>
		</>
	)
}	

export default Piutang