import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormCheck,
	CInputGroup,CInputGroupText,
	CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialogform,
	MyDialoglookup,
	MyDialogview,
	MyPage500,
} from '../../components/index'
import {
	ContHeaderProfile,
} from '../../containers/index'
import NumberFormat from 'react-number-format';
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Billingform 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const { tabel_id } 			= useParams(); 
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTabelID 					= parseInt(tabel_id)||0;
	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uUsersettingObj			= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uPasienselectedObj		= useSelector(state => state.gInitTabelSelected) || {};
	const uPasienID					= parseInt(uPasienselectedObj.initID) || 0;
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uUsersettingObj.jml_mobile || 10) : (uUsersettingObj.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const [uFirstLoad,setFirstLoad]			= React.useState("YA");
	const [uDataheaderObj,setDataheaderObj]	= React.useState({})
	const [uDataloadObj,setDataloadObj]		= React.useState({})
	const [uNotacaption,setNotacaption]		= React.useState("");
	const [uDatainputObj,setDatainputObj]	= React.useState({})
	const [uIteminputObj,setIteminputObj]	= React.useState({})
	const [uTabeleditObj,setTabeleditObj]	= React.useState({})
	const [uISFormupdated,setFormupdated]	= React.useState(false)
	const [uDatabarangArr,setDatabarangArr]	= React.useState([])
	const [uDatanurseArr,setDatanurseArr]	= React.useState([])

	//--DLG_FORM--/
	const [uDlgformObj,setDlgformObj]		= React.useState({})
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false)
	const [uDlglookupObj,setDlglookupObj]	= React.useState({})
	//--END DLG_LOOKUP--/

	//--NOTA_CETAK--/
	const [uDatanotaObj,setDatanotaObj]		= React.useState({})
	const uHTMLstylenota					= useSelector(state => state.gHTMLstyleNota);
	const uSettingcetakObj					= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas						= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri						= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize							= parseInt(uSettingcetakObj.fontsize)||12;
	const uFontstyle						= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 						= document.getElementById("ifmcontentstoprint");
	//--END NOTA_CETAK--/

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if(uISFormupdated) {
			if(await(Konfirm(
				"Field Input/Edit Sudah Ada yang Berubah.."+
				"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
				"<br/>"+
				"<br/><b>Apakah Anda Yakin</b> ?"+
				""))) uNavigate(-1);

			return;
		}
		uNavigate(-1);
	}
	const hdlKDownDocument = (_EV) => {
		if(_EV.which === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();

			document.getElementById("btnSimpanmain") &&
				document.getElementById("btnSimpanmain").click();
			return;
		}
	}
	const hdlKlikReset = async() => {
		if(uHtml500msg) { apiLoaddata(); return }
		if(!uISFormupdated) { apiLoaddata(); return }

		const vFunc	= () => {
			resetForm();
		}

		if(await(Konfirm(
			"Field Input/Edit Sudah Ada yang Berubah.."+
			"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
			"<br/>"+
			"<br/><b>Apakah Anda Yakin</b> ?"+
			""))) vFunc();
	}
	const hdlKlikNursepilih = () => {
		uDlgformObj.header 		= "Pilih Asistensi Nurse";
		uDlgformObj.jenis 		= "FORMNURSE";
		uDlgformObj.nurse_arr	= [...(uDatainputObj.nurse_arr||[]),{id:0,nama_nurse:"_FAKE_"}];
		setDlgformshow(true)
	}
	const hdlChangeNursecheck = (_VALUE) => {
		_VALUE 			= parseInt(_VALUE)||0;

		const vNurseArr	= uDlgformObj.nurse_arr || [];
		const vArridx	= UFunc.getObjectIndeks(vNurseArr,_VALUE);
 
		if(vArridx < 0) {
			const vArrnurse	= UFunc.getObjectIndeks(uDatanurseArr,_VALUE);
			uDlgformObj.nurse_arr = [...vNurseArr,{
				id:(uDatanurseArr[vArrnurse].id||"0").toString(),
				nama_nurse:uDatanurseArr[vArrnurse].nama_nurse,
			}]
		} else {
			(uDlgformObj.nurse_arr||[]).splice(vArridx,1);
		}
		setEfekview(!uEfekview);
	}
	const hdlKlikNursetukar = () => {
		const vNurseArr = uDatainputObj.nurse_arr || [];
		vNurseArr.reverse();

		//uDatainputObj.nurse_arr = vNurseArr;
		setEfekview(!uEfekview);
	}
	const hdlKlikNurseclear = async() => {
		if(await(Konfirm("Apakah Anda Yakin ?"))) {
			uDatainputObj.nurse_arr = undefined;
			setEfekview(!uEfekview);
		}
	}
	const hdlKDownItemnama = (_EV) => {
		if(_EV.keyCode !== 13) { return; }
		if((uIteminputObj.caption_barang||"").trim() === "") return;

		_EV.stopPropagation()
		_EV.preventDefault()

		const vfilterArr = uDatabarangArr.filter(vItems => 
			(vItems.caption_barang||"").toUpperCase().includes((uIteminputObj.caption_barang||"").trim().toUpperCase()) ||
				(vItems.nama_jenis||"").toUpperCase().includes((uIteminputObj.caption_barang||"").trim().toUpperCase())
		);

		if(vfilterArr.length === 1) {
			uIteminputObj.caption_barang = vfilterArr[0].caption_barang;

			const vArridxmatch = UFunc.getObjectIndeks((uDatainputObj.items_arr||[]),vfilterArr[0].id,"id_barang");
			if(vArridxmatch >= 0) {
				showToast("<b>Barang Tersebut Sudah Ditambahkan</b>.."+
					"<br/>Mohon Ubah QTY jika ADA perubahan !"+
					"");
				return;
			}

			uDatainputObj.items_arr = [{
				id:"0",
				id_barang: vfilterArr[0].id,
				caption_barang: vfilterArr[0].caption_barang,
				qty:1,
				harga_bahan: parseFloat(vfilterArr[0].harga_bahan),
				fee_nurse_1: parseFloat(vfilterArr[0].fee_nurse_1),
				fee_nurse_2: parseFloat(vfilterArr[0].fee_nurse_2),
				harga_satuan: parseFloat(vfilterArr[0].harga_bahan) +
					parseFloat(vfilterArr[0].fee_nurse_1) +
					parseFloat(vfilterArr[0].fee_nurse_2),
				},...(uDatainputObj.items_arr||[])]

			setIteminputObj({})

			if(!isMobile) {
				document.getElementById("inpitemnama") &&
					document.getElementById("inpitemnama").focus()
			}
			return
		}

		hdlKlikItemlookup()
	}
	const hdlKlikItemlookup = () => {
		uDlglookupObj.header 		= "Lookup Data Barang";
		uDlglookupObj.inputvalue 	= uIteminputObj.caption_barang||"";
		uDlglookupObj.data_arr		= uDatabarangArr.map(vItems=>{
			return ({
				id: vItems.id,
				caption: vItems.nama_barang,
				caption_sub: vItems.nama_jenis,
				harga_satuan: parseFloat(vItems.harga_bahan) +
					parseFloat(vItems.fee_nurse_1) +
					parseFloat(vItems.fee_nurse_2),
			})
		});

		setDlglookupshow(true);
	}
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformObj.jenis === "FORMNURSE")
			{ prosesNurse(); return }
		if(uDlgformObj.jenis === "FORMEDITQTY")
			{ prosesTabelqty(); return }
		if(uDlgformObj.jenis === "FORMMAIN")
			{ prosesMain(); return }

		hdlToggleDlgform();
	}
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookup = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0;if(_IDITEM <= 0) return;

		const vArridx = UFunc.getObjectIndeks(uDatabarangArr,_IDITEM);
		if(vArridx < 0) {
			hdlToggleDlglookup(); return;
		}

		uIteminputObj.caption_barang = uDatabarangArr[vArridx].caption_barang;
		const vArridxmatch = UFunc.getObjectIndeks((uDatainputObj.items_arr||[]),_IDITEM,"id_barang");
		if(vArridxmatch >= 0) {
			showToast("<b>Barang Tersebut Sudah Ditambahkan</b>.."+
				"<br/>Mohon Ubah QTY jika ADA perubahan !"+
				"");
			return;
		}

		uDatainputObj.items_arr = [{
			id:"0",
			id_barang: uDatabarangArr[vArridx].id,
			caption_barang: uDatabarangArr[vArridx].caption_barang,
			qty:1,
			harga_bahan: parseFloat(uDatabarangArr[vArridx].harga_bahan),
			fee_nurse_1: parseFloat(uDatabarangArr[vArridx].fee_nurse_1),
			fee_nurse_2: parseFloat(uDatabarangArr[vArridx].fee_nurse_2),
			harga_satuan: parseFloat(uDatabarangArr[vArridx].harga_bahan) +
				parseFloat(uDatabarangArr[vArridx].fee_nurse_1) +
				parseFloat(uDatabarangArr[vArridx].fee_nurse_2),
			},...(uDatainputObj.items_arr||[])]

		setIteminputObj({})
		hdlToggleDlglookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout)
			if(!isMobile) {
				document.getElementById("inpitemnama") &&
					document.getElementById("inpitemnama").focus()
			}
		},350)
	}
	const hdlKlikTabeleditfield = (_IDXKEY,_NAMAFIELD) => {
		_IDXKEY 	= parseInt(_IDXKEY);
		_NAMAFIELD	= (_NAMAFIELD||"").trim().toUpperCase();

		const vItemArr = uDatainputObj.items_arr || [];
		/*const vItemArr	= (uDatainputObj.items_arr || []).filter(vItems=>
			(vItems.init_hapus||"TIDAK") !== "YA");//-*/

		uTabeleditObj.idxkey 	= _IDXKEY;
		uTabeleditObj.field 	= _NAMAFIELD;
		uTabeleditObj.value 	= vItemArr[_IDXKEY].qty;
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			if(!isMobile) {
				const vElfocus	= document.getElementById("inptabelqty");
				vElfocus && vElfocus.focus();
			}
		},300);
	}
	const hdlKDownTabelinputfield = (_EV) => {
		if(_EV.which === 27) {
			_EV.preventDefault();
			_EV.stopPropagation();

			setTabeleditObj({});
			setEfekview(!uEfekview);
			return;
		}

		if(_EV.which === 13) {
			_EV.preventDefault();
			_EV.stopPropagation();

			hdlKlikTabelbuttonfield();
			return;

		}
	}
	const hdlKlikTabelbuttonfield = () => {
		const vArridx	= parseInt(uTabeleditObj.idxkey);
		const vField 	= uTabeleditObj.field || "";
		const vValue 	= uTabeleditObj.value;

		let vElfocus	= document.getElementById("inptabelqty");
		if(parseInt(vValue) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("QTY Tidak Valid !"); return;
		}

		/*const vItemArr	= (uDatainputObj.items_arr || []).filter(vItems=>
			(vItems.init_hapus||"TIDAK") !== "YA");//-*/
		(uDatainputObj.items_arr || [])[vArridx].qty = vValue;
		//uDatainputObj.items_arr(vItemArr);
		setTabeleditObj({});
		prosesSUM();
		//setEfekview(!uEfekview);
	}
	const hdlKlikTabeleditmobile = (_IDXKEY) => {
		_IDXKEY 	= parseInt(_IDXKEY);

		const vItemArr	= (uDatainputObj.items_arr || []);
		/*const vItemArr	= (uDatainputObj.items_arr || []).filter(vItems=>
			(vItems.init_hapus||"TIDAK") !== "YA");//-*/

		uTabeleditObj.row_idx	= _IDXKEY;
		uDlgformObj.idxkey		= _IDXKEY;
		uDlgformObj.caption_barang= vItemArr[_IDXKEY].caption_barang;
		uDlgformObj.harga_satuan= vItemArr[_IDXKEY].harga_satuan;
		uDlgformObj.itemqty		= vItemArr[_IDXKEY].qty;
		uDlgformObj.header 		= "Edit QTY";
		uDlgformObj.jenis 		= "FORMEDITQTY";
		setDlgformshow(true)
	}
	const hdlKlikTabelhapus = async(_IDXKEY) => {
		_IDXKEY 	= parseInt(_IDXKEY);

		const vTabelitemID	= parseInt((uDatainputObj.items_arr||[])[_IDXKEY].item_id)||0;
		const vProseshapus  = () => {
			if(vTabelitemID > 0) {
				(uDatainputObj.items_arr ||[])[_IDXKEY].init_hapus = "YA";
			} else {
				(uDatainputObj.items_arr||[]).splice(_IDXKEY,1);
			}
			prosesSUM();
			setEfekview(!uEfekview);
		}
		if(await(Konfirm(
			"Apakah Anda Yakin ?"+
			""))) vProseshapus()
	}

	const hdlKlikSimpanmain = () => {
		//--VALIDASI--/
		let vElfocus = document.getElementById("inpitemnama");
		if(parseInt(uDatainputObj.sum_item||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Item Barang Belum Ditambahkan !"); return;
		}

		const vFilterfeenurseArr = (uDatainputObj.items_arr||[]).filter(vItems=>
			parseFloat(vItems.fee_nurse_1||0) > 0 ||
				parseFloat(vItems.fee_nurse_2||0) > 0
			);
		if((uDatainputObj.nurse_arr||[]).length <= 0 && vFilterfeenurseArr.length > 0) {
			showToast("<b>Ada Item yang Mensyaratkan TINDAKAN NURSE</b>.."+
				"<br/>Mohon Tambahkan NURSE Terlebih Dahulu !"); return;
		}
		if((uDatainputObj.nurse_arr||[]).length > 0 && vFilterfeenurseArr.length <= 0) {
			showToast("<b>TIDAK Ada Item yang Mensyaratkan TINDAKAN NURSE</b>.."+
				"<br/>Mohon HAPUS Daftar NURSE atau Tambahkan Item dengan Tindakan NURSE Terlebih Dahulu !"); return;
		}
		//--END VALIDASI--/

		uDlgformObj.header 	= "Pembayaran Transaksi: "+uNotacaption;
		uDlgformObj.jenis 	= "FORMMAIN";
		setDlgformshow(true);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile) {
				const vElfocus = document.getElementById("inpmaindiskon");
				vElfocus && vElfocus.focus();
			}
		},300);
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const resetForm = () => {
		if(uTabelID > 0) setDatainputObj(uDataloadObj);
		else setDatainputObj({});
		setIteminputObj({});
		setTabeleditObj({});
		setDatanotaObj({});
		setEfekview(!uEfekview)
	}
	const prosesSUM = () => {
		//const vItemArr 	= uDatainputObj.items_arr || [];
		const vSumitem 	= (uDatainputObj.items_arr || []).reduce((a,b)=> a + b.qty,0);
		const vSumnilai = (uDatainputObj.items_arr || []).reduce((a,b)=>{
			return a + (parseInt(b.qty) * parseFloat(b.harga_satuan))
		},0);

		uDatainputObj.sum_item 	= vSumitem;
		uDatainputObj.sum_nilai	= vSumnilai;
		uDatainputObj.nilai_uang= vSumnilai;
		setEfekview(!uEfekview);
	}
	const prosesNurse = () => {
		const vNurseArr		= uDlgformObj.nurse_arr || [];
		if(vNurseArr.length > 3) {
			showToast("<b>Jumlah NURSE Maksimal 2</b>..<br/>Mohon Diperbaiki !");
			return;
		}

		const vArridx	= UFunc.getObjectIndeks(vNurseArr,"_FAKE_","nama_nurse");
		vNurseArr.splice(vArridx,1);

		uDatainputObj.nurse_arr = vNurseArr;
		hdlToggleDlgform();
	}
	const prosesTabelqty = () => {
		if((parseInt(uDlgformObj.itemqty)||0) <= 0) return;

		const vArridx = parseInt(uDlgformObj.idxkey);
		(uDatainputObj.items_arr || [])[vArridx].qty = uDlgformObj.itemqty;
		prosesSUM();
		hdlToggleDlgform();
	}
	const prosesKembalian = () => {
		const vNilaibruto	= parseFloat(uDatainputObj.sum_nilai||0);
		const vNilaidiskon	= parseFloat(uDatainputObj.diskon||0);
		const vTotal		= vNilaibruto - vNilaidiskon;

		const vNilaibayar	= parseFloat(uDatainputObj.nilai_uang||0);
		const vKembalian	= vTotal - vNilaibayar;

		uDatainputObj.nilai_netto	 	= vTotal;
		uDatainputObj.nilai_kembalian 	= vKembalian;
		if(vKembalian > 0) {
			uDatainputObj.caption_kembalian	= "SISA PIUTANG";
			uDatainputObj.color_kembalian	= "text-danger";
		} else {
			uDatainputObj.caption_kembalian	= "Kembalian";
			uDatainputObj.color_kembalian	= "text-success";
		}
		setEfekview(!uEfekview);
	}
	const prosesMain = () => {
		//--VALIDASI--/
		let vElfocus = document.getElementById("inpmaindiskon");
		if(parseFloat(uDatainputObj.nilai_netto||0) < 0) {
			vElfocus && vElfocus.focus();
			showToast(
				"<b>Nilai Diskon Tidak Valid</b>.."+
				"<br/>Mohon Diperbaiki Sehingga Nilai TOTAL tidak MINUS !"+
				""); return;
		}
		//--END VALIDASI--/

		apiProsesmain();
	}
	const prosesCetaknota = () => {
		let vContent 	= contentNota();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();

		setDatanotaObj({});
		uKeywordObj.kwd_billingpasien = undefined; 
	    localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		uDispatch({type: "set", gInitTabelSelected: {}});
		showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
		uNavigate("/dashboard", { replace: true });
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentTanggal = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg mb-3" id="idcardtanggal">
		<CCardHeader className="py-0 text-muted">
			<small className="classfontsmaller">Tanggal</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="my-2">
			<CCol xs="6" className="">Tanggal</CCol>
			<CCol className="text-end">
				{(uTokenObj.userhak==="SUPERADMIN" || uTokenObj.userhak==="PENGELOLA") ? (
				<CFormInput size="sm" type="date"
					value={uDatainputObj.tgl_transaksi||""}
					onChange={(e)=>{uDatainputObj.tgl_transaksi=e.target.value;setEfekview(!uEfekview)}}
					id="inptanggal"/>
				) : (
				<b>{UFunc.TglAngka(uDatainputObj.tgl_transaksi)}</b>
				)}
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="6" className="">No.Nota</CCol>
			<CCol className="text-end">
				<b className="">{uNotacaption || "-"}</b>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentNurse = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg mb-3" id="idcardnurse">
		<CCardHeader className="py-0 text-muted">
		<div className="pt-1 d-flex justify-content-between align-items-center">
			<small className="classfontsmaller">Asistensi Nurse</small>
			<small className="classfontsmaller">
				<CLink 
					onClick={()=>hdlKlikNursepilih()}
					className="classcursorpointer">Pilih Nurse</CLink>
				<span className="mx-1 fw-bolder">|</span>
				<CLink 
					onClick={()=>hdlKlikNurseclear()}
					className="classcursorpointer">CLEAR</CLink>
			</small>
		</div>
		</CCardHeader>

		<CCardBody className="">
			{(uDatainputObj.nurse_arr||[]).length <= 0 ? (
			<div className="text-warning fst-italic text-center my-2">&middot;&middot;NURSE BELUM DIPILIH&middot;&middot;</div>
			) : (
			<div className="d-flex align-items-center">
			<div className="w-25 text-center">
				<CTooltip content="Tukar Urutan Nurse">
				<CLink
					onClick={()=>hdlKlikNursetukar()}
					className="classcursorpointer">
					<CIcon icon="cilSwapVertical" height={30}/>
				</CLink>
				</CTooltip>
			</div>
			<ol className="my-0 w-75">
			{(uDatainputObj.nurse_arr||[]).map((vItems,vKeys)=>{
				return (
				<li key={vKeys}>{vItems.nama_nurse}</li>
				)
			})}
			</ol>
			</div>
			)}
		</CCardBody>
		</CCard>
		)
	}
	const contentItemform = () => {
		if(uHtml500msg) return (<></>)

		return (
		<>
		<small className="text-muted classfontsmaller d-none d-md-block">Lookup Barang</small>
		<span className="mx-1 d-none d-md-block">&middot;</span>
		<div style={{width:"30%",minWidth:300}}>
		<CInputGroup size="sm">
		<CFormInput size="sm"
			placeholder="--Lookup Keyword Barang.."
			value={uIteminputObj.caption_barang||""}
			onChange={(e)=>{uIteminputObj.caption_barang=e.target.value;setEfekview(!uEfekview)}}
			onKeyDown={(e)=>hdlKDownItemnama(e)}
			id="inpitemnama"/>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemlookup()}
			id="btnitemlookup">
			<CIcon icon="cilMagnifyingGlass"/>
		</CButton>
		</CInputGroup>
		</div>
		</>
		)
	}
	const contentItem = () => {
		if(uHtml500msg) return (<></>)

		const vItemArr	= (uDatainputObj.items_arr || []);

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="align-top px-0 classfontsmaller">No</th>
			<th className="align-top px-0">
			<small className="classfontsmaller">
			<CRow className="mx-0">
			<CCol md="5" className="text-start">{uBahasaObj.caption_namabarang||"Nama Barang"}</CCol>
			<CCol md="1" className="">&middot;</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_hsatuan||"H.Satuan"}<div>(Rp)</div></CCol>
			<CCol md="2" className="text-center">{uBahasaObj.word_qty||"Qty"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_ntotal||"N.Total"}<div>(Rp)</div></CCol>
			</CRow>
			</small>
			</th>
			</tr>
		</thead>

		<tbody>
		{vItemArr.map((vItems,vKeys)=>{
			const {
				id,id_barang,caption_barang,qty,harga_satuan,init_hapus
			} = vItems;

			const vCaptionupper	= (caption_barang||"").toUpperCase();
			const vArridxmatch	= UFunc.getObjectIndeks(uDatabarangArr,id_barang);
			const vStokmin	 	= vArridxmatch>=0 ? parseInt(uDatabarangArr[vArridxmatch].stok_minimal) : 0; 
			const vStokinit 	= vArridxmatch>=0 ? parseInt(uDatabarangArr[vArridxmatch].stok_init) : 0; 

			if((init_hapus||"TIDAK")==="YA") return "";

			return (
			<tr id={"idtr"+vKeys} key={vKeys} className={(uTabeleditObj.row_idx===vKeys?"classrowselect":"")}>
			<td className="px-1 align-top text-end">{(vKeys+1)+"."}</td>

			<td className="px-1 px-md-0 align-top text-end">
			<CRow className="mx-0">
			<CCol xs="12" md="5" className="px-1 text-start d-flex justify-content-between">
				<div>
				{caption_barang||""}
				{vStokinit <= vStokmin && (
				<>
				<small className="text-danger classblinking">
				<span className="ms-1">&#9913;</span>
					Stok: <b>{UFunc.formatAngka(vStokinit)} PCS</b>
				<span className="">&#9913;</span>
				</small>
				</>
				)}
				</div>

				<CDropdown className="d-md-none align-items-start">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>

				<CDropdownMenu>
				<CDropdownItem onClick={()=>hdlKlikTabeleditmobile(vKeys)}>
				<CIcon className="classikonedit me-1 align-middle" height={25}/>{uBahasaObj.caption_editqty||"Edit QTY"}
				</CDropdownItem>
				<CDropdownItem onClick={()=>hdlKlikTabelhapus(vKeys)}>
				<CIcon className="classikonhapus me-1 align-middle" height={25}/>{uBahasaObj.caption_hapusitem||"Hapus Item"}
				</CDropdownItem>
				</CDropdownMenu>
				</CDropdown>
			</CCol>
			
			<CCol md="1" className="px-0 text-center d-none d-md-block">
				<CTooltip content={"--Hapus Item: "+vCaptionupper}>
				<CLink onClick={()=>hdlKlikTabelhapus(vKeys)} 
					className="classcursorpointer classikontabel classikonhapus"/>
				</CTooltip>
			</CCol>

			<CCol xs="12" className="px-1 d-md-none classborderbottom my-2"/>
			<CCol xs="6" className="px-1 d-md-none text-start">QTY / H.Satuan(Rp)</CCol>
			<CCol xs="6" md="2" className="px-1 ">
			<span className="d-md-none"><b className="text-primary">{UFunc.formatAngka(qty)}PCS</b> x </span>
			{UFunc.formatAngka(harga_satuan)}
			</CCol>
			
			<CCol md="2" className="px-1 text-md-center d-none d-md-block">
				{(uTabeleditObj.field==="QTY" && uTabeleditObj.idxkey===vKeys) ? (
				<div className="px-lg-5">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					className="text-end"
					value={uTabeleditObj.value||qty}
					onFocus={(e)=>e.target.select()}
					onChange={(e)=>{uTabeleditObj.value=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>hdlKDownTabelinputfield(e)}
					id="inptabelqty"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikTabelbuttonfield(vKeys)}
					id="btntabelqty">
					<CIcon icon="cilCheck"/>
				</CButton>
				</CInputGroup>
				</div>
				) : (
				<CTooltip content="--Klik Untuk EDIT">
				<CLink 
					onClick={()=>hdlKlikTabeleditfield(vKeys,"QTY")}
					className="classcursorpointer fw-bolder">
					<>{UFunc.formatAngka(qty)} PCS</>
				</CLink>
				</CTooltip>
				)}
			</CCol>
			
			<CCol xs="12" className="px-1 d-md-none classborderbottom my-3"/>
			<CCol xs="7" className="px-1 d-md-none text-start">N.Total (Rp)</CCol>
			<CCol xs="5" md="2" className="px-1 text-success">
				<b>{UFunc.formatAngka(harga_satuan * qty)}</b>
			</CCol>
			
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)

		if(uDlgformObj.jenis === "FORMNURSE") return contentFormnurse();
		if(uDlgformObj.jenis === "FORMMAIN") return contentFormmain();
		if(uDlgformObj.jenis === "FORMEDITQTY") return contentFormeditqty();
		
		return (<></>)
	}
	const contentFormnurse = () => {
		if(uDlgformObj.jenis !== "FORMNURSE") return (<></>);

		return (
		<CRow className="mx-0">
		{uDatanurseArr.map((vItems,vKeys)=>{
			return (
			<CCol md="6" lg="4" className="p-0 pe-1 pb-1" key={vKeys}>
				<small>
				<CFormCheck size="sm" type="checkbox"
					className="bg-light border p-1 ps-4"
					value={vItems.id}
					label={vItems.nama_nurse}
					checked={(uDlgformObj.nurse_arr||[]).filter(vItemnurse=>
						(vItemnurse.id||"").toString()===(vItems.id||"").toString()).length===1}
					onChange={(e)=>hdlChangeNursecheck(e.target.value)}
					id={"inpnurseid"+vItems.id}/>
				</small>
			</CCol> 
			)
		})}
		</CRow>
		)
	}
	const contentFormeditqty = () => {
		if(uDlgformObj.jenis !== "FORMEDITQTY") return (<></>);

		//console.log("(Billingform - contentFormeditqty) uTabeleditObj.idxkey = "+uTabeleditObj.idxkey);
		const vArridx 	= parseInt(uTabeleditObj.idxkey); if(vArridx < 0) return (<>{vArridx}</>);
		const vItemArr	= (uDatainputObj.items_arr || []).filter(vItems=>
			(vItems.init_hapus||"TIDAK") !== "YA");

		return (
		<CForm>
		<CRow className="my-2">
			<CCol className="text-info">{uDlgformObj.caption_barang}</CCol>
		</CRow>

		<div className="my-2 classborderbottom"/>

		<CRow className="my-2">
			<CCol>{uBahasaObj.caption_hsatuan} (Rp)</CCol>
			<CCol className="text-end">{UFunc.formatAngka(uDlgformObj.harga_satuan)}</CCol>
		</CRow>

		<CRow className="my-3">
			<CCol className="fw-bolder">{uBahasaObj.word_qty}</CCol>
			<CCol className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					className="text-end"
					value={uDlgformObj.itemqty||0}
					onChange={(e)=>{uDlgformObj.itemqty=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpformtabelqty"/>
				<CInputGroupText>PCS</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentFormmain = () => {
		if(uDlgformObj.jenis !== "FORMMAIN") return (<></>);

		return (
		<CForm>
		<CRow className="mx-0 my-1">
		<CCol xs="6" className="">Tanggal</CCol> 
		<CCol xs="6" className="text-end">{UFunc.TglAngka(uDatainputObj.tgl_transaksi)}</CCol> 
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="">Nama Pasien</CCol> 
		<CCol xs="6" className="text-end text-info fw-bolder">{uDataheaderObj.nama_pasien}</CCol> 
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="">Jml Item</CCol> 
		<CCol xs="6" className="text-end fw-bolder">{UFunc.formatAngka((uDatainputObj.items_arr||[]).length)} Barang</CCol> 
		</CRow>

		<div className="classborderbottom my-3"/>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="">Nilai Transaksi (Rp)</CCol> 
		<CCol xs="6" className="text-end fw-bolder">{UFunc.formatAngka(uDatainputObj.sum_nilai)}</CCol> 
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="text-danger">Diskon</CCol> 
		<CCol className="text-end px-1">
			<NumberFormat 
				value={uDatainputObj.diskon||0}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => uDatainputObj.diskon=(values.value)}
				renderText={values => (
					<CFormInput size="sm"
						className="text-end"
						value={values}
						onChange={(e)=>{uDatainputObj.diskon=e.target.value;setEfekview(!uEfekview)}}
						onFocus={(e)=>e.target.select()}
						id="inpmaindiskon"/>
				)} 
			prefix={isMobile?'':'Rp'}/>
		</CCol> 
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="">&nbsp;</CCol> 
		<CCol xs="6" className="text-end classborderbottom"/>
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="fw-bolder">Total (Rp)</CCol> 
		<CCol xs="6" className="text-end fw-bolder">
			{UFunc.formatAngka(uDatainputObj.nilai_netto)}
		</CCol> 
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="fw-bolder text-primary">JML BAYAR</CCol> 
		<CCol className="text-end px-1">
			<NumberFormat 
				value={uDatainputObj.nilai_uang||0}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => uDatainputObj.nilai_uang=(values.value)}
				renderText={values => (
					<CFormInput size="sm"
						className="text-end"
						value={values}
						onChange={(e)=>{uDatainputObj.nilai_uang=e.target.value;setEfekview(!uEfekview)}}
						onFocus={(e)=>e.target.select()}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
						id="inpmaindiskon"/>
				)} 
			prefix={isMobile?'':'Rp'}/>
		</CCol> 
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className="">&nbsp;</CCol> 
		<CCol xs="6" className="text-end classborderbottom"/>
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol xs="6" className={""+(uDatainputObj.color_kembalian||"text-muted")}>
			<big>{uDatainputObj.caption_kembalian||"Kembalian"} (Rp)</big>
		</CCol> 
		<CCol xs="6" className={"text-end "+(uDatainputObj.color_kembalian||"text-muted")}>
			<big>{UFunc.formatAngka(Math.abs(uDatainputObj.nilai_kembalian||0))}</big>
		</CCol> 
		</CRow>
		</CForm>
		)
	}
	const contentNota = () => {
		//console.log("uDatanotaObj = "+JSON.stringify(uDatanotaObj||{}));
		if(UFunc.isEmpty(uDatanotaObj)) return "";

		let vHTMLs 	= uHTMLstylenota;

		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= (uSystemsettingObj.view_image_nota||"YA")==="YA";
		const vURLlogo		= uSystemsettingObj.url_image_nota||"";//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(uDatanotaObj);
		vHTMLs 			+=  vMainbody;

		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<div>
				<img src="`+vURLlogo+`" height="25mm" style="height:25mm" />
			</div>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((uSystemdataObj.telp || "") !== "")
			vTelpPerusahaan += `
			<div>`+
				(uBahasaObj.caption_telphp||"caption_telphp")+". "+
				UFunc.capitalize(uSystemdataObj.telp || "")+
			`</div>
			`;
		//---END TELP--/

		const vFooternota = (uSystemdataObj.footer_kasir||"").trim() !=="" ?
			`&nbsp;
			<hr/>
			<div align="center">`+(uSystemdataObj.footer_kasir||"")+`</div>
			` : ``;
		
		vHTMLs 		= vHTMLs.replace(/_DIVLOGOIMAGE_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(uSystemdataObj.nama_klinik || "").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(uSystemdataObj.alamat || ""));
		vHTMLs 		= vHTMLs.replace(/_DIVROWTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_DIVFOOTERNOTA_/g,vFooternota);

		return vHTMLs;
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDataheaderObj({});
		setDataloadObj({});
		setIteminputObj({});
		setTabeleditObj({});

		setDatabarangArr([]);
		setDatanurseArr([]);

		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_pasienid : uPasienID,
			send_firstload : uFirstLoad,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_billingtransaksi/ld_form";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Barang - apiLoaddata) output_string.databarang : "+output_string.databarang);
				setDataheaderObj(JSON.parse(output_string.datapasien || "{}"));
				setDatabarangArr(JSON.parse(output_string.databarang || "[]"));
				setDatanurseArr(JSON.parse(output_string.datanurse || "[]"));
				setNotacaption(output_string.newnotastr || "UNDF");

				if(uTabelID > 0) {
					setDataloadObj(JSON.parse(output_string.datamain || "{}"))
				}
				if(uFirstLoad==="YA") { setFirstLoad("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Billingform - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg(vMsg);
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Billingform - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesmain = () => {
		const vElTombol	= document.getElementById("btnDialogSimpan");
		vElTombol && (vElTombol.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);
				hdlToggleDlgform();

					setDatainputObj({});
					showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
					if(!isMobile) {
						document.getElementById("inpitemnama") &&
							document.getElementById("inpitemnama").focus();

						document.getElementById("inptanggal") &&
							document.getElementById("inptanggal").focus();
					}
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_pasienid : uPasienID,
			send_datajson : JSON.stringify(uDatainputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_billingtransaksi/pr_simpanmain";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);
			if(output_string.sukses) {
				hdlToggleDlgform();
				if(uTabelID <= 0) {
					setDatainputObj({});
					//setNotacaption(output_string.newnotastr || uNotacaption);
					if(!isMobile) {
						document.getElementById("inpitemnama") &&
							document.getElementById("inpitemnama").focus();

						document.getElementById("inptanggal") &&
							document.getElementById("inptanggal").focus();
					}
					setDatanotaObj(JSON.parse(output_string.datanota||"{}"))
				} else uNavigate(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Billingform - apiProsesmain) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			vElTombol && (vElTombol.disabled = false);
			console.log("(Billingform - apiProsesmain) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !!uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		apiLoaddata();

    	if(!isMobile) {
    		let vElfocus = document.getElementById("inpitemnama");
    		vElfocus && vElfocus.focus();

    		vElfocus = document.getElementById("inptanggal");
    		vElfocus && vElfocus.focus();
    	}

		const vHeighttanggal = document.getElementById("idcardtanggal").offsetHeight;
		document.getElementById("idcardnurse").style.minHeight = vHeighttanggal+"px";

		return () => {
			hdlToggleDlgform();hdlToggleDlglookup();
			/*setDataloadObj({});
			setDatainputObj({});
			setDatanotaObj({});
			setDataheaderObj({});
			setDatanurseArr([]);setDatabarangArr([]);//-*/
		}
	},[])
	React.useEffect(()=>{
		if(!uFrameExport) return;

		let pri 		= uFrameExport.contentWindow;
		const vURLlogo	= uSystemsettingObj.url_image_nota;
		pri.document.open();
		pri.document.write(`<img src="`+vURLlogo+`"/>`);
		pri.document.close();
	},[uFrameExport])
	React.useEffect(()=>{
		if(!uIsDlgformshow) {
			setTabeleditObj({}); setDlgformObj({});
			return;
		}
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow) {
			setDlglookupObj({}); return;
		}
	},[uISDlglookupshow])
	React.useEffect(()=>{
		if(uTabelID <= 0) return;

		setDatainputObj(uDataloadObj);
	},[uDataloadObj])
	React.useEffect(()=>{
		if(uDataloadObj.tgl_transaksi === undefined)
			uDataloadObj.tgl_transaksi = UFunc.DbDate();

		const vSumitem 	= (uDataloadObj.items_arr || []).reduce((a,b)=> a + b.qty,0);
		const vSumnilai = (uDataloadObj.items_arr || []).reduce((a,b)=>{
			return a + (parseInt(b.qty) * parseFloat(b.harga_satuan))
		},0);

		uDataloadObj.sum_item 	= vSumitem;
		uDataloadObj.sum_nilai	= vSumnilai;
	},[uDataloadObj,
	uDataloadObj.tgl_transaksi,
	uDataloadObj.items_arr
	])
	React.useEffect(()=>{
		if(uDatainputObj.tgl_transaksi === undefined)
			uDatainputObj.tgl_transaksi = UFunc.DbDate();

		if(uDatainputObj.pasien_caption === undefined) 
			uDatainputObj.pasien_caption = uDataheaderObj.nama_pasien;
		if(uDatainputObj.diskon === undefined) uDatainputObj.diskon = 0;
		if(uDatainputObj.nilai_uang === undefined) uDatainputObj.nilai_uang = 0;

		prosesSUM();
	},[uDatainputObj,
	uDataheaderObj.nama_pasien,
	uDatainputObj.pasien_caption,
	uDatainputObj.tgl_transaksi,
	uDatainputObj.items_arr,
	uDatainputObj.diskon,
	//uDatainputObj.nilai_uang
	])
	React.useEffect(()=>{
		prosesKembalian();
	},[uDataloadObj,
	uDatainputObj.sum_nilai,
	uDatainputObj.diskon,
	uDatainputObj.nilai_uang
	])
	React.useEffect(()=>{
		const vItemArr 		= uDatainputObj.items_arr || [];
		const vItemloadArr 	= uDataloadObj.items_arr || [];
		const vNurseArr 	= uDatainputObj.nurse_arr || [];
		const vNurseloadArr = uDataloadObj.nurse_arr || [];
		if(uDatainputObj.tgl_transaksi !== uDataloadObj.tgl_transaksi
			|| vItemArr.length !== vItemloadArr.length
			|| vNurseArr.length !== vNurseloadArr.length
			|| uDatainputObj.sum_item !== uDataloadObj.sum_item
			|| uDatainputObj.sum_nilai !== uDataloadObj.sum_nilai
			) setFormupdated(true);
		else setFormupdated(false);
	},[uDatainputObj,
	uDatainputObj.tgl_transaksi,
	uDatainputObj.nurse_arr,
	uDatainputObj.items_arr
	]);
	React.useEffect(()=>{
		if(UFunc.isEmpty(uDatanotaObj)) return;

		prosesCetaknota();
	},[uDatanotaObj])
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }

	//console.log("(Billingform) uNavigate => "+JSON.stringify(uNavigate))
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent pb-4 py-md-0">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop">
		<CContainer fluid className="m-0 p-2 border-bottom">
			<CHeaderToggler className="ms-1 px-2"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="d-md-flex me-auto text-normal align-items-center">
				<span>Form Kasir Penunjang</span>
				<span className="mx-1">&middot;</span>
				<CImage src={pjson.homepage+"api/images/icon_step_2.png"} 
					className="d-none d-md-inline-block" 
					height={32}/>
			</CHeaderNav>

			<CHeaderNav className="ms-3 align-items-center">
				<ContHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 px-3 px-lg-4 align-items-center">
			<div className="d-none d-md-block d-lg-flex align-items-center">
			{(uHtml500msg) ? ("") : (
			<>
				{(UFunc.isEmpty(uDataheaderObj)) ? (
					<big className="classblinking fst-italic text-warning">Loading..</big>
				) : (
				<>
				<b className="text-info text-uppercase">{uDataheaderObj.nama_pasien||"NAMA PASIEN"}</b>
				<span className="mx-1">&middot;</span>
				<small style={{
					color:(uDataheaderObj.sex||"")[0]==="L"?"#752421":"#FE47F3",
					}}>[ {(uDataheaderObj.sex||"")[0]||"SEX"} ]</small>
				<span className="mx-1 d-none d-lg-inline-block">&middot;</span>
				<small className="fst-italic d-none d-lg-inline-block">{UFunc.br2nl(uDataheaderObj.alamat||"Alamat Panjang disini")}</small>
				<span className="mx-1 d-none d-lg-inline-block">&middot;</span>
				<div><small className="classfontsmaller">Telp/HP: <b>{uDataheaderObj.no_telp||"NO TELP"}</b></small></div>
				</>
				)}
			</>
			)}
			</div>

			<div className="d-md-none"/>
			<div className="text-end">
			<CLink className="classikon classikonreset classcursorpointer"
				 onClick={()=>hdlKlikReset()}/>
			<span className="mx-2">&middot;</span>
			<small className="align-top classfontsmaller">Rp</small>
			<span style={{fontSize:32}} className="align-middle">
				<span className="text-success fw-bold">{UFunc.formatAngka(uDatainputObj.sum_nilai||0)}</span>
			</span>
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-4">
		<CContainer xxl className="px-0">
			{(uHtml500msg) ? (
				<MyPage500 content={uHtml500msg}/>
			) : (
			<CForm>
			<CRow className="mx-0 d-md-none">
			<CCol className="">
				{(UFunc.isEmpty(uDataheaderObj)) ? (
					<center className="classblinking fst-italic mx-auto mb-2 text-warning">Loading..</center>
				) : (
				<CCard className="classcardbg mb-3">
				<CCardHeader className="py-0 text-muted classfontsmaller">Data Pasien</CCardHeader>
				<CCardBody className="">
				<small>
					<CRow className="my-1">
					<CCol xs="4" className="text-start">Nama Pasien</CCol>
					<CCol className="text-end">
						<span className="text-info">{uDataheaderObj.nama_pasien||""}</span>
						<span className="mx-1">&middot;</span>
						<small style={{
							color:(uDataheaderObj.sex||"")[0]==="L"?"#752421":"#FE47F3",
							}}>[ {(uDataheaderObj.sex||"")[0]||"SEX"} ]
						</small>
					</CCol>
					</CRow>

					<CRow className="my-1">
					<CCol xs="4" className="text-start">Alamat</CCol>
					<CCol className="text-end fst-italic">{UFunc.br2nl(uDataheaderObj.alamat||"Alamat Panjang disini")}</CCol>
					</CRow>

					<CRow className="my-1">
					<CCol xs="4" className="text-start">Telp/HP</CCol>
					<CCol className="text-end">
						<b>{uDataheaderObj.no_telp||"-"}</b>
					</CCol>
					</CRow>
				</small>
				</CCardBody>
				</CCard>
				)}
			</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol md="6" className="pe-md-2">{contentTanggal()}</CCol>
			<CCol md="6" className="ps-md-2">{contentNurse()}</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol className="">
			<CCard className="classcardbg mb-3">
			<CCardHeader className="d-flex align-items-center">{contentItemform()}</CCardHeader>
			<CCardBody>{contentItem()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="fixed" 
			className="px-3 text-black-50 justify-content-center"  
			style={{backgroundColor:"#f0f0f0"}}>
		<MyButton
			disabled={uHtml500msg || uIsDlgformshow}
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaObj.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{centered:true,size:uDlgformObj.jenis==="FORMMAIN"?undefined:"lg"}}
			onSimpan={hdlKlikDlgformsimpan}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformObj.header}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>
		<MyDialoglookup
			show={uISDlglookupshow} 
			dialogHeader={uDlglookupObj.header||""}
			toggle={hdlToggleDlglookup}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr||[]}
			onKlikPilihItem={hdlKlikDlglookup}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	)//>
}	

export default Billingform