import React from 'react'
import { useDispatch } from 'react-redux'
import { ContContent, ContSidebar, ContFooter, ContHeader } from '../containers/index'

const Layout = (props) => {
	const { setToken, setLoading,showToast } = props; 

	//console.log("Layout - PASSED")
	return (
		<>
		<ContSidebar {...props}/>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
			<ContHeader {...props}/>
			<div className="body flex-grow-1 px-1 px-md-2 px-lg-4 mb-2">
				<ContContent {...props}/>
			</div>
			<ContFooter {...props}/>
		</div>
		</>
  )
}

export default Layout