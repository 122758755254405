import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	CImage,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
	CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
	MyPagination,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Stokurgent 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? 20 : 100;
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;
	const uHandelrekap 		= uHeaderActionObj.isHeaderrekap || false;

	//--DATA_TABEL--/
	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword") || "{}");
	const uSearchObj		= uKeywordObj.kwd_stokurgent || {};

	const uTokenCabangidArr	= (uTokenObj.usercabangid||"").split(",");
	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const uCabangviewArr	= uTokenObj.userhak==="SUPERADMIN" ? uDatacabangArr
		: uDatacabangArr.filter(
			vItems => uTokenCabangidArr.includes(vItems.id.toString()));
	const uInitCabangid		= localStorage.getItem("cabangidselect") || uCabangviewArr[0].id;

	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	//const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	const uElJmlmobile	= document.getElementById("idjmldatamobile");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		const vCabangid  	= uSearchObj.id_cabang || uInitCabangid;
		const vCabangidx	= UFunc.getObjectIndeks(uDatacabangArr,vCabangid);
		const vCabangcaption= uDatacabangArr[vCabangidx].nama_cabang || "UNDF";
		const vColspan 		= 6;
		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vTmpObj 		= {
			no: "",
			caption_barang: "",
			status_barang: "",
			stok_minimal: "",
			stok_init: "",
			caption_stok: "",
		}
		vDatasetsArr.push(vTmpObj);

		uDatamainArr.map((vItems,vKeys) => {
			const {
				nama_barang,jenis_barang,kode_sku,stok_init,sort_order,
				id,stok_minimal,selisih_init,status_barang,
			} = vItems;

			const vCaptionbarang = 
				((jenis_barang||"").trim()!=="" ? (jenis_barang||"").trim()+" " : "")+
				(nama_barang||"")+
				((kode_sku||"").trim()!=="" ? " - "+(kode_sku||"").trim().toUpperCase() : "")+
			"";

			const vCaptionstok = parseInt(sort_order) >= 1 ? ""
				: ( (parseInt(selisih_init||0) <= 0 && parseInt(stok_init) > 0) 
					? "URGENT" : ((parseInt(stok_init) <= 0) ? "Kosong" : "")
				);

			const vStokminimal	= (parseFloat(stok_minimal)||0) < 0 ? 0 : parseFloat(stok_minimal);
			vTmpObj 		= {
				no : {v:vKeys+1,t:"n"},
				caption_barang: vCaptionbarang,
				status_barang: {v:(status_barang||"UMUM"),s:{alignment:{horizontal:"center"}}},
				stok_minimal: {v:vStokminimal,t:"n",s:{alignment:{horizontal:"center"}}},
				stok_init: {v:stok_init,t:"n",s:{alignment:{horizontal:"center"}}},
				caption_stok: vCaptionstok,
			}
			vDatasetsArr.push(vTmpObj);
		});

		const wsrows	= [{hpt:48}]
		const wscols	= [
			{wpx:40},
			{wpx:275},
			{wpx:75},
			{wpx:55},
			{wpx:85},
		];
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
		]

		const vTanggalnowDT	= new Date()
		const vBulanID 		= vTanggalnowDT.getMonth();
		const vTahun 		= vTanggalnowDT.getFullYear();
		const vNamafile 	= 
			vTahun+
			UFunc.leadZero(vBulanID+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_STOKTERKINI_"+
			UFunc.leadZero(vTanggalnowDT.getDate())+
			(cBulanPendek[vBulanID]).toUpperCase()+
			vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\n"+UFunc.WaktuPendek()+
			"\r\n("+vCabangcaption+")"+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Nama Barang",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Status Brg",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Stok Min",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Sisa Stok",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END HANDEL--/

	//--INIT--/
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uSearchObj.keyword || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="7" className="text-start">{uBahasaObj.caption_namabarang||"Nama Barang"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_statusbarang||"Status Brg"}</CCol>
			<CCol md="1" className="">{uBahasaObj.caption_stokmin||"Stok Min"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_sisastok||"Sisa Stok"}</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				nama_barang,jenis_barang,kode_sku,stok_init,sort_order,
				id,stok_minimal,selisih_init,status_barang,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (nama_barang||"").toUpperCase();

			const vNamabarang	= vKeyword==="" ? (nama_barang||"Undf") 
				: (nama_barang||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vKodesku	= vKeyword==="" ? (kode_sku||"") 
				: (kode_sku||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vJenisbarang	= vKeyword==="" ? (jenis_barang||"") 
				: (jenis_barang||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vStatusbarang	= vKeyword==="" ? (status_barang||"UMUM") 
				: (status_barang||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vStokminimal	= (parseFloat(stok_minimal)||0) < 0 ? 0 : parseFloat(stok_minimal);
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 pe-0">{vNumber}.</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol md="7" className="px-1 text-start">
				<div className="d-none d-md-flex">
				<div className="text-capitalize">
					{(jenis_barang||"")!=="" ? (
					<span className="">{UFunc.renderHTML(vJenisbarang)}</span>
					) : (
					<small className="text-muted fst-italic">(Unselect Jenis)</small>
					)}
				</div>

				<span className="mx-1">&middot;</span>

				<div className="text-info text-capitalize">{UFunc.renderHTML(vNamabarang)}</div>

				{(kode_sku||"")!=="" && (
				<>
				<span className="mx-1">&middot;</span>
				<b className="text-uppercase">{UFunc.renderHTML(vKodesku)}</b>
				</>
				)}
				</div>

				<div className="d-md-none">
					{(jenis_barang||"")!=="" && (
					<small className="text-capitalize fst-italic">{UFunc.renderHTML(vJenisbarang)}</small>
					)}

					<div>
					<span className="text-info text-capitalize">{UFunc.renderHTML(vNamabarang)}</span>

					{(kode_sku||"")!=="" && (
					<>
					<span className="mx-1">&middot;</span>
					<b className="text-uppercase">{UFunc.renderHTML(vKodesku)}</b>
					</>
					)}
					</div>
				</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_statusbarang||"Status Barang"}</CCol>
			<CCol xs="7" md="2" className="px-1 text-md-center">{UFunc.renderHTML(vStatusbarang)}</CCol>

			<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_stokmin||"Stok Min"}</CCol>
			<CCol xs="7" md="1" className="px-1 text-md-center">
				<span className="text-dark">{UFunc.formatAngka(vStokminimal)}</span>
			</CCol>

			<CCol md="2" className="px-0 text-md-center d-none d-md-block">
				<big className={"fw-bolder"+(parseInt(selisih_init||0)<0?" text-danger":"")}>
					<big>{UFunc.formatAngka(stok_init)}</big>
				</big>
				{parseInt(sort_order) < 1 && (
				<>
					{(parseInt(selisih_init||0) <= 0 && parseInt(stok_init) > 0) ? (
					<CBadge color="warning" shape="rounded-pill" className="classblinking align-top">
						<small>!!!</small>
					</CBadge>
					) : (
					<>
					{(parseInt(stok_init) <= 0) && (
					<CBadge color="danger" shape="rounded-pill" className="align-top pt-0">
						<small>Kosong</small>
					</CBadge>
					)}
					</>
					)}
				</>
				)}
			</CCol>

			<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_stokterkini||"Sisa Stok"}</CCol>
			<CCol xs="7" md="2" className="px-1 text-md-center d-md-none">
				<b className={(parseInt(selisih_init||0)<0?" text-danger":"")}>
					<>{UFunc.formatAngka(stok_init)}</>
				</b>
			</CCol>

			{parseInt(sort_order) < 1 && (
			<>
			<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>

			<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_statusstok||"Status Stok"}</CCol>
			<CCol xs="7" className="px-1 text-md-center d-md-none">
				{(parseInt(selisih_init||0) <= 0 && parseInt(stok_init) > 0) ? (
					<CBadge color="warning" shape="rounded-pill" className="">
						<small>Urgent</small>
					</CBadge>
				) : (
				<>
					{(parseInt(stok_init) <= 0) ? (
					<CBadge color="danger" shape="rounded-pill" className="">
						<small>Kosong</small>
					</CBadge>
					) : (
					<span className="text-muted fst-italic">Normal</span>
					)}
				</>
				)}
			</CCol>
			</>
			)}
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDatamainArr([]);
		setDataselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpArr		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uSearchObj.keyword || "";
		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_cabangid : uSearchObj.id_cabang || uInitCabangid,
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_stokopname/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpArr);

				if(uFirstload==="YA") { setFirstload("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Stokurgent - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Stokurgent - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA");
    		setDatamainArr([]);
    	}
	},[])
	React.useEffect(()=>{
		//console.log("(Stokurgent-[uHandelView]) uHandelView = "+uHandelView)
		if(!uHandelView) return;

		const vKeyword = (uSearchObj.keyword||"").trim();
		if(vKeyword === "") setFirstload("YA");
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("(Stokurgent-[uHandelTambah]) uHandelTambah = "+uHandelTambah)
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uNavigate("/stokopname/formstokopname");
	},[uHandelTambah])
	React.useEffect(()=>{
		if(!uHandelrekap) return;

		uHeaderActionObj.isHeaderrekap = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uNavigate("/stokopname/rekapstokopname");
	},[uHandelrekap])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
		if(uDatamainArr.length <= 0) return;
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		uElJmlmobile && (uElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	return (
		<>
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<span>
		<b>{uBahasaObj.caption_stokterkini||"Stok Terkini"}</b> <small><small>(by System)</small></small>
		</span>
		
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary" id="idjmldatamobile">{UFunc.formatAngka(uJmlData)}</b>)
		<span className="ms-2">&middot;</span>
		</small>
		
		{uJmlData > 0 && (
		<>
		{!isMobile ? (
			<CTooltip content="--Export-2-Excel">
			<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
			</CTooltip>
		) : (
			<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
		)}
		<span className="me-2">&middot;</span>
		</>
		)}

		<span className="classfontsmaller">
			<small className="text-muted">{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		</>
	)
}	

export default Stokurgent