import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	CFormSelect,CInputGroup,CInputGroupText,CButton,
	CSpinner,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyPage500,
	MyDialoglookup,
} from '../components/index'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cBulanPanjang,cBulanPendek } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Lapstokopname = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? 30 : 100;
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const uSettingsystemObj		= useSelector(state => state.gSystemsetting);
	const uHTMLstyleTabel		= useSelector(state => state.gHTMLstyleLaporan);
	
	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction);
	const uHandelFilter			= uHeaderActionObj.isHeaderFilter || false;
	const uHandelReset 			= uHeaderActionObj.isHeaderReset || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});
	const [uDataloadedObj,setDataloadedObj]	= React.useState();
	const [uTitelObj,setTitelObj]			= React.useState({});
	const [uSortingObj,setSortingObj]		= React.useState({});

	const [uDatabarangArr,setDatabarangArr]	= React.useState([]);
	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]");

	const uCabandIDtokenArr	= ((uTokenObj||{}).usercabangid || "").split(",");
	const uCabangallowedArr = uTokenObj.userhak === "SUPERADMIN" ? uDatacabangArr
		: uDatacabangArr.filter(vItems => uCabandIDtokenArr.includes(vItems.id.toString()));

	//console.log("uCabandIDtokenArr => "+JSON.stringify(uCabandIDtokenArr));
	//console.log("uCabangallowedArr => "+JSON.stringify(uCabangallowedArr));
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgforminputObj,setDlgforminputObj]= React.useState({});
	//--END DLG_FORM--/

	//--DLGLOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]		= React.useState({});
	//--END DLGLOOKUP--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	const uElJmlmobile	= document.getElementById("idjmldatamobile");
	const uFrameExport 	= document.getElementById("ifmcontentstoprint");
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikBaranglookup = () => {//->TIDAK_DIPAKAI--/
		uDlglookupObj.headers 	= "Pilih Item Barang";
		uDlglookupObj.inputvalue= "";

		const vItemArr			= uDatabarangArr.map(vItems=>{ 
			return {id:vItems.id,caption:vItems.caption_barang,
		}})
		uDlglookupObj.items_arr	= vItemArr || [];
		uDlgforminputObj.barang_id		= 0;
		uDlgforminputObj.barang_caption	= "";
		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {//->TIDAK_DIPAKAI--/
		_IDITEM	= parseInt(_IDITEM)||0;if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatabarangArr,_IDITEM);
		if(vArridx < 0) return;

		uDlgforminputObj.barang_id		= _IDITEM;
		uDlgforminputObj.barang_caption	= uDatabarangArr[vArridx].caption_barang;
		setDlglookupshow(false);
	}//--*/
	const hdlKlikDlgformsimpan = () => {
		let vElFocus = document.getElementById("inpcabangid");
		if((parseInt(uDlgforminputObj.cabang_id)||0) <= 0) {
			vElFocus && vElFocus.focus();
			showToast("Cabang Harus Dipilih !");return;
		}

		vElFocus = document.getElementById("inpbln");
		const vTanggals	= (uDlgforminputObj.thn+"-"+uDlgforminputObj.bln+"-01");
		const vTanggalDT = UFunc.toDate(vTanggals);
		if(!UFunc.DateIsValid(vTanggals)) {
			vElFocus && vElFocus.focus();
			showToast("Periode Tanggal/Bulan Tidak Valid !");return;
		}
		if(UFunc.DateIsBeyond(vTanggalDT)) {
			vElFocus && vElFocus.focus();
			showToast("Periode Tanggal/Bulan Belum Tercapai !");return;
		}

		/*vElFocus = document.getElementById("inpbaranglookup");
		if((parseInt(uDlgforminputObj.barang_id)||0) <= 0) {
			vElFocus && vElFocus.focus();
			showToast("Nama Barang Harus Dipilih !");return;
		}//-*/

		apiLoadview();
	}
	const hdlKlikDetil = (_IDTABEL) => {

	}
	const hdlKlikSorting = (_NAMAFIELD) => {//--TIDAK_DIPAKAI--/
		_NAMAFIELD 			= (_NAMAFIELD || "").trim();
		const vSortnamenow	= uSortingObj.nama || "";
		const vSortjenis	= (uSortingObj.jenis||"").toUpperCase();
		const vSortarget	= vSortjenis===""?"DESC"
			: (vSortjenis==="DESC" ? "ASC" : "");

		let vSortednew	= {}
		if(vSortnamenow !== _NAMAFIELD) {
			vSortednew = { nama: _NAMAFIELD, jenis: "DESC" }
		} else {
			if(vSortjenis === "ASC") vSortednew = {}
			else vSortednew = { nama: _NAMAFIELD, jenis: vSortarget }
		}
		setSortingObj(vSortednew);
		setEfekview(!uEfekview);
	}//-*/
	const hdlKlikCetak = () => {
		if(uJmlData <= 0) return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(uJmlData <= 0) return;

		//const vNurseID	= parseInt((uDataloadedObj||{}).barang_id)||"0";
		const vBulanID	= parseInt((uDataloadedObj||{}).bln)||"0";
		const vTahun	= parseInt((uDataloadedObj||{}).thn)||"0";

		const vColspan 	= 4;//(parseInt(uDataloadedObj.barang_id)||0) > 0 ? 5 : 3;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			tgl_opname: "",
			keterangan: "",
			jml_item: "",
		}
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				id,tgl_opname,keterangan,jml_item,
			} = vItems;

			vRowcount++;
			vColposwidth = (keterangan||"").length > vColposwidth 
				? (keterangan||"").length : vColposwidth; 

			const vTgltransaksi	= UFunc.toDate(tgl_opname);
			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				tgl_opname: {v:(vTgltransaksi),t:"d",s:{alignment:{horizontal:"left"},}},
				keterangan: (keterangan||"Undf"),
				jml_item: { v:parseFloat(jml_item), t:"n", s:{numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			tgl_opname: "",
			keterangan: "",
			jml_item: { v:parseFloat(uDataloadedObj.sum_item)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
				{wpx:40},
				{wpx:100},
				{wch:vColposwidth},
				{wpx:85},
			];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 2}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPSTOKOPNAME_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+uTitelObj.periode+
			(uTitelObj.subtitel ? "\r\n"+uTitelObj.subtitel : '')+
		"";
		const vHeaderArr =  [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Tanggal",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Keterangan",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"Jml Item",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormreset = () => {
		setDataselect({})
		setDlgforminputObj({});
		setEfekview(!uEfekview);
	}
	const prosesReset = async() => {
		const vFuncnext = () => {
			setDataloadedObj();
			setDatamainArr([]);
			setEfekview(!uEfekview);
		}

		if(uDataloadedObj) {
			if(await Konfirm("Apakah Anda Yakin Akan Reset Laporan ?")) vFuncnext();
		}// else { apiLoadinit(); }
	}
	const initSUMdata = () => {
		uDataloadedObj && (
			uDataloadedObj.sum_item = (uDatamainArr||[]).reduce((vSums,vItems)=>{
				return vSums + (parseFloat(vItems.jml_item)||0)
			},0)
		);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(!uDataloadedObj || uHtml500msg) return (<></>);

		if(uDataloadedObj && uJmlData <= 0) return (
			<div className="m-2 p-2 m-lg-5 p-lg-5 text-center fst-italic text-primary">
			&middot;&middot; Maaf, Data Tidak Ditemukan.. &middot;&middot;
			</div>
		)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vColspan 		= 5;

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabellaporan">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1 text-start" width="15%">{uBahasaObj.word_tanggal||"Tgl Opname"}</th>
			<th className="px-1 text-start">{uBahasaObj.word_keterangan||"Keterangan"}</th>
			<th className="px-1" width="5%">&middot;</th>
			<th className="px-1" width="15%">{uBahasaObj.caption_jmlmutasi||"Jml Item Mutasi"}</th>
			</tr>
			<tr className="d-none"><th colSpan={vColspan}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,keterangan,tgl_opname,jml_item,
				is_loading,
			} = vItems;

			vNumber++;
			const vCaptionupper	= UFunc.TglAngka(tgl_opname).toUpperCase();

			return (
			<tr id={"idtr"+vKeys} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 pe-0">{vNumber}.</td>
			<td className="px-1 text-info text-start">{UFunc.TglAngka(tgl_opname)}</td>
			<td className="px-1 text-capitalize text-start fst-italic">{(keterangan||"-")}</td>
			<td className="px-1 text-center">
				<CLink onClick={()=>hdlKlikDetil(id)} className="classcursorpointer">
				<CIcon icon="cilSearch" className="classikonbox" height={25}/>
				</CLink>
			</td>
			<td className="px-1 text-center">{UFunc.formatAngka(jml_item)}</td>
			</tr>
			)
		})}
		</tbody>
		<tfoot>
		<tr>
		<td colSpan={vColspan-1} align="center">JUMLAH</td>
		<td align="center">{UFunc.formatAngka(uDataloadedObj.sum_item)}</td>
		</tr>
		</tfoot>
		</table>
		);
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)

		const vJmlTahunlaporan	= parseInt(uSettingsystemObj.jml_tahun_laporan)||5;
		return (
		<CForm className="">
		<CRow className="my-2">
		<CCol xs="4" className="text-start">{uBahasaObj.word_cabang||"Cabang"}</CCol>
		<CCol className="text-end">
			<CFormSelect size="sm"
				value={uDlgforminputObj.cabang_id}
				onChange={(e)=>{uDlgforminputObj.cabang_id=e.target.value;setEfekview(!uEfekview)}}
				id="inpcabangid">
			{uCabangallowedArr.map((vItems,vKeys)=>{
				return (
				<option key={vKeys} value={vItems.id}>{vItems.nama_cabang}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="4" className="text-start">{(uBahasaObj.word_bulan||"Bulan")}</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormSelect size="sm"
				className="w-auto"
				value={uDlgforminputObj.bln}
				onChange={(e)=>{uDlgforminputObj.bln=e.target.value;setEfekview(!uEfekview)}}
				id="inpbln">
				{cBulanPanjang.map((vItems,vKeys)=>{
					return(
					<option key={vKeys} value={UFunc.leadZero(vKeys+1)}>{vItems.toUpperCase()}</option>
					)
				})}
			</CFormSelect>
			<span className="mx-1 d-none d-lg-block"/>
			<CFormSelect size="sm"
				value={uDlgforminputObj.thn}
				onChange={(e)=>{uDlgforminputObj.thn=e.target.value; setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpthn">
				{(() => {
					let vTmpArr 	= [];
					const vTahunnow	= (new Date()).getFullYear();
					for(var vIdx=0;vIdx < vJmlTahunlaporan;vIdx++) {
						vTmpArr.push(
						<option value={(vTahunnow - vIdx)} key={vIdx}>{(vTahunnow - vIdx)}</option>
						)
					}
					return vTmpArr;
				})()}
			</CFormSelect>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2 d-none">
		<CCol xs="4" className="text-start">{uBahasaObj.word_barang||"Barang"}</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				readOnly={true}
				placeholder="--Pilih Nama Barang.."
				value={uDlgforminputObj.barang_caption||""}
				//onChange={(e)=>{uDlgforminputObj.barang_caption=e.target.value;setEfekview(!uEfekview)}}
				onClick={()=>hdlKlikBaranglookup()}
				id="inpbaranglookup"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikBaranglookup()}
				id="btnbaranglookup">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		//console.log("(Lapstokopname - contentHTMLcetak) uDataidentitasObj => "+(uDataidentitasObj));

		const vColspan 		= 4;// (parseInt(uDataloadedObj.barang_id)||0) > 0 ? 5 : 3;
		const vDataviewArr	= uDatamainArr;
		//const vNurseID		= parseInt(uDataloadedObj.barang_id)||0;
		//const vNurseidx		= UFunc.getObjectIndeks(uDatabarangArr,vNurseID);
		const vCabangidx	= UFunc.getObjectIndeks(uDatacabangArr,uDataloadedObj.cabang_id);

		vHTMLs	+= UFunc.RenderTabel(vColspan);
		
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()];
		const vJudulsub		= `
			<div>Periode <b>`+uTitelObj.periode+`</b> (`+uTitelObj.cabang+`)</div>`+
			(uTitelObj.subtitel ? `<div>`+uTitelObj.subtitel+`</div>` : ``)+
		``;

		const vTabelheader	= `
			<th width="5%">No</th>
			<th width="18%" align="left">`+(uBahasaObj.word_tanggal||"Tanggal")+`</th>
			<th align="left">Keterangan</th>
			<th width="13%">Jml Item</th>
			</tr>
			`;
		
		let vTabelbody	= "";
		let vNumber		= 0;
		vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_opname,keterangan,jml_item,
			} = vItems;
			vNumber++;

			vTabelbody += `
				<tr valign="top" key=`+vKeys+`>
				<td align="right">`+vNumber+`.</td>
				<td align="left">`+
					UFunc.TglAngka(tgl_opname)+`
				</td>

				<td align="left">`+
					`<i>`+(keterangan||"-")+`</i>
				</td>

				<td align="center"><b>`+UFunc.formatAngka(jml_item)+`</b></td>
				</tr>
				`;
		}).join("");
		
		const vTabelfooter 	= `
			<td colspan="`+(vColspan-1)+`">JUMLAH</td>
			<td align="center">`+UFunc.formatAngka(uDataloadedObj.sum_item)+`</td>
		`;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadinit = () => {//->TIDAK_DIPAKAI--/
		setHtml500msg();
		setDatamainArr([]);
		setDataselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapstokopname/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDatabarangArr(JSON.parse(output_string.databarang ||"[]"));
				if(uFirstload==="YA") setFirstload("TIDAK");
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapstokopname - apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Lapstokopname - apiLoadinit) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadview = () => {
		setDataloadedObj();
		setDatamainArr([]);setDatadefaultArr([]);
		setDataselect({});
		setRowselect(-1);
		setSortingObj({});

		const vBtnsimpan	= document.getElementById("btnDialogSimpan");
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiLoadview) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson : JSON.stringify(uDlgforminputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lapstokopname/ld_view";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.tampil) {
				const vTmpArr = JSON.parse(output_string.dataview||"[]");
				setDatamainArr(vTmpArr);
				setDatadefaultArr([{init_change:"1"},...vTmpArr]);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);

				setDataloadedObj(uDlgforminputObj);
				setDlgformshow(false);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lapstokopname - apiLoadview) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lapstokopname - apiLoadview) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	//apiLoadinit();

    	return () => {
    		setDatamainArr([]); setDataloadedObj(); setDatadefaultArr([]); setDatabarangArr([]);
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA"); setSortingObj({});

			setDlgformshow(false);
    	}
	},[])
	React.useEffect(()=>{
		//console.log("(Lapstokopname-[uHandelFilter]) uHandelFilter = "+uHandelFilter)
		if(!uHandelFilter) return;
		uHeaderActionObj.isHeaderFilter = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uDlgforminputObj.headers = "Filter Laporan Stok Opname";
		setDlgformshow(true);
	},[uHandelFilter])
	React.useEffect(()=>{
		//console.log("(Lapstokopname-[isHeaderReset]) isHeaderReset = "+isHeaderReset)
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderReset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		prosesReset();
	},[uHandelReset])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
		if(uDatamainArr.length <= 0) return;

		initSUMdata(); 
	},[uDatamainArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		uElJmlmobile && (uElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		const vEls 	= document.getElementById("btnFilter");
		if(!uIsDlgformshow) { 
			initFormreset(); 
			vEls && (vEls.disabled = false);
			return; 
		}

		const vTglnowDT 	= new Date();
		if(uDlgforminputObj.cabang_id === undefined) {
			const vCabangid = localStorage.getItem("cabangidselect") || uCabangallowedArr[0].id;
			uDlgforminputObj.cabang_id = vCabangid;
		}
		if(uDlgforminputObj.bln === undefined) 
			uDlgforminputObj.bln = uDataloadedObj ? uDataloadedObj.bln : UFunc.leadZero(vTglnowDT.getMonth() + 1);
		if(uDlgforminputObj.thn === undefined) 
			uDlgforminputObj.thn = uDataloadedObj ? uDataloadedObj.thn : UFunc.leadZero(vTglnowDT.getFullYear(),4);
		if(uDlgforminputObj.barang_id === undefined) 
			uDlgforminputObj.barang_id = uDataloadedObj ? uDataloadedObj.barang_id : 0;
		if(uDlgforminputObj.barang_caption === undefined) 
			uDlgforminputObj.barang_caption = uDataloadedObj ? uDataloadedObj.barang_caption : "";

		vEls && (vEls.disabled = true);

		//console.log("("+cComponentName+"[uIsDlgformshow]) uIsDlgformshow 1: "+uIsDlgformshow);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElFocus = document.getElementById("inpcabangid");
			if(!isMobile) vElFocus && vElFocus.focus();
		},250);
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uDataloadedObj) { setTitelObj({}); return }

		const vIdxcabang	= UFunc.getObjectIndeks(uDatacabangArr,uDataloadedObj.cabang_id);
		uTitelObj.cabang 	= uDatacabangArr[vIdxcabang].nama_cabang;

		uTitelObj.periode 	= cBulanPanjang[parseInt(uDataloadedObj.bln)-1]+" "+uDataloadedObj.thn;
		
		const vArridx		= UFunc.getObjectIndeks(uDatabarangArr,uDataloadedObj.barang_id);
		if(vArridx >= 0)
			uDatabarangArr && (uTitelObj.subtitel = uDatabarangArr[vArridx].nama_barang);
	},[uDataloadedObj]);
	/*React.useEffect(()=>{//--GA_PAKE_SORT--/
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else { setRowselect(-1); setDataselect({}); }

		if(JSON.stringify(uSortingObj||{}) === "{}") { setDatamainArr(uDatadefaultArr.slice(1)); return; }

		//let vSortedArr	= [];
		const vNamasort = (uSortingObj.nama||"").toLowerCase();
		if(uSortingObj.jenis === "ASC") {
			uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]);
		}else if(uSortingObj.jenis === "DESC") {
			uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			const vDefaultsort = uDatadefaultArr.slice(1);
			setDatamainArr(vDefaultsort);//setEfekview(!uEfekview);
		}
	},[uSortingObj,
	uSortingObj.nama,
	uSortingObj.jenis,
	])//-*/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )

	//console.log("uDatamainArr => "+JSON.stringify(uDatamainArr));
	return (
		<>
		{(!uDataloadedObj) ? (
		<CCard className="classcardbg">
			<CCardHeader className="">
			<small className="fw-bolder">Pilih Filter Laporan</small>
			</CCardHeader>

			<CCardBody className="d-flex justify-content-center align-items-center" style={{minHeight:200}}>
			<div className="classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classcardbg">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<div>
			<div className="">Periode <b>{uTitelObj.periode}</b> &middot; ({uTitelObj.cabang})</div>
			<small className="text-primary">{uTitelObj.subtitel}</small>
			</div>

			<div>
				<CLink onClick={()=>hdlKlikCetak()} className="classcursorpointer classikon classikoncetak"/>
				<span className="mx-1">&middot;</span>
				<CLink onClick={()=>hdlKlikExcel()} className="classcursorpointer classikon classikonexcel"/>
				<span className="mx-1">&middot;</span>
				<span className="classfontsmaller">
					<small className="text-muted">
						{UFunc.formatAngka(uTimeelapse)}ms
					</small>
				</span>
			</div>
		</CCardHeader>

		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgforminputObj.headers}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}
			{...props}/>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.headers}
			inputValue={uDlglookupObj.inputvalue}
			dataItemArr={uDlglookupObj.items_arr || []}
			onKlikPilihItem={hdlKlikDlglookuppilih}
			{...props}/>

		<iframe id="ifmcontentstoprint" className="d-none"></iframe>
		</>
	)
}	

export default Lapstokopname