import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CImage,
	CCardHeader,
	CCardBody,
	CCard,
	CCardFooter,
	CLink,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { MyButton } from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json');
const Error500 = (props) => {
	const uNavigate = useNavigate();

	const hdlKlikRefresh=()=>{
		const vLastURL	= localStorage.getItem("lasturl") || "";

		document.getElementById("btnRefresh") 
			&& (document.getElementById("btnRefresh").disabled = true);
    	if(vLastURL==="") {
    		window.location.replace("/");
    		return;
    	}
    	uNavigate(-1);
	}

	return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadertop px-3" style={{minHeight:64}}>
			<CHeaderToggler className="px-0"
				onClick={() => hdlKlikRefresh()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand style={{}}
				className="d-none d-md-flex me-auto">
				<b>Page Error 500</b>
			</CHeaderBrand>
		</CContainer>
		</CHeader>
			
		<div className="body flex-grow-1 px-1 px-lg-5">
			<CContainer className="">
			<CCard className="mx-1 mx-md-2 mx-md-5 text-center classcardbg">
			<CCardHeader className="">
			{(() => {
				const vIcon = (
					<CIcon className="classikon classikonlogo" 
						style={{margin:0}}
						height={40}/>
				)
				const vNameArr = pjson.name.split("tor");
				return (
					<>
					<CLink href="/" style={{color:"#405d0b"}}
						className="classcursorpointer classfontbrand">
						{vNameArr[0]}t{vIcon}r{vNameArr[1]}
					</CLink>
					</>
				)
			})()}
			</CCardHeader>
			<CCardBody className="px-2 px-lg-5">
			<CImage src={window.location.origin+"/images/img_error500.png"} height="250" />
			<div id="idcontentmsg" className="mt-3">{UFunc.renderHTML((pjson.mydefault.msg500page||"UNDF").join(""))}</div>
			</CCardBody>
			<CCardFooter>
				<MyButton 
					iconname="cilReload" 
					id="btnRefresh"
					onClick={()=>hdlKlikRefresh()}>Refresh</MyButton>
			</CCardFooter>
			</CCard>
			</CContainer>
		</div>
		</div>
	)
}

export default Error500
