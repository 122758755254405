import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CButton,
  CImage,
  CRow
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import { MyButton } from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json');
const Login = (props) => {
	const {
		setToken,setLoading, showToast,setSessionaktif
	} = props; 

	//console.log("Login - props => "+JSON.stringify(props));
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uBahasaob  		= useSelector(state => state.listBahasa);
	const uRouteProfileArr	= useSelector(state => state.profileRoute); 
	const uListnav			= useSelector(state => state.listNav)
	const uTokenObj			= JSON.parse(localStorage.getItem("token")||"{}") ;

	const uCabangArr	 	= JSON.parse(localStorage.getItem("cabanglist") || "[]");
	const [uInpcabangID,setInpcabangID] = React.useState(localStorage.getItem("cabangidselect") || "0");
	const [uInpUsername,setUsername]  	= React.useState(localStorage.getItem("lastusername") || "");
	const [uInpPasswd,setInpPasswd]   	= React.useState('');
	const [uBtnDisabled,setBtnDisabled]	= React.useState(true);

	const initBrandname	= () =>{
		const vIcon = (
			<CIcon className="classikon classikonlogo" 
				style={{margin:0}}
				height={40}/>
		)
		const vNameArr = pjson.name.split("tor");
		return (
			<>
			<span className="classfontbrand">
				{vNameArr[0]}t{vIcon}r{vNameArr[1]}
			</span>
			{" "}
			<small>v{pjson.version}</small>
			</>
		)//>
	}

	const hdlKlikLogin = () => {
		//---VALIDASI--/
		if(uInpUsername.trim() === "" || uInpPasswd === "") return;
		//---END VALIDASI--/

		apiProseslogin();
	}

	const apiProseslogin = () => {
		setLoading(true);
		setBtnDisabled(true);

		/*//--TEST_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return;
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid : uInpcabangID,
			send_username : uInpUsername,
			send_passwd : uInpPasswd,
		});
		const vURLs	= pjson.homepage+"api/api_login/pr_login";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setLoading(false);
			setBtnDisabled(false);
			//console.log("(Login - apiProseslogin) output_string : "+JSON.stringify(output_string));

			if(output_string.sukses) {
				setSessionaktif(true);
				setBtnDisabled(true);
				uDispatch({type: "set", listBahasa: JSON.parse(output_string.langs||JSON.stringify(uBahasaob))});
				uDispatch({type: "set", listNav: JSON.parse(output_string.nav||JSON.stringify(uListnav))});
				if(output_string.routes) {
					const vRouteLoadArr = JSON.parse(output_string.routes||"[]");
					const vNewRoutes	= [...uRouteProfileArr,...vRouteLoadArr];
					localStorage.setItem("routeslist", JSON.stringify(vNewRoutes));
				}//-*/
				localStorage.setItem("cabangidselect",(uInpcabangID||"0"));
				localStorage.setItem("lastusername",(uInpUsername||"").trim());
				uDispatch({type: "set", gSystemsetting: JSON.parse(output_string.systemsetting || "{}")});
				uDispatch({type: "set", gSystemdata: JSON.parse(output_string.systemdata || "{}")});
					
				setToken(JSON.parse(output_string.token||"{}"));
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Login - apiProseslogin) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
			}
		})
		.catch((error) =>{
			setBtnDisabled(false);
			setLoading(false);
			console.log("(Login - apiProseslogin) catch-error: "+error);
		});
	}

	React.useEffect(() => {
		const vElWatermark = document.getElementById("idlogowatermark")
		vElWatermark && (vElWatermark.style.display = 'none')

		//localStorage.removeItem("lastusername")

		let vElfocus = document.getElementById("inpusername")
		if(uInpUsername.trim() !== "")
			vElfocus = document.getElementById("inppasswd")

		if(!isMobile) vElfocus.focus()

		return () => {
			vElWatermark && (vElWatermark.style.display = 'block')
		}
	},[])
	React.useEffect(() => {
		setBtnDisabled(true);
		if((parseInt(uInpcabangID)||0) > 0
			&& uInpUsername.trim() !== "" 
			&& uInpPasswd !== "") setBtnDisabled(false);
	},[uInpUsername,uInpPasswd,uInpcabangID])

	if(!UFunc.isEmpty(uTokenObj)) {
		window.location.replace("/"); return;
	}

	return (
		<>
		<div className="classbglogintop" style={{
			backgroundImage:"url(images/bg_gradient_bottom.png)"
			}}/>
		<div className="classbgloginwatermark" style={{
			backgroundImage:"url(images/bg_waterkmark.png)"
			}}/>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
			<div className="body flex-grow-1 px-3 d-flex align-items-center">
			<CRow className="justify-content-center w-100 mx-auto">
			<CCol xs="11" md="3" className="d-none d-md-flex flex-row align-items-center">
				<CImage src={"images/logo192.png"} height={175} className="m-auto" />
			</CCol>
			<CCol md="1" lg="0" className="border-end"/>

			<CCol xs="11" md="6" lg="5" className="px-1 px-lg-4">

			<CForm className="px-md-4 px-lg-5">
				<center>
				{initBrandname()}
				<div className="classfontsmaller fst-italic">
					{UFunc.renderHTML(uBahasaob.petunjuk_login || "Silahkan Login untuk Masuk dan<br />Menggunakan Aplikasi Ini")}
				</div>
				</center>

				<CInputGroup className="my-2">
					<CInputGroupText>
					<CIcon icon="cilBuilding"/>
					</CInputGroupText>
					<CFormSelect
						onChange={(e)=>setInpcabangID(e.target.value)} 
						value={uInpcabangID}
						id="inpcabangid">
						<option value="0">--PILIH CABANG--</option>
						{uCabangArr.map((vItems,vKeys)=>{
							return (
							<option value={vItems.id} key={vKeys}>{(vItems.nama_cabang||"UNDF").toUpperCase()}</option>
							)
						})}
					</CFormSelect>
				</CInputGroup>

				<CInputGroup className="my-2">
					<CInputGroupText>
					<CIcon icon="cilUser" className=""/>
					</CInputGroupText>
					<CFormInput type="text" 
						onChange={(e)=>setUsername(e.target.value)} 
						placeholder={(uBahasaob.word_username||"Username")+".."} 
						autoComplete="off" 
						value={uInpUsername}
						id="inpusername"
						className="form-control"
						required/>
				</CInputGroup>

				<CInputGroup className="my-2">
					<CInputGroupText>
					<CIcon icon="cilLockLocked" className=""/>
					</CInputGroupText>
					<CFormInput type="password" 
						onChange={(e)=>{setInpPasswd(e.target.value);}} 
						placeholder={(uBahasaob.word_passwd||"Password")+".."} 
						id="inppasswd"
						autoComplete="current-password" required />
				</CInputGroup>

				<CInputGroup className="my-2 my-md-4">
				<MyButton
					className="mx-auto mx-md-0"
					iconname="cilLockUnlocked"
					onClick={()=>hdlKlikLogin()}
					disabled={uBtnDisabled}
					id="btnLogin">L o g i n</MyButton>
				</CInputGroup>
				<div className="text-center">
					<span className="fst-italic">
					{uBahasaob.caption_lupapasswd||"Lupa Password"}?
					</span>
					<span className="mx-1"/>
					<b className="text-capitalize classfontcolor">Hubungi {(uBahasaob.hakuser_pengelola || "Supervisor").toLowerCase()}</b>
				</div>
			</CForm>
			</CCol>
			</CRow>
			</div>
		</div>	
		</>
	)
}

export default Login
