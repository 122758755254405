import { createStore } from 'redux';

const initialState = {
	sidebarShow: true,
	sidebarUnfoldable: true,
	activeRoute: "",
	listToast: [],
	listBahasa: [],
	listNav: [],
	listRoute: [],
	gListUserSetting: [],
	gUrlbefore:"",
	gInitHeaderAction:{},
	gInitTabelSelected:{},
	gSystemsetting:{},
	gSystemdata:{},
	gIsScrollBottom: false,
	profileRoute:[
		{"path" : "/a1profile","exact" : true,"name":"a1profile"},
	],
	gHTMLstyleLaporan: `
		<style>
		@page {
			size: auto;
			margin: 5mm 5mm 5mm 5mm !important;
		}
		@media print {
			table#idtabelhtml {
				font-family: Calibri,Arial,Sans Serif;/**/
				width:100%;
				margin: 0 auto;
				border-spacing: 0;
				padding: 0;
				text-align: center;
				display: table;
			}
			table#idtabelhtml caption {
			}
			table#idtabelhtml thead tr td {
				padding:10px 3px;
				font-weight:bolder;
				font-size:larger;
			}
			table#idtabelhtml thead tr th {
				border-top: 2px solid grey;border-bottom: 1px solid grey;
				padding: 10px 3px;
			}
			table#idtabelhtml tbody td {
				padding: 3px; border-bottom: 1px solid #f0f0f0;
				overflow-wrap: break-word; word-wrap: break-word; -ms-word-break: break-all;
				word-break: break-all; word-break: break-word;
				-ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;
				hyphens: auto;
			}
			table#idtabelhtml tbody td .classpetunjuk {
				font-size:smaller;font-style:italic;margin-top:3px;
				border-top:1px dashed #DCDCDC;padding:3px 7px;
				padding-bottom:0;
			}
			table#idtabelhtml tfoot td	{
				border-bottom: 2px solid grey;border-top: 1px solid grey;
				padding: 5px 3px;
				font-weight:bolder;
			}
		}
		</style>`,
	gHTMLstyleNota:`
		<style>
		@page {
			size: auto;
			margin: 0;
		}
		@media print {
			html,body {
				width: _LEBARKERTAS_;
				font-family: _FONTFAMILY_;/*Arial, Helvetica,sans-serif;/**/
				font-size: _FONTSIZE_px;
				margin: 0;
				padding: 0;
				margin-top: 1mm !important;
				margin-right: 1mm !important;
				margin-left: _MARGINKIRI_mm !important;
			}
			table.classtabelhtml {
				font-family: _FONTFAMILY_;/*Arial, Helvetica,sans-serif;/**/
				width: 100%;
				margin: 0 auto;
				border-spacing: 0;
				padding: 0;
				font-size: _FONTSIZE_px;
				text-align: left;
				display: table;
			}
			hr, table.classtabelhtml hr {
				border-top-style: dashed;
				border-top-width: 0.075em;
				border-left: none;
				border-right: none;
				border-bottom: none;
			}
			table.classtabelhtml caption {
				font-weight:bolder;
				padding: 7px;
			}
			table.classtabelhtml thead tr th {
				font-weight:bolder;
				text-align:center;
				border-bottom: 0.075em solid black;
				padding: 3px;
			}
			table.classtabelhtml tbody td {
				padding: 3px; 
				overflow-wrap: break-word; word-wrap: break-word; -ms-word-break: break-all;
				word-break: break-all; word-break: break-word;
				-ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto;
				hyphens: auto;
			}
			.classborderbox {
				border: 0.05em solid black !important;
			}
			.classborderside {
				border-right: 0.05em solid black !important;
				border-left: 0.05em solid black !important;
			}
			.classborderbottomtabel {
				border-bottom: 0.075em solid black !important;
			}
			.classcetakdnone {
				display:none;
			}
			table.classtabelhtml tbody td .classpetunjuk {
				font-size:smaller;font-style:italic;margin-top:3px;
				border-top:0.05em dashed #DCDCDC;padding:3px 7px;
				padding-bottom:0;
			}
			table.classtabelhtml tfoot td	{
				border-bottom: 2px solid grey;border-top: 0.075em solid grey;
				padding: 5px 3px;
				font-weight:bolder;
			}
		}
		</style>`,
};

const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case 'set':
			return {...state, ...rest }
		default:
			return state
	}
}

const store = createStore(changeState);
export default store;