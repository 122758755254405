import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialogform,
	MyDialoglookup,
	MyPage500,
	MyProfiledetil,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'
import NumberFormat from 'react-number-format';

const pjson 	= require('../../../package.json')
const Formmasuk = (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif
	} = props;

	//--DOM--/
	const { tabel_id } 			= useParams(); 
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTabelID 					= parseInt(tabel_id)||0;
	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSystemdataObj			= useSelector(state => state.gSystemdata);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA")
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const [uFirstLoad,setFirstLoad]			= React.useState("YA");
	const [uDataloadObj,setDataloadObj]		= React.useState({})
	const [uDatainputObj,setDatainputObj]	= React.useState({})
	const [uIteminputObj,setIteminputObj]	= React.useState({})
	const [uTabeleditObj,setTabeleditObj]	= React.useState({})
	const [uISFormupdated,setFormupdated]	= React.useState(false)
	const [uJmlitem,setJmlitem]				= React.useState(0);
	const [uDatabarangArr,setDatabarangArr]	= React.useState([])

	//--DLG_FORM--/
	const [uDlgformheader,setDlgformheader]	= React.useState("")
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false)
	const [uDlglookupObj,setDlglookupObj]	= React.useState({})
	//--END DLG_LOOKUP--/

	//---HANDEL--/
	const hdlKlikBack = async() => {
		if(uISFormupdated) {
			if(await(Konfirm(
				"Field Input/Edit Sudah Ada yang Berubah.."+
				"<br/>Data yang Belum Disimpan Akan Hilang Atau Kembali Seperti Semula."+
				"<br/>"+
				"<br/><b>Apakah Anda Yakin</b> ?"+
				""))) uNavigate(-1);

			return;
		}
		uNavigate(-1);
	}
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikReset = () => {}
	const hdlKDownItemnama = (_EV) => {}
	const hdlKlikItemlookup = () => {
		uDlglookupObj.header 		= "Lookup Data Barang";
		uDlglookupObj.inputvalue 	= uIteminputObj.caption_item||"";
		uDlglookupObj.data_arr		= uDatabarangArr.map(vItems=>{
			return ({
				id: vItems.id,
				caption: vItems.nama_barang
			})
		});

		setDlglookupshow(true);
	}
	const hdlKlikItemtambah = () => {}
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgformsimpan = () => {}
	const hdlToggleDlglookup = () => { setDlglookupshow(false) }
	const hdlKlikDlglookup = () => {}
	const hdlKlikSimpanmain = () => {}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentTanggal = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg">
		<CCardHeader className="py-1 text-muted classfontsmaller">
			<small>Tanggal</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="mx-0">
			<CCol className="text-start">Tanggal Pakai</CCol>

			<CCol className="text-end">
			<CFormInput size="sm" type="date"
				value={uDatainputObj.tgl_pakai||""}
				onChange={(e)=>{uDatainputObj.tgl_pakai=e.target.value;setEfekview(!uEfekview)}}
				id="inptglmasuk"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentNota = () => {
		if(uHtml500msg) return (<></>)

		return (
		<CCard className="classcardbg">
		<CCardHeader className="py-1 text-muted classfontsmaller">
			<small>Nota</small>
		</CCardHeader>

		<CCardBody className="">
			<CRow className="mx-0">
			<CCol className="text-start">Keterangan</CCol>

			<CCol className="text-end">
			<CFormInput size="sm"
				maxLength={30}
				value={uDatainputObj.no_nota||""}
				onChange={(e)=>{uDatainputObj.no_nota=e.target.value;setEfekview(!uEfekview)}}
				className="text-uppercase"
				id="inpnonota"/>
			</CCol>
			</CRow>
		</CCardBody>
		</CCard>
		)
	}
	const contentItemform = () => {
		if(uHtml500msg) return (<></>)

		return (
		<>
		<small className="text-muted classfontsmaller">Lookup Barang</small>
		<span className="mx-1">&middot;</span>
		<div style={{width:"30%",minWidth:275}}>
		<CInputGroup size="sm">
		<CFormInput size="sm"
			placeholder="--Lookup Keyword Barang.."
			value={uIteminputObj.caption_item||""}
			onChange={(e)=>{uIteminputObj.caption_item=e.target.value;setEfekview(!uEfekview)}}
			onKeyDown={(e)=>hdlKDownItemnama(e)}
			id="inpitemnama"/>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemlookup()}
			id="btnitemlookup">
			<CIcon icon="cilMagnifyingGlass"/>
		</CButton>
		</CInputGroup>
		<div className="classpetunjuk">Nama Barang</div>
		</div>

		<span className="mx-1">&middot;</span>

		<div style={{width:"5%",minWidth:50}}>
		<CFormInput size="sm" type="number"
			value={uIteminputObj.qty||0}
			min={0}
			onChange={(e)=>{uIteminputObj.qty=e.target.value;setEfekview(!uEfekview)}}
			onFocus={(e)=>e.target.select()}
			className="text-end"
			id="inpitemqty"/>
		<div className="classpetunjuk">QTY</div>
		</div>

		<span className="mx-1">&middot;</span>
		<div>
		<CButton size="sm"
			color="dark"
			onClick={()=>hdlKlikItemtambah()}
			id="btnitemtambah">
			<CIcon icon="cibAddthis"/>
		</CButton>
		</div>
		</>
		)
	}
	const contentItem = () => {
		if(uHtml500msg) return (<></>)

		const vItemArr	= uDatainputObj.items_arr || [];
		return (
		<table className="table table-borderless table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center">
			<th width="5%" className="align-top px-0 classfontsmaller">No</th>
			<th className="align-top px-0">
			<small className="classfontsmaller">
			<CRow className="mx-0">
			<CCol md="8" className="text-start">{uBahasaob.caption_namabarang||"Nama Barang"}</CCol>
			<CCol md="2" className="">{uBahasaob.word_qty||"Qty"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</small>
			</th>
			</tr>
		</thead>

		<tbody>
		{vItemArr.map((vItems,vKeys)=>{
			const {
				id,id_barang,caption_barang,qty
			} = vItems;

			return (
			<tr id={"idtr"+vKeys} key={vKeys} className={(uTabeleditObj.row_idx===vKeys?"classrowselect":"")}>
			<td className="px-1 align-top text-end">{(vKeys+1)+"."}</td>

			<td className="px-2 px-md-0 align-top text-end">
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDataloadObj({});
		setIteminputObj({});
		setTabeleditObj({});
		setLoading(true);

		//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_firstload : uFirstLoad,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangmasuk/ld_form";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Barang - apiLoaddata) output_string.datajenisbarang : "+output_string.datajenisbarang);
				setDatabarangArr(JSON.parse(output_string.databarang || "[]"));
				if(uTabelID > 0) {
					setDatainputObj(JSON.parse(output_string.dataobject || "{}"))
				}
				if(uFirstLoad==="YA") { setFirstLoad("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Formmasuk - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Formmasuk - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);
		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		hdlToggleDlgform();
		uDispatch({type: "set", effectApp: !!uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

    	if(!isMobile) {
    		const vElfocus = document.getElementById("inptglmasuk");
    		vElfocus && vElfocus.focus();
    	}
    	return () => {
    		setDatainputObj({});
    	}
	},[])
	React.useEffect(()=>{ },[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uISDlglookupshow) {
			setDlglookupObj({}); return;
		}
	},[uISDlglookupshow])
	React.useEffect(()=>{
		if(uTabelID <= 0) return;

		setDatainputObj(uDataloadObj);
	},[uDataloadObj])
	React.useEffect(()=>{
		if(uDatainputObj.tgl_masuk === undefined)
			uDatainputObj.tgl_masuk = UFunc.DbDate();

		const vItemArr = uDatainputObj.items_arr || [];
		let vSumitem = 0;
		vSumitem = vSumitem + vItemArr.map(vItems=> vItems.qty);
		setJmlitem(vSumitem); 
	},[uDatainputObj,
	uDatainputObj.tgl_masuk,
	uDatainputObj.items_arr
	])
	React.useEffect(()=>{
		const vItemArr 		= uDatainputObj.items_arr || [];
		const vItemloadArr 	= uDataloadObj.items_arr || [];
		if(uDatainputObj.tgl_masuk !== (uDataloadObj.tgl_masuk||UFunc.DbDate())
			|| (uDatainputObj.no_nota||"") !== (uDataloadObj.no_nota||"")
			|| vItemArr.length !== vItemloadArr.length
			) setFormupdated(true);
		else setFormupdated(false);
	},[uDatainputObj,
	uDatainputObj.tgl_masuk,
	uDatainputObj.no_nota,
	uDatainputObj.items_arr
	])
	//---END EFFECT--/

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return; }

	//console.log("(Formmasuk) uNavigate => "+JSON.stringify(uNavigate))
	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop">
		<CContainer fluid className="m-0 p-2 border-bottom">
			<CHeaderToggler className="ms-1 px-2"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="d-md-flex me-auto text-normal">
				<span>Form Barang Masuk</span>
			</CHeaderNav>

			<CHeaderNav className="me-1 align-items-center">
				<MyProfiledetil {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white m-0 px-3 px-lg-4 align-items-center">
			<div/>
			<div>
			<CLink className="classikon classikonreset classcursorpointer"
				 onClick={()=>hdlKlikReset()}/>
			<span className="mx-2">&middot;</span>
			<small className="align-top d-none">Rp</small>
			<span style={{fontSize:27}} className="align-middle">
				(<span className="text-primary fw-bold">{UFunc.formatAngka(uJmlitem)}</span>)
			</span>
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1 px-md-2 px-lg-5">
		<CContainer xxl className={"px-0"}>
			{(uHtml500msg) ? (
				<MyPage500 content={uHtml500msg}/>
			) : (
			<CForm>
			<CRow className="mx-0 mb-3">
			<CCol className="px-md-0 pe-md-2">{contentTanggal()}</CCol>
			<CCol className="px-md-0 ps-md-2">{contentNota()}</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol className="px-md-0">
			<CCard className="classcardbg">
			<CCardHeader className="py-1 d-flex">{contentItemform()}</CCardHeader>
			<CCardBody>{contentItem()}</CCardBody>
			</CCard>
			</CCol>
			</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="fixed" 
			className="px-3 text-black-50 justify-content-center"  
			style={{backgroundColor:"#f0f0f0"}}>
		<MyButton
			disabled={uHtml500msg}
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaob.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>
		<MyDialoglookup
			show={uISDlglookupshow} 
			dialogHeader={uDlglookupObj.header||""}
			toggle={hdlToggleDlglookup}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr||[]}
			onKlikPilihItem={()=>hdlKlikDlglookup()}/>
		</>
	) 
}	

export default Formmasuk