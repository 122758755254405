import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,CButton,CFormCheck,
	CFormSelect,CInputGroup,CInputGroupText,CFormSwitch,
	//CImage,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cHariPanjang,cAngkaTgl } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Settingumum 	= (props) => {
	const {
		setToken,setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uSettingsystemObj			= useSelector(state => state.gSystemsetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= 5;//isMobile ? (uSettinguserObj.jml_mobile || 12) : (uSettinguserObj.jml_tabel || 30);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uDataemailObj,setDataemailObj]= React.useState({});
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const uDataemailArr		= uDataemailObj.data_arr || [];
	const uKirimemailDefault				= JSON.parse(uSettingsystemObj.emailsend_str||"{}");
	const [uKirimemailObj,setKirimemailObj]	= React.useState(uKirimemailDefault);
	const [uActionkirim,setActionkirim] 	= React.useState(false);
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgforminputObj,setDlgforminputObj]= React.useState({});
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	const uElJmlmobile		= document.getElementById("idjmldatamobile");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlKlikTambahemail = () => {
		if(uDlgforminputObj.cabangid_arr === undefined)
			uDlgforminputObj.cabangid_arr= [];//-*/
		uDlgforminputObj.size		= "lg";
		uDlgforminputObj.headers	= "Tambah Email Manajemen";
		setDlgformshow(true);
	}
	const hdlKlikEditemail = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vDataArr 		= uDataemailArr;
		const vArridx 		= UFunc.getObjectIndeks(vDataArr,_IDTABEL);
		const vCaptionupper = (vDataArr[vArridx].email||"").toUpperCase();
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);
		setDataselect({id:vDataArr[vArridx].id})

		uDlgforminputObj.email 	= (vDataArr[vArridx].email||"");
		uDlgforminputObj.cabangid_arr= (vDataArr[vArridx].cabangid_list||"").split(",");

		uDlgforminputObj.size	= "lg";
		uDlgforminputObj.headers= ("Edit Data: "+vCaptionupper);
		setDlgformshow(true);
	}
	const hdlKlikHapusemail = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vDataArr 		= uDataemailArr;
		const vArridx 		= UFunc.getObjectIndeks(vDataArr,_IDTABEL);
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionupper = (vDataArr[vArridx].email||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionupper+"</B>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiEmailhapus(_IDTABEL);
	}
	const hdlKlikDlgformsimpan = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpemail");
		if((uDlgforminputObj.email||"").trim()==="") {
			vElFocus.focus();
			showToast("Nama Jenis Harus Diisi !");return;
		}
		if((uDlgforminputObj.cabangid_arr||[]).length <= 0) {
			showToast("Data Laporan Cabang Harus Dipilih !");return;
		}

		//console.log("(User - hdlKlikDlgsimpan) vArridx = "+vArridx); 
		apiEmailsimpan(uDataselect.id||0);
	}
	const hdlKlikKirimemail = () => { apiKirimemail(); }
	//--END HANDEL--/

	//--INIT--/
	const initFormreset = () => {
		setDataselect({})
		setDlgforminputObj({});
		setEfekview(!uEfekview);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentDataemail = () => {
		if(uHtml500msg) return (<></>);

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vDataviewArr	= (uDataemailArr).slice(vMin,(vMax));

		return (
		<CCard className="classcardbg mb-3">
		<CCardHeader className="d-flex justify-content-between align-items-center">
			<div>
			<b>{uBahasaObj.caption_emailadmin||"Email Manajemen"}</b>
			<span className="mx-1">&middot;</span>
			<span className="classfontsmaller">
			<small>
			<b className="text-primary">{UFunc.formatAngka(uDataemailObj.jml_data)}</b> Data
			</small>
			</span>
			</div>

			<div>
			<CLink onClick={hdlKlikTambahemail} className="classikontabel classikontambah classcursorpointer"/>
			
			<span className="mx-1">&middot;</span>
			
			<span className="classfontsmaller text-muted">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
			</span>
			</div>
		</CCardHeader>

		<CCardBody className="px-0">
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="10" className="text-start">{uBahasaObj.caption_email||"Email"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDataemailArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,email,is_processing,deskripsi,cabangid_list,
			} = vItems;

			vNumber++;
			const vCaptionupper	= (email||"").toUpperCase();

			const vCabangviewArr= uDatacabangArr.filter(vItemcabang => 
				((cabangid_list||"").split(",")).includes(vItemcabang.id) );

			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-1 pe-0">{vNumber}.</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol md="10" className="d-none">
				<div className="text-info">{(email||"undf@undf.dnf")}</div>

				<span className="text-danger classfontsmaller">
				<small>
				[{UFunc.WaktuAngka(tgl_input||"")}
				<span className="mx-1">&middot;</span>
				{UFunc.Usercaptionfilter(userinput||"Undf")}]
				</small>
				</span>
			</CCol>

			<CCol xs="12" className="px-1">
			<div className="d-flex justify-content-between">
				<span className="text-info">{(email||"undf@undf.dnf")}</span>

				<div className="d-none d-md-block">
				<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikEditemail(id)} className="classikontabel classikonedit classcursorpointer"/>
				</CTooltip>

				{uIsHapusallow && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapusemail(id)} className="classikontabel classikonhapus classcursorpointer"/>
				</CTooltip>
				</>
				)}
				</div>

				<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikEditemail(id)}>
						<CIcon className="classikonedit me-2 align-middle" height={25}/>
						{uBahasaObj.caption_editdata||"Edit Data"}
					</CDropdownItem>
							
					{(uIsHapusallow) && (
					<>
					<CDropdownItem onClick={()=>hdlKlikHapusemail(id)}>
						<CIcon className="align-middle classikonhapus me-2" height={25}/>
						{uBahasaObj.caption_hapusdata||"Hapus Data"}
					</CDropdownItem>
					</>
					)}
				</CDropdownMenu>
				</CDropdown>
			</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_cabang||"Cabang"}</CCol>
			<CCol xs="8" md="12" className="px-1 text-start">
				<small>
				{vCabangviewArr.map((vItemcabang,vKeycabang)=>{

					return (
					<span key={vKeycabang}>
					<i>{vItemcabang.nama_cabang}</i>
					{vKeycabang < vCabangviewArr.length-1 && (
						<span className="ms-1">&middot; </span>
					)}
					</span>
					)
				})}
				</small>
			</CCol>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
			<CCol xs="8" md="12" className="px-1 text-danger text-md-start">
				<small>[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.Usercaptionfilter(userinput||"Undf")}]</small>
			</CCol>

			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</CCardBody>

		{(parseInt(uDataemailObj.jml_data||"0") > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uDataemailObj.jml_hal||1}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>
		)
	}
	const contentSetkirim = () => {
		if(uHtml500msg) return (<></>);

		const vTanggalfilter	= cAngkaTgl.filter(vItems => vItems < 29);
		return (
		<CCard className="classcardbg mb-3">
		<CCardHeader><b>Setting Waktu Pengiriman Email</b></CCardHeader>
		
		<CCardBody>
			<CForm className="my-3">
			<div className="classpetunjuk">
			CATATAN:
			<ul>
				<li>Beberapa Laporan dapat dikirimkan secara otomatis, ke beberapa EMAIL yang diinputkan..</li>
				<li>Sistem menyediakan DUA pilihan waktu pengiriman Laporan melalui EMAIL, yaitu secara MINGGUAN ataupun BULANAN.</li>
				<li>Silahkan Pilih Waktu Pengiriman yang sesuai dengan kebijakan Manajemen Anda.</li>
			</ul>
			</div>
			
			<div className="classborderbottom my-3"/>

			<CRow className="mx-0">
			<CCol xs="6" className="border-end">
				<CFormSwitch size="xl" type="radio"
					className="fw-bolder" 
					label="MINGGUAN"
					value="MINGGUAN"
					name="inpperiode"
					checked={(uKirimemailObj.periode||"MINGGUAN")==="MINGGUAN"}
					onChange={(e)=>{uKirimemailObj.periode = e.target.value;setActionkirim(true)}}
					id="inpperiodeminggu"/>

				<div className="my-3"/>
				
				<div className="d-lg-flex justify-content-lg-between">
				<div className={"fw-bolder"+(uKirimemailObj.periode!=="MINGGUAN"?" text-muted":"")}>Pilih HARI</div>
				
				<div style={{minWidth:125,width:"50%"}}>
					<CFormSelect size="sm"
						value={uKirimemailObj.no_hari||1}
						onChange={(e)=>{uKirimemailObj.no_hari=e.target.value;setActionkirim(true)}}
						id="inphari">
					{cHariPanjang.map((vItems,vKeys)=>{
						return (
						<option value={vKeys+1} key={vKeys}>{vItems.toUpperCase()}</option>
						)
					})}
					</CFormSelect>
				</div>
				</div>
			</CCol>
			<CCol xs="6">
				<CFormSwitch size="xl" type="radio"
					className="fw-bolder" 
					label="BULANAN"
					value="BULANAN"
					name="inpperiode"
					checked={(uKirimemailObj.periode||"MINGGUAN")==="BULANAN"}
					onChange={(e)=>{uKirimemailObj.periode = e.target.value;setActionkirim(true)}}
					id="inpperiodebulan"/>

				<div className="my-3"/>
				
				<div className="d-lg-flex justify-content-lg-between">
				<div className={"fw-bolder"+(uKirimemailObj.periode!=="BULANAN"?" text-muted":"")}>Pilih TANGGAL</div>
				
				<div style={{minWidth:100,width:"40%"}}>
					<CFormSelect size="sm"
						value={uKirimemailObj.no_tanggal||1}
						onChange={(e)=>{uKirimemailObj.no_tanggal=e.target.value;setActionkirim(true)}}
						id="inptgl">
					{vTanggalfilter.map((vItems,vKeys)=>{
						return (
						<option value={vItems} key={vKeys}>{UFunc.leadZero(vItems)}</option>
						)
					})}
					</CFormSelect>
				</div>
				</div>
			</CCol>
			</CRow>
			
			<div className="classborderbottom my-3"/>

			<center>
			<CButton size="sm" color="primary"
				className="px-4"
				onClick={()=>hdlKlikKirimemail()}
				id="btnKirimTest">
				<CIcon icon="cilEnvelopeLetter" className="me-2"/>
				Kirim Email Sekarang
			</CButton>
			</center>

			</CForm>
		</CCardBody>
		</CCard>
		)
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)

		return (
		<CForm className="">
		<CRow className="my-2 mx-0">
		<CCol md="3" className="">{uBahasaObj.word_email||"Email"}</CCol>
		<CCol md="5" lg="4" className="text-end">
			<CFormInput size="sm"
				maxLength={100}
				value={uDlgforminputObj.email||""}
				onChange={(e)=>{uDlgforminputObj.email = (e.target.value); setEfekview(!uEfekview);}}
				id="inpemail"/>
		</CCol>
		</CRow>

		<CRow className="my-3 mx-0">
		<CCol md="3" className="">
			<CLink onClick={()=>{
				if((uDlgforminputObj.cabangid_arr||[]).length >= uDatacabangArr.length )
					uDlgforminputObj.cabangid_arr = [];
				else
					uDlgforminputObj.cabangid_arr = uDatacabangArr.map(vItems => vItems.id);
				setEfekview(!uEfekview);
				}} 
				className="classcursorpointer">
			{uBahasaObj.caption_datacabang||"Data Cabang"}
			</CLink>
		</CCol>

		<CCol md="9" className="text-start px-1">
			<CInputGroup size="sm">
			{uDatacabangArr.map((vItems,vKeys)=>{
			return (
			<CInputGroupText style={{minWidth:165}} 
				className="mx-1 mx-md-0 me-md-1 mx-lg-1 mb-2 bg-light border-0" key={vKeys}>
			<CFormCheck size="sm"
				label={vItems.nama_cabang}
				value={vItems.id}
				checked={(uDlgforminputObj.cabangid_arr||[]).includes(vItems.id)}
				onChange={(e)=>{
					if(!(uDlgforminputObj.cabangid_arr||[]).includes(e.target.value)) {
						uDlgforminputObj.cabangid_arr.push(e.target.value);// = [...(uDlgforminputObj.cabangid_arr||[]),e.target.value];
					} else {
						(uDlgforminputObj.cabangid_arr||[]).splice(
							(uDlgforminputObj.cabangid_arr||[]).indexOf(e.target.value),
							1);
					}
					setEfekview(!uEfekview);
				}}
				id={"inpcabangid"+vItems.id}
				name="inpcabangid"/>
			</CInputGroupText>
			)
			})}
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDataemailObj({});
		setDataselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_settingumum/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				setDataemailObj({ data_arr : JSON.parse(output_string.dataemail||"[]") });
				//console.log("(Settingumum - apiLoaddata) vTmpArr : "+JSON.stringify(uDataemailObj.data_arr));
				//setEfekview(!uEfekview);
				
				if(uFirstload==="YA") setFirstload("TIDAK");

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Settingumum - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Settingumum - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiEmailsimpan = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0;

		const vArridx 	= UFunc.getObjectIndeks(uDataemailArr,_IDTABEL);
		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiEmailsimpan) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_datajson : JSON.stringify(uDlgforminputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_settingumum/pr_simpanemail";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTABEL <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||255,
						tgl_input: "",
						cabangid_list: (uDlgforminputObj.cabangid_arr||[]).join(","),
						userinput: uTokenObj.usercaption,
						email:uDlgforminputObj.email,
					}
					uDataemailObj.data_arr	= [vTmpObj,...uDataemailArr];
					initFormreset();

					setPageActive(1);
					setRowselect(-1);
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");

					const vElFocus = document.getElementById("inpemail");
					vElFocus && vElFocus.focus();
				} else {
					uDataemailArr[vArridx].cabangid_list= (uDlgforminputObj.cabangid_arr||[]).join(",");
					uDataemailArr[vArridx].email 	= uDlgforminputObj.email;
					setDlgformshow(false);
				}
				//setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Settingumum - apiEmailsimpan) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Settingumum - apiEmailsimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiEmailhapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDataemailArr,_IDTABEL);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiEmailsimpan) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_settingumum/pr_hapusemail";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				(uDataemailObj.data_arr||[]).splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Settingumum - apiEmailhapus) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Settingumum - apiEmailhapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiSetemail = () => {
		const vTmpsimpanObj = {
			periode : uKirimemailObj.periode,
			no_tanggal: uKirimemailObj.periode==="MINGGUAN"?undefined:uKirimemailObj.no_tanggal,
			no_hari: uKirimemailObj.periode==="BULANAN"?undefined:uKirimemailObj.no_hari,
		}
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(setTimeout - apiSetemail) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false)
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_datajson: JSON.stringify(vTmpsimpanObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_settingumum/pr_setkirim";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				setKirimemailObj(vTmpsimpanObj);

				const vSettingsystemObj = uSettingsystemObj;
				vSettingsystemObj.emailsend_str = JSON.stringify(vTmpsimpanObj);
				uDispatch({type: "set", gSystemsetting: vSettingsystemObj});
				setEfekview(!uEfekview);
			} else {
				setKirimemailObj(uKirimemailDefault);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Settingumum - apiSetemail) output_string.errors : "+output_string.errors);
					const vMsg	= (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setDlgformshow(false); setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			setKirimemailObj(uKirimemailDefault);
			setLoading(false);
			console.log("(Settingumum - apiSetemail) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiKirimemail = () => {
		const vElbutton	= document.getElementById("btnKirimTest");
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(setTimeout - apiKirimemail) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false)
			vElbutton && (vElbutton.disabled = false);

			showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_x: "x",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_settingumum/pr_kirimmanual";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vElbutton && (vElbutton.disabled = false);
			if(output_string.sukses) {
				showToast(uBahasaObj.pesan_sukses||"Berhasil !","SUKSES");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Settingumum - apiKirimemail) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setKirimemailObj(uKirimemailDefault);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(Settingumum - apiKirimemail) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA");setDlgformshow(false);
    		setDataemailObj({});
    		setPageActive(1);

			setKirimemailObj(uKirimemailDefault); 
    	}
	},[])
	React.useEffect(()=>{
		//console.log("(Settingumum-[uHandelView]) uHandelView = "+uHandelView)
		if(!uHandelView) return;

		const vKeyword = (uKeywordObj.kwd_barangjenis||"").trim();
		if(vKeyword === "") setFirstload("YA");
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("(Settingumum-[uHandelTambah]) uHandelTambah = "+uHandelTambah)
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		uDlgforminputObj.headers = ((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setDlgformshow(true);
	},[uHandelTambah])
	React.useEffect(()=>{
		//console.log("(Settingumum - [uDataemailObj]) uDataemailObj : "+JSON.stringify(uDataemailObj));
		uDataemailObj.jml_data	= uDataemailArr.length;
	},[uDataemailObj,
	uDataemailArr,
	uDataemailArr.length
	])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil((uDataemailObj.jml_data||0) / uMaxData);
		uDataemailObj.jml_hal = vJmlHal;

		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uDataemailObj.jml_data||0));
		uElJmlmobile && (uElJmlmobile.innerHTML = UFunc.formatAngka(uDataemailObj.jml_data||0));
	},[uDataemailObj.jml_data])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsDlgformshow) { initFormreset(); return; }

		//console.log("("+cComponentName+"[uIsDlgformshow]) uIsDlgformshow 1: "+uIsDlgformshow);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElFocus = document.getElementById("inpemail");
			if(!isMobile) vElFocus && vElFocus.focus();
		},250);
	},[uIsDlgformshow])//-*/
	React.useEffect(()=>{
		if(uKirimemailObj.periode === undefined)
			uKirimemailObj.periode = "MINGGUAN";
		if(uKirimemailObj.no_hari === undefined)
			uKirimemailObj.no_hari = 1;
		if(uKirimemailObj.no_tanggal === undefined)
			uKirimemailObj.no_tanggal = 1;
	},[uKirimemailObj,
	uKirimemailObj.periode,
	uKirimemailObj.no_hari,
	uKirimemailObj.no_tanggal,
	])
	React.useEffect(()=>{
		if(uKirimemailObj.periode === undefined) return;

		const vELhari = document.getElementById("inphari");
		const vELtgl = document.getElementById("inptgl");
		if(uKirimemailObj.periode === "MINGGUAN") {
			vELhari && (vELhari.disabled = false);
			vELtgl && (vELtgl.disabled = true);
		} else {
			vELhari && (vELhari.disabled = true);
			vELtgl && (vELtgl.disabled = false);
		}
	},[uKirimemailObj.periode]);
	React.useEffect(()=>{ 
		if(!uActionkirim) return;
		setActionkirim(false);

		apiSetemail(); 
	},[uActionkirim]);

	//console.log("(Settingumum) uDataemailArr => "+JSON.stringify(uDataemailArr));

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg} isWithrefresh={true} hdlReset={apiLoaddata}/> )

	return (
		<>
		<CRow className="mx-0">
		<CCol md="6" className="px-1 px-lg-2">{contentDataemail()}</CCol>
		<CCol md="6" className="px-1 px-lg-2">{contentSetkirim()}</CCol>
		</CRow>

		<MyDialogform
			options={{size:uDlgforminputObj.size,centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgforminputObj.headers}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>
		</>
	)
}	

export default Settingumum