import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	//CImage,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyPage500,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Barangjenis 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 12) : (uSettinguserObj.jml_tabel || 30);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DATA_TABEL--/
	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uDlgformheader,setDlgformheader]	= React.useState("");
	const [uDlgforminputObj,setDlgforminputObj]= React.useState({});
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	const uElJmlmobile		= document.getElementById("idjmldatamobile");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlDlgformtoggle = () => { setDlgformshow(false) };
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vCaptionupper = (uDatamainArr[vArridx].nama_jenis||"").toUpperCase();
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);
		setDataselect({id:uDatamainArr[vArridx].id})

		uDlgforminputObj.nama_jenis= (uDatamainArr[vArridx].nama_jenis||"");

		setDlgformheader("Edit Data: "+vCaptionupper);
		setDlgformshow(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vArridx 		= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vIdxRow		= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionupper = (uDatamainArr[vArridx].nama_jenis||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionupper+"</B>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikDlgformsimpan = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpnamajenis");
		if((uDlgforminputObj.nama_jenis||"").trim()==="") {
			vElFocus.focus();
			showToast("Nama Jenis Harus Diisi !");return;
		}

		//console.log("(User - hdlKlikDlgsimpan) vArridx = "+vArridx); 
		apiProsessimpan(uDataselect.id||0);
	}
	//--END HANDEL--/

	//--INIT--/
	const initFormreset = () => {
		setDataselect({})
		setDlgforminputObj({});
		setEfekview(!uEfekview);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_barangjenis || "");
		const vKeywordcolor = uSettinguserObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<div className="mx-lg-5 px-lg-5">
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="10" className="text-start">{uBahasaObj.caption_jenisbarang||"Jenis Barang"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>
			<tr className="d-none"><th colSpan={2}>{JSON.stringify(uDatamainArr)}</th></tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,nama_jenis
			} = vItems;

			vNumber++;
			const vCaptionupper	= (nama_jenis||"").toUpperCase();

			const vNamajenis	= vKeyword==="" ? (nama_jenis||"") 
				: (nama_jenis||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end "+(uRowselect===vKeys?"classrowselect":"")}>
			<td className="px-0 pe-1">{vNumber}.</td>

			<td className="px-1">
			<CRow className="mx-0">
			<CCol md="10" className="d-none d-md-block px-1 text-md-start">
				<div><big className="text-info text-capitalize">{UFunc.renderHTML(vNamajenis)}</big></div>

				<small className="text-danger classfontsmaller">
				[{UFunc.WaktuAngka(tgl_input||"")}
				<span className="mx-1">&middot;</span>
				{UFunc.renderHTML(vUserinput)}]
				</small>
			</CCol>

			<CCol xs="12" className="px-1 d-md-none">
			<div className="d-flex justify-content-between">
				<span className="text-info text-capitalize">{UFunc.renderHTML(vNamajenis)}</span>

				<CDropdown className="d-md-none align-items-start">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" 
					color="transparent">
					<CIcon icon="cilOptions" className="classikonbox" height={20}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
						<CIcon className="classikonedit me-2 align-middle" height={25}/>
						{uBahasaObj.caption_editdata||"Edit Data"}
					</CDropdownItem>
							
					{(uIsHapusallow) && (
					<>
					<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
						<CIcon className="align-middle classikonhapus me-2" height={25}/>
						{uBahasaObj.caption_hapusdata||"Hapus Data"}
					</CDropdownItem>
					</>
					)}
				</CDropdownMenu>
				</CDropdown>
			</div>
			</CCol>

			<CCol xs="12" className="px-1 my-1 d-md-none classborderbottom"/>

			<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
			<CCol xs="8" className="px-1 text-danger d-md-none">
				<small className="classfontsmaller">[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
			</CCol>

			<CCol md="2" className="px-0 text-md-center d-none d-md-block">
				<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
				</CTooltip>

				{uIsHapusallow && (
				<>
				<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionupper}>
					<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
				</CTooltip>
				</>
				)}
			</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		)//>
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		return (
		<CForm className="mx-md-1 mx-lg-5 px-lg-5">
		<CRow className="my-2">
		<CCol md="4" className="text-md-end">{uBahasaObj.caption_namajenis||"Nama Jenis"}</CCol>
		<CCol md="8" className="">
			<CFormInput size="sm"
				maxLength={50}
				className="text-capitalize"
				value={uDlgforminputObj.nama_jenis||""}
				onChange={(e)=>{uDlgforminputObj.nama_jenis = (e.target.value); setEfekview(!uEfekview);}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpnamajenis"/>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();
		setDatamainArr([]);
		setDataselect(0);
		setRowselect(-1);
		setPageActive(1);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj		= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_barangjenis || "";
		const vDATAS	= JSON.stringify({
			send_firstload : uFirstload,
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangjenis/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				if(uFirstload==="YA") { setFirstload("TIDAK"); }

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Barangjenis - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Barangjenis - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0;

		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiProsessimpan) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_datajson : JSON.stringify(uDlgforminputObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangjenis/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTABEL <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||255,
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						nama_jenis:uDlgforminputObj.nama_jenis,
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);

					initFormreset();
					setPageActive(1);
					setRowselect(-1);
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");

					const vElFocus = document.getElementById("inpnamajenis");
					vElFocus && vElFocus.focus();
				} else {
					uDatamainArr[vArridx].nama_jenis 	= uDlgforminputObj.nama_jenis;
					hdlDlgformtoggle();
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Barangjenis - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Barangjenis - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL 		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArridx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL);
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiProsessimpan) LEWAT "); 
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_barangjenis/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vArridx,1);
				setRowselect(-1);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Barangjenis - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setDlgformshow(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Barangjenis - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setFirstload("YA");
    		setDatamainArr([]);
			hdlDlgformtoggle();
    	}
	},[])
	React.useEffect(()=>{
		//console.log("(Barangjenis-[uHandelView]) uHandelView = "+uHandelView)
		if(!uHandelView) return;

		const vKeyword = (uKeywordObj.kwd_barangjenis||"").trim();
		if(vKeyword === "") setFirstload("YA");
		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("(Barangjenis-[uHandelTambah]) uHandelTambah = "+uHandelTambah)
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		if(uHtml500msg) return;

		setDlgformheader((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setDlgformshow(true);
	},[uHandelTambah])
	React.useEffect(()=>{
		setJmlData(uDatamainArr.length);
		if(uDatamainArr.length <= 0) return;
	},[uDatamainArr,uDatamainArr.length])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
		uElJmlmobile && (uElJmlmobile.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uIsDlgformshow) { initFormreset(); return; }

		//console.log("("+cComponentName+"[uIsDlgformshow]) uIsDlgformshow 1: "+uIsDlgformshow);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
			const vElFocus = document.getElementById("inpnamajenis");
			vElFocus && vElFocus.focus();
		},250);
	},[uIsDlgformshow])//-*/
	//React.useEffect(()=>{ },[uDlgforminputObj])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 content={uHtml500msg}/> )//>

	return (
		<>
		<CCard className="classcardbg mx-lg-5">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<strong>{uBahasaObj.caption_listdata||"List Data"}</strong>
		<div>
		<small className="me-2 d-md-none">
		(<b className="text-primary" id="idjmldatamobile">{UFunc.formatAngka(uJmlData)}</b>) &middot;
		</small>
		<span className="classfontsmaller"><small className="text-muted">{UFunc.formatAngka(uTimeelapse)}ms</small></span>
		</div>
		</CCardHeader>

		<CCardBody className="px-0 px-lg-5">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
		</CCardFooter>
		)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlDlgformtoggle}
			renderContent={contentDlgform()}/>
		</>
	)//>
}	

export default Barangjenis