import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CRow,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormSwitch,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CImage,
  CAvatar,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
} from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json')
const Role = (props) => {
	const {
		setToken, setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	const uRoledefaultArr = [
		{id:"1",value:"MANAJER",caption:"MANAJER",imgsrc:pjson.homepage+"api/images/img_manajer.png"},
		{id:"2",value:"GUDANG",caption:"GUDANG",imgsrc:pjson.homepage+"api/images/img_gudang.png"}
	];
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDataroleArr,setDataroleArr]	= React.useState(uRoledefaultArr);

	const hdlChangerole = (_IDMENU,_IDROLE) => {
		_IDMENU = parseInt(_IDMENU)||0
		_IDROLE = parseInt(_IDROLE)||0
		if(_IDMENU <= 0||_IDROLE <= 0) return

		apiProsesrole(_IDMENU,_IDROLE)
	}

	const initContentmain = () => {
		if(uHtml500msg) 
			return (<div className="mx-1 mx-md-2 mx-lg-5">{UFunc.renderHTML(uHtml500msg)}</div>);

		const vWidthcol	= Math.round((100-25-3) / (uDataroleArr.length))
		return (
		<div className="mx-0">
		<table className="table table-borderless" id="idtabeldata">
		<thead className="text-center classborderbottom">
			<tr>
			<th width="3%" className="align-middle text-end px-1">&nbsp;</th>
			<th width="25%" className="align-middle px-1 text-start">
				Nama Menu
			</th>
			{uDataroleArr.map((vItems,vKeys)=>{
				return (
				<th width={vWidthcol+"%"} key={vKeys} className="align-middle px-0">
				<CAvatar src={vItems.imgsrc} size="sm"/>
				<div>{vItems.caption}</div>
				</th>
				)
			})}
			</tr>
		</thead>
		<tbody>
		{uDatamainArr.map((vItems,vKeys)=>{
			const vLevels = parseInt(vItems.levels)||1;

			const vClassadd = vLevels < 2 ? "fw-bold text-uppercase"
				: "ps-3 "+(vLevels > 2 ? "ps-5 classfontsmaller " : "text-info "); 

			const vRolearr	= vItems.rolearr ||[];
			return (
			<tr className="classborderbottom" key={vKeys}>
			<td className="align-middle text-end px-1">&nbsp;</td>
			<td className={"align-middle text-start px-0 "+vClassadd}>{vItems.caption}</td>
			{vRolearr.map((vItemrole,vKeyrole)=>{
				return (
				<td className="align-middle text-center px-1" key={vKeyrole}>
				<CFormSwitch size="lg"
					checked={vItemrole.role_akses||false}
					className="d-inline-block"
					onChange={()=>hdlChangerole(vItems.id,vItemrole.role_id)}
					name="inproles"
					id={"inprole"+vItems.id+"_"+vItemrole.role_id}/>
				</td>
				)
			})}
			</tr>
			)
		})}
		</tbody>
		</table>
		</div>
		);
	}

	const apiLoaddata = () => {
		setHtml500msg("");

		setDatamainArr([]);
		setDataroleArr(uRoledefaultArr);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_role/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		//}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(Role - apiLoaddata) output_string.dataobject : "+output_string.dataobject);
				//console.log("(Role - apiLoaddata) output_string.datarole : "+output_string.datarole);
				setDatamainArr(JSON.parse(output_string.dataobject||"[]"));
				if(output_string.datarole)
					setDataroleArr(JSON.parse(output_string.datarole));

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Role - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Role - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsesrole = (_IDMENU,_IDROLE) => {
		_IDMENU = parseInt(_IDMENU)||0
		_IDROLE = parseInt(_IDROLE)||0
		if(_IDMENU <= 0||_IDROLE <= 0) return

		const vElrole	= document.getElementsByName("inproles");

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDMENU);
		const vRolearr	= uDatamainArr[vIdxObj].rolearr||[];
		const vIdxRole	= UFunc.getObjectIndeks(vRolearr,_IDROLE,"role_id");
		(uDatamainArr[vIdxObj].rolearr||[])[vIdxRole].role_akses = !((uDatamainArr[vIdxObj].rolearr)[vIdxRole].role_akses);

		//---CREATE_DATASEND--/
		const vRoleID			= _IDROLE;
		const vRoleSelectArr	= [];
		uDatamainArr.forEach((vItems,vKeys)=>{
			const vTmproleArr	= vItems.rolearr || [];
			vTmproleArr.forEach((vItemrole,vKeyrole)=>{
				if((vItemrole.role_id||0).toString() === vRoleID.toString())
					if((vItemrole.role_akses||false)===true)
						vRoleSelectArr.push(vItems.id);
			});
		});
		const vRoleselectStr	= vRoleSelectArr.join(",");
		//console.log("(Role - apiProsesrole) vRoleselectStr => "+(vRoleselectStr));
		//---END CREATE_DATASEND--/

		setEfekview(!uEfekview);
		vElrole && (vElrole.disabled = true);
		setLoading(true);

		/*//---TEST_FRONTEND---/
		let vTimeout = setTimeout(()=>{
			vElrole && (vElrole.disabled = false);
			setLoading(false);
	
			//(uDatamainArr[vIdxObj].rolearr||[])[vIdxRole].role_akses = !((uDatamainArr[vIdxObj].rolearr)[vIdxRole].role_akses);
		},2000); return
		//---END TEST_FRONTEND--*/

		if((uTokenObj.userinit||"") === "")
			{ prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_tabelid : vRoleID,
			send_modullist : vRoleselectStr,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_role/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		//}).then((response)=> { if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setSessionaktif(true);
			vElrole && (vElrole.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
			} else {
				(uDatamainArr[vIdxObj].rolearr||[])[vIdxRole].role_akses = !((uDatamainArr[vIdxObj].rolearr)[vIdxRole].role_akses);
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(User - apiProsessimpan) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			vElrole && (vElrole.disabled = false);
			setLoading(false);

			(uDatamainArr[vIdxObj].rolearr||[])[vIdxRole].role_akses = !((uDatamainArr[vIdxObj].rolearr)[vIdxRole].role_akses);
			console.log("(User - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		apiLoaddata();

		return ()=> {setDatamainArr([]); setDataroleArr([])}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;

		apiLoaddata();

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
	},[uHandelView])
	
	if(UFunc.isEmpty(uTokenObj)) {
		setLoading(true); prosesExpired(); return;
	}
	
	//console.log("Role - uActiveroute => "+(uActiveroute));
	return (
	<CCard>
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<strong>{"Atur Role Akses Pengguna Sistem"}</strong>
		<small className="text-muted classfontsmaller">{UFunc.formatAngka(uTimeelapse)}ms</small>
		</CCardHeader>
		<CCardBody className="px-0">{initContentmain()}</CCardBody>
	</CCard>
	)
}	

export default Role