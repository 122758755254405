import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CCard,CCardHeader,CCardBody,CCardFooter,
	CForm,
	CFormInput,
	//CFormSelect,CInputGroup,CInputGroupText,CFormCheck,
	CImage,
	CLink,
	CTooltip,
	CWidgetStatsD,CWidgetStatsB,CWidgetStatsC,CWidgetStatsF,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
	CProgress,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPage500,
} from '../components/index'
import { CChart,CChartLine } from '@coreui/react-chartjs';
import { getStyle, hexToRgba } from '@coreui/utils';
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'
//import NumberFormat from 'react-number-format';

const pjson 		= require('../../package.json')
const Dashboard 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif,
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj 			= useSelector(state => state.gListUserSetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 12) : (uSettinguserObj.jml_tabel || 30);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="SUPERADMIN"||uTokenObj.userhak==="PENGELOLA");// ? true : false;
	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--DATA_TABEL--/
	/*const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uDataselect,setDataselect]	= React.useState({});//-*/

	const uKeywordObj					= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const [uDatawidgetObj,setDatawidgetObj]	= React.useState({});
	//const [uDatagrafikArr,setDatagrafikArr]	= React.useState([]);
	const [uLoadingObj,setLoadingObj]	= React.useState({});
	const [uMsgvarObj,setMsgvarObj]		= React.useState({});
	const vFilterObj					= JSON.parse(uKeywordObj.filter_dashboard || "{}");
	//--END DATA_TABEL--/

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	//--END DLG_VIEW--/

	//--DOM_VARS--/
	const uElJmlData		= document.getElementById("idjmldata");
	const uElJmlmobile		= document.getElementById("idjmldatamobile");
	//--END DOM_VARS--/

	//--HANDEL--/
	//--END HANDEL--/

	//--INIT--/
	const initResetdata = () => {
		setHtml500msg();
		//setDatagrafikArr([]);
		//setDatawidgetObj({});

		uDatawidgetObj.grafiksales 		= null;
		uDatawidgetObj.salesmonth 		= null;
		uDatawidgetObj.salesrevenue 	= null;
		uDatawidgetObj.topalertstok 	= null;
		uDatawidgetObj.topproductsales 	= null;
		uDatawidgetObj.laststokopname 	= null;
		uDatawidgetObj.jmlpakai 		= null;
		uDatawidgetObj.jmlpenunjang 	= null;
		uDatawidgetObj.feenurse			= null;

		uMsgvarObj.grafiksales 		= null;
		uMsgvarObj.salesmonth 		= null;
		uMsgvarObj.salesrevenue 	= null;
		uMsgvarObj.topalertstok 	= null;
		uMsgvarObj.topproductsales 	= null;
		uMsgvarObj.laststokopname 	= null;
		uMsgvarObj.jmlpakai 		= null;
		uMsgvarObj.jmlpenunjang 	= null;
		uMsgvarObj.feenurse			= null;

		uLoadingObj.grafiksales 	= false;
		uLoadingObj.salesmonth 		= false;
		uLoadingObj.salesrevenue 	= false;
		uLoadingObj.topalertstok 	= false;
		uLoadingObj.topproductsales = false;
		uLoadingObj.laststokopname 	= false;
		uLoadingObj.jmlpakai 		= false;
		uLoadingObj.jmlpenunjang 	= false;
		uLoadingObj.feenurse		= false;
	}
	const initRESgrafiksales = (_OUTPUTOBJ) => {
		//console.log("(Dashboard - initRESgrafiksales) _OUTPUTOBJ : "+_OUTPUTOBJ);// return;
		uLoadingObj.grafiksales	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.grafiksales = JSON.parse(_OUTPUTOBJ.datalist||"[]");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.grafiksales = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initRESgrafiksales) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.grafiksales = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); 
		}
	}
	const initREStopalertstok = (_OUTPUTOBJ) => {
		uLoadingObj.topalertstok	= false;

		//console.log("(Dashboard - initREStopalertstok) _OUTPUTOBJk = >"+JSON.stringify(_OUTPUTOBJ));
		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.topalertstok = JSON.parse(_OUTPUTOBJ.datalist||"[]");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.topalertstok = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initREStopalertstok) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.topalertstok = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); 
		}
	}
	const initREStopproductsales = (_OUTPUTOBJ) => {
		uLoadingObj.topproductsales	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.topproductsales = JSON.parse(_OUTPUTOBJ.datalist||"[]");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.topproductsales = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initREStopproductsales) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.topproductsales = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); 
		}
	}
	const initRESlaststokopname = (_OUTPUTOBJ) => {
		uLoadingObj.laststokopname	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.laststokopname = JSON.parse(_OUTPUTOBJ.datalist||"[]");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.laststokopname = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initRESlaststokopname) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.laststokopname = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); 
		}
	}
	const initRESjmlpenunjang = (_OUTPUTOBJ) => {
		uLoadingObj.jmlpenunjang	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.jmlpenunjang = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.jmlpenunjang = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initRESjmlpenunjang) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.jmlpenunjang = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); 
		}
	}
	const initRESjmlpakai = (_OUTPUTOBJ) => {
		uLoadingObj.jmlpakai	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.jmlpakai = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.jmlpakai = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initRESjmlpakai) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.jmlpakai = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); 
		}
	}
	const initRESfeenurse = (_OUTPUTOBJ) => {
		uLoadingObj.feenurse	= false;

		if(_OUTPUTOBJ.tampil) {
			uDatawidgetObj.feenurse = JSON.parse(_OUTPUTOBJ.datalist||"{}");
		} else if(_OUTPUTOBJ.info) {
			uMsgvarObj.feenurse = _OUTPUTOBJ.info;
		} else if(_OUTPUTOBJ.errors) {
			console.log("(Dashboard - initRESfeenurse) _OUTPUTOBJ.errors : "+_OUTPUTOBJ.errors);
			const vMsg = (pjson.mydefault.environment=="development")
				? _OUTPUTOBJ.errors : pjson.mydefault.msgFetchError;
			uMsgvarObj.feenurse = "<font color='red'>"+vMsg+"</font>";
		} else if(_OUTPUTOBJ.expired) {
			setLoading(true); prosesExpired(); 
		}
	}
	//--END INIT--/

	//--CONTENT--/
	const contentGrafiksales = () => {
		if(uHtml500msg) return (<></>);
		if((uLoadingObj.grafiksales||false)===true) return "";
		if(uMsgvarObj.grafiksales) return ""

		const vDataArr	= uDatawidgetObj.grafiksales || [];

		let vLabelXArr	= [];
		let vValueArr	= [];
		let vRataArr	= [];
		let vSUMTotal	= 0;
		const vGrafikArr= vDataArr;

		vGrafikArr.map((vItems,vKeys)=>{
			vLabelXArr.push((vItems.tanggal))
			vValueArr.push(vItems.nilai)

			vSUMTotal += parseFloat(vItems.nilai||0)
		}).join("");

		const vRata2	= Math.floor(vSUMTotal/(vGrafikArr.length));
		for(var i=0; i<=vGrafikArr.length-1; i++) {
			vRataArr.push(vRata2);
		}

		return (
			<CChart type="line" className="mb-3"
				data={{
					labels: vLabelXArr,
					datasets: [
					{
						label: uBahasaObj.word_sales||"Sales",
						borderColor: getStyle('--cui-primary'),
						backgroundColor: hexToRgba(getStyle('--cui-primary'), 5),
						pointHoverBackgroundColor: getStyle('--cui-primary'),
			        	borderWidth: 2,
			        	fill: true,
						data: vValueArr,						
					},
					{
						label: uBahasaObj.caption_rata || "Rata2",// obBahasa.word_barang||"Barang",
						borderColor: getStyle("--cui-danger"),
						pointHoverBackgroundColor: getStyle("--cui-danger"),
						backgroundColor: getStyle("--cui-danger"),
			        	borderWidth: 1,
			        	fill: false,
						borderDash: [8, 5],
						data: vRataArr,
					}],
				}}
				options={{
					animation : false,
					maintainAspectRatio: true,
					plugins: {
						legend: { display: false },
						tooltip:{
							callbacks: {
								intersect: false,
								mode: 'index',
								title: (tooltipItem) => {
									return UFunc.HariAngka(tooltipItem[0].label);
								},
								label: (tooltipItem) => {									
									//-->tooltipItem.dataset={"label":"Pendapatan","borderColor":"#321fdb","backgroundColor":"rgba(50, 31, 219, 0.05)","pointHoverBackgroundColor":"#321fdb","borderWidth":2,"fill":true,"data":["1250000","3500000","2500000","1500000","4500000","7500000","4500000","2500000","0","6500000","1500000","6500000","3500000"]}
									//console.log("label.tooltipItem = "+JSON.stringify(tooltipItem.dataset));//-*/

									let vlabels = tooltipItem.dataset.label || '';
			                        if (vlabels) vlabels += ': ';
			                        if (tooltipItem.value !== null) {
			                        	vlabels += "Rp"+UFunc.formatAngka(tooltipItem.parsed.y);
			                        }//-*/

									return vlabels;
								},
							},
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
						point: {
							radius: 0,
							hitRadius: 10,
							hoverRadius: 4,
							hoverBorderWidth: 3
						}
					},
					scales: {
						y: {
							ticks : {
								beginAtZero: true,
								maxTicksLimit: 5,
								stepSize: Math.ceil(250 / 5),
								max: 250,
								callback: (label, index, labels) => {
									return UFunc.formatAngka(label/1000000)+'M'
								},
							},
							scaleLabel: {
								display:true,
							},
						},
						x:{
							ticks : {
								callback: (label, index, labels) => {
									//console.log("labels = "+JSON.stringify(labels))
									//console.log("labels = "+labels)
									//return JSON.stringify(labels);//[index];
									//return UFunc.TglAngka(vLabelXArr[index]).substr(0,5)
								},
							},
							grid: { drawOnChartArea: false }
						},
					},
				}}
			id="idgrafiksales"/>
		)//>
	}
	const contentWidgsalesmonth = () => {
		if(uHtml500msg) return ""
		if((uLoadingObj.grafiksales||false)===true) return "";
		if(uMsgvarObj.grafiksales) return ""

		const vDataObj	= uDatawidgetObj.salesmonth || {};

		const vHTMLvaluemonth = UFunc.renderHTML("<span class='text-primary'>"+
			UFunc.formatAngka(vDataObj.value_month/1000000)+"M"+
			"</span>");
		const vHTMLvalueday = UFunc.renderHTML("<span class='text-primary'>"+
			UFunc.formatAngka(vDataObj.value_day/1000)+"K"+
			"</span>");

		const vHTMLcaptionday	= (vDataObj.tgl_last||UFunc.DbDate()) === UFunc.DbDate() ? "Hari Ini"
			: UFunc.renderHTML("Last: <small>"+UFunc.TglAngka(vDataObj.tgl_last)+"</small>");
		return (
			<CWidgetStatsD
    			icon={<CImage className="my-1 my-md-3 text-white" 
    				src={pjson.homepage+"api/images/menus_rekapnota.png"} 
    				height={35}/>
    			}
    			style={{ '--cui-card-cap-bg': '#00000022' }}
    			values={[
    			{ title: "PER BULAN", value: vHTMLvaluemonth },
    			{ title: vHTMLcaptionday, value: vHTMLvalueday },
    			]}
			className=""/>
		)//>
	}
	const contentWidgsalesrevenue = () => {
		if(uHtml500msg) return (<></>);
		if((uLoadingObj.grafiksales||false)===true) return "";
		if(uMsgvarObj.grafiksales) return ""

		const vTglfilter	= vFilterObj.tgl_awal || "";
		const vDataObj		= uDatawidgetObj.salesrevenue || {};
		//console.log("vTglfilter => "+vTglfilter);
		return (
			<CWidgetStatsB
				color="primary"
				inverse
				progress={{ value: vDataObj.progressvalue }}
				value={"Rp"+UFunc.formatAngka(vDataObj.value)}
				text={"Tgl Transaksi: "+(UFunc.HariAngka(vDataObj.caption || vTglfilter))}
				title={"Most Sales Revenue"}
				className="mb-3"/>
		)//>
	}
	const contentWidgtopalertstok = () => {
		if(uHtml500msg) return (<></>);
		if((uLoadingObj.topalertstok||false)===true) return (<>{contentSpinner("danger")}</>)

		const vDataArr	= uDatawidgetObj.topalertstok || [];

		return (
			<CCard className="classcardbg h-100">
			<CCardHeader className="classfontsmaller text-danger d-flex justify-content-between">
				<div>
					<CImage src={pjson.homepage+"api/images/menus_stokurgent.png"} 
						className="me-1 align-bottom" height={25}/>
					<b>Top 5 Alert Stok</b>
				</div>
				<CLink onClick={()=>uNavigate("/substok/stokurgent")} className="classcursorpointer text-danger">
					<CIcon icon="cilArrowRight" height={20}/>
				</CLink>
			</CCardHeader>
			
			<CCardBody>
			{(uMsgvarObj.topalertstok||null) ? (<small>{UFunc.renderHTML(uMsgvarObj.topalertstok)}</small>)
			: (
			<>
			{vDataArr.length <= 0 && (
				<center className="mt-5">
				<CImage src={pjson.homepage+"api/images/icon_dataempty.png"} 
					className="border p-2 rounded-3 bg-light" height={70}/>
				<div className="mt-2 text-muted fst-italic classfontsmaller">
				&middot;&middot; Data Empty &middot;&middot;
				</div>
				</center>
			)}
			<div className="d-none">{JSON.stringify(vDataArr)}</div>
			{vDataArr.map((vItems,vKeys)=>{
				const vStokmin		= parseFloat(vItems.stok_minimal|| 0) < 0 ? 0 : parseFloat(vItems.stok_minimal);
				const vStokinit		= parseFloat(vItems.stok_init||0);
				const vPersentase 	= 
					(parseFloat(vItems.stok_init)/(parseFloat(vItems.stok_init) + vStokmin )) *
					100;

				const vProgresscolor = vStokinit < 0 ? "danger" : "info";

				return (
				<div className="progress-group" key={vKeys}>
				<div className="progress-group-header">
					<small className="fst-italic">{vItems.caption}</small>
					<span className="ms-auto fw-semibold">
						<span className={"text-"+vProgresscolor}>{UFunc.formatAngka(vStokinit)}</span>
						<span className="me-1"/>
						<span className="text-medium-emphasis small">(Min: {vStokmin})</span>
					</span>
				</div>

				<div className="progress-group-bars">
					<CProgress thin color={vProgresscolor} variant="striped" value={vPersentase}/>
				</div>
				</div>
				)
			})}
			</>
			)}
			</CCardBody>
			</CCard>
		)//>
	}
	const contentWidgtopproductsales = () => {
		if(uHtml500msg) return ""
		if((uLoadingObj.topproductsales||false)===true) return contentSpinner("primary")

		const vDataArr	= uDatawidgetObj.topproductsales || [];

		return (
			<CCard className="classcardbg h-100">
			<CCardHeader className="classfontsmaller text-primary d-flex justify-content-between">
				<div>
					<CImage src={pjson.homepage+"api/images/menus_rekapnota.png"} 
						className="me-1 align-bottom" height={25}/>
					<b>Top 5 Product Sales</b>
				</div>
				<CLink onClick={()=>uNavigate("/subbilling/billingtransaksi")} 
					className="classcursorpointer text-primary">
					<CIcon icon="cilArrowRight" height={20}/>
				</CLink>
			</CCardHeader>

			<CCardBody>
			{(uMsgvarObj.topproductsales||null) ? (<small>{UFunc.renderHTML(uMsgvarObj.topproductsales)}</small>)
			: (
			<>
				{vDataArr.length <= 0 && (
				<center className="mt-5">
				<CImage src={pjson.homepage+"api/images/icon_dataempty.png"} 
					className="border p-2 rounded-3 bg-light" height={70}/>
				<div className="mt-2 text-muted fst-italic classfontsmaller">
				&middot;&middot; Data Empty &middot;&middot;
				</div>
				</center>
				)}

				{vDataArr.map((vItems,vKeys)=>{
				const vNilai = (vItems.nilai/1000);
				return (
				<div className="progress-group" key={vKeys}>
				<div className="progress-group-header">
					<CIcon className="me-2" icon="cibCodesandbox" size="lg"/>
					<span>{vItems.caption}</span>
					<span className="ms-auto fw-semibold">
						<span className="text-primary">{UFunc.formatAngka(vNilai)}K</span>
						<span className="me-1"/>
						<span className="text-medium-emphasis small">({UFunc.formatAngka(vItems.persen)}%)</span>
					</span>
				</div>

				<div className="progress-group-bars">
					<CProgress thin color="primary" striped={true} value={vItems.persen} />
				</div>
				</div>
				)
				})}
			</>
			)}
			</CCardBody>
			</CCard>
		)//>
	}
	const contentWidglaststokopname = () => {
		if(uHtml500msg) return ""
		if((uLoadingObj.laststokopname||false)===true) return contentSpinner("success");

		const vDataArr	= uDatawidgetObj.laststokopname || [];

		return (
			<CCard className="classcardbg h-100">
			<CCardHeader className="classfontsmaller text-success d-flex justify-content-between">
				<div>
					<CImage src={pjson.homepage+"api/images/menus_stokopname.png"} 
						className="me-1 align-bottom" height={25}/>
					<b>Last 5 Stok Opname</b>
				</div>
				<CLink onClick={()=>uNavigate("/substok/stokopname")} 
					className="classcursorpointer text-success">
					<CIcon icon="cilArrowRight" height={20}/>
				</CLink>
			</CCardHeader>
			
			<CCardBody>
			{(uMsgvarObj.laststokopname||null) ? (<small>{UFunc.renderHTML(uMsgvarObj.laststokopname)}</small>)
			: (
			<>
				{vDataArr.length <= 0 && (
				<center className="mt-5">
				<CImage src={pjson.homepage+"api/images/icon_dataempty.png"} 
					className="border p-2 rounded-3 bg-light" height={70}/>
				<div className="mt-2 text-muted fst-italic classfontsmaller">
				&middot;&middot; Data Empty &middot;&middot;
				</div>
				</center>
				)}

				{vDataArr.map((vItems,vKeys)=>{
				return (
				<CRow className="mx-0 py-2 border-bottom border-bottom-success" key={vKeys}>
				<CCol xs="8">{UFunc.HariAngka(vItems.tgl_opname)}</CCol>
				<CCol xs="4" className="text-end text-success"><b>{vItems.jml_items}</b> Items</CCol>
				</CRow>
				)
				})}
			</>
			)}
			</CCardBody>
			</CCard>
		)//>
	}
	const contentWidgjmlpenunjang = () => {
		if(uHtml500msg) return "";
		if((uLoadingObj.jmlpenunjang||false)===true) return contentSpinner("success");
		if(uMsgvarObj.jmlpenunjang) return (
			<div className="border rounded-3 p-3 h-100 classfontsmaller">{UFunc.renderHTML(uMsgvarObj.jmlpenunjang)}</div>
		)//>
		const vDataObj	= uDatawidgetObj.jmlpenunjang || {};

		return (
		<CWidgetStatsD
			icon=
				{
				<div className="text-center my-2">
				<CImage src={pjson.homepage+"api/images/menus_billingtransaksi.png"} 
					className="" 
					height={52}/>
				<div className="fst-italic text-white">
					<small><span className="d-md-none d-lg-inline">Barang</span> PENUNJANG Terbanyak</small>
				</div>
				</div>
				}
			values={[
				{ title: vDataObj.caption||UFunc.renderHTML("&middot; Empty &middot;"), value: UFunc.formatAngka(vDataObj.nilai)+" PCS" },
			]}
			color="success"
			className="h-100 classcardbg"/>
		)//>
	}
	const contentWidgjmlpakai = () => {
		if(uHtml500msg) return ""
		if((uLoadingObj.jmlpakai||false)===true) return contentSpinner("warning")
		if(uMsgvarObj.jmlpakai) return (
			<div className="border rounded-3 h-100 p-3 classfontsmaller">{UFunc.renderHTML(uMsgvarObj.jmlpakai)}</div>
		)//>
		const vDataObj	= uDatawidgetObj.jmlpakai || {};

		return (
		<CWidgetStatsD
			icon=
				{
				<div className="text-center my-2">
				<CImage src={pjson.homepage+"api/images/menus_pakaiklinik.png"} 
					className="" 
					height={52}/>
				<div className="fst-italic text-dark">
					<small><span className="d-md-none d-lg-inline">Barang</span> PAKAI KLINIK Terbanyak</small>
				</div>
				</div>
				}
			color="warning"
			values={[
				{ title: vDataObj.caption||UFunc.renderHTML("&middot; Empty &middot;"), value: (UFunc.formatAngka(vDataObj.nilai))+" PCS" },
			]}
			className="h-100 classcardbg"/>
		)
	}
	const contentWidgfeenurse = () => {
		if(uHtml500msg) return ""
		if((uLoadingObj.jmlpakai||false)===true) return contentSpinner("info")
		if(uMsgvarObj.feenurse) return (
			<div className="border rounded-3 p-3 h-100 classfontsmaller">{UFunc.renderHTML(uMsgvarObj.feenurse)}</div>
		)//>

		const vDataObj	= uDatawidgetObj.feenurse || {};

		return (
		<CWidgetStatsD
			icon=
				{
				<div className="text-center my-2">
				<CImage src={pjson.homepage+"api/images/menus_lapfeenurse.png"} 
					className="" 
					height={52}/>
				<div className="fst-italic text-white"><small>Fee Nurse Tertinggi</small></div>
				</div>
				}
			values={[
				{ title: vDataObj.caption||UFunc.renderHTML("&middot; Empty &middot;"), value: UFunc.formatAngka(vDataObj.nilai/1000)+"K" },
			]}
			color="info"
			className="h-100 classcardbg"/>
		)
	}
	const contentSpinner = (_COLOR) => {
		_COLOR = _COLOR || "info";

		return (
			<div className="text-center p-5 mb-3 border rounded-3 h-100">
			<CSpinner color={_COLOR} className="mx-auto my-3"/>
			</div>
		)//>
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoaddata = async() => {
		const vElcabang	= document.getElementById("inpcabangid");
		const vElperiode= document.getElementById("inptglawal");

		initResetdata();
		uLoadingObj.grafiksales = true;
		uLoadingObj.salesmonth = true;
		uLoadingObj.salesrevenue = true;
		uLoadingObj.topalertstok = true;
		uLoadingObj.topproductsales = true;
		uLoadingObj.laststokopname = true;
		uLoadingObj.jmlpakai = true;
		uLoadingObj.jmlpenunjang = true;
		uLoadingObj.feenurse = true;

		vElcabang && (vElcabang.disabled = true)
		vElperiode && (vElperiode.disabled = true)
		setEfekview(!uEfekview);

		/*//--TESTING_LOADING--/
		console.log("(Dashboard - apiLoaddata) "+new Date())
		console.log("(Dashboard - apiLoaddata) uLoadingObj = "+JSON.stringify(uLoadingObj))
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},3000); return; 
		//--END TESTING_LOADING--*/

		const vURLs		= pjson.homepage+"api/api_dashboard/";
		const vEmptyStr	= JSON.stringify({
			id_cabang: localStorage.getItem("cabangidselect")||uDatacabangArr[0].id,
			tgl_awal: UFunc.DbDate(),
		});
		const vJSONsend	= (uKeywordObj.filter_dashboard||vEmptyStr);
		const vDATAS    = JSON.stringify({
			send_parsjson: vJSONsend,
			send_tokenauth : uTokenObj.userinit
		});
		const vHeader	= {
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}

		setSessionaktif(false);
		try {
			const [
				resgrafiksales, 
				restopalertstok,
				restopproductsales,
				reslaststokopname,
				resjmlpenunjang,
				resjmlpakai,
				resfeenurse,
			] = await Promise.all([
				fetch(vURLs+"ld_grafiksales",vHeader),
				fetch(vURLs+"ld_topalertstok",vHeader),
				fetch(vURLs+"ld_topproductsales",vHeader),
				fetch(vURLs+"ld_stokopname",vHeader),
				fetch(vURLs+"ld_jmlpenunjang",vHeader),
				fetch(vURLs+"ld_jmlpakai",vHeader),
				fetch(vURLs+"ld_feenurse",vHeader),
			])
			//.then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }});
			setSessionaktif(true);

			const output_grafiksales = await resgrafiksales.json();
			await initRESgrafiksales(output_grafiksales);
			const output_topalertstok = await restopalertstok.json();
			await initREStopalertstok(output_topalertstok);
			const output_topproductsales = await restopproductsales.json();
			await initREStopproductsales(output_topproductsales);
			const output_laststokopname = await reslaststokopname.json();
			await initRESlaststokopname(output_laststokopname);
			const output_jmlpenunjang = await resjmlpenunjang.json();
			await initRESjmlpenunjang(output_jmlpenunjang);
			const output_jmlpakai = await resjmlpakai.json();
			await initRESjmlpakai(output_jmlpakai);
			const output_feenurse = await resfeenurse.json();
			initRESfeenurse(output_feenurse);

			setEfekview2(!uEfekview2);

			vElcabang && (vElcabang.disabled = false)
			vElperiode && (vElperiode.disabled = false)
		} catch(error) {
			vElcabang && (vElcabang.disabled = false)
			vElperiode && (vElperiode.disabled = false)
			console.log("(Dashboard - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		}
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			initResetdata();
			uKeywordObj.filter_dashboard = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
    	}
	},[])
	React.useEffect(()=>{
		if(!uHandelView) return;
		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		const vDataArr 		= uDatawidgetObj.grafiksales || [];
		if(vDataArr.length <= 0) {
			uDatawidgetObj.salesmonth = {};
			uDatawidgetObj.salesrevenue = {};
			return
		}
		const vNilaiBulan 	= (vDataArr||[]).reduce((vSums,vItems)=>{
			return vSums + (parseFloat(vItems.nilai)||0)
		},0);

		const vDatasort1Arr	= [{x:"x"},...vDataArr];
		vDatasort1Arr.sort((a, b) => 
			(b.tanggal > a.tanggal) ? 1 : ((a.tanggal > b.tanggal) ? -1 : 0) );
		vDatasort1Arr.splice(0,1);

		const vDatasort2Arr	= [{x:"x"},...vDataArr];
		vDatasort2Arr.sort((a, b) => b.nilai - a.nilai );
		vDatasort2Arr.splice(0,1);

		uDatawidgetObj.salesmonth = {
			tgl_last:vDatasort1Arr.length > 0 ? vDatasort1Arr[0].tanggal : null,
			value_day:vDatasort1Arr.length > 0 ? vDatasort1Arr[0].nilai||0 : 0,
			value_month:vNilaiBulan
		}

		//console.log("(Dashboard - [grafiksales]) vTglfilter => "+(vTglfilter));
		const vProgressvalue	= vDatasort2Arr.length <= 0 ? 0
			: ((parseFloat(vDatasort2Arr[0].nilai)/vNilaiBulan)*100);
		uDatawidgetObj.salesrevenue = {
			progressvalue: vProgressvalue,
			value:vDatasort2Arr.length > 0 ? vDatasort2Arr[0].nilai||0 : 0,
			caption:vDatasort2Arr.length > 0 ? vDatasort2Arr[0].tanggal : null,
		}
		setEfekview(!uEfekview);
	},[uDatawidgetObj,uDatawidgetObj.grafiksales])

	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }
	if(uHtml500msg)  return ( <MyPage500 isWithrefresh={true} hdlReset={apiLoaddata} content={uHtml500msg}/> )//>

	return (
		<>
		<CCard className="classcardbg mb-3">
		<CCardHeader>Sales Overview</CCardHeader>
		<CCardBody>
		{(uLoadingObj.grafiksales||false)===true ? (
			<center><CSpinner color="primary" className="my-5"/></center>
		) : (
		<>
			{(uMsgvarObj.grafiksales||"")!=="" ? UFunc.renderHTML(uMsgvarObj.grafiksales) : (
			<CRow className="align-item-stretch">
			<CCol md="7" lg="8">{contentGrafiksales()}</CCol>
			<CCol md="5" lg="4">
				<div className="my-3 mt-md-0">{contentWidgsalesmonth()}</div>
				<div>{contentWidgsalesrevenue()}</div>
			</CCol>
			</CRow>
			)}
		</>
		)}
		</CCardBody>
		</CCard>

		<CRow className="align-item-stretch">
		<CCol md="4" className="mb-3">{contentWidgtopalertstok()}</CCol>
		<CCol md="4" className="mb-3">{contentWidgtopproductsales()}</CCol>
		<CCol md="4" className="mb-3">{contentWidglaststokopname()}</CCol>
		</CRow>

		<CRow className="align-item-stretch">
		<CCol md="4" className="mb-3">{contentWidgjmlpenunjang()}</CCol>
		<CCol md="4" className="mb-3">{contentWidgjmlpakai()}</CCol>
		<CCol md="4" className="mb-3">{contentWidgfeenurse()}</CCol>
		</CRow>
		</>
	)//>
}	

export default Dashboard